import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import apiEndpoints from 'api/endpoints'
import { FilterName, FilterType } from 'core/types'
import { ALL_SELECTED, prepareOrderPaymentMethodsList, prepareOrderStatusList } from 'core/utils'

export type FiltersState = {
  [FilterName.ALL_DELIVERY_SITES]: string
  [FilterName.ALL_VENDORS]: string
  [FilterName.ORDER_STATUS]: string
  [FilterName.PAYMENT_METHODS]: string
}
export const filtersStateInit = {
  [FilterName.ALL_DELIVERY_SITES]: ALL_SELECTED,
  [FilterName.ALL_VENDORS]: ALL_SELECTED,
  [FilterName.ORDER_STATUS]: ALL_SELECTED,
  [FilterName.PAYMENT_METHODS]: ALL_SELECTED,
}

export const useOrdersFilters = () => {
  const { t } = useTranslation()

  const filters = useMemo(
    () => [
      {
        filterName: FilterName.ALL_DELIVERY_SITES,
        api: apiEndpoints.getAllDeliverySites({ Page: 1, PageSize: 40, Sorts: 'name' }),
        filterType: FilterType.SELECT,
      },
      {
        filterName: FilterName.ALL_VENDORS,
        api: apiEndpoints.getAllVendors({ Page: 1, PageSize: 40 }),
        filterType: FilterType.SELECT,
      },
      {
        filterName: FilterName.ORDER_STATUS,
        data: prepareOrderStatusList(t),
        filterType: FilterType.SELECT_CHIP,
      },
      {
        filterName: FilterName.PAYMENT_METHODS,
        data: prepareOrderPaymentMethodsList(t),
        filterType: FilterType.SELECT,
      },
    ],
    [t],
  )

  return {
    filters,
  }
}
