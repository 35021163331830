import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Close } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { ReactComponent as DotykackaLogo } from 'core/assets/dotykacka-logo.svg'
import { LinearProgressBar, MainPaper, MainTable, PaperTitle } from 'core/components'
import { useDotykackaIntegrationUrl } from 'core/hooks/api'
import { DeliverySite } from 'core/types'

import { cellStyles, mapDeliverySitesColumns, mapDeliverySitesData } from './mapDeliverySitesTable'

interface DeliverySitesTableProps {
  deliverySites: DeliverySite[]
  onAddButtonClicked: () => void
  onEdit: (site: DeliverySite) => void
  onDelete: (site: DeliverySite) => void
}

enum PosType {
  DOTYKACKA,
}

type SelectedDeliverySite = {
  deliverySiteId: number
  type: PosType
}

const DeliverySitesTable: React.FC<DeliverySitesTableProps> = ({ deliverySites, onAddButtonClicked, onEdit, onDelete }) => {
  const { t } = useTranslation()

  const [openRedirectionDialog, setOpenRedirectionDialog] = useState(false)

  const [selectedDeliverySite, setSelectedDeliverySite] = useState<SelectedDeliverySite | null>(null)

  const { integrationUrlData, integrationUrlIsError } = useDotykackaIntegrationUrl(selectedDeliverySite?.deliverySiteId ?? null)

  const handleGetDotykackaIntegrationUrl = (deliverySiteId: number) => {
    setSelectedDeliverySite({
      deliverySiteId,
      type: PosType.DOTYKACKA,
    })
    setOpenRedirectionDialog(true)
  }

  const handleRedirectToPos = () => {
    integrationUrlData && window.location.replace(integrationUrlData?.redirectUrl)
  }

  return (
    <>
      <MainPaper>
        <PaperTitle
          title={t('deliverySites.title')}
          rightEl={
            <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClicked}>
              {t('deliverySites.add')}
            </Button>
          }
        />
        <MainTable
          columns={mapDeliverySitesColumns(t)}
          rows={mapDeliverySitesData(deliverySites, t, {
            handleEdit: onEdit,
            handleDelete: onDelete,
            handleGetDotykackaIntegrationUrl,
          })}
          cellStyles={cellStyles}
        />
      </MainPaper>

      {/* redirect to pos confirmation */}
      {openRedirectionDialog && (
        <Dialog open={openRedirectionDialog} fullWidth maxWidth="sm">
          <DialogTitle>
            {t('pos.redirectionTo')} <DotykackaLogo height={42} />
          </DialogTitle>
          <DialogContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {integrationUrlIsError ? (
              <Typography color="error" textAlign="center" whiteSpace="pre-line">
                {t('common.errors.dotykackaIntegrationUrl')}
              </Typography>
            ) : (
              <>
                <Typography mb={4}>{t('pos.youWillBeRedirectedToDotykacka')}</Typography>
                <LinearProgressBar timerValue={200} onTimeIsOver={handleRedirectToPos} />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenRedirectionDialog(false)} variant="outlined" startIcon={<Close />}>
              {t('common.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default DeliverySitesTable
