import { SxProps, Theme } from '@mui/material'

export const commonInputStyle: SxProps<Theme> = {
  m: { xs: 0.6, sm: 1 },
  width: '4rem',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
    p: 1.375,
  },
}
