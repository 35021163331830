import { Link as RouterLink } from 'react-router-dom'

import { Chip, Link, Tooltip } from '@mui/material'
import { OrderItem } from 'core/types'
import { statusDisplayProperties } from 'core/types/statusDisplayProperties'
import { formatPrice } from 'core/utils'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

const formatDateAndTime = (dateString: string | null, time?: boolean): string => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  return `${date.toLocaleDateString()} ${time ? dayjs(date).format('HH:mm') : ''}`
}

export const mapUserOrdersData = (items: OrderItem[], t: TFunction) => {
  const mappedData = items.map(el => {
    const { color, messageKey } = statusDisplayProperties[el.status] || { color: 'black', messageKey: 'order.status.unknown' }

    return {
      rowId: el.id,
      data: {
        friendlyOrderNumber: (
          <Link component={RouterLink} underline="hover" to={`/my-orders/${el.id}`}>
            {el.friendlyOrderNumber}
          </Link>
        ),
        deliverySiteName: el.deliverySiteName,
        counterparty: el.counterparty,
        date: (
          <Tooltip title={formatDateAndTime(el.orderDate, true)}>
            <span>{formatDateAndTime(el.orderDate)}</span>
          </Tooltip>
        ),
        sum: formatPrice(el.totalCost),
        status: <Chip label={t(messageKey)} sx={{ bgcolor: color, color: 'common.white' }} />,
      },
    }
  })

  return mappedData
}

export const mapUserOrdersColumns = (t: TFunction) => [
  { title: t('userOrders.order'), id: 'friendlyOrderNumber' },
  { title: t('userOrders.restaurant'), id: 'deliverySiteName' },
  { title: t('finanse.vendor'), id: 'counterparty' },
  { title: t('userOrders.orderDate'), id: 'orderDate' },
  { title: t('userOrders.sum'), id: 'sum' },
  { title: t('userOrders.status'), id: 'status' },
]

export const ordersCellStyles = [
  {
    id: 'deliverySiteName',
    styles: {
      fontWeight: 700,
      color: 'primary.main',
    },
  },
]
