import { Link as RouterLink } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Link, Tooltip, Typography } from '@mui/material'
import { useAuth } from 'auth/contexts/AuthProvider'
import { NotificationEvent } from 'core/types'
import dayjs from 'dayjs'
import { t } from 'i18next'

import { getNotificationIcon, getOrderPath } from './helpers/orderPathFinder'
import { notificationStyle, notificationUnreadStyle } from './styles'

export interface SingleNotificationProps extends NotificationEvent {
  onRead: (id: string) => void
  onClose: () => void
}

const SingleNotification: React.FC<SingleNotificationProps> = ({
  id,
  timestamp,
  title,
  orderId,
  read,
  onClose,
  content,
  variant,
  link,
  onRead,
}: SingleNotificationProps) => {
  const { userInfo } = useAuth()
  const roles = userInfo?.roles || []

  const orderPath = getOrderPath(roles)
  return (
    <Box sx={[notificationStyle, !read && notificationUnreadStyle]}>
      {getNotificationIcon(variant)}
      <Box>
        <Typography variant="h5" fontWeight={700} mb={1.5} mr={8}>
          {title}
        </Typography>
        {!read && (
          <Button
            onClick={() => onRead(id)}
            sx={{
              position: 'absolute',
              right: '0.5rem',
              top: '0.5rem',
            }}
          >
            <CloseIcon />
          </Button>
        )}
        {content && (
          <Typography variant="body1" mb={1.5}>
            {content}
          </Typography>
        )}
        {orderId && (
          <Link
            to={`/${orderPath}/${orderId}`}
            onClick={() => {
              onClose()
              onRead(id)
            }}
            underline="hover"
            component={RouterLink}
          >
            {t('orders.seeDetails')}
          </Link>
        )}
        {link && (
          <Link
            to={link.to}
            onClick={() => {
              onClose()
              onRead(id)
            }}
            underline="hover"
            component={RouterLink}
          >
            {link.title}
          </Link>
        )}
        <Box>
          <Tooltip title={dayjs(timestamp).format('DD/MM/YYYY hh:mm')}>
            <Typography color="grey.500" mt={1.5} display="inline-flex">
              {dayjs(timestamp).fromNow()}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}

export default SingleNotification
