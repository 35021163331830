import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { GetParams, PaginatedResult } from 'core/types'
import { DotykackaPosProducts } from 'management/types'

interface WarehouseParams extends GetParams {
  from?: Date
  to?: Date
  deliverySiteId?: number | null
}

export const useGetDotykackaPosProducts = (params: WarehouseParams) => {
  const { data, ...other } = useRequest<PaginatedResult<DotykackaPosProducts>>(apiEndpoints.getDotykackaPosProducts(params))
  return { dotykackaWarehouse: data, ...other }
}
