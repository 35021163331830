import apiEndpoints from 'api/endpoints'
import { FileType } from 'core/consts'
import { useRequest } from 'core/hooks'

export const useDownloadInvoice = (type: FileType, clientId: string, id: string) => {
  const { data, ...other } = useRequest<string>(apiEndpoints.downloadInvoice(type, clientId, id), {
    enabled: !!id,
    retry: 0,
  })
  return { downloadInvoiceData: data, ...other }
}
