import { createTheme as createMuiTheme } from '@mui/material'
import shadows, { Shadows } from '@mui/material/styles/shadows'

import { createThemeComponents } from './components'
import mixins from './mixins'
import { darkPalette, lightPalette } from './palette'
import shape from './shape'
import transitions from './transitions'
import typography from './typography'

const customShadows = [
  'none',
  '0 1px 4px 0 rgba(56, 65, 74, 0.1)',
  '0 2px 4px 0 rgba(56, 65, 74, 0.1)',
  '0 2px 6px -2px rgba(56, 65, 74, 0.16),0 2px 6px 0 rgba(56, 65, 74, 0.12)',
  '0px 3px 10px 0px rgba(56, 65, 74, 0.16)',
  '0px 3px 10px 0px rgba(56, 65, 74, 0.2)',
  '0px 8px 20px 0px rgba(56, 65, 74, 0.2)',
].concat(shadows.slice(-19)) as Shadows

export const createTheme = (direction: 'ltr' | 'rtl', mode: 'dark' | 'light') => {
  const palette = mode === 'dark' ? darkPalette : lightPalette

  // Create base theme
  const baseTheme = createMuiTheme({
    direction,
    mixins,
    palette,
    shape,
    transitions,
    typography,
    shadows: customShadows,
  })

  // Inject base theme to be used in components
  return createMuiTheme(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      components: createThemeComponents(baseTheme),
    },
    baseTheme,
  )
}
