import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import LogoAddAll from '../images/addAll.png'
import APILogo from '../images/apiMarket.jpg'
import ChefsCulinareLogo from '../images/chefsCulinare.jpg'
import MaracanaLogo from '../images/maracana.png'
import Marco2Logo from '../images/marco2.jpg'
import ZaczykLogo from '../images/zaczyk.jpg'

const meetings = [
  {
    id: '1',
    person: 'ADD ALL sp. z o.o.',
    date: 'Piątek 19.01, 8:00 - 10:00',
    image: LogoAddAll,
  },
  {
    id: '2',
    person: 'Maracana',
    date: 'Piątek 19.01, 11:00 - 12:00',
    image: MaracanaLogo,
  },
  {
    id: '3',
    person: 'API Market',
    date: 'Piątek 19.01, 10:00 - 12:00',
    image: APILogo,
  },
  {
    id: '4',
    person: 'Chefs Culinar',
    date: 'Piątek 19.01, 10:00 - 14:00',
    image: ChefsCulinareLogo,
  },
  {
    id: '5',
    person: 'Marco 2',
    date: 'Sobota 20.01, 10:00 - 11:30',
    image: Marco2Logo,
  },
  {
    id: '6',
    person: 'Zaczyk',
    date: 'Sobota 20.01, 11:00 - 13:00',
    image: ZaczykLogo,
  },
]

const MeetingWidgets = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Typography component="h2" marginBottom={3} variant="h4">
        {t('admin.home.meeting.title')}
      </Typography>
      {meetings.map(meeting => (
        <Card key={meeting.id} sx={{ mb: 2 }}>
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={`${meeting.person} avatar`} src={meeting.image} sx={{ mr: 2, padding: '5px', backgroundColor: 'white' }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography component="div" variant="h6">
                {meeting.person}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="div">
                {meeting.date}
              </Typography>
            </Box>
            <IconButton aria-label="Go to details" component={RouterLink} to="/">
              <ChevronRightIcon />
            </IconButton>
          </CardContent>
        </Card>
      ))}
    </React.Fragment>
  )
}

export default MeetingWidgets
