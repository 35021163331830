/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RadioButtonChecked, RadioButtonUnchecked, Search } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, FormHelperText, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { AvatarImg, DailyPrice, LabelWithIcon, ProductAndVendorName, TextField } from 'core/components'
import { FilterName, Item } from 'core/types'
import { formatPricePerUnit } from 'core/utils'
import noImage from 'items/images/noImage.png'
import { debounce } from 'lodash'
import { useCatalog } from 'management/hooks'

interface ProductSearchProps {
  vendorId: string
  onSelectProduct: (el: Item | null) => void
  categorySelected?: boolean
}

export const ProductSearch = ({ vendorId, onSelectProduct, categorySelected }: ProductSearchProps) => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(0)
  const pageSize = 10
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<Item | null>(null)

  const { data: paginatedProducts, isFetching } = useCatalog(
    {
      Filters: `search@=*${searchValue}`,
      VendorId: vendorId,
      Sorts: 'name',
      Page: currentPage + 1,
      PageSize: pageSize,
    },
    {
      queryKey: [searchValue, vendorId, currentPage, pageSize],
      disabled: !vendorId || !searchValue || categorySelected,
    },
  )

  const [productsList, setProductsList] = useState<Item[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setSearchValue(searchTerm)
    }, 500),
    [],
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)
    debouncedSearch(newSearchTerm)
    setCurrentPage(0)
  }

  useEffect(() => {
    debouncedSearch(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    setProductsList(currentPage === 0 ? paginatedProducts?.items ?? [] : [...productsList, ...(paginatedProducts?.items ?? [])])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedProducts, currentPage])

  useEffect(() => {
    if (vendorId || categorySelected) {
      setSelectedProduct(null)
      setProductsList([])
      setSearchTerm('')
    }
  }, [vendorId, categorySelected])

  const handleSelectProduct = (product: Item) => () => {
    setSelectedProduct(prev => (prev && prev.id === product.id ? null : product))
  }
  const handleLoadMore = () => {
    setCurrentPage(prev => prev + 1)
  }

  useEffect(() => {
    onSelectProduct(selectedProduct)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box>
        <TextField
          fullWidth
          name={FilterName.ALL_PRODUCT_CATEGORIES}
          label={<LabelWithIcon label={t('orders.productName')} icon={Search} />}
          value={searchTerm}
          placeholder={t('common.action.searchProduct')}
          onChange={handleSearchChange}
          sx={{ width: { xs: '100%' }, bgcolor: 'background.paper' }}
          disabled={categorySelected}
        />
        {categorySelected && <FormHelperText>{t('platformManagement.categorySelected')}</FormHelperText>}
      </Box>
      <List sx={{ mt: 2, mb: 1, width: '100%', maxHeight: 300, overflowY: 'auto' }}>
        {productsList.map(el => {
          return (
            <ListItem key={el.id} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={handleSelectProduct(el)}
                dense
                sx={{ bgcolor: selectedProduct?.id === el.id ? 'background.paper' : 'inherit' }}
              >
                <ListItemIcon>{selectedProduct?.id === el.id ? <RadioButtonChecked /> : <RadioButtonUnchecked />}</ListItemIcon>
                <AvatarImg
                  imgUrl={el.images && el.images.length > 0 ? el.images[0].uri : null}
                  img={noImage}
                  name={el.name}
                  sx={{ mr: 2 }}
                />
                <ListItemText
                  id={`productsList-label-${el.id}`}
                  primary={
                    <Box display="flex" justifyContent="space-between" gap={1}>
                      <ProductAndVendorName productName={el.name} vendorLogoUrl={el.vendorLogoUrl} vendorName={el.vendorName} />
                      <Typography variant="body2">{el.isDailyPrice ? <DailyPrice /> : formatPricePerUnit(el.price, el.unit)}</Typography>
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          )
        })}
        {paginatedProducts?.totalCount === 0 && <Typography>{t('filters.noDataFound')}</Typography>}
      </List>
      {productsList.length > 0 && productsList.length < Number(paginatedProducts?.totalCount) && (
        <LoadingButton loading={isFetching} onClick={handleLoadMore}>
          {t('common.action.loadMore')}
        </LoadingButton>
      )}
    </Box>
  )
}
