import { SxProps, Theme, lighten } from '@mui/material'

export const cardStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  p: 2,
  border: 1,
  borderColor: 'grey.300',
  bgcolor: 'background.paper',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-0.1rem)',
    transition: 'all 0.3s',
    boxShadow: 2,
  },
}

export const iconStyles = (color: string, mode: string): SxProps<Theme> => ({
  p: 2,
  bgcolor: lighten(color, mode === 'dark' ? 0.75 : 0.9),
  color: color,
  borderRadius: 0.5,
  display: 'flex',
  alignItems: 'center',
})
