import { SxProps, Theme } from '@mui/material'

export const commonInputStyle: SxProps<Theme> = {
  width: '100%',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  borderRadius: 1,
  bgcolor: 'background.paper',
}

export const phoneAdornmentStyle: SxProps<Theme> = {
  color: 'text.secondary',
  px: 0.8,
  borderRight: '1px solid',
  borderRightColor: 'grey.300',
}

export const boxPhoneStyles: SxProps<Theme> = {
  '& .MuiInputBase-adornedStart': {
    bgcolor: 'grey.100',
  },
}
