import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MainPaper, MainTable, NotReadyWrapper } from 'core/components'
import { PaymentMethods } from 'items/types/types'
import { useGetPaymentMethodsLimit } from 'management/hooks'

import EditPaymentMethodsLimitsDialog from './EditPaymentMethodsLimits'
import { mapPaymentMethodsLimitsColumns, mapPaymentMethodsLimitsData } from './mapPaymentMethodsLimits'

const PaymentsManagement: React.FC = () => {
  const { t } = useTranslation()
  const { paymentMethodsLimits, refetch, isLoading, isError, isSuccess } = useGetPaymentMethodsLimit()

  const [selectedPayment, setSelectedPayment] = useState<PaymentMethods | null>(null)

  const handleOpenEditDialog = (payment: PaymentMethods) => {
    setSelectedPayment(payment)
  }

  const handleCloseEditDialog = () => {
    setSelectedPayment(null)
  }

  return (
    <NotReadyWrapper isLoading={isLoading} isSuccess={isSuccess} isError={isError} errorMsg={t('payment.fetchingPaymentLimitsError')}>
      <MainPaper>
        {paymentMethodsLimits && (
          <MainTable
            columns={mapPaymentMethodsLimitsColumns(t)}
            rows={mapPaymentMethodsLimitsData(paymentMethodsLimits, t, { handleEdit: handleOpenEditDialog })}
          />
        )}
        {selectedPayment && (
          <EditPaymentMethodsLimitsDialog handleClose={handleCloseEditDialog} paymentData={selectedPayment} refetchData={refetch} />
        )}
      </MainPaper>
    </NotReadyWrapper>
  )
}

export default PaymentsManagement
