import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Box, SelectChangeEvent, Typography } from '@mui/material'
import { MainPaper, MainTable, PageTitle, SearchComponent, Select, StyledSearchContainer } from 'core/components'
import { FileType } from 'core/consts'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { Client, InvoiceList } from 'core/types'
import { mapInvoicesList, mapInvoicesListColumns, invoicesListSortOptions as sortOptions } from 'invoices/helpers'
import { useDownloadInvoice, useGetInvoicesList } from 'invoices/hooks'

interface InvoicesListProps {
  clients: Client[]
}

export const ClientInvoicesList = ({ clients }: InvoicesListProps) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sort, setSort] = useState('-issueDate')
  const [searchTerm, setSearchTerm] = useState('')
  const [invoiceId, setInvoiceId] = useState('')
  const [fileType, setFileType] = useState<FileType>(FileType.DEFAULT)
  const [selectedClient, setSelectedClient] = useState<string>(clients.length > 1 ? '' : clients[0].id)
  const {
    invoicesList,
    refetch: refetchInvoicesList,
    isFetching,
  } = useGetInvoicesList({
    clientId: selectedClient,
    Page: currentPage + 1,
    PageSize: pageSize,
    Sorts: sort,
    Filters: searchTerm,
  })
  const { downloadInvoiceData, isError: downloadInvoiceError } = useDownloadInvoice(fileType, selectedClient, invoiceId)

  const handlePageChange = useCallback((newPage: number) => {
    setCurrentPage(newPage)
  }, [])

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize)
  }, [])

  const handleChangeClient = (e: SelectChangeEvent<any>) => {
    setSelectedClient(e.target.value)
  }

  const handleSearch = useCallback((search: string) => {
    setSearchTerm(`InvoiceNumber@=*${search}`)
    setCurrentPage(0)
  }, [])

  useEffect(() => {
    if (downloadInvoiceError) {
      snackbar.error(t('invoices.downloadInvoiceError'))
      setInvoiceId('')
      setFileType(FileType.DEFAULT)
    } else if (downloadInvoiceData) {
      const file = new Blob([downloadInvoiceData], { type: fileType === FileType.PDF ? 'application/pdf' : 'text/csv' })
      const fileURL = URL.createObjectURL(file)

      const downloadLink = document.createElement('a')
      downloadLink.href = fileURL
      downloadLink.download = fileType === FileType.PDF ? 'faktura.pdf' : 'plik.csv'
      downloadLink.click()
      setInvoiceId('')
      setFileType(FileType.DEFAULT)
      refetchInvoicesList()
      snackbar.success(t('invoices.downloadInvoiceSuccess'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadInvoiceData, downloadInvoiceError])

  const handleDownloadInvoice = (invoice: InvoiceList, fileType: FileType) => {
    setInvoiceId(invoice.id)
    setFileType(fileType)
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <PageTitle
          title={t('finanse.reportFor')}
          subtitle={
            clients.length > 1 ? (
              <Select
                label={t('clients.companyName')}
                name="client"
                value={selectedClient}
                onChange={handleChangeClient}
                options={clients}
                disabled={clients.length === 1}
              />
            ) : (
              <Typography variant="h2" component="div" color="primary" display="inline-flex" alignItems="center">
                {clients[0].name}
              </Typography>
            )
          }
        />
      </Box>
      {selectedClient ? (
        <>
          <Alert variant="outlined" severity="info" sx={{ bgcolor: 'background.paper', mb: 4 }}>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              <Trans i18nKey="invoices.clientInvoicesListInfo" />
            </Typography>
          </Alert>
          <StyledSearchContainer>
            <SearchComponent onSearch={handleSearch} sx={{ flex: 1, maxWidth: '36.5rem' }} />
            <Select
              fullWidth
              name="sort"
              label={t('orders.sortTitle')}
              value={sort}
              onChange={e => setSort(e.target.value)}
              options={sortOptions(t)}
            />
          </StyledSearchContainer>
          <MainPaper sx={{ mt: 2 }}>
            <MainTable
              columns={mapInvoicesListColumns(t)}
              rows={mapInvoicesList(invoicesList ?? { items: [], totalCount: 0 }, t, { handleDownloadInvoice })}
              count={invoicesList?.totalCount}
              pagination={{
                page: currentPage,
                onPageChange: handlePageChange,
                pageSize,
                onPageSizeChange: handlePageSizeChange,
              }}
              loading={isFetching}
            />
          </MainPaper>
        </>
      ) : (
        <>
          <Alert variant="outlined" severity="info" sx={{ bgcolor: 'background.paper', mb: 4 }}>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              <Trans i18nKey="invoices.clientInvoicesListInfo" />
            </Typography>
          </Alert>
          <MainPaper>
            <Typography>{t('finanse.clientNotSelected')}</Typography>
          </MainPaper>
        </>
      )}
    </Box>
  )
}
