import { Link as RouterLink } from 'react-router-dom'

import { Box, Link, Paper, SvgIconProps, Typography, lighten } from '@mui/material'
import { useSettings } from 'core/contexts/SettingsProvider'

import { cardStyles, iconStyles } from './styles'

export interface InfoCardProps {
  title: string
  icon: React.FC<SvgIconProps>
  value: string | number
  linkTitle: string
  linkTo: string
  color: string
}

export const InfoCard = ({ title, icon, value, linkTitle, linkTo, color }: InfoCardProps) => {
  const { mode } = useSettings()
  const Icon = icon
  return (
    <Paper sx={cardStyles}>
      <Typography variant="subtitle1" color="text.secondary" mb={3}>
        {title.toUpperCase()}
      </Typography>
      <Typography variant="h2" color="text.primary">
        {value}
      </Typography>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap={1}>
        <Link component={RouterLink} sx={{ color: color, textDecorationColor: lighten(color, 0.75) }} to={linkTo} variant="body1">
          {linkTitle}
        </Link>
        <Box sx={iconStyles(color, mode)}>
          <Icon color="inherit" />
        </Box>
      </Box>
    </Paper>
  )
}
