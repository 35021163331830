import { Paper, SxProps, Theme } from '@mui/material'

import { mainPaperStyles } from './styles'

export interface MainPaperProps {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

const MainPaper: React.FC<MainPaperProps> = ({ children, sx = [] }: MainPaperProps) => {
  return <Paper sx={[mainPaperStyles, ...(Array.isArray(sx) ? sx : [sx])]}>{children}</Paper>
}

export default MainPaper
