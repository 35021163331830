import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Collapse, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'

import AvatarImg from '../AvatarImg'

export interface ProductAndVendorNameProps {
  productName: string
  vendorName: string
  vendorLogoUrl: string
}

const maxLength = 54

export const ProductAndVendorName = ({ productName, vendorName, vendorLogoUrl }: ProductAndVendorNameProps) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('sm'))

  const [isExpanded, setIsExpanded] = useState(false)

  const isTextLong = productName.length > maxLength
  const truncatedProductName = isTextLong ? productName.substring(0, maxLength) + '...' : productName

  const toggleExpand = () => setIsExpanded(!isExpanded)

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box>
        {!isMobile ? (
          <Tooltip title={isTextLong ? productName : ''}>
            <Typography variant="subtitle2">{truncatedProductName}</Typography>
          </Tooltip>
        ) : (
          <Box>
            <Collapse in={isExpanded} collapsedSize={44}>
              <Typography variant="subtitle2">{productName}</Typography>
            </Collapse>
            {isTextLong && (
              <Button onClick={toggleExpand} size="small" sx={{ transition: 'transform 300ms', ml: -0.5, mt: 0.5, mb: 1, p: 0.5 }}>
                {isExpanded ? (
                  <>
                    {t('common.action.seeLess')} <ExpandLessIcon />
                  </>
                ) : (
                  <>
                    {t('common.action.seeWholeName')} <ExpandMoreIcon />
                  </>
                )}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Tooltip title={vendorName} placement="bottom-start">
        <Box display="inline-flex" alignItems="center" gap={1}>
          <AvatarImg imgUrl={vendorLogoUrl} name={vendorName} sx={{ width: 32, height: 32 }} />
          <Box sx={{ display: 'flex', maxWidth: 'calc(100% - 2.5rem)' }}>
            <Box sx={{ flex: '1 1 100%', minWidth: 0 }}>
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  cursor: 'default',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '11rem',
                }}
                variant="body2"
              >
                {vendorName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </Box>
  )
}
