import { QueryKey } from '@tanstack/react-query'
import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { Item, PaginatedResult } from 'core/types'

type Params = {
  Filters: any
  VendorId: string
  Sorts: string
  Page?: number
  PageSize?: number
}

export const useCatalog = (params: Params, config?: { queryKey?: QueryKey; disabled?: boolean }) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<Item>>(apiEndpoints.getCatalog(params, config?.queryKey), {
    enabled: !config?.disabled,
  })
  return { isLoadingCatalog: isLoading, data, ...other }
}
