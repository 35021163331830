import { ReactNode } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { titleStyles } from './styles'

export interface PageTitleProps {
  title: string
  subtitle?: ReactNode
  rightEl?: ReactNode
}

const PageTitle = ({ title, subtitle, rightEl }: PageTitleProps) => {
  return (
    <Box sx={[titleStyles]}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        <Typography variant="h3" mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }} alignSelf="center">
          {title}
        </Typography>
        <Box>{subtitle}</Box>
      </Box>
      {rightEl && <Box ml={2}>{rightEl}</Box>}
    </Box>
  )
}

export { PageTitle }
