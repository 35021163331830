import { StyledComponent } from '@emotion/styled'
import { Paper, PaperProps, SxProps, Theme, styled } from '@mui/material'

export const formControlLabelStyle: SxProps<Theme> = {
  alignItems: 'flex-start',
  width: '100%',
  m: 0,
}

export const radioStyle: SxProps<Theme> = {
  color: 'grey.300',
  '&.Mui-checked': {
    color: 'text.primary',
  },
  mr: { xs: 0, md: 1.5 },
}

export const StyledRadioPaper: StyledComponent<PaperProps, { active: boolean }> = styled(Paper, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  padding: '.8rem 2.4rem .8rem 1.5rem',
  marginBottom: '.8rem',
  border: `.1rem solid ${active ? theme.palette.grey[400] : theme.palette.grey[200]}`,
  '&:hover': {
    borderColor: active ? theme.palette.grey[400] : theme.palette.grey[300],
  },
  [theme.breakpoints.down('md')]: {
    padding: '.8rem 1.2rem .8rem 0',
  },
}))
