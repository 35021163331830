import { UseQueryResult } from '@tanstack/react-query'
import apiEndpoints, { Endpoint } from 'api/endpoints'
import { AxiosError, AxiosResponse } from 'axios'

import { SelectOption } from './form'

export enum FilterName {
  DELIVERY_SITES = 'deliverySites',
  ALL_DELIVERY_SITES = 'allDeliverySites',
  CLIENTS = 'clients',
  ALL_CLIENTS = 'allClients',
  VENDORS = 'vendors',
  ALL_VENDORS = 'allVendors',
  ORDER_STATUS = 'status',
  PAYMENT_METHODS = 'paymentMethods',
  ALL_PRODUCT_CATEGORIES = 'allProductCategories',
  VALID_FROM = 'validFrom',
  VALID_TO = 'validTo',
  POS_PRODUCT_NAME = 'posProductName',
  ALL_SUBCATEGORIES = 'allSubCategories',
  ACTIVE_CLIENTS = 'activeClients',
  CONFIRMED_CLIENTS = 'identityConfirmedCLients',
}

export enum FilterType {
  SELECT = 'select',
  SELECT_CHIP = 'select-chip',
  AUTOCOMPLETE = 'autocomplete',
  DATEPICKER = 'date-picker',
}

export type FilterNameType = Partial<FilterName>

export type FiltersState = {
  [key in FilterName]?: string
}

export type SingleFilter = {
  filterName: FilterNameType
  api?: Endpoint
  apiDisabled?: boolean
  data?: SelectOption[]
  filterType: FilterType
  callback?: boolean
  // for autocomplete
  apiRequest?: keyof typeof apiEndpoints
  noPagination?: boolean
}

export type MappedSingleFilter = {
  filterName: FilterName
  apiResult: UseQueryResult<AxiosResponse<any>, AxiosError<Error>>
}

export interface SortOption {
  id: string
  name: string
  icon: React.ReactNode
}

export type CallbackFnData = {
  callbackType: 'isSuccess' | 'isError'
  filterName: FilterNameType
  data: any
}
