import { DeliverySiteBaseReportRes, DeliverySiteDetailedReportRes, VendorInfo } from 'core/types'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

interface ResultItem {
  date: string
  [siteId: string]: number | string
}
type ObjItem = { [key: string]: string | number }

export function mapDeliverySiteData(array: DeliverySiteBaseReportRes): ResultItem[] {
  const resultMap: { [date: string]: { [siteId: string]: number } } = {}

  array.forEach(item => {
    for (const date in item.totalOrderCost) {
      if (!resultMap[date]) {
        resultMap[date] = {}
      }
      resultMap[date][item.deliverySiteId.toString()] = item.totalOrderCost[date]
      resultMap[date][item.deliverySiteName.toString()] = item.totalOrderCost[date]
    }
  })

  const resultArray: ResultItem[] = []
  for (const date in resultMap) {
    const resultItem: ResultItem = { date: dayjs(date).format('MMMM') }
    for (const siteId in resultMap[date]) {
      resultItem[siteId] = resultMap[date][siteId]
    }
    resultArray.push(resultItem)
  }

  return resultArray
}

export const replaceDataKeys = (obj: any, t: TFunction) => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [t(`payment.paymentsMethodVal.${key}`), value]))
}
export const transformedObject = (arr: VendorInfo[]) => {
  return arr.reduce((acc: ObjItem, vendor) => {
    acc[vendor.vendorName] = vendor.totalCost
    return acc
  }, {})
}

export const mapPaymentMethodsData = (dsDetailedReportData: DeliverySiteDetailedReportRes | undefined, t: TFunction) =>
  dsDetailedReportData
    ? dsDetailedReportData.map(el => ({
        deliverySiteName: el.deliverySiteName,
        ...replaceDataKeys(el.costsPerPaymentMethod, t),
      }))
    : []

export const mapVendorData = (dsDetailedReportData: DeliverySiteDetailedReportRes | undefined) =>
  dsDetailedReportData
    ? dsDetailedReportData.map(el => ({
        deliverySiteName: el.deliverySiteName,
        ...transformedObject(el.costsPerVendor),
      }))
    : []

export const getUniqueKeys = (arr: ObjItem[]) => {
  const uniqueKeys: { [key: string]: boolean } = {}
  arr.forEach((obj: ObjItem) => {
    Object.keys(obj).forEach(key => {
      if (key !== 'deliverySiteName' && key !== 'name') {
        uniqueKeys[key] = true
      }
    })
  })
  return Object.keys(uniqueKeys)
}
