import { ChangeEvent, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { getIn, useFormikContext } from 'formik'

import { ControlledCheckbox } from '../Checkbox/ControlledCheckbox'

interface SelectAllControlProps {
  fields: string[]
  name?: string
  disabledFields?: string[]
  sx?: SxProps
}

export const SelectAllControl = memo(({ name = 'selectAll', fields, disabledFields = [], sx }: SelectAllControlProps) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext()
  const value = getIn(values, name)

  useEffect(() => {
    const allMatchTrue = Object.entries(values as any).every(([key, value]) => {
      if (fields.includes(key)) {
        return value === true
      }
      return true
    })
    if (allMatchTrue && !value) {
      setFieldValue(name, true)
    } else if (!allMatchTrue && value) {
      setFieldValue(name, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, name, value, values])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setFieldValue(name, checked, true)
    fields.forEach(el => {
      if (!disabledFields.find(d => d === el)) {
        setFieldValue(el, checked, true)
      }
    })
  }

  return (
    <ControlledCheckbox
      name={name}
      label={
        <Typography variant="inherit" component="span" fontWeight={700}>
          {t('common.action.selectAll')}
        </Typography>
      }
      onChange={handleChange}
      sx={sx}
    />
  )
})
