import apiEndpoints from 'api/endpoints'
import { OnError, OnSuccess, useMutate } from 'core/hooks'

import { UserResponse } from '../types/types'

export const useLogin = (onError: OnError, onSuccess: OnSuccess) => {
  const { isLoading, mutate, isSuccess, error } = useMutate<UserResponse>(apiEndpoints.postLogin(), {
    onError,
    onSuccess,
    noAuth: true,
  })
  return { isLoggingIn: isLoading, login: mutate, isSuccess, error }
}
