import { Trans } from 'react-i18next'

import { Typography } from '@mui/material'

export interface TitleWithNameProps {
  title: string
  value?: React.ReactNode
}

export const TitleWithName = ({ title, value }: TitleWithNameProps) => {
  return (
    <Trans
      i18nKey={title}
      values={{
        name: value,
      }}
      components={{
        nameTag: <Typography variant="inherit" component="span" color="primary" ml={1}></Typography>,
      }}
    />
  )
}
