import { SxProps, Theme } from '@mui/material'

export const connectorTextStyle: SxProps<Theme> = {
  borderRadius: 0.5,
  px: 2,
  py: 1,
  bgcolor: 'secondary.main',
  width: 'unset',
}

export const connectorStyle: SxProps<Theme> = {
  height: '2rem',
  width: '1px',
  bgcolor: 'grey.400',
}
