import { SxProps, Theme } from '@mui/material'

export const commonSelectStyle: SxProps<Theme> = {
  bgcolor: 'background.paper',
  '& .MuiSelect-icon': {
    top: 'calc(50% - 12px)',
    transition: '0.3s',
  },
}

export const commonSelectItemStyle: SxProps<Theme> = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
}

export const chipStyle = {
  color: 'common.white',
  my: -1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}
