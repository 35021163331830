import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useClientDetailsByTaxNumber } from 'companies/hooks'
import { ControlledTextField, Loader } from 'core/components'
import { AddAddress } from 'core/types'
import { useFormikContext } from 'formik'

const normalizeString = (str: string) => str.toLowerCase()

interface TaxNumberControlProps {
  data: any[] | undefined
  setIsDuplicateTaxNumber: React.Dispatch<React.SetStateAction<boolean>>
  initValues: { [key: string]: any }
  addressKey: string
  setClientName?: (clientName: string) => void
  disabled?: boolean
}

export const TaxNumberControl = ({
  data,
  initValues,
  addressKey,
  setIsDuplicateTaxNumber,
  setClientName,
  disabled = false,
}: TaxNumberControlProps) => {
  const { t } = useTranslation()
  const formik: any = useFormikContext()

  const { data: clientData, isFetching: isFetchingClientDetails } = useClientDetailsByTaxNumber(formik.values.taxNumber, disabled)

  useEffect(() => {
    if (clientData && clientData.name && !disabled) {
      if (clientData.name && typeof setClientName === 'function') {
        setClientName(clientData.name)
      }
      const newValues = {
        name: clientData.name,
        [addressKey]: {
          street: clientData.street,
          streetNumber: clientData.streetNumber,
          apartmentNumber: clientData.appartmentNumber,
          city: clientData.city,
          district: normalizeString(clientData?.district ?? ''),
          postalCode: clientData.postalCode,
        } as AddAddress,
      }

      formik.setValues((prevValues: any) => ({
        ...prevValues,
        ...newValues,
      }))
    } else if (formik.values.taxNumber.length === 10) {
      formik.resetForm({
        values: {
          ...initValues,
          taxNumber: formik.values.taxNumber,
        },
      })
      if (typeof setClientName === 'function') {
        setClientName('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, formik.values.taxNumber, disabled])

  const handleUpdateTaxNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const enteredTaxNumber = e.target.value.replace(/[^0-9]/g, '')
    formik.setFieldValue('taxNumber', enteredTaxNumber)

    const foundDuplicate = data?.some(el => el.taxNumber === enteredTaxNumber)

    setIsDuplicateTaxNumber(!!foundDuplicate)
  }
  return (
    <>
      {isFetchingClientDetails && <Loader />}
      <ControlledTextField name="taxNumber" label={t('vendors.taxNumber')} onChange={handleUpdateTaxNumber} required disabled={disabled} />
    </>
  )
}
