import { CatalogUploadApiErrorCode } from 'core/consts'

export const errorCode = {
  [CatalogUploadApiErrorCode.IncorrectFile]: {
    title: 'Plik zawiera błędy',
  },
  [CatalogUploadApiErrorCode.IncorrectVatDefinition]: {
    title: 'Żądanie musi zawierać kolumnę VAT lub stałą wartość podatku VAT',
  },
  [CatalogUploadApiErrorCode.IncorrectPriceColumnDefinition]: {
    title: 'Błędnie zdefiniowana kolumna ceny (powinna zawierać pojedynczą literę lub cyfrę)',
  },
  [CatalogUploadApiErrorCode.IncorrectNameColumnDefinition]: {
    title: 'Nieprawidłowo zdefiniowana kolumna nazwy (powinna zawierać pojedynczą literę lub cyfrę)',
  },
  [CatalogUploadApiErrorCode.OtherIntegrationType]: {
    title: 'Dostawca nie może mieć pliku Excel z konfiguracją dynamiczną',
  },
  [CatalogUploadApiErrorCode.MismatchedColumns]: {
    title: 'Nazwa kolumny nie jest zgodna z plikiem',
  },
  [CatalogUploadApiErrorCode.MissingConfiguration]: {
    title: 'Brak konfiguracji dla dostawcy. Skontaktuj się z administratorem',
  },
  unknownError: {
    title: 'Wystąpił nieoczekiwany błąd',
  },
  codeVerification: {
    generationError:
      'Niestety podczas podczas generowania kodu do potwierdzenia nr telefonu wystąpił nieoczekiwany błąd.\nProsimy spróbować za chwilę.',
  },
}
