import { memo } from 'react'

import { SxProps, Typography } from '@mui/material'

export interface RequiredSignProps {
  sx?: SxProps
}

export const RequiredSign = memo(({ sx = [] }: RequiredSignProps) => {
  return (
    <Typography variant="inherit" component="span" color="error.main" sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
      *
    </Typography>
  )
})
