import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Typography } from '@mui/material'
import { AddToBasket, AvatarImg, DailyPrice, ExclamationMark, HelperDialog, MainPaper } from 'core/components'
import { PaymentTypeVal, ProductUnit } from 'core/consts'
import { formatPrice, formatPricePerUnit } from 'core/utils'
import noImage from 'items/images/noImage.png'
import { CartItem } from 'items/types/types'

import { paperStyles } from './styles'

interface ProductCardProps {
  product: CartItem
  selectedPaymentType: PaymentTypeVal | null
  handleUpdateQuantity: (quantity: number, el: CartItem) => Promise<number>
  handleDeleteItem: (el: CartItem) => void
  disabled: boolean
}

export const ProductCard = ({ product, selectedPaymentType, disabled, handleUpdateQuantity, handleDeleteItem }: ProductCardProps) => {
  const { t } = useTranslation()
  const { productId, quantity, price, primaryImage, name, unit, minCartAmount, purchaseIncrement, isDailyPrice } = product

  const totalPricePerProduct = quantity * price

  return (
    <MainPaper key={productId} sx={paperStyles}>
      <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
        <AvatarImg imgUrl={primaryImage ? primaryImage : null} img={noImage} name={name} />
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" mr={3} mt={0.5}>
            {name}
          </Typography>
          {!isDailyPrice && (
            <Typography variant="body2" color="text.secondary" display="flex" mt={1}>
              {formatPricePerUnit(price, unit)}
            </Typography>
          )}
          <IconButton
            onClick={() => handleDeleteItem(product)}
            disabled={disabled}
            sx={{
              position: 'absolute',
              right: -8,
              top: -8,
            }}
          >
            <DeleteIcon />
          </IconButton>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', gap: 2, mt: 1.5, flexWrap: 'wrap' }}>
              <Box flex={{ xs: 2, md: 1 }}>
                <AddToBasket
                  value={quantity}
                  onInputAction={quantity => handleUpdateQuantity(quantity, product)}
                  name={productId}
                  unit={unit}
                  min={minCartAmount}
                  purchaseIncrement={purchaseIncrement}
                  variableWeightProductHidden
                />
              </Box>
              <Box flex={2}>
                <Typography variant="h5" fontWeight={700} component="div" display="flex" justifyContent="flex-end" mt={1}>
                  {isDailyPrice ? (
                    <Box display="flex" alignItems="center" gap={0.5}>
                      <DailyPrice variant="h5" />
                      <HelperDialog title="common.dailyPrice">
                        <Typography>{t('common.dailyPriceBasicInfo')}</Typography>
                      </HelperDialog>
                    </Box>
                  ) : (
                    formatPrice(totalPricePerProduct)
                  )}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={2}>
              {(selectedPaymentType === PaymentTypeVal.OnlinePayment || selectedPaymentType === PaymentTypeVal.Spingo) &&
                unit !== ProductUnit.Piece && (
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Typography variant="body2" color="text.secondary">
                      {t('common.variableWeightProduct')}
                    </Typography>
                    <ExclamationMark />
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
    </MainPaper>
  )
}
