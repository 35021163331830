import { Box, Typography } from '@mui/material'

import { StyledConnector } from '../styledComponents'
import { connectorTextStyle } from './styles'

export const Connector = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ alignSelf: 'flex-start' }}>
    <StyledConnector />
    <Typography sx={connectorTextStyle}>{children}</Typography>
    <StyledConnector />
  </Box>
)
