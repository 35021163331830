import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { ControlledTextField } from 'core/components'
import { ProductOrder } from 'core/types'
import { Formik } from 'formik'
import { t } from 'i18next'
import * as Yup from 'yup'

type SetDailyPriceDialogProps = {
  onClose: () => void
  orderDetail: ProductOrder
  onSave: (price: number) => void
  processing: boolean
}

const SetDailyPriceDialog: React.FC<SetDailyPriceDialogProps> = ({ onClose, onSave, processing, orderDetail }) => {
  const initialValues = {
    price: orderDetail.skaloPrice.toFixed(2),
  }
  const validationSchema = Yup.object({
    price: Yup.number().moreThan(0, t('common.validations.moreThanZero')).required(t('common.validations.required')),
  })
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`${t('orders.setDailyPrice')} ${orderDetail.productName}`}</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSave(Number(values.price))
        }}
      >
        {(formik: any) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent>
                <Alert severity="warning" sx={{ mb: 4 }}>
                  <Typography variant="subtitle1">{t('orders.setDailyPriceInfo')}</Typography>
                </Alert>
                <ControlledTextField name="price" type="number" label={t('common.price')} />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined" startIcon={<CloseIcon />}>
                  {t('common.cancel')}
                </Button>
                <LoadingButton loading={processing} variant="contained" type="submit">
                  {t('common.save')}
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default SetDailyPriceDialog
