import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { Category } from 'core/types'

export const useGetCategories = () => {
  const { data, isLoading, ...other } = useRequest<Category[]>(apiEndpoints.getCategories())
  return { isLoadingCategories: isLoading, categoriesData: data, ...other }
}

export const useGetClientAvailableCategories = (params: { deliverySiteId: number | undefined }) => {
  const { data, isFetching, ...other } = useRequest<Category[]>(apiEndpoints.getClientAvailableCategories(params), {
    enabled: params.deliverySiteId !== undefined,
  })
  return { isLoadingCategories: isFetching, categories: data, ...other }
}
