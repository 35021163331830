import { useTranslation } from 'react-i18next'

import { Box, Card, CardContent, Typography } from '@mui/material'
import { getUserRoles, useAuth } from 'auth/contexts/AuthProvider'
import { Logo } from 'core/components'
import { UserRole } from 'core/consts'

//only presentation purposes, TODO remove in prod release 1.0
import AddAllLogo from '../images/addAll.png'
import BukatLogo from '../images/bukat_logo.png'

const WelcomeWidget = () => {
  const { userInfo } = useAuth()
  const roles = getUserRoles()
  const { t } = useTranslation()
  const isVendor = roles?.includes(UserRole.VENDOR)
  const isTestVendor = roles?.includes(UserRole.TEST_VENDOR)

  return (
    <Card elevation={0} sx={{ backgroundColor: 'transparent', mb: 2 }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography component="div" gutterBottom variant="h1">
          {t('admin.home.welcome.title', { name: userInfo?.firstName })}
        </Typography>
        <Typography component="div" sx={{ fontWeight: 300, mb: 3 }} variant="h1">
          {t('admin.home.welcome.subTitle')}
        </Typography>
        <Typography color="textSecondary" component="p" gutterBottom marginBottom={2} variant="subtitle1">
          {t('admin.home.welcome.message')}
        </Typography>
        <Box>
          {/* only presentation purposes, TODO remove in prod release 1.0 */}
          {isTestVendor && <img style={{ width: '100%', maxWidth: '300px' }} src={AddAllLogo} alt="Add All logo" />}
          {!isTestVendor && (isVendor ? <img style={{ width: '100%', maxWidth: '300px' }} src={BukatLogo} alt="Bukat logo" /> : <Logo />)}
        </Box>
      </CardContent>
    </Card>
  )
}

export default WelcomeWidget
