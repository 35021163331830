import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'

import { VendorMonthlyReportParams, VendorMonthlyReportType } from '../types/types'

export const useGetVendorMonthlyReport = (params: VendorMonthlyReportParams, vendorId: string) => {
  const { data, ...other } = useRequest<VendorMonthlyReportType>(apiEndpoints.getVendorMonthlyReport(params), {
    enabled: !!vendorId,
  })

  return { vendorMonthlyReport: data, ...other }
}
