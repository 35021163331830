import React, { memo, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Search } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, InputAdornment, SxProps } from '@mui/material'
import debounce from 'lodash/debounce'

import { LabelWithIcon, TextField } from './fields'

interface SearchComponentProps {
  onSearch: (searchTerm: string) => void
  searchParam?: string
  sx?: SxProps
}

const SearchComponent = memo(({ onSearch, searchParam, sx = [] }: SearchComponentProps) => {
  const [searchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState(searchParam ? searchParams.get(searchParam) || '' : '')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      onSearch(searchTerm)
    }, 500),
    [],
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value
    const sanitizedSearchTerm = event.target.value.replaceAll(',', ' ')
    setSearchTerm(newSearchTerm)
    debouncedSearch(sanitizedSearchTerm)
  }

  useEffect(() => {
    if (searchParam && searchParams.get(searchParam)) {
      onSearch(searchParams.get(searchParam) || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, searchParams])

  const handleClear = () => {
    setSearchTerm('')
    onSearch('')
  }

  return (
    <Box sx={[{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <TextField
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        name="search"
        label={<LabelWithIcon label="search.placeholder" icon={Search} />}
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: searchTerm !== '' && (
            <InputAdornment position="end">
              <IconButton aria-label="clear-search" onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
})

export default SearchComponent
