import { Field, FormikValues, getIn, useFormikContext } from 'formik'

import { TextField, TextFieldProps } from '.'

export interface ControlledTextFieldProps extends TextFieldProps, FormikValues {
  submitDisabled?: boolean
}

export const ControlledTextField = ({ name, submitDisabled, ...otherProps }: ControlledTextFieldProps) => {
  const { errors, touched, isSubmitting, isValid, submitForm } = useFormikContext()
  const error = getIn(errors, name) && getIn(touched, name)
  const errorMsg = getIn(errors, name)

  const onSubmit = () => {
    if (!isSubmitting && isValid) {
      submitForm()
    }
  }
  return (
    <Field
      as={TextField}
      name={name}
      error={error}
      errorHelperText={errorMsg}
      {...otherProps}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !submitDisabled) {
          e.preventDefault()
          onSubmit()
        }
      }}
    />
  )
}
