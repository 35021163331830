import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import ActivityWidgetVendor from 'vendor/widgets/ActivityWidgetVendor'
import OverviewWidgetVendor from 'vendor/widgets/OverviewWidgetVendor'

const overviewItems = [
  {
    unit: 'Największa sprzedaż',
    value: '(Lipiec) 1 581 706',
  },
  {
    unit: 'Najmniejsza sprzedaż',
    value: '(styczeń) 1 440 684',
  },
  {
    unit: 'Średnia sprzedaż',
    value: '1 504 150',
  },
]

const VendorFinances = () => {
  const { t } = useTranslation()

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {overviewItems.map((item, index) => (
          <Grid key={index} item xs={6} md={3}>
            <OverviewWidgetVendor description={t(item.unit)} title={item.value} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ActivityWidgetVendor />
        </Grid>
      </Grid>
    </div>
  )
}

export default VendorFinances
