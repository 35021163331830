import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { menuRoutes } from 'config/menuRoutes'
import { Drawer, Footer, SettingsDrawer } from 'core/components'
import { useSettings } from 'core/contexts/SettingsProvider'

import AppBar from './AppBar'
import { containerStyle } from './styles'

interface MainLayoutProps {
  title?: React.ReactNode
  children: React.ReactNode
}

export const MainLayout = ({ title, children }: MainLayoutProps) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const matchesMobile = useMediaQuery(breakpoints.down('lg'))
  const { pathname } = useLocation()

  const [settingsOpen, setSettingsOpen] = useState(false)

  const { collapsed, open, toggleDrawer } = useSettings()

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        collapsed={collapsed}
        mobileOpen={open}
        onDrawerToggle={toggleDrawer}
        onSettingsToggle={handleSettingsToggle}
        items={menuRoutes(t)}
      />
      <SettingsDrawer onDrawerToggle={handleSettingsToggle} open={settingsOpen} />
      <AppBar title={title ?? ''} />
      <Box component="main" sx={containerStyle}>
        {/* temporary since only basket is done for mobile */}
        {matchesMobile && pathname === '/basket' ? (
          <Box mb={6}>{children}</Box>
        ) : (
          <Container maxWidth={false} sx={{ mb: 6 }}>
            {children}
          </Container>
        )}
        <Footer linksVisible />
      </Box>
    </Box>
  )
}
