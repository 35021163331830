import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import EditIcon from '@mui/icons-material/Edit'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Button, Grid } from '@mui/material'
import { VendorManagementTab } from 'config/routeTypes'
import { Loader, MainTable, NotReadyWrapper } from 'core/components'
import MainPaper from 'core/components/MainPaper'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { VendorListItem } from 'core/types'
import { useVendorDetails } from 'management/hooks'
import { usePostUploadCatalog } from 'vendor/hooks'
import { useGetVendorsList } from 'vendor/hooks/useGetVendorsList'

import VendorDetails from './VendorDetails'
import { mapVendorsColumns, mapVendorsData } from './mapVendorsTable'

const VendorConfiguration: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { vendorsList, isSuccess, isError, isLoading } = useGetVendorsList()
  const {
    vendorData,
    isFetching: vendorDataIsLoading,
    isError: vendorDataIsError,
  } = useVendorDetails(vendorsList && vendorsList?.length === 1 ? vendorsList[0].id : '')

  const snackbar = useSnackbar()
  const { uploadVendorCatalog, isLoading: isUploadCatalogLoading } = usePostUploadCatalog()

  const handleUploadCatalog = (vendor: VendorListItem, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('VendorExcel', file)
      formData.append('VendorId', vendor.id)

      uploadVendorCatalog(formData, {
        onSuccess: () => {
          snackbar.success(t('vendors.catalogUpload.success'))
        },
        onError: () => {
          snackbar.error(t('vendors.catalogUpload.error'))
        },
      })
    }
  }

  const redirectToVendorAction = (vendor: VendorListItem, tab: VendorManagementTab) => navigate(`/vendor-configuration/${vendor.id}/${tab}`)

  return (
    <MainPaper sx={{ position: 'relative' }}>
      {isUploadCatalogLoading && <Loader relative />}
      <NotReadyWrapper
        isSuccess={isSuccess || vendorDataIsLoading}
        isLoading={isLoading || vendorDataIsLoading}
        isError={isError || vendorDataIsError}
      >
        {vendorsList && (
          <>
            {vendorData && (
              <>
                <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center" mb={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => redirectToVendorAction(vendorData, VendorManagementTab.MAIN_DATA)}
                    >
                      {t('vendors.editData')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button component="label" role={undefined} variant="outlined" tabIndex={-1} startIcon={<UploadFileIcon />}>
                      {t('common.catalogUpload', { fileFormat: '.xlsx' })}
                      <input
                        name="fileUpload"
                        type="file"
                        hidden
                        onChange={e => handleUploadCatalog(vendorData, e)}
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                    </Button>
                  </Grid>
                </Grid>
                <VendorDetails vendor={vendorData} />
              </>
            )}

            {vendorsList.length !== 1 && (
              <MainTable
                columns={mapVendorsColumns(t)}
                rows={mapVendorsData(vendorsList, t, { handleEdit: redirectToVendorAction, handleUploadCatalog })}
              />
            )}
          </>
        )}
      </NotReadyWrapper>
    </MainPaper>
  )
}

export { VendorConfiguration }
