import { SxProps, Theme, lighten } from '@mui/material'

export const menuPaperStyle: SxProps<Theme> = {
  overflow: 'visible',
  border: 1,
  borderColor: 'grey.300',
  mt: 1.5,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  minWidth: '20rem',
  maxWidth: '32rem',
  '& .MuiList-root': {},
  '&::before': {
    borderTop: 1,
    borderLeft: 1,
    borderColor: 'grey.300',
    content: '""',
    display: { xs: 'none', md: 'block' },
    position: 'absolute',
    top: -1,
    right: 23,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}

export const notificationStyle = {
  px: 2,
  py: 3,
  borderBottom: 1,
  borderTop: 1,
  borderColor: 'grey.200',
  my: 0.5,
  position: 'relative',
  bgcolor: 'inherit',
  display: 'inline-flex',
  gap: 2,
}
export const notificationUnreadStyle = {
  background: (theme: Theme) => lighten(theme.palette.secondary.main, 0.8),
}
