import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { TFunction } from 'i18next'

export const catalogSortOptions = (t: TFunction) => [
  {
    id: 'name',
    name: t('orders.nameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-name',
    name: t('orders.nameDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'price',
    name: t('orders.priceAsc'),
    icon: <ArrowDownward />,
  },
  {
    id: '-price',
    name: t('orders.priceDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: '-orderCount',
    name: t('orders.orderCountDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: '-stock',
    name: t('orders.stockDesc'),
    icon: <ArrowDownward />,
  },
]
