import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector'

import { Close, PlayCircleOutline } from '@mui/icons-material'
import { Box, Dialog, DialogContent, Grow, IconButton, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

import { PlayButton, StyledContainer, StyledFullVideo, StyledVideo, closeDialogButtonStyle } from './styles'
import { getVideos } from './videos'

export const relativeAspectRatio = 2.18

interface FullScreenAnimationProps {
  onLoaded: (loaded: boolean) => void
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} timeout={{ enter: 1000, exit: 500 }} />
})

export const FullScreenAnimation = ({ onLoaded }: FullScreenAnimationProps) => {
  const { t, i18n } = useTranslation()
  const { width, height, ref } = useResizeDetector({
    handleHeight: false,
  })
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9)
  const [videoSrc, setVideoSrc] = useState({ src: '', srcWebm: '', full: '', lang: i18n.language })
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const checkIs2K = useCallback(() => Number(width) >= 2300 || Number(height) >= 2300, [width, height])

  useEffect(() => {
    const updateAspectRatio = () => {
      const aspect = width && height ? width / height : 2
      setAspectRatio(aspect)
      const videos = getVideos(i18n.language)
      let bestVideo = videos[0]
      let minDifference = Math.abs(bestVideo.width / bestVideo.height - aspect)

      for (const video of videos) {
        const videoAspectRatio = video.width / video.height
        const difference = Math.abs(videoAspectRatio - aspect)
        if (difference < minDifference) {
          minDifference = difference
          bestVideo = video
        }
      }

      setVideoSrc(prev => {
        if (prev.src !== '' && prev.src !== bestVideo.src) {
          onLoaded(false)
        }
        return {
          src: checkIs2K() ? bestVideo.src2k : bestVideo.src,
          srcWebm: bestVideo.srcWebm ?? bestVideo.src,
          full: bestVideo.full,
          lang: i18n.language,
        }
      })
    }
    updateAspectRatio()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, i18n.language])

  const handleDialogOpen = () => {
    setDialogOpen(true)
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.pause()
      }
    }, 500)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play()
      }
    }, 500)
  }

  useEffect(() => {
    if (videoSrc.src) {
      const videoElement = videoRef.current

      if (videoElement) {
        videoElement.defaultMuted = true
        videoElement.muted = true
        const timer = setTimeout(() => {
          onLoaded(true)
          videoElement.play()
        }, 3000)

        const handleCanPlayThrough = () => {
          clearTimeout(timer)
          onLoaded(true)
        }

        videoElement.addEventListener('canplaythrough', handleCanPlayThrough)

        // Clean up the event listener on component unmount
        return () => {
          videoElement.removeEventListener('canplaythrough', handleCanPlayThrough)
          clearTimeout(timer)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoSrc.src])

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <StyledContainer aspectRatio={aspectRatio}>
        <StyledVideo key={videoSrc.src} ref={videoRef} autoPlay playsInline muted loop aspectRatio={aspectRatio} width="100%">
          <source src={videoSrc.src} type="video/mp4" />
          <source src={videoSrc.srcWebm} type="video/webm" />
          Your browser does not support the video tag.
        </StyledVideo>
      </StyledContainer>
      <PlayButton onClick={handleDialogOpen}>
        <PlayCircleOutline sx={{ width: 48, height: 'auto' }} />
        <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
          {t('common.turnSoundOn')}
        </Typography>
      </PlayButton>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
        PaperProps={{ onClick: e => e.stopPropagation() }} // Prevent dialog close when clicking inside the video area
        maxWidth={aspectRatio > relativeAspectRatio ? 'xs' : 'md'}
      >
        <DialogContent onClick={handleDialogClose} style={{ padding: 0, overflow: 'hidden' }}>
          <StyledFullVideo
            src={videoSrc.full}
            controls
            autoPlay
            onClick={e => e.stopPropagation()} // Prevent dialog close when clicking inside the video area
          />
        </DialogContent>
      </Dialog>
      {dialogOpen && (
        <IconButton onClick={handleDialogClose} sx={closeDialogButtonStyle}>
          <Close />
        </IconButton>
      )}
    </Box>
  )
}
