/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { RuleDatesValid } from 'management/types'

const today = dayjs()

interface DateRangeProps {
  ruleDatesValid: RuleDatesValid
  setRuleDatesValid: Dispatch<SetStateAction<RuleDatesValid>>
}

const DateRange = ({ ruleDatesValid, setRuleDatesValid }: DateRangeProps) => {
  const { t } = useTranslation()

  function handleAsapChange(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
    setRuleDatesValid(prev => ({
      ...ruleDatesValid,
      startAsap: checked,
      validFrom: checked ? null : prev.validFrom,
    }))
  }

  function handleValidDateChange(key: string, value: Date | null) {
    setRuleDatesValid({
      ...ruleDatesValid,
      [key]: value,
    })
  }

  const basicDateProps = {
    format: 'DD-MM-YYYY HH:mm',
    disablePast: true,
    ampm: false,
    sx: { bgcolor: 'background.paper' },
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" gap={2} alignItems="center">
        <DateTimePicker
          name="validFrom"
          onChange={value => {
            handleValidDateChange('validFrom', value)
          }}
          label={t('margins.validFrom')}
          disabled={ruleDatesValid.startAsap}
          value={ruleDatesValid.validFrom}
          // @ts-ignore
          minDate={ruleDatesValid.startAsap ? null : today}
          {...basicDateProps}
        />
        <DateTimePicker
          name="validTo"
          onChange={value => {
            handleValidDateChange('validTo', value)
          }}
          label={t('margins.validTo')}
          value={ruleDatesValid.validTo}
          minDate={ruleDatesValid.validFrom}
          {...basicDateProps}
        />
      </Box>
      <FormControlLabel
        label={t('margins.startAsap')}
        control={<Checkbox onChange={handleAsapChange} checked={ruleDatesValid.startAsap} />}
        sx={{ mt: 2 }}
      />
    </LocalizationProvider>
  )
}

export default DateRange
