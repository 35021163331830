import axios, { AxiosInstance } from 'axios'
import { APP_BASE_URL } from 'core/consts'

import { getAuthInfo, getJwtToken, handleFailedTokenRefresh, refreshToken } from './tokenManagement'

const axiosInstance = axios.create({
  baseURL: APP_BASE_URL,
  timeout: 10000,
})

let isRefreshing = false
let failedQueue: {
  resolve: (token: string) => void
  reject: (error: any) => void
}[] = []

axiosInstance.interceptors.request.use(
  config => {
    const token = getJwtToken()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve: (token: string) => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`
              resolve(axios(originalRequest))
            },
            reject: (err: any) => {
              reject(err)
            },
          })
        })
      }

      isRefreshing = true

      try {
        const authInfo = getAuthInfo()
        if (!authInfo) {
          throw new Error('Authentication information not found')
        }
        const { email, refreshToken: currentRefreshToken } = authInfo
        const userResponse = await refreshToken({ email, refreshToken: currentRefreshToken })

        failedQueue.forEach(promise => {
          promise.resolve(userResponse.token)
        })
        failedQueue = []

        originalRequest.headers['Authorization'] = `Bearer ${userResponse.token}`
        return axiosInstance(originalRequest)
      } catch (refreshError) {
        failedQueue.forEach(promise => {
          promise.reject(refreshError)
        })
        failedQueue = []
        handleFailedTokenRefresh()

        return Promise.reject(refreshError)
      } finally {
        isRefreshing = false
      }
    }

    return Promise.reject(error)
  },
)

const axiosNoAuthInstance: AxiosInstance = axios.create({ baseURL: APP_BASE_URL })

export default axiosInstance

export { axiosNoAuthInstance }
