import { useMemo } from 'react'

import { Category, DeliverySite, FilterName, FilterType, SingleFilter, Vendor } from 'core/types'

export type FiltersState = {
  [FilterName.ALL_DELIVERY_SITES]: DeliverySite | null
  [FilterName.ALL_VENDORS]: Vendor | null
  [FilterName.ALL_PRODUCT_CATEGORIES]: Category | null
  [FilterName.VALID_FROM]: Date | null
  [FilterName.VALID_TO]: Date | null
}
export const filtersStateInit = {
  [FilterName.ALL_DELIVERY_SITES]: null,
  [FilterName.ALL_VENDORS]: null,
  [FilterName.ALL_PRODUCT_CATEGORIES]: null,
  [FilterName.VALID_FROM]: null,
  [FilterName.VALID_TO]: null,
}

export const useFilters = () => {
  const filters = useMemo(
    (): SingleFilter[] => [
      {
        filterName: FilterName.ALL_DELIVERY_SITES,
        apiRequest: 'getAllDeliverySites',
        filterType: FilterType.AUTOCOMPLETE,
      },
      {
        filterName: FilterName.ALL_VENDORS,
        apiRequest: 'getAllVendors',
        filterType: FilterType.AUTOCOMPLETE,
      },
      {
        filterName: FilterName.ALL_PRODUCT_CATEGORIES,
        apiRequest: 'getCategories',
        filterType: FilterType.AUTOCOMPLETE,
        noPagination: true,
      },
      {
        filterName: FilterName.VALID_FROM,
        filterType: FilterType.DATEPICKER,
      },
      {
        filterName: FilterName.VALID_TO,
        filterType: FilterType.DATEPICKER,
      },
    ],
    [],
  )

  return {
    filters,
  }
}
