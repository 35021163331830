import { ReactElement } from 'react'

import { Check, EventAvailable, PlaylistAddCheck } from '@mui/icons-material'
import Favorite from '@mui/icons-material/Favorite'
import 'core/contexts/CatalogProvider'
import { CataloLogisticsState, CatalogFiltersState, CatalogSelectedCategory, Category } from 'core/types'

export function createFilters(searchTerm: string, filtersState: CatalogFiltersState, selectedLogistic?: CataloLogisticsState) {
  const filters = []

  if (searchTerm.trim()) {
    filters.push(`search@=*${searchTerm}`)
  }
  if (filtersState.previouslyOrdered) {
    filters.push('Ordered==true')
  }
  if (filtersState.favouriteOnly) {
    filters.push('favouriteOnly==true')
  }
  if (filtersState.isInStock) {
    filters.push('isInStock==true')
  }
  if (filtersState.nextDayDelivery) {
    filters.push('nextDayDelivery==true')
  }
  if (selectedLogistic && selectedLogistic?.minimalLogisticsValue !== '') {
    filters.push(`MinimalLogistics${selectedLogistic?.minimalLogisticsComparison}${selectedLogistic?.minimalLogisticsValue}`)
  }

  return filters.join(',')
}

export const filterSelectedCategories = (
  selectedCategories: CatalogSelectedCategory[],
  categories: Category[],
): CatalogSelectedCategory[] => {
  return selectedCategories.flatMap(selectedCategory => {
    const category = categories.find(cat => cat.id === selectedCategory.category.id)
    if (!category) return []

    const validSubcategories = selectedCategory.subcategories.filter(subcat =>
      category.subcategories.some(catSubcat => catSubcat.id === subcat.id),
    )

    if (validSubcategories.length === 0) return []

    return [
      {
        ...selectedCategory,
        subcategories: validSubcategories,
        areAllSubcategoriesSelected: validSubcategories.length === category.subcategories.length,
        totalSubcategories: category.subcategories.length,
      },
    ]
  })
}

const iconStyles = { fontSize: '1rem', opacity: 0.8 }

export const otherFiltersItems: { name: keyof CatalogFiltersState; label: string; icon?: ReactElement }[] = [
  {
    name: 'favouriteOnly',
    label: 'orders.favourite',
    icon: <Favorite sx={[iconStyles, { color: 'error.light' }]} />,
  },
  {
    name: 'previouslyOrdered',
    label: 'orders.previouslyOrdered',
    icon: <PlaylistAddCheck color="info" sx={iconStyles} />,
  },
  {
    name: 'isInStock',
    label: 'orders.productsInStock',
    icon: <Check color="success" sx={iconStyles} />,
  },
  {
    name: 'nextDayDelivery',
    label: 'orders.nextDayDelivery',
    icon: <EventAvailable sx={iconStyles} />,
  },
]
