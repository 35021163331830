import { SelectOption } from 'core/types'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

export const getMonthsList = () =>
  Array.from({ length: 12 }, (_, index) => ({
    id: index.toString(),
    name: dayjs(dayjs().month(Number(index.toString()))).format('MMMM'),
  }))

export const last12month = 'last12month'
export const lastDaysNo = (val: number) => `lastDays${val}`

export const currentYear = dayjs().year()

export const getCurrentDate = (): Date => {
  return dayjs().toDate()
}

export const getFirstDayOfMonth = (): Date => {
  return dayjs().startOf('month').toDate()
}

export const getLastYearsList = (count: number, t: TFunction) => {
  const currentYear = dayjs().year()
  const years = [
    {
      id: last12month,
      name: t('finanse.Last12months'),
    },
  ]
  for (let i = 0; i < count; i++) {
    years.push({
      id: (currentYear - i).toString(),
      name: (currentYear - i).toString(),
    })
  }
  return years
}

export const getYearsList = (count: number) => {
  const currentYear = dayjs().year()
  const years = []
  for (let i = 0; i < count; i++) {
    years.push({
      id: (currentYear - i).toString(),
      name: (currentYear - i).toString(),
    })
  }
  return years
}
export const getWeekdaysList = () =>
  Array.from({ length: 7 }, (_, index) => ({
    id: index,
    name: dayjs(dayjs().weekday(index)).format('ddd'),
  }))

export const getWeekdaysListNames = () => Array.from({ length: 7 }, (_, index) => dayjs(dayjs().weekday(index)).format('ddd'))

export const getHourList = (): SelectOption[] => {
  const hourList: SelectOption[] = []

  for (let i = 0; i < 24; i++) {
    const hour = dayjs().startOf('day').add(i, 'hour')
    const formattedHour = hour.format('HH:00')
    hourList.push({ id: i, name: formattedHour })
  }
  return hourList
}

export const getDaysAgoList = (val: number, t: TFunction) => ({
  id: dayjs().subtract(val, 'day').toISOString(),
  name: t('finanse.LastDaysNo', { val }),
})

// Function to return a formatted range of weekdays from an array of weekday numbers
export const getWeekdayRange = (weekdayNumbers: number[]) => {
  // Define weekday names corresponding to weekday numbers (0 for Monday, 6 for Sunday)
  // const weekdayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const weekdayNames = getWeekdaysListNames()

  // Map weekday numbers to weekday names and filter out any invalid numbers
  const validWeekdays = weekdayNumbers
    .map(weekdayNum => (weekdayNum >= 0 && weekdayNum <= 6 ? weekdayNames[weekdayNum] : null))
    .filter(weekdayName => weekdayName !== null)

  // If there are no valid weekdays, return an empty string
  if (validWeekdays.length === 0) {
    return ''
  }

  // Create a formatted range string for consecutive weekdays
  const ranges = []
  let startRange = validWeekdays[0]

  for (let i = 1; i < validWeekdays.length; i++) {
    if (weekdayNumbers[i] !== weekdayNumbers[i - 1] + 1) {
      // End the current range and start a new range
      ranges.push(startRange === validWeekdays[i - 1] ? startRange : `${startRange}-${validWeekdays[i - 1]}`)
      startRange = validWeekdays[i]
    }
  }

  // Add the last range to the list
  ranges.push(
    startRange === validWeekdays[validWeekdays.length - 1] ? startRange : `${startRange}-${validWeekdays[validWeekdays.length - 1]}`,
  )

  // Join the ranges into a comma-separated string
  const rangeString = ranges.join(', ')

  return rangeString
}

export const formatDateAndTime = (dateString: string | null, time?: boolean): string => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  return `${date.toLocaleDateString()} ${time ? dayjs(date).format('HH:mm') : ''}`
}
