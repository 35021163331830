import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Button, Grid, Typography } from '@mui/material'
import { Loader, MainPaper, MainTable, SearchComponent } from 'core/components'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { Item } from 'core/types'
import { usePostUploadCatalog } from 'vendor/hooks'
import { useGetVendorCatalog } from 'vendor/hooks/useGetVendorCatalog'
import { useGetVendorsList } from 'vendor/hooks/useGetVendorsList'

import { mapVendorCatalog, mapVendorCatalogColumns, vendorCatalogCellStyles } from './mapVendorCatalog'

const VendorCatalog = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const { vendorsList, isLoading: isLoadingVendorList, isError: isErrorVendorList } = useGetVendorsList()
  const firstVendorId = vendorsList ? vendorsList[0].id : ''
  const {
    vendorCatalog,
    isLoading: isLoadingVendorCatalog,
    isError: isErrorVendorCatalog,
  } = useGetVendorCatalog(
    {
      Page: page + 1,
      PageSize: rowsPerPage,
      Filters: searchTerm,
    },
    firstVendorId, //only for version 1.0,
    ['Vendor', 'List', firstVendorId, page, rowsPerPage, searchTerm],
  )

  const handleSearch = useCallback((search: string) => {
    setSearchTerm(`search@=*${search}`)
    setPage(0)
  }, [])

  const { uploadVendorCatalog, isLoading: isUploadCatalogLoading } = usePostUploadCatalog()

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setRowsPerPage(newPageSize)
  }

  const handleUploadCatalog = (vendorId: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('VendorExcel', file)
      formData.append('VendorId', vendorId)

      uploadVendorCatalog(formData, {
        onSuccess: () => {
          snackbar.success(t('vendors.catalogUpload.success'))
        },
        onError: () => {
          snackbar.error(t('vendors.catalogUpload.error'))
        },
      })
    }
  }

  const handleEdit = (item: Item) => {
    navigate(`/vendor-catalog/${item.id}/edit`)
  }

  return (
    <MainPaper sx={{ position: 'relative' }}>
      {(isUploadCatalogLoading || isLoadingVendorList) && <Loader relative />}
      <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center" mb={4}>
        <Grid item xs={12} md={8}>
          <SearchComponent onSearch={handleSearch} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: { xs: 'flex-star', md: 'flex-end' } }}>
          <Button component="label" role={undefined} variant="outlined" tabIndex={-1} startIcon={<UploadFileIcon />}>
            {t('common.catalogUpload', { fileFormat: '.xlsx' })}
            <input
              name="fileUpload"
              type="file"
              hidden
              onChange={e => handleUploadCatalog(firstVendorId, e)}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </Button>
        </Grid>
      </Grid>
      {isErrorVendorList && (
        <Typography color="error" sx={{ whiteSpace: 'pre-line' }}>
          {t('common.errors.unexpected.subTitle')}
        </Typography>
      )}

      {firstVendorId && (
        <MainTable
          columns={mapVendorCatalogColumns(t)}
          rows={mapVendorCatalog(vendorCatalog ?? { totalCount: 0, items: [] }, { handleEdit }, t)}
          count={vendorCatalog?.totalCount}
          pagination={{
            page: page,
            onPageChange: handlePageChange,
            pageSize: rowsPerPage,
            onPageSizeChange: handlePageSizeChange,
          }}
          loading={isLoadingVendorCatalog}
          error={isErrorVendorCatalog}
          cellStyles={vendorCatalogCellStyles}
        />
      )}
    </MainPaper>
  )
}

export { VendorCatalog }
