/**
 * The `formatPhoneNumber` function takes a phone number as input and returns it formatted with
 * separators.
 * @param {string} phoneNumber - The `phoneNumber` parameter is a string that represents a phone number.
 * It can contain any combination of numbers and non-numeric characters.
 * @param {' ' | '-'} [separator] - The `separator` parameter is an optional parameter that specifies
 * the character used to separate the different parts of the phone number.
 * If no separator is provided, the default separator is a hyphen ('-').
 * @returns The function `formatPhoneNumber` returns a formatted phone number as a string.
 */
export const formatPhoneNumber = (phoneNumber: string, separator?: ' ' | '-'): string => {
  const sep = separator ?? '-'
  const number = phoneNumber?.trim().replace(/[^0-9]/g, '') ?? ''

  if (number.length < 4) return number
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, `$1${sep}$2`)
  if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, `$1${sep}$2${sep}$3`)
  return number.replace(/(\d{3})(\d{4})(\d{4})/, `$1${sep}$2${sep}$3`)
}

/**
 * The function `formatPhoneNumberWithoutSeparator` removes any non-numeric characters from a given phone number string.
 */
export const formatPhoneNumberWithoutSeparator = (phoneNumber: string): string => {
  return phoneNumber
    .trim()
    .replace(/[^0-9]/g, '')
    .slice(0, 9)
}
