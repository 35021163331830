import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { Item, PaginatedResult } from 'core/types'

type Params = {
  ClientId?: string
  DeliverySiteId?: number
  VendorId?: string
  Filters: string
  Sorts?: string
  Page: number
  PageSize: number
  SubcategoryIds?: number[]
  PaymentMethods?: string[]
}

export const useGetProducts = (params: Params) => {
  const { data, isLoading, isFetching, ...other } = useRequest<PaginatedResult<Item>>(apiEndpoints.getProducts(params), {
    keepPreviousData: true,
  })
  return { isLoadingCatalog: isLoading || isFetching, data, ...other }
}
