import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { DeliverySiteManagementTab, RouteVal } from 'config/routeTypes'
import { NotReadyWrapper } from 'core/components'
import { ActionType } from 'core/consts'
import { DeliverySite } from 'core/types'

import { useDeliverSites } from '../../hooks/useDeliverSites'
import ConfirmDeleteDeliverySiteDialog from './ConfirmDeleteDeliverySiteDialog'
import DeliverySitesTable from './DeliverySiteTable'

type DeliverySiteManagementDialog = {
  deliverySite: DeliverySite | null
  actionType: ActionType
}

const DeliverySiteManagement = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [dialogOpen, setDialogOpen] = useState<DeliverySiteManagementDialog | null>(null)

  const { isLoadingDeliverySites, deliverySites, isSuccess, isError, refetchDeliverySites } = useDeliverSites()

  const handleOpenDialog = (actionType: ActionType, deliverySite?: DeliverySite) => {
    setDialogOpen({ actionType, deliverySite: deliverySite ?? null })
  }

  const handleCloseDialog = () => {
    setDialogOpen(null)
  }

  const handleDeleteCallback = () => {
    refetchDeliverySites()
    handleCloseDialog()
  }

  const redirectToAddDeliverySite = () => navigate(`/${RouteVal.DS_MANAGEMENT}/${DeliverySiteManagementTab.ADD_DELIVERY_SITE}`)

  const redirectToDeliverySiteAction = (data: DeliverySite, tab: DeliverySiteManagementTab) =>
    navigate(`/${RouteVal.DS_MANAGEMENT}/${data.id}/${tab}`)

  return (
    <>
      <NotReadyWrapper
        isLoading={isLoadingDeliverySites}
        isSuccess={isSuccess}
        isError={isError}
        errorMsg={t('deliverySites.errors.unexpected.subTitle')}
      >
        {deliverySites && (
          <DeliverySitesTable
            deliverySites={deliverySites}
            onAddButtonClicked={redirectToAddDeliverySite}
            onEdit={data => redirectToDeliverySiteAction(data, DeliverySiteManagementTab.MAIN_DATA)}
            onDelete={site => handleOpenDialog(ActionType.DELETE, site)}
          />
        )}
      </NotReadyWrapper>
      {dialogOpen?.actionType === ActionType.DELETE && (
        <ConfirmDeleteDeliverySiteDialog onClose={handleCloseDialog} onCallback={handleDeleteCallback} site={dialogOpen?.deliverySite} />
      )}
    </>
  )
}

export default DeliverySiteManagement
