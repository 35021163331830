import { useTranslation } from 'react-i18next'

import { Tooltip, Typography, TypographyVariant } from '@mui/material'

interface DailyPriceProps {
  title?: string
  variant?: TypographyVariant
}

export const DailyPrice = ({ title, variant = 'subtitle2' }: DailyPriceProps) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={title ? title : t('common.dailyPriceBasicInfo')}>
      <Typography component="p" sx={{ cursor: 'default', color: 'warning.main' }} variant={variant} fontWeight={700}>
        {t('common.dailyPrice')}
      </Typography>
    </Tooltip>
  )
}
