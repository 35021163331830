import apiEndpoints from 'api/endpoints'
import { useMutate, useRequest } from 'core/hooks'
import { PaginatedResult, PricingRuleSimulatedRes } from 'core/types'

export const usePostPricingRule = () => {
  const { mutate, ...other } = useMutate(apiEndpoints.postPricingRule())
  return { postPricingRule: mutate, ...other }
}

export const usePutPricingRuleSimulateAdd = () => {
  const { mutate, ...others } = useMutate<PricingRuleSimulatedRes>(apiEndpoints.putPricingRuleSimulateAdd())
  return { putPricingRuleSimulateAdd: mutate, ...others }
}

export const useGetPricingRuleList = (params: any) => {
  const { data, ...other } = useRequest<PaginatedResult<any>>(apiEndpoints.getPricingRuleList(params))
  return { pricingRuleListData: data, ...other }
}
