import { SxProps, Theme } from '@mui/material'

export const cardStyles = {
  display: 'flex',
  alignItems: { xs: 'flex-start', md: 'center' },
  flexDirection: 'column',
  cursor: 'pointer',
  justifyContent: 'center',
  border: 1,
  boxShadow: 1,
  py: { xs: 1.5, md: 2 },
  pr: 4,
  pl: 3,
  position: 'relative',
  transition: 'all 0.3s',
  height: '100%',
}

export const checkStyles: SxProps<Theme> = {
  position: 'absolute',
  top: '.5rem',
  right: '.5rem',
}
