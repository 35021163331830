export const enum AddProductErrorCode {
  DISALLOWED_ADD = 'ProductOrder.DisallowedAdd',
}

export const enum CancelOrderErrorCode {
  STRIPE_REFUND_FAILURE = 'Payment.RefundFailure',
}

export const enum OrderApiErrorCode {
  NO_PAYMENT_SELECTED = 'Order.NoPaymentMethod',
  TOO_LOW_AMOUNT_FOR_ONLINE_PAYMENT = 'Order.TooLowAmountForOnlinePayment',
  OrderLogisticMinimum = 'Order.OrderLogisticMinimum',
  IncorrectAmount = 'Order.IncorrectAmount',
  VendorDisabledDeliverySite = 'Order.VendorDisabledDeliverySite',
  IncorrectProductMinCartAmount = 'Product.IncorrectProductMinCartAmount',
}

export const enum EditQuantityInPlacedOrderErrorCode {
  EXCEEDED_PRECHARGE = 'Order.ExceededPrecharge',
  INVALID_QUANTITY = 'ProductOrder.InvalidQuantity',
  NOTHING_TO_UPDATE = 'ProductOrder.NothingToUpdate',
}
