import { AllInclusive } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { PaginatedResult } from 'core/types'
import { getAvatar } from 'core/utils'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

export const mapRulesData = (data: PaginatedResult<any>, _t: TFunction) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.id,
      data: {
        vendor: (
          <Box display="flex" alignItems="center">
            {getAvatar(el.vendor.name, el.vendor.logoUrl)}
            {el.vendor.name}
          </Box>
        ),
        deliverySite: (
          <Box display="flex" alignItems="center">
            {el.deliverySite ? (
              <>
                {getAvatar(el.deliverySite.name, el.deliverySite.logoUrl)}
                {el.deliverySite.name}
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
        category: el.category ? el.category.name : '-',
        product: el.product ? el.product.name : '-',
        validFrom: el.validFrom ? (
          <div>
            <Typography variant="body2">{dayjs(el.validFrom).format('YYYY-MM-DD')}</Typography>
            <Typography variant="body2" color="text.secondary">
              {dayjs(el.validFrom).format('HH:mm')}
            </Typography>
          </div>
        ) : (
          'ASAP'
        ),
        validTo: el.validTo ? (
          <div>
            <Typography variant="body2">{dayjs(el.validTo).format('YYYY-MM-DD')}</Typography>
            <Typography variant="body2" color="text.secondary">
              {dayjs(el.validTo).format('HH:mm')}
            </Typography>
          </div>
        ) : (
          <AllInclusive />
        ),
        specialDiscount: (
          <Typography variant="body2" color={el.specialDiscount === 0 ? 'info.main' : 'inherit'}>
            {el.specialDiscount}%
          </Typography>
        ),
        retroCommission: (
          <Typography variant="body2" color={el.retroCommission === 0 ? 'info.main' : 'inherit'}>
            {el.retroCommission}%
          </Typography>
        ),
        margin: (
          <Typography variant="body2" color={el.margin < 0 ? 'error.main' : el.margin === 0 ? 'info.main' : 'inherit'}>
            {el.margin}%
          </Typography>
        ),
      },
    }
  })

  return mappedData
}

export const mapRulesColumns = (t: TFunction) => [
  { title: t('margins.vendor'), id: 'vendor' },
  { title: t('margins.deliverySite'), id: 'deliverySite' },
  { title: t('margins.category'), id: 'category' },
  { title: t('margins.product'), id: 'product' },
  { title: t('platformManagement.validFrom'), id: 'validFrom' },
  { title: t('platformManagement.validTo'), id: 'validTo' },
  { title: t('platformManagement.specialDiscount'), id: 'specialDiscount' },
  { title: t('platformManagement.retroComission'), id: 'retroComission' },
  { title: t('platformManagement.margin'), id: 'margin' },
]
export const cellStyles = [
  {
    id: 'validFrom',
    styles: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: 'validTo',
    styles: {
      whiteSpace: 'nowrap',
    },
  },
]
