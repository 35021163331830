import { CheckCircleOutline, Circle, ErrorOutline, InfoOutlined, ListAlt, SettingsApplications, WarningAmber } from '@mui/icons-material'
import { UserRole } from 'core/consts'
import { NotificationEventVariant } from 'core/types'

export const getOrderPath = (roles: UserRole[]) => {
  if (roles.includes(UserRole.ADMIN)) {
    return 'admin-orders'
  } else if (roles.includes(UserRole.CLIENT) || roles.includes(UserRole.RESTAURANT_MANAGER)) {
    return 'my-orders'
  } else if (roles.includes(UserRole.VENDOR)) {
    return 'vendor-orders'
  } else {
    return ''
  }
}

export const getNotificationIcon = (nEvent: NotificationEventVariant) => {
  switch (nEvent) {
    case NotificationEventVariant.ERROR:
      return <ErrorOutline color="error" fontSize="large" />

    case NotificationEventVariant.WARNING:
      return <WarningAmber color="warning" fontSize="large" />
    case NotificationEventVariant.INFO:
      return <InfoOutlined color="info" fontSize="large" />
    case NotificationEventVariant.SYSTEM:
      return <SettingsApplications fontSize="large" />
    case NotificationEventVariant.ORDER:
      return <ListAlt color="primary" fontSize="large" />
    case NotificationEventVariant.SUCCESS:
      return <CheckCircleOutline color="success" fontSize="large" />

    default:
      return <Circle color="primary" fontSize="large" />
  }
}
