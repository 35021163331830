import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { DotykackaIntegrationUrlRes } from 'core/types'

export const useDotykackaIntegrationUrl = (deliverySiteId: number | null) => {
  const { data, isFetching, isError, ...other } = useRequest<DotykackaIntegrationUrlRes>(
    apiEndpoints.getDotykackaIntegrationUrl(deliverySiteId ?? 0),
    {
      enabled: !!deliverySiteId,
      retry: 0,
    },
  )

  return { integrationUrlIsLoading: isFetching, integrationUrlData: data, integrationUrlIsError: isError, ...other }
}
