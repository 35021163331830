import { memo } from 'react'

import { Box, Grid, Typography } from '@mui/material'

export interface ChartLegendProps {
  payload: { value: number | string; color: string }[] | undefined
  rounded?: boolean
}

export const ChartLegend = memo(({ payload, rounded }: ChartLegendProps) => {
  return (
    <ul>
      {payload?.map((el: any) => (
        <Grid container alignItems="baseline" key={el.value}>
          <Grid item xs="auto" mr={1}>
            <Box sx={{ width: '0.725rem', height: '0.725rem', bgcolor: el.color, borderRadius: rounded ? '50%' : 0 }} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" color={el.color}>
              {el.value}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </ul>
  )
})
