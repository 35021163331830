import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { HelpOutline } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

interface HelperDialogProps {
  onCloseCallback?: () => void
  title: string
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  children: ReactNode
}

export const HelperDialog = ({ onCloseCallback, title, color, children }: HelperDialogProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    if (typeof onCloseCallback === 'function') {
      onCloseCallback()
    }
  }

  return (
    <>
      <IconButton onClick={handleClickOpen} size="small" color={color}>
        <HelpOutline />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { mx: 1 },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {t(title)}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={theme => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </>
  )
}
