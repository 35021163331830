import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { AdminOrderItem } from 'core/types'
import { GetParams, PaginatedResult } from 'core/types/paginatedResult'

type Params = {
  DeliverySiteId?: string
  VendorId?: string
  Status?: string
  PaymentMethod?: string
} & GetParams

export const useGetAllOrders = (params: Params) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<AdminOrderItem>>(apiEndpoints.getAllOrders(params))
  return { allOrders: data, isLoading, ...other }
}
