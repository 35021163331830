import { memo, useCallback } from 'react'

import ImportExportIcon from '@mui/icons-material/ImportExport'
import { SxProps } from '@mui/material'
import { LabelWithIcon, SearchComponent, Select, StyledSearchContainer } from 'core/components'
import { SortOption } from 'core/types'

export interface SearchWithSortProps {
  onSearch: (searchTerm: string) => void
  sort: string
  onSetSort: (sort: string) => void
  sortOptions: SortOption[]
  sx?: SxProps
}

export const SearchWithSort = memo(({ onSearch, sort, onSetSort, sortOptions, sx = [] }: SearchWithSortProps) => {
  const handleSetSort = useCallback((e: any) => {
    onSetSort(e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledSearchContainer sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
      <SearchComponent onSearch={onSearch} sx={{ flex: 1, maxWidth: '36.5rem' }} />
      <Select
        fullWidth
        name="sort"
        label={<LabelWithIcon label="orders.sortTitle" icon={ImportExportIcon} />}
        value={sort}
        onChange={handleSetSort}
        options={sortOptions}
      />
    </StyledSearchContainer>
  )
})
