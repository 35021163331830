import { useMutation } from '@tanstack/react-query'
import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'

import axiosInstance from '../../api/axiosInstance'

interface ItemStockParams {
  productId: string
}

const getItemStock = async (params: ItemStockParams): Promise<number> => {
  const { data } = await axiosInstance(apiEndpoints.getProductStock(params))
  return data
}

// TODO remove this function and use the useRequest
export function useGetItemStock() {
  const { isLoading, mutateAsync } = useMutation((params: ItemStockParams) => getItemStock(params))

  return { isLoading, fetchItemStock: mutateAsync }
}

export const useGetCurrentItemStock = (params?: ItemStockParams) => {
  const { data, isLoading, refetch } = useRequest<number>(apiEndpoints.getProductStock(params), { enabled: !!params })

  return { stockData: data, isLoading, fetchItemStock: refetch }
}
