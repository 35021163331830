import NotificationHub from 'SignalR/Notifications/NotificationHub'
import axios from 'axios'
import { APP_BASE_URL } from 'core/consts'

import { RefreshTokenPayload, UserResponse } from '../auth/types/types'

export const JWT_TOKEN_KEY = 'jwtToken'
export const USER_RESPONSE_KEY = 'userResponse'

export const refreshToken = async ({ email, refreshToken }: RefreshTokenPayload): Promise<UserResponse> => {
  const { data } = await axios.post<UserResponse>(`${APP_BASE_URL}User/refreshToken/`, { email, refreshToken })
  setJwtToken(data.token, data.refreshToken)
  NotificationHub.connect()
  // window.dispatchEvent(new Event('storage'))
  return data
}

export const setJwtToken = (token: string, refreshToken?: string) => {
  localStorage.setItem(JWT_TOKEN_KEY, token)
  if (refreshToken) {
    const userResponse: UserResponse | null = JSON.parse(localStorage.getItem(USER_RESPONSE_KEY) ?? 'null')
    if (userResponse) {
      userResponse.token = token
      userResponse.refreshToken = refreshToken
      localStorage.setItem(USER_RESPONSE_KEY, JSON.stringify(userResponse))
    }
  }
}

export const getJwtToken = (): string | null => {
  return localStorage.getItem(JWT_TOKEN_KEY)
}

export const getUserInfo = (): UserResponse | null => {
  return JSON.parse(localStorage.getItem(USER_RESPONSE_KEY) ?? 'null')
}

export const handleFailedTokenRefresh = () => {
  localStorage.removeItem(JWT_TOKEN_KEY)
  localStorage.removeItem(USER_RESPONSE_KEY)
  window.location.href = '/login'
}

export const getAuthInfo = (): { email: string; refreshToken: string } | null => {
  const userResponse: UserResponse | null = getUserInfo()
  if (userResponse && userResponse.email && userResponse.refreshToken) {
    return {
      email: userResponse.email,
      refreshToken: userResponse.refreshToken,
    }
  }
  return null
}
