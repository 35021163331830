import { Item } from 'core/types'
import { TFunction } from 'i18next'
import * as Yup from 'yup'

export const initialProductValues = (product: Item) => ({
  name: product.name,
  subcategory: { name: product.category.subcategories[0].name, id: product.category.subcategories[0].id.toString() },
  price: product.price,
  unit: product.unit,
  stock: product.stock,
  vat: product.vatPercent,
  tags: product.tags,
  image: product.images,
  storageAmount: product.storageAmount,
  minCartAmount: product.minCartAmount,
  isActive: product.isActive,
  purchaseIncrement: product.purchaseIncrement,
  file: null,
})

export const validationProductSchema = (t: TFunction) =>
  Yup.object({
    minCartAmount: Yup.number().required(t('common.validations.required')).positive(t('common.validations.positive')),
    purchaseIncrement: Yup.number().nullable().positive(t('common.validations.positive')).integer(t('common.validations.integer')),
  })
