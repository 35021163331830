import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'

interface ClientDetailsResponse {
  name: string | null
  district: string | null
  city: string | null
  postalCode: string | null
  street: string | null
  streetNumber: string | null
  appartmentNumber: string | null
}

export const useClientDetailsByTaxNumber = (taxNumber: string, disabled: boolean) => {
  const { data, ...other } = useRequest<ClientDetailsResponse>(apiEndpoints.getClientDetailsByTaxNumber(taxNumber), {
    enabled: taxNumber.length === 10 && !disabled, // Only run query if taxNumber has 10 digits
  })

  return { data, ...other }
}
