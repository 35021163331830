import { prepareComparisonList } from 'core/utils'

export const minimalLogisticsInit = {
  comparison: prepareComparisonList()[0].id.toString(),
  min: '',
}

export const catalogMainFiltersStateInit = {
  favouriteOnly: false,
  previouslyOrdered: false,
  isInStock: false,
  nextDayDelivery: false,
}

export const catalogFiltersStateInit = {
  isInStock: true,
  favouriteOnly: false,
  previouslyOrdered: false,
  nextDayDelivery: false,
}

export const cataloLogisticsInit = {
  minimalLogisticsComparison: minimalLogisticsInit.comparison,
  minimalLogisticsValue: minimalLogisticsInit.min,
}
