import TouchApp from '@mui/icons-material/TouchApp'
import { Box, Typography } from '@mui/material'
import { AvatarImg, DailyPrice } from 'core/components'
import { ValueRange } from 'core/consts'
import { Item, ProductOrder } from 'core/types'
import { formatPricePerUnit } from 'core/utils/formatUtils'
import { TFunction } from 'i18next'
import noImage from 'items/images/noImage.png'

type Actions = {
  handleSelect: (el: Item) => void
}

export const mapVendorProductsToSwap = (data: Item[], action: Actions, orderProducts: ProductOrder[], t: TFunction) => {
  const mappedData = data.map(el => {
    const sameEl = el.id === orderProducts.find(p => p.productId === el.id)?.productId

    const mappedActions = action
      ? [
          {
            title: 'select',
            icon: <TouchApp />,
            callback: () => action!.handleSelect(el),
            disabled: sameEl,
          },
        ]
      : []

    return {
      rowId: el.id,
      data: {
        img: (
          <AvatarImg
            imgUrl={el.images && el.images.length > 0 ? el.images[0].uri : null}
            img={noImage}
            name={el.name}
            sx={{ width: 40, height: 40 }}
          />
        ),
        name: (
          <Box>
            <Typography variant="inherit">{el.name}</Typography>
            {sameEl && (
              <Typography color="info.main" variant="subtitle2" mt={1}>
                {/* checks if it's swapping or adding new product */}
                {orderProducts.length === 1 ? t('vendor.order.uSwapThisProduct') : t('vendor.order.uAlreadyHaveThisProduct')}
              </Typography>
            )}
          </Box>
        ),
        price: el.isDailyPrice ? <DailyPrice /> : formatPricePerUnit(el.price, el.unit),
        vatPercent: el.vatPercent + '%',
        quantity: el.stock === ValueRange.MAX_POSITIVE_VALUE ? 1000 : el.stock,
      },
      actions: mappedActions,
      styles: sameEl && { backgroundColor: 'grey.50' },
    }
  })
  return mappedData
}

export const mapVendorProductsToSwapColumns = (t: TFunction) => [
  { title: '', id: 'img' },
  { title: t('vendor.catalog.name'), id: 'name' },
  { title: t('basket.priceNet'), id: 'price' },
  { title: t('acceptOrder.vat'), id: 'vatPercent' },
  { title: t('vendor.catalog.quantity'), id: 'quantity' },
]
