import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import { MainTable } from 'core/components'
import { PricingRuleSimulatedRes } from 'core/types'

import { mapSimulatedColumns, mapSimulatedData, simulatedCellStyles } from './mapSimulatedData'
import { fieldBoxStyle } from './styles'

interface SimulateAddPreviewProps {
  simulatedData: PricingRuleSimulatedRes | null
}

const SimulateAddPreview = ({ simulatedData }: SimulateAddPreviewProps) => {
  const { t } = useTranslation()

  return (
    <Box mt={{ xs: 4, md: 6 }}>
      <Typography variant="h4" mb={2}>
        {t('platformManagement.rules.newRuleImpact')}
      </Typography>
      <Box sx={[fieldBoxStyle]}>
        <MainTable columns={mapSimulatedColumns(t)} rows={mapSimulatedData(simulatedData, t)} cellStyles={simulatedCellStyles} />
      </Box>
    </Box>
  )
}

export default SimulateAddPreview
