import { useCallback, useState } from 'react'

import { Typography } from '@mui/material'
import { Autocomplete } from 'core/components'
import { CallbackFnData, DeliverySite, FilterName, SelectOption } from 'core/types'

interface AdminCompanySelectorProps {
  onChange: (selectedCompany: DeliverySite | null) => void
}

export const AdminCompanySelector: React.FC<AdminCompanySelectorProps> = ({ onChange }) => {
  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(null)

  const [deliverySiteData, setDeliverySiteData] = useState<CallbackFnData | null>(null)

  const onChangeFiltersVal = useCallback(
    (val: SelectOption) => {
      if (val) {
        setSelectedCompany(val)
        onChange(val.otherData)
      } else {
        setSelectedCompany(null)
        onChange(null)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliverySiteData],
  )

  const handleCallbackFn = useCallback((data: CallbackFnData) => {
    setDeliverySiteData(data)
  }, [])

  return (
    <>
      <Autocomplete
        name={FilterName.ALL_DELIVERY_SITES}
        filterName={FilterName.ALL_DELIVERY_SITES}
        apiRequest="getAllDeliverySites"
        value={selectedCompany}
        setValue={onChangeFiltersVal}
        callbackFn={handleCallbackFn}
      />

      {deliverySiteData?.callbackType === 'isError' && <Typography>Error loading restaurants</Typography>}
    </>
  )
}
