import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

type OverviewWidgetProps = {
  color?: 'primary' | 'warning' | 'error'
  description: string
  title: string
  progress?: string
}

const OverviewWidgetVendor = ({ description, title, progress }: OverviewWidgetProps) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography gutterBottom component="div" variant="h3">
          {title} <span style={{ color: 'green' }}>{progress}</span>
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default OverviewWidgetVendor
