import { Trans } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { t } from 'i18next'

type DeleteProductDialogProps = {
  onClose: () => void
  processing: boolean
  onSave: () => void
  selectedProductName: string
}

const DeleteProductDialog = ({ onClose, processing, onSave, selectedProductName }: DeleteProductDialogProps) => {
  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('vendorOrders.deleteProduct')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <Trans i18nKey="vendorOrders.deleteProductFromOrder" values={{ productName: selectedProductName }} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" startIcon={<CloseIcon />}>
          {t('common.cancel')}
        </Button>
        <LoadingButton loading={processing} onClick={onSave} color="error" variant="contained" type="submit">
          {t('common.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteProductDialog
