export const globals = {
  html: {
    scrollBehavior: 'smooth',
    '@media (max-width:600px)': {
      fontSize: 14,
    },
  },
  body: {
    overflowY: 'scroll',
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
  },
  'a, a:link, a:visited': {
    textDecoration: 'none',
  },
}
