import { ListAlt, MonetizationOn, ShowChart, SupervisorAccount } from '@mui/icons-material'
import { formatPrice } from 'core/utils'
import { t } from 'i18next'

export const cardsData = (data: any, palette: any) => [
  {
    title: t('vendorFinances.totalIncome'),
    value: `${formatPrice(data.totalIncome)}`,
    icon: MonetizationOn,
    color: palette.success.main,
    linkTitle: t('vendorFinances.viewVendorOrders'),
    linkTo: '/vendor-orders',
  },
  {
    title: t('vendorFinances.totalOrders'),
    value: data.totalOrders,
    icon: ListAlt,
    color: palette.success.main,
    linkTitle: t('vendorFinances.viewVendorOrders'),
    linkTo: '/vendor-orders',
  },
  {
    title: t('vendorFinances.averageOrderValue'),
    value: `${formatPrice(data.averageOrderValue)}`,
    icon: ShowChart,
    color: palette.success.main,
    linkTitle: t('vendorFinances.viewVendorOrders'),
    linkTo: '/vendor-orders',
  },
  {
    title: t('vendorFinances.totalCustomers'),
    value: data.totalCustomers,
    icon: SupervisorAccount,
    color: palette.success.main,
    linkTitle: t('vendorFinances.viewVendorOrders'),
    linkTo: '/vendor-orders',
  },
  {
    title: t('vendorFinances.topPerformingCategory'),
    value: data.topPerformingCategory,
    icon: ListAlt,
    color: palette.info.main,
    linkTitle: t('vendorFinances.viewCatalog'),
    linkTo: '/vendor-catalog',
  },
  {
    title: t('vendorFinances.topPerformingSubcategory'),
    value: data.topPerformingSubcategory,
    icon: ShowChart,
    color: palette.info.main,
    linkTitle: t('vendorFinances.viewCatalog'),
    linkTo: '/vendor-catalog',
  },
  {
    title: t('vendorFinances.highestRevenueProduct'),
    value: data.highestRevenueProduct ? data.highestRevenueProduct.productName : '',
    icon: MonetizationOn,
    color: palette.info.main,
    linkTitle: t('vendorFinances.viewCatalog'),
    linkTo: '/vendor-catalog',
  },
  {
    title: t('vendorFinances.mostSoldProduct'),
    value: data.mostSoldProduct ? data.mostSoldProduct.productName : '',
    icon: ListAlt,
    color: palette.info.main,
    linkTitle: t('vendorFinances.viewCatalog'),
    linkTo: '/vendor-catalog',
  },
]
