import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, AvatarProps, Box, Dialog, DialogContent, DialogTitle, SxProps, Theme, Tooltip } from '@mui/material'
import { APP_IMAGE_URL } from 'core/consts'

export interface AvatarImgProps extends AvatarProps {
  imgUrl?: string | null
  img?: string | null
  name: string
  sx?: SxProps<Theme>
  zoomOnClick?: boolean
}

const AvatarImg = ({ name, imgUrl, img, sx = [], zoomOnClick = false, ...otherProps }: AvatarImgProps) => {
  const { t } = useTranslation()
  const imgUri = imgUrl ? `${APP_IMAGE_URL}${imgUrl}` : img
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleZoomImage = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const avatarComponent = (
    <Avatar
      src={imgUri ?? name}
      alt={name}
      variant="rounded"
      onClick={zoomOnClick && imgUrl ? handleZoomImage : undefined}
      sx={[
        { bgcolor: imgUri ? 'transparent' : 'grey.200', width: 56, height: 56 },
        zoomOnClick && imgUrl ? { cursor: 'pointer' } : {},

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
    />
  )

  return (
    <>
      {zoomOnClick && imgUrl ? (
        <Tooltip title={t('orders.zoomImg')}>
          <Box>{avatarComponent}</Box>
        </Tooltip>
      ) : (
        avatarComponent
      )}
      {zoomOnClick && (
        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
          <DialogTitle>{name}</DialogTitle>
          <DialogContent sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
            <img src={imgUri ?? name} alt={name} style={{ minWidth: '200px' }} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default AvatarImg
