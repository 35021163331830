import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { ClientManagementTab, RouteVal } from 'config/routeTypes'
import { MainPaper, MainTable, PaperTitle } from 'core/components'
import { ActionType } from 'core/consts'
import { Client } from 'core/types'
import { useGetSpingoLimit } from 'items/hooks'
import { WithDeleteDialogInjectedProps, withDeleteDialog } from 'management/components/ClientsManagement/hooks'

import SpingoLimitDialog from './SpingoLimitDialog'
import { cellStyles, mapClientsColumns, mapClientsData } from './mapClientsTable'

enum ClientManagementDialogActionType {
  CHECK_SPINGO = 'check-spingo',
}

type ClientManagementDialog = {
  client: Client | null
  actionType: ActionType | ClientManagementDialogActionType
}

interface ClientManagementProps {
  clients: Client[]
  refetchClients: () => void
}

const ClientManagementContent = withDeleteDialog(({ clients, openDeleteDialog }: ClientManagementProps & WithDeleteDialogInjectedProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dialogOpen, setDialogOpen] = useState<ClientManagementDialog | null>(null)

  // check spingo limit if payment is available
  const { spingoLimitData, spingoLimitIsFetching } = useGetSpingoLimit(
    dialogOpen?.client?.id ?? '',
    dialogOpen?.actionType === ClientManagementDialogActionType.CHECK_SPINGO,
  )

  const handleOpenDialog = (actionType: ActionType | ClientManagementDialogActionType, client?: Client) => {
    setDialogOpen({ actionType, client: client ?? null })
  }

  const handleCloseDialog = () => {
    setDialogOpen(null)
  }

  const redirectToAddClient = () => navigate(`/${RouteVal.CLIENTS_MANAGEMENT}/${ClientManagementTab.ADD_CLIENT}`)

  const redirectToClientAction = (data: Client, tab: ClientManagementTab) => navigate(`/${RouteVal.CLIENTS_MANAGEMENT}/${data.id}/${tab}`)

  return (
    <>
      <MainPaper>
        <PaperTitle
          title={t('clients.title')}
          rightEl={
            <Button variant="outlined" startIcon={<AddIcon />} onClick={redirectToAddClient}>
              {t('clients.add')}
            </Button>
          }
        />
        <MainTable
          columns={mapClientsColumns(t)}
          rows={mapClientsData(clients, t, {
            handleEdit: redirectToClientAction,
            handleDelete: openDeleteDialog,
            handleCheckSpingo: client => handleOpenDialog(ClientManagementDialogActionType.CHECK_SPINGO, client),
          })}
          cellStyles={cellStyles}
          loading={spingoLimitIsFetching}
        />
      </MainPaper>

      {!spingoLimitIsFetching && dialogOpen?.actionType === ClientManagementDialogActionType.CHECK_SPINGO && (
        <SpingoLimitDialog onClose={handleCloseDialog} paymentLimit={spingoLimitData} client={dialogOpen?.client} />
      )}
    </>
  )
})

const ClientManagement = (props: ClientManagementProps) => {
  return <ClientManagementContent onDeleteCallback={() => props.refetchClients} {...props} />
}

export default ClientManagement
