import { StyledComponent } from '@emotion/styled'
import { Box, BoxProps, SxProps, Theme, darken, lighten, styled } from '@mui/material'
import splashLogo from 'core/assets/logo-loop.gif'

import { relativeAspectRatio } from '.'

export const PlayButton: StyledComponent<BoxProps> = styled(Box)<BoxProps>(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    zIndex: 11,
    bottom: '12%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    gap: { xs: 1, md: 2 },
    alignItems: 'center',
    cursor: 'pointer',
    border: 1,
    borderColor: 'primary.light',
    px: { xs: 1, md: 2 },
    py: 1,
    borderRadius: 1.5,
    color: theme.palette.mode === 'dark' ? 'common.black' : 'text.primary',
    boxShadow: '0px .1rem 1.5rem rgba(0,0,0,0.16)',
    transition: 'all 0.3s ease-in-out',
    bgcolor: 'transparent',

    '&:hover': {
      bgcolor: 'common.white',
      boxShadow: '0px 0.5rem 1.725rem rgba(0,0,0,0.2)',
    },
  }),
)

export const StyledContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'aspectRatio',
})<{ aspectRatio: number }>(({ aspectRatio }) => ({
  position: aspectRatio > relativeAspectRatio ? 'static' : 'absolute',
  marginTop: aspectRatio > 2 ? -30 : 0,
  zIndex: 10,
  inset: 0,
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 50%',
  backgroundImage: 'none',
  minHeight: '100%',
}))

export const StyledVideo = styled('video', {
  shouldForwardProp: prop => prop !== 'aspectRatio',
})<{ aspectRatio: number }>(({ aspectRatio }) => ({
  background: `url(${splashLogo}) no-repeat top left transparent`,
  backgroundPosition: 'center',
  margin: 'auto',
  position: aspectRatio > relativeAspectRatio ? 'static' : 'absolute',
  zIndex: '-1',
  top: '50%',
  left: '50%',
  transform: aspectRatio > relativeAspectRatio ? 'none' : 'translate(-50%, -50%)',
  visibility: 'visible',
  opacity: 1,
  width: '100%',
  height: aspectRatio > relativeAspectRatio ? '100%' : '100vh',
  minHeight: '100%',
  objectFit: 'cover',
}))

export const StyledFullVideo = styled('video')({
  marginBottom: -7,
  width: '100%',
  height: '100%',
  objectFit: 'scale-down',
})
export const closeDialogButtonStyle: SxProps<Theme> = {
  position: 'fixed',
  top: '1.3rem',
  right: '1rem',
  color: 'white',
  zIndex: 1301,
  background: (theme: Theme) =>
    theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.5) : lighten(theme.palette.secondary.main, 0.6),
}
