import { NotificationEventType } from 'core/types'

export const notification = {
  notifications: 'Notifications',
  noNewNotifications: 'No new notifications',
  countNotifications: '{{count}} notifications',
  moreThanCountNotifications: 'More than {{count}} notifications',
  [NotificationEventType.OrderAccepted]: {
    title: 'Order has been accepted',
    content: 'Congratulations! Your order has been accepted by {{vendorName}}.',
  },
  [NotificationEventType.OrderPlaced]: {
    title: 'New order available from {{deliverySiteName}}',
    content: 'New order for {{totalPriceGross}}PLN from {{deliverySiteName}} is waiting for you.',
  },
  [NotificationEventType.CatalogUpdated]: {
    title: 'The new product catalog for {{vendorName}} has been successfully imported',
  },
  [NotificationEventType.CatalogUpdateFail]: {
    title: 'Failed to import new product catalog for {{vendorName}}',
    content: 'Try again. If the problem persists, contact Skalo team.',
  },
  [NotificationEventType.OrderCommented]: {
    title: 'Your order {{friendlyOrderNumber}} was commented',
  },
  [NotificationEventType.PosSynchroFinishedSuccess]: {
    title: 'Synchronization with "Dotykacka" completed successfully',
  },
  [NotificationEventType.PosSynchroFinishedFailed]: {
    title: 'Synchronization with "Dotykacka" failed',
    content: 'Try again. If the problem persists, contact Skalo team.',
  },
  [NotificationEventType.AddProductToOrder]: {
    title: '{{productName}} was added to your order {{friendlyOrderNumber}}',
  },
  [NotificationEventType.OrderCancelledByClient]: {
    title: '{{clientName}} cancelled order no: {{friendlyOrderNumber}}',
  },
}
