import { useClients } from 'companies/hooks'
import { NotReadyWrapper } from 'core/components'
import { FinanseSummary } from 'finanse/components'

const Finanse = () => {
  const { isLoadingClients, clients, isSuccess: isSuccessClients, isError: isErrorClients } = useClients()

  return (
    <NotReadyWrapper isLoading={isLoadingClients} isSuccess={isSuccessClients} isError={isErrorClients}>
      {clients && clients?.length > 0 && <FinanseSummary clients={clients} />}
    </NotReadyWrapper>
  )
}

export default Finanse
