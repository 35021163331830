import { initReactI18next } from 'react-i18next'

import { APP_SKALO_SERVER } from 'core/consts'
import { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'

import { messages_en, messages_pl, messages_uk } from './locales'

const i18n = use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: messages_en,
      },
      pl: {
        translation: messages_pl,
      },
      uk: {
        translation: messages_uk,
      },
    },
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    supportedLngs: ['pl', 'en', 'uk', 'de'],
    debug: APP_SKALO_SERVER === 'DEV',
  })
export default i18n
