import {
  AccountBalance,
  Beenhere,
  CheckCircle,
  House,
  LocalShipping,
  MonetizationOn,
  Paid,
  Payment,
  RemoveCircleOutline,
  Restaurant,
  Update,
} from '@mui/icons-material'
import { Box } from '@mui/material'
import { AvatarImg, LabelWithIcon } from 'core/components'
import { OrderStatus, PaymentTypeVal, ProductUnit } from 'core/consts'
import {
  AvailableVendors,
  Category,
  Client,
  DeliverySite,
  FilterName,
  FilterNameType,
  PaginatedResult,
  SelectOption,
  Vendor,
} from 'core/types'
import { statusDisplayProperties } from 'core/types/statusDisplayProperties'
import { TFunction } from 'i18next'

export const selectAll = (list: any, key = 'id') => list.map((item: any) => item[key])

export const ALL_SELECTED = ''

export const selectOne = (selected: any, id: string) => {
  const selectedIndex = selected.indexOf(id)
  let newSelected: string[] = []

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id)
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1))
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1))
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
  }

  return newSelected
}

export const getAvatar = (name: string, img: string | null) => (
  <AvatarImg imgUrl={img} name={name} sx={{ mr: 1.6, width: '2rem', height: '2rem' }} />
)

export const prepareVendorsList = (vendors: AvailableVendors, t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    vendors.map(el => ({
      id: el.id,
      name: el.name,
      icon: getAvatar(el.name, el.logoUrl),
      iconPosition: 'start',
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allVendors'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareAllVendorsList = (vendors: PaginatedResult<Vendor>, t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    vendors.items.map(el => ({
      id: el.id,
      name: el.name,
      icon: getAvatar(el.name, el.vendorLogoUrl),
      iconPosition: 'start',
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allVendors'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareDeliverySitesList = (items: DeliverySite[], t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    items.map(el => ({
      id: el.id.toString(),
      name: el.name,
      icon: getAvatar(el.name, el.deliverySiteLogoUrl ?? null),
      iconPosition: 'start',
      otherData: el,
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allDeliverySites'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareClientsList = (items: Client[], t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    items.map(el => ({
      id: el.id.toString(),
      name: el.name,
      otherData: el,
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allClients'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareAllDeliverySitesList = (data: PaginatedResult<DeliverySite>, t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    data.items.map(el => ({
      id: el.id.toString(),
      name: el.name,
      icon: getAvatar(el.name, el.logoUrl),
      iconPosition: 'start',
      otherData: el,
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allDeliverySites'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const productUnitList = (t: TFunction) =>
  Object.values(ProductUnit).map(val => ({
    id: val as string,
    name: t(`product.unitVal.${val}`),
  }))

export const prepareOrderStatusList = (t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    Object.entries(OrderStatus).map(([key, val]) => ({
      id: key,
      name: t(statusDisplayProperties[val]?.messageKey),
      styles: {
        color: statusDisplayProperties[val]?.color,
        '&.Mui-selected': {
          bgcolor: statusDisplayProperties[val]?.color,
          color: 'common.white',
        },
      },
    })) ?? []

  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allStatuses'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareOrderPaymentMethodsList = (
  t: TFunction,
  others?: { noAll?: boolean; iconSize?: 'small' | 'large'; noRenderedLabel?: boolean },
) => {
  const list: SelectOption[] =
    Object.entries(PaymentTypeVal).map(([key, val]) => ({
      id: key,
      name: t(`payment.paymentsMethodVal.${val}`),
      icon: (
        <Box mr={2} display="flex">
          {getPaymentMethodIcon(val, others?.iconSize ?? 'large')}
        </Box>
      ),
      iconPosition: 'start',
      noRenderedLabel: others?.noRenderedLabel,
    })) ?? []

  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allStatuses'),
    })
  return list
}
export const prepareAllCategoriesList = (items: Category[], t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    items.map(el => ({
      id: el.id.toString(),
      name: el.name,
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allCategories'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareAllSubCategoriesList = (items: Category[], t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    items.flatMap(category =>
      category.subcategories.map(subcategory => ({
        id: subcategory.id.toString(),
        name: subcategory.name,
        otherData: { parent: category.name },
      })),
    ) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allCategories'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareClients = (data: Client[], t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    data.map(el => ({
      id: el.id,
      name: el.name,
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allClientsallClients'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}
export const prepareAllClients = (data: PaginatedResult<Client>, t: TFunction, others?: { noAll?: boolean }) => {
  const list: SelectOption[] =
    data.items.map(el => ({
      id: el.id.toString(),
      name: el.name,
    })) ?? []
  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.allClients'),
      styles: { '& p': { fontWeight: 700 } },
    })
  return list
}

export const prepareActiveClientsList = (t: TFunction, others?: { noAll?: boolean; iconSize?: 'small' | 'large' }) => {
  const list: SelectOption[] = [
    { id: 'true', name: t('filters.active'), icon: <CheckCircle color="success" />, iconPosition: 'start' },
    { id: 'false', name: t('filters.inActive'), icon: <RemoveCircleOutline color="disabled" />, iconPosition: 'start' },
  ]

  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.all'),
    })
  return list
}

export const prepareConfirmedClientsList = (t: TFunction, others?: { noAll?: boolean; iconSize?: 'small' | 'large' }) => {
  const list: SelectOption[] = [
    { id: 'true', name: t('filters.identityConfirmed'), icon: <CheckCircle sx={{ color: 'success.dark' }} />, iconPosition: 'start' },
    { id: 'false', name: t('filters.identityUnconfirmed'), icon: <RemoveCircleOutline color="warning" />, iconPosition: 'start' },
  ]

  !others?.noAll &&
    list.unshift({
      id: ALL_SELECTED,
      name: t('filters.all'),
    })
  return list
}

export const getPaymentMethodIcon = (paymentMethod: PaymentTypeVal, fontSize: 'small' | 'large' = 'small') => {
  switch (paymentMethod) {
    case PaymentTypeVal.CashOnDelivery:
      return <Paid fontSize={fontSize} color="warning" />
    case PaymentTypeVal.DeferredPayment:
      return <Update fontSize={fontSize} />
    case PaymentTypeVal.OnlinePayment:
      return <Payment fontSize={fontSize} color="success" />
    case PaymentTypeVal.Spingo:
      return <AccountBalance fontSize={fontSize} color="info" />
    default:
      return null
  }
}

export const mapPaymentMethods = (data: PaymentTypeVal[], t: TFunction, fontSize?: 'small' | 'large') =>
  data.map(el => ({
    key: el as string,
    title: t(`payment.paymentsMethodVal.${el}`),
    icon: getPaymentMethodIcon(el, fontSize),
  }))

export const mapFiltersData = (filterName: FilterNameType, data: any, t: TFunction, others?: { noAll?: boolean }): SelectOption[] => {
  switch (filterName) {
    case FilterName.DELIVERY_SITES:
      return prepareDeliverySitesList(data, t, others)
    case FilterName.ALL_DELIVERY_SITES:
      return prepareAllDeliverySitesList(data, t, others)
    case FilterName.VENDORS:
      return prepareVendorsList(data, t, others)
    case FilterName.ALL_VENDORS:
      return prepareAllVendorsList(data, t, others)
    case FilterName.ALL_PRODUCT_CATEGORIES:
      return prepareAllCategoriesList(data, t, others)
    case FilterName.ALL_SUBCATEGORIES:
      return prepareAllSubCategoriesList(data, t, others)
    case FilterName.CLIENTS:
      return prepareClientsList(data, t, others)
    case FilterName.ALL_CLIENTS:
      return prepareAllClients(data, t, others)
    case FilterName.POS_PRODUCT_NAME:
      return data?.items.map((el: string) => ({ id: el, name: el }))
    default:
      // for stale data
      return data
  }
}

export const mapFilterLabel = (filterName: FilterNameType, t: TFunction, disabled?: boolean): any => {
  switch (filterName) {
    case FilterName.DELIVERY_SITES:
      return <LabelWithIcon label="userOrders.restaurant" icon={Restaurant} disabled={disabled} />
    case FilterName.ALL_DELIVERY_SITES:
      return <LabelWithIcon label="userOrders.restaurant" icon={Restaurant} disabled={disabled} />
    case FilterName.VENDORS:
      return <LabelWithIcon label="userOrders.vendor" icon={LocalShipping} disabled={disabled} />
    case FilterName.ALL_VENDORS:
      return <LabelWithIcon label="userOrders.vendor" icon={LocalShipping} disabled={disabled} />
    case FilterName.ORDER_STATUS:
      return <LabelWithIcon label="userOrders.status" icon={Beenhere} disabled={disabled} />
    case FilterName.PAYMENT_METHODS:
      return <LabelWithIcon label="userOrders.paymentMethod" icon={MonetizationOn} disabled={disabled} />
    case FilterName.ALL_PRODUCT_CATEGORIES:
      return <LabelWithIcon label="orders.category" icon={Beenhere} disabled={disabled} />
    case FilterName.ALL_SUBCATEGORIES:
      return <LabelWithIcon label="orders.category" icon={Beenhere} disabled={disabled} />
    case FilterName.ALL_CLIENTS:
      return <LabelWithIcon label="margins.client" icon={House} disabled={disabled} />
    case FilterName.CLIENTS:
      return <LabelWithIcon label="clients.companyName" icon={House} disabled={disabled} />
    case FilterName.ACTIVE_CLIENTS:
      return t('clients.activeOrInactive')
    case FilterName.CONFIRMED_CLIENTS:
      return t('clients.identityConfirmedOrNotConfirmed')
    case FilterName.POS_PRODUCT_NAME:
      return t('orders.stockupDotykackaWarehouse.selectOption')
    case FilterName.VALID_FROM:
      return t('margins.validFrom')
    case FilterName.VALID_TO:
      return t('margins.validTo')

    default:
      return filterName
  }
}

export const prepareComparisonList = () => {
  const arr = ['<=', '<', '>=', '>', '==']
  const list: SelectOption[] = arr.map(el => ({ id: el, name: el })) ?? []
  return list
}
