import Box, { BoxProps } from '@mui/material/Box'
import logoBeta from 'core/assets/SkaloBeta.png'
import logoDev from 'core/assets/SkaloDev.png'
// Import the PNG logo
import logoPng from 'core/assets/skalo.png'
import { APP_SKALO_SERVER } from 'core/consts'

const getLogo = () => {
  switch (APP_SKALO_SERVER) {
    case 'DEV':
      return logoDev
    case 'BETA':
      return logoBeta
    default:
      return logoPng
  }
}
type LogoProps = {
  colored?: boolean
  size?: number
} & BoxProps

const Logo = ({ size = 130, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <img src={getLogo()} width={size} alt="Logo" />
    </Box>
  )
}

export default Logo
