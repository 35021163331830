import apiEndpoints from 'api/endpoints'

import { useMutate } from '../useAbstractRequest'

export const usePostGenerateVerificationCode = () => {
  return useMutate(apiEndpoints.postGenerateVerificationCode())
}

export const usePostVerifyVerificationCode = () => {
  return useMutate(apiEndpoints.postVerifyVerificationCode())
}
