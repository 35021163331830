import { Drawer, Theme, lighten, styled } from '@mui/material'

export const menuItemStyle = {
  color: 'common.black',
  textAlign: 'center',
  borderRadius: 1,
  ':hover': {
    background: (theme: Theme) => lighten(theme.palette.secondary.main, 0.8),
  },
  '&.active': {
    background: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
  },
}
export const menuContainerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}

export const FullscreenDrawer = styled(Drawer)({
  '.MuiDrawer-paper': {
    width: '100%',
    height: '100%',
  },
})
