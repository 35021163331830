import EditIcon from '@mui/icons-material/Edit'
import { TFunction } from 'i18next'
import { PaymentMethods } from 'items/types/types'

type Actions = {
  handleEdit: (el: PaymentMethods) => void
}

export const mapPaymentMethodsLimitsData = (data: PaymentMethods[], t: TFunction, action: Actions) => {
  const mappedData = data.map(el => {
    return {
      rowId: el.paymentMethod,
      data: {
        paymentMethod: t(`payment.paymentsMethodVal.${el.paymentMethod}`),
        minimumAmount: el.minimumAmount,
        maximumAmount: el.maximumAmount,
      },
      actions: [
        {
          title: t('common.edit'),
          icon: <EditIcon />,
          callback: () => action.handleEdit(el),
        },
      ],
    }
  })

  return mappedData
}

export const mapPaymentMethodsLimitsColumns = (t: TFunction) => [
  { title: t('paymentsMethods.paymentMethod'), id: 'paymentMethod' },
  { title: t('paymentsMethods.minimumAmount'), id: 'minimumAmount' },
  { title: t('paymentsMethods.maximumAmount'), id: 'maximumAmount' },
  { title: t('common.actions'), id: 'actions', align: 'right' },
]
