import { APP_IMAGE_URL } from 'core/consts'

enum VideoDimension {
  DESKTOP = 'Desktop',
  MOBILE = '9X16',
  TABLET = '4X3',
  TABLET_VERTICAL = '3X4',
}

const getVideoSrc = (d: VideoDimension, ilang: string) => {
  const langMap: { [key: string]: string } = {
    pl: 'Pl',
    en: 'Eng',
    de: 'Ger',
  }
  const lang = langMap[ilang] || 'Pl'

  return {
    src: `${APP_IMAGE_URL}animations/1k/Skalo_short_${d}_${lang}.mp4`,
    src2k: `${APP_IMAGE_URL}animations/2k/Skalo_short_${d}_${lang}.mp4`,
    srcWebm: `${APP_IMAGE_URL}animations/1k_webm/Skalo_short_${d}_${lang}.webm`,
    full: `${APP_IMAGE_URL}animations/2k/Skalo_${lang}.mp4`,
  }
}

export const getVideos = (ilang: string) => {
  const dimensions = [
    { dimension: VideoDimension.DESKTOP, width: 2560, height: 1440 },
    { dimension: VideoDimension.MOBILE, width: 1440, height: 2560 },
    { dimension: VideoDimension.TABLET, width: 2560, height: 1920 },
    { dimension: VideoDimension.TABLET_VERTICAL, width: 1920, height: 2560 },
  ]
  return dimensions.map(({ dimension, width, height }) => ({
    width,
    height,
    ...getVideoSrc(dimension, ilang),
  }))
}
