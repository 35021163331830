import { useEffect, useState } from 'react'

export const useMobileHorizontal = () => {
  const [isMobileHorizontal, setIsMobileHorizontal] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1024
      const isLandscape = window.innerWidth > window.innerHeight
      setIsMobileHorizontal(isMobile && isLandscape)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobileHorizontal
}
