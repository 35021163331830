import { useMemo } from 'react'

import apiEndpoints from 'api/endpoints'
import { FilterName, FilterType } from 'core/types'
import { ALL_SELECTED } from 'core/utils'

export type FiltersState = {
  [FilterName.ALL_VENDORS]: string
}
export const filtersStateInit = {
  [FilterName.ALL_VENDORS]: ALL_SELECTED,
}

export const useCatalogFilters = () => {
  const filters = useMemo(
    () => [
      {
        filterName: FilterName.ALL_VENDORS,
        api: apiEndpoints.getAllVendors({ Page: 1, PageSize: 40 }),
        filterType: FilterType.SELECT,
      },
    ],
    [],
  )

  return {
    filters,
  }
}
