import { useTranslation } from 'react-i18next'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { ControlledTextField } from 'core/components'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { Formik } from 'formik'
import { PaymentMethods } from 'items/types/types'
import { usePostSetPaymentMethodsLimits } from 'management/hooks'
import * as Yup from 'yup'

interface EditDialogProps {
  handleClose: () => void
  paymentData: PaymentMethods
  refetchData: () => void
}

const EditPaymentMethodsLimitsDialog: React.FC<EditDialogProps> = ({ handleClose, paymentData, refetchData }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { postSetPaymentMethodsLimits } = usePostSetPaymentMethodsLimits()

  const handleSaveChanges = (values: { minimumAmount: number; maximumAmount: number | null }) => {
    if (paymentData && values.maximumAmount !== null) {
      const updatedData = {
        paymentMethod: paymentData.paymentMethod,
        minimumAmount: values.minimumAmount,
        maximumAmount: values.maximumAmount,
      }
      postSetPaymentMethodsLimits([updatedData], {
        onSuccess: () => {
          handleClose()
          snackbar.success(t('paymentsMethods.editSuccess'))
          refetchData()
        },
        onError: () => {
          snackbar.error(t('common.errors.unexpected.subTitle'))
        },
      })
    }
  }

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>{t('paymentsMethods.editPayments')}</DialogTitle>
      <Formik
        initialValues={{
          minimumAmount: paymentData.minimumAmount,
          maximumAmount: paymentData.maximumAmount,
        }}
        validationSchema={Yup.object({
          minimumAmount: Yup.number().required(t('common.validations.required')).moreThan(0, t('common.validations.moreThanZero')),
          maximumAmount: Yup.number()
            .required(t('common.validations.required'))
            .moreThan(Yup.ref('minimumAmount'), t('paymentsMethods.incorrectValues')),
        })}
        onSubmit={values => handleSaveChanges(values)}
      >
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <DialogContent>
              <Box justifyContent="center" display="flex" width="100%">
                <Typography variant="h3">{t(`payment.paymentsMethodVal.${paymentData?.paymentMethod}`)}</Typography>
              </Box>
              <ControlledTextField label={t('paymentsMethods.minimumAmount')} name="minimumAmount" margin="normal" />
              <ControlledTextField label={t('paymentsMethods.maximumAmount')} name="maximumAmount" margin="normal" />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t('common.cancel')}</Button>
              <Button type="submit" variant="contained">
                {t('common.saveChanges')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default EditPaymentMethodsLimitsDialog
