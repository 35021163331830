import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { ArrowDownward, ArrowUpward, KeyboardDoubleArrowRight } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useCompanyContext } from 'companies/contexts/CompanyContext'
import { Loader, MainPaper, MainTable, PaperTitle, SearchWithSort } from 'core/components'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { useGetPosIngredients } from 'core/hooks/api'
import { PosPreviouslyOrdered } from 'core/types'
import { TFunction } from 'i18next'
import { useCreateOrderItem, useGetOrderItems } from 'items/hooks'
import { cellStyles, mapPosWarehouseColumns, mapPosWarehouseData } from 'pos/helpers/mapPosWarehouse'

export const PosIngredients = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { selectedCompany } = useCompanyContext()
  const { createOrderItem } = useCreateOrderItem()
  const { refetchOrderItems } = useGetOrderItems(selectedCompany?.id)

  const sortOptions = (t: TFunction) => [
    {
      id: '-stock',
      name: t('administrator.quantityAsc'),
      icon: <ArrowUpward />,
    },
    {
      id: 'stock',
      name: t('administrator.quantityDesc'),
      icon: <ArrowDownward />,
    },
  ]

  const [sort, setSort] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const {
    posIngredients,
    isLoading: isLoadingPosIngredients,
    isError: isErrorPosIngredients,
    isSuccess: isSuccessPosIngredients,
  } = useGetPosIngredients({
    deliverySiteId: Number(selectedCompany?.id),
    Page: currentPage + 1,
    PageSize: pageSize,
    Sorts: sort,
    Filters: searchTerm,
  })

  const handleSearch = (search: string) => {
    setSearchTerm(`(LastPurchase.Name|Name)@=*${search}`)
    setCurrentPage(0)
  }

  const handleConfirmAddToBasket = (product: PosPreviouslyOrdered, quantity: number) => {
    if (selectedCompany) {
      createOrderItem(
        {
          deliverySiteId: selectedCompany.id,
          productId: product.id,
          quantity: quantity,
          vendorId: product.vendorId,
        },
        {
          onSuccess: () => {
            refetchOrderItems()
            snackbar.success(t('orders.basketAdd', { item: product.name }))
          },
          onError: () => {
            snackbar.error(t('orders.basketAddFailed'))
          },
        },
      )
    } else {
      snackbar.error(t('users.selectCompany'))
    }
  }

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const handleSetSort = useCallback((data: string) => {
    setSort(data)
  }, [])

  return (
    <>
      <SearchWithSort sort={sort} sortOptions={sortOptions(t)} onSearch={handleSearch} onSetSort={handleSetSort} />
      <MainPaper sx={{ mt: 2 }}>
        <PaperTitle title={t('pos.warehouse')} />
        {selectedCompany ? (
          <Box position="relative" sx={{ minHeight: 80 }}>
            {isLoadingPosIngredients && <Loader relative />}
            {isErrorPosIngredients && (
              <Typography color="error" sx={{ whiteSpace: 'pre-line' }}>
                {t('common.errors.unexpected.subTitle')}
              </Typography>
            )}
            {isSuccessPosIngredients && (
              <>
                {posIngredients && posIngredients.totalCount > 0 ? (
                  // pos ingredients and product previously ordered :)
                  <MainTable
                    columns={mapPosWarehouseColumns(t)}
                    rows={mapPosWarehouseData(posIngredients, t, { handleConfirmAddToBasket })}
                    count={posIngredients.totalCount}
                    pagination={{
                      page: currentPage,
                      onPageChange: handlePageChange,
                      pageSize,
                      onPageSizeChange: handlePageSizeChange,
                    }}
                    cellStyles={cellStyles}
                  />
                ) : (
                  // products not found for selected deliverySiteId
                  <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                    <Typography>{t('pos.notFoundDotykackaData')}</Typography>
                    <Button component={RouterLink} to="/restaurants" endIcon={<KeyboardDoubleArrowRight />}>
                      {t('common.integrateDotykacka')}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        ) : (
          // deliverySiteId not selected
          <Typography variant="body2" color="text.secondary">
            {t('pos.selectCompanyToSeeWhatYouSold')}
          </Typography>
        )}
      </MainPaper>
    </>
  )
}
