import { ReactNode, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Add, Remove } from '@mui/icons-material'
import { Box, Button, FormHelperText, InputAdornment, LinearProgress, Theme, Tooltip } from '@mui/material'
import { ExclamationMark } from 'core/components'
import { ProductUnit } from 'core/consts'

import { TextField } from '../TextField'
import { StyledActionBtn, boxStyle, boxVerticalStyle, progressStyle } from './styles'

export interface AddToBasketProps {
  stock?: number
  tooltipTitle?: string
  name?: string
  value?: number
  disabled?: boolean
  icon?: ReactNode
  // onAction if action is triggered by user clicking btn
  onAction?: (quantity: number) => void
  // onInputAction if action is triggered when user changes input or clicks -/+
  onInputAction?: (quantity: number) => Promise<number>
  unit?: ProductUnit
  min?: number
  purchaseIncrement?: number | null | undefined
  inline?: boolean
  variableWeightProduct?: boolean
  variableWeightProductHidden?: boolean
}

export const AddToBasket = memo(
  ({
    onAction,
    stock,
    tooltipTitle,
    value,
    name = 'addToBasket',
    onInputAction,
    disabled,
    icon,
    unit = ProductUnit.Piece,
    min,
    purchaseIncrement,
    inline,
    variableWeightProduct,
    variableWeightProductHidden,
  }: AddToBasketProps) => {
    const adjustedMin = purchaseIncrement ?? min ?? 1
    const { t } = useTranslation()
    const [amount, setAmount] = useState<string>(value !== undefined ? value.toString() : '0')
    const [updating, setUpdating] = useState(false)
    const [valueAfterUpdate, setValueAfterUpdate] = useState(0)

    const maxStock = stock || 1000

    const handleChange = (val: string | number) => {
      setAmount(() => {
        const allowDecimal = unit !== ProductUnit.Piece
        const sanitizedVal = sanitizeValue(val.toString(), allowDecimal)
        return parseAndLimitValue(sanitizedVal, maxStock, adjustedMin)
      })
    }

    const handleAction = () => {
      if (typeof onAction === 'function') {
        const val = amount === '0' ? adjustedMin : Number(amount)
        setAmount(val.toString())
        onAction(val)
        setValueAfterUpdate(val)
      }
    }

    const handleDecrease = () => {
      setAmount(val => {
        const newVal = Math.round((Number(val) - (purchaseIncrement ? purchaseIncrement : 1)) * 100) / 100
        return newVal <= 0 ? '0' : adjustedMin && newVal <= adjustedMin ? String(adjustedMin) : String(newVal)
      })
    }

    const handleIncrease = () => {
      setAmount(val => {
        if (val === '0') return adjustedMin.toString()
        const nVal = Number(val)
        let newVal = nVal + 1
        if (purchaseIncrement) {
          const maxV = Math.ceil(nVal / purchaseIncrement) * purchaseIncrement
          const minV = Math.floor(nVal / purchaseIncrement) * purchaseIncrement

          newVal = nVal % purchaseIncrement !== 0 ? (maxV <= maxStock ? maxV : minV) : nVal + purchaseIncrement
        }
        return String(Math.round(newVal * 100) / 100)
      })
    }

    useEffect(() => {
      value !== undefined && setAmount(value.toString())
    }, [value])

    useEffect(() => {
      if (typeof onInputAction === 'function' && value !== Number(amount)) {
        setUpdating(true)
        const delayDebounceFn = setTimeout(() => {
          const onActionRes = onInputAction(Number(amount))
          onActionRes
            .then((val: number) => {
              setAmount(val.toString())
              setUpdating(false)
            })
            .catch((val: number) => {
              setAmount(val.toString())
              setUpdating(false)
            })
        }, 800)
        return () => {
          clearTimeout(delayDebounceFn)
          setUpdating(false)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, value])

    const minVal = min && (
      <>
        <FormHelperText error={amount !== '0' && Number(amount) < adjustedMin}>
          {t('common.minimal', { val: `${adjustedMin} ${t(`product.unitValShort.${unit}`)}` })}
        </FormHelperText>
        {Number(stock) <= adjustedMin - 1 && <FormHelperText>{t('orders.notEnoughInStockToOrder')}</FormHelperText>}
      </>
    )

    const purchaseIncrementVal = purchaseIncrement && (
      <FormHelperText sx={{ color: 'info.dark' }}>
        {t(unit === ProductUnit.Piece ? 'productDetails.productPurchaseIncrementShort' : 'productDetails.productPurchaseIncrementShort1', {
          count: Math.floor(Number(amount) / purchaseIncrement),
        })}
      </FormHelperText>
    )

    const addToCartDisabled = Boolean(stock && stock <= adjustedMin - 1)
    const isPulsated = !addToCartDisabled && Number(amount) !== value && Number(amount) !== valueAfterUpdate

    const decreaseActionDisabled = (stock && stock <= 0) || Number(amount) <= adjustedMin
    const increaseActionDisabled = (stock && stock <= 0) || Number(stock) - Number(amount) < adjustedMin

    return (
      <Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Box sx={inline ? boxVerticalStyle : boxStyle}>
            <Box position="relative">
              <Box display="inline-flex" py={1} px={1}>
                <Tooltip title={decreaseActionDisabled ? '' : t('common.decrease')}>
                  <Box component="span" display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      sx={{ p: 0.2, minWidth: 'unset', mr: 0.5, borderRadius: 2 }}
                      disabled={decreaseActionDisabled}
                      onClick={handleDecrease}
                    >
                      <Remove />
                    </Button>
                  </Box>
                </Tooltip>

                <TextField
                  value={amount.toString()}
                  type="number"
                  onChangeOverride={handleChange}
                  name={`${name}-amount`}
                  variant="standard"
                  inputProps={{ max: maxStock }}
                  disabled={disabled || Number(purchaseIncrement) > 0}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t(`product.unitValShort.${unit}`)}</InputAdornment>,
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && typeof onAction === 'function' && !addToCartDisabled) {
                      e.preventDefault()
                      handleAction()
                    }
                  }}
                  sx={[
                    {
                      minWidth: inline ? '3rem' : '5rem',
                      width: '100%',
                      '& input': { textAlign: 'center' },
                      '& .MuiInput-root .Mui-disabled': {
                        color: (theme: Theme) => `${theme.palette.text.primary} !important`,
                        WebkitTextFillColor: 'inherit',
                      },
                    },
                  ]}
                />
                <Tooltip title={increaseActionDisabled ? '' : t('common.increase')}>
                  <Box component="span" display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      sx={{ p: 0.2, minWidth: 'unset', ml: 1, borderRadius: 2 }}
                      disabled={increaseActionDisabled}
                      onClick={handleIncrease}
                    >
                      <Add />
                    </Button>
                  </Box>
                </Tooltip>
              </Box>
              {updating && <LinearProgress sx={progressStyle} />}
            </Box>
            {inline && (
              <Box m={1.5} mt={0}>
                {minVal}
                {purchaseIncrementVal}
              </Box>
            )}
            {typeof onAction === 'function' && icon && (
              <Tooltip title={(stock && stock === 0) || !tooltipTitle || addToCartDisabled ? '' : tooltipTitle}>
                <span>
                  <StyledActionBtn
                    variant="contained"
                    onClick={handleAction}
                    disabled={addToCartDisabled}
                    pulsate={isPulsated}
                    inline={inline}
                  >
                    {icon}
                  </StyledActionBtn>
                </span>
              </Tooltip>
            )}
          </Box>
          {typeof onInputAction === 'function' && !variableWeightProductHidden && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <ExclamationMark sx={{ visibility: variableWeightProduct ? 'visible' : 'hidden' }} />
            </Box>
          )}
        </Box>
        {!inline && (
          <Box>
            {minVal}
            {purchaseIncrementVal}
          </Box>
        )}
      </Box>
    )
  },
)

export const sanitizeValue = (value: string, allowDecimal: boolean) => {
  // Remove all special characters except for commas and dots and Replace all commas with dots
  const regex = allowDecimal ? /[^0-9,.]/g : /[^0-9]/g
  const sanitized = value.replace(regex, '')

  return allowDecimal ? sanitized.replace(/,/g, '.') : sanitized
}

export const parseAndLimitValue = (value: string, maxStock: number, minStock: number) => {
  if (value === '' || value === '0') return '0'

  // important to handle e.g 2.
  if (/^\d+[.]$/.test(value)) return value

  const parsedVal = Math.round(Number(parseFloat(value).toFixed(2)) * 100) / 100

  if (parsedVal < minStock) return minStock.toString()
  if (parsedVal > maxStock) return maxStock.toString()

  return parsedVal.toString()
}
