import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import { useAuth } from 'auth/contexts/AuthProvider'
import { ChartTooltip, InfoCard, MainPaper, NotReadyWrapper, PaperTitle, Select } from 'core/components'
import { currentYear, formatPrice, getMonthsList } from 'core/utils'
import dayjs from 'dayjs'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useGetVendorMonthlyReport, useGetVendorStatistics } from 'vendor/hooks'
import { useGetVendorsList } from 'vendor/hooks/useGetVendorsList'
import { cardsData } from 'vendor/utils'

const DashboardVendor = () => {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const { userInfo } = useAuth()
  const [month, setMonth] = useState(dayjs().month().toString())

  const { vendorsList } = useGetVendorsList()
  const vendorId = vendorsList && vendorsList.length > 0 ? vendorsList[0].id : ''

  const { vendorStatistics, isLoading, isSuccess, isError } = useGetVendorStatistics({ vendorId }, vendorId)
  const { vendorMonthlyReport } = useGetVendorMonthlyReport({ month: parseInt(month) + 1, year: currentYear, vendorId }, vendorId)
  return (
    <NotReadyWrapper isLoading={isLoading} isSuccess={isSuccess} isError={isError}>
      {vendorStatistics && (
        <>
          {/* title */}
          <Box>
            <Typography variant="h1" fontWeight={500} mb={2}>
              <Trans
                i18nKey="dashboard.welcome"
                values={{
                  name: userInfo?.firstName,
                }}
              />
            </Typography>
            <Typography color="textSecondary" component="p" gutterBottom mb={4} variant="subtitle1">
              {t('admin.home.welcome.message')}
            </Typography>
          </Box>

          {/* cards */}
          {vendorStatistics.totalOrders < 1 && (
            <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
              <Typography variant="subtitle1">{t('dashboard.noOrdersInfo')}</Typography>
            </Alert>
          )}
          <Grid container spacing={2} mb={6}>
            {cardsData(vendorStatistics, palette).map(el => (
              <Grid key={el.title} item xs={12} sm={6} xl={3}>
                <InfoCard {...el} />
              </Grid>
            ))}
          </Grid>
          {/* lineChart */}
          {vendorMonthlyReport && (
            <MainPaper sx={{ mb: 2, position: 'relative' }}>
              <PaperTitle
                sx={{ gap: 2 }}
                title={
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant="h3">{t('dashboard.monthlyIncome')}</Typography>
                    <Select
                      label={t('finanse.Month')}
                      name="month"
                      value={month}
                      onChange={e => setMonth(e.target.value)}
                      options={getMonthsList()}
                      sx={{ width: { xs: '100%', sm: '10rem' } }}
                    />
                  </Box>
                }
                rightEl={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body1">{t('common.sum')}</Typography>
                    <Typography variant="subtitle1">{formatPrice(vendorMonthlyReport.totalIncome)}</Typography>
                  </Box>
                }
              />

              <ResponsiveContainer width="99%" height={244}>
                <LineChart width={500} height={300} data={vendorMonthlyReport.dailyIncome}>
                  <XAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} dataKey="day" tickMargin={8} />
                  <YAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} tickMargin={8} />
                  <CartesianGrid strokeDasharray="1 3" vertical={false} />
                  <Line type="linear" dataKey="value" stroke={palette.primary.main} strokeWidth={4} activeDot={{ r: 4 }} />
                  <Tooltip
                    content={({ active, payload, label }: any) => (
                      <ChartTooltip
                        active={active}
                        label={label}
                        payload={payload?.map((el: any) => ({
                          value: el.value,
                          color: el.stroke,
                        }))}
                        rounded
                      />
                    )}
                  />
                </LineChart>
              </ResponsiveContainer>
            </MainPaper>
          )}
        </>
      )}
    </NotReadyWrapper>
  )
}

export default DashboardVendor
