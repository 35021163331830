import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ControlledTextField, Loader } from 'core/components'
import { ValueRange } from 'core/consts'
import { ProductOrder } from 'core/types'
import { Formik } from 'formik'
import { t } from 'i18next'
import { useGetCurrentItemStock } from 'items/hooks/useGetItemStock'
import { UpdateSingleProductOrder } from 'vendor/types/types'
import * as Yup from 'yup'

type EditOrderDetailDialogProps = {
  onClose: () => void
  orderDetail: ProductOrder
  onSave: (updateOrder: UpdateSingleProductOrder) => void
  processing: boolean
}

const EditOrderDetailDialog: React.FC<EditOrderDetailDialogProps> = ({ onClose, orderDetail, onSave, processing }) => {
  const { stockData, isLoading: isLoadingStock } = useGetCurrentItemStock({ productId: orderDetail.productId })
  const customStock = stockData === ValueRange.MAX_POSITIVE_VALUE ? 1000 : stockData

  const initialValues = {
    productOrderId: orderDetail.id,
    quantity: orderDetail.quantity,
    comment: '',
  }
  const maxQuantity = initialValues.quantity + (customStock ?? 0)

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .positive(t('common.validations.positive'))
      .test('lessOrEqual', t('common.validations.lessOrEqual', { quantity: maxQuantity }), function () {
        const { quantity } = this.parent
        return quantity <= maxQuantity
      })
      .test('valueChanged', t('common.validations.valueChanged', { quantity: initialValues.quantity }), function () {
        const { quantity } = this.parent
        return quantity !== initialValues.quantity
      })
      .required(t('common.validations.required')),
  })

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{`${t('orders.editItem')} ${orderDetail.productName}`}</DialogTitle>
      {isLoadingStock && <Loader relative />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSave({ ...values, quantity: Number(values.quantity), comment: values.comment.trim() })
        }}
      >
        {(formik: any) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent>
                <ControlledTextField
                  name="quantity"
                  type="number"
                  label={t('acceptOrder.quantity')}
                  helperText={`${t('orders.availableStock')} ${maxQuantity}`}
                  sx={{ mb: 2 }}
                />
                <ControlledTextField name="comment" label={t('vendorOrders.addComment')} />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined" startIcon={<CloseIcon />}>
                  {t('common.cancel')}
                </Button>
                <LoadingButton loading={processing} variant="contained" type="submit">
                  {t('common.save')}
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default EditOrderDetailDialog
