import React from 'react'

import { TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { Field, FormikValues, getIn, useFormikContext } from 'formik'

import { TagsInput } from './TagsInput'

export type ControlledTagsInputProps = { name: string } & MuiTextFieldProps & FormikValues

export const ControlledTagsInput: React.FC<ControlledTagsInputProps> = ({ name, ...otherProps }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext<FormikValues>()

  const error = getIn(errors, name) && getIn(touched, name)
  const errorMsg = getIn(errors, name)

  return (
    <Field
      as={TagsInput}
      name={name}
      error={error}
      helperText={errorMsg}
      selectedItems={values.tags}
      setSelectedItems={(tags: string[]) => setFieldValue(name, tags)}
      {...otherProps}
    />
  )
}
