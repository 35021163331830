import { memo, useCallback } from 'react'

import { Grid, SxProps } from '@mui/material'
import { RadioCard } from 'core/components'

export interface CardGroupProps {
  items: {
    title: React.ReactNode
    subtitle?: React.ReactNode
    icon?: React.ReactNode | JSX.Element
    key: string | number
    disabled?: boolean
  }[]
  disabled?: boolean
  selectedValue: string | number | null
  onChange: (selected: string) => void
  sx?: SxProps
}

export const CardGroup = memo(function CardGroup({ items, disabled, selectedValue, onChange }: CardGroupProps) {
  const handleChange = useCallback((key: string | number) => {
    onChange(key.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container direction={{ xs: 'row' }} justifyContent="flex-start" spacing={{ xs: 2 }}>
      {items.map((el, idx) => (
        <Grid item xs={12} sm={items.length === 1 ? 12 : 6} md="auto" key={idx}>
          <RadioCard
            title={el.title}
            subtitle={el.subtitle}
            icon={el.icon}
            selected={el.key === selectedValue}
            onSelect={() => handleChange(el.key)}
            disabled={el.disabled || disabled}
          />
        </Grid>
      ))}
    </Grid>
  )
})
