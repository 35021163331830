import { useTranslation } from 'react-i18next'

import { Box, SvgIconProps, SxProps } from '@mui/material'

export interface LabelWithIconProps {
  label: string
  icon: React.FC<SvgIconProps>
  sx?: SxProps
  disabled?: boolean
}

export const LabelWithIcon = ({ label, icon, disabled, sx = [] }: LabelWithIconProps) => {
  const { t } = useTranslation()
  const Icon = icon
  return (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      gap={1}
      sx={[{ color: disabled ? 'grey.300' : 'inherit' }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Icon sx={{ color: disabled ? 'grey.300' : 'grey.500' }} />
      {t(label)}
    </Box>
  )
}
