import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { PaginatedResult } from 'core/types'

import { VendorOrdersParams, VendorOrdersType } from '../types/types'

export const useGetVendorOrders = (params: VendorOrdersParams) => {
  const { data, ...other } = useRequest<PaginatedResult<VendorOrdersType>>(apiEndpoints.getVendorOrders(params))

  return { vendorOrders: data, ...other }
}
