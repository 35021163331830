import { ClientManagementTab, RouteVal } from 'config/routeTypes'
import { NotificationEventType, NotificationEventVariant, NotificationItem } from 'core/types'
import { TFunction } from 'i18next'

export function notificationItemFactory(eventType: NotificationEventType, t: TFunction, params: any): NotificationItem {
  switch (eventType) {
    case NotificationEventType.OrderPlaced:
      return {
        eventKey: NotificationEventType.OrderPlaced,
        variant: NotificationEventVariant.ORDER,
        title: t(`notification.${NotificationEventType.OrderPlaced}.title`, { deliverySiteName: params.deliverySiteName }),
        content: t(`notification.${NotificationEventType.OrderPlaced}.content`, {
          totalPriceGross: params.totalPriceGross,
          deliverySiteName: params.deliverySiteName,
        }),
        orderId: params.orderId,
      }

    case NotificationEventType.OrderAccepted:
      return {
        eventKey: NotificationEventType.OrderAccepted,
        variant: NotificationEventVariant.SUCCESS,
        title: t(`notification.${NotificationEventType.OrderAccepted}.title`),
        content: t(`notification.${NotificationEventType.OrderAccepted}.content`, { vendorName: params.vendorName }),
        orderId: params.orderId,
      }

    case NotificationEventType.OrderAcceptedWithModification:
      return {
        eventKey: NotificationEventType.OrderAcceptedWithModification,
        variant: NotificationEventVariant.SUCCESS,
        title: t(`notification.${NotificationEventType.OrderAcceptedWithModification}.title`),
        content: t(`notification.${NotificationEventType.OrderAcceptedWithModification}.content`, { vendorName: params.vendorName }),
        orderId: params.orderId,
      }

    case NotificationEventType.CatalogUpdated:
      return {
        eventKey: NotificationEventType.CatalogUpdated,
        variant: NotificationEventVariant.SYSTEM,
        title: t(`notification.${NotificationEventType.CatalogUpdated}.title`, { vendorName: params.vendorName }),
      }

    case NotificationEventType.CatalogUpdateFail:
      return {
        eventKey: NotificationEventType.CatalogUpdated,
        variant: NotificationEventVariant.ERROR,
        title: t(`notification.${NotificationEventType.CatalogUpdateFail}.title`, { vendorName: params.vendorName }),
        content: t(`notification.${NotificationEventType.CatalogUpdateFail}.content`),
      }

    case NotificationEventType.ProductOrdersMatched:
      return {
        eventKey: NotificationEventType.ProductOrdersMatched,
        variant: NotificationEventVariant.INFO,
        title: t(`notification.${NotificationEventType.ProductOrdersMatched}.title`),
        content: t(`notification.${NotificationEventType.ProductOrdersMatched}.content`, {
          vendorName: params.vendorName,
          friendlyOrderNumber: params.friendlyOrderNumber,
        }),
      }

    case NotificationEventType.ClientCreated:
      return {
        eventKey: NotificationEventType.ClientCreated,
        variant: NotificationEventVariant.SYSTEM,
        title: t(`notification.${NotificationEventType.ClientCreated}.title`),
        content: t(`notification.${NotificationEventType.ClientCreated}.content`, {
          clientName: params.clientName,
          clientTaxId: params.clientTaxId,
          creatingUser: params.creatingUser,
        }),
        link: {
          title: t('notification.goToClientDetails'),
          to: `/${RouteVal.CLIENTS_MANAGEMENT}/${params.clientId}/${ClientManagementTab.DETAILS}`,
        },
      }

    case NotificationEventType.OrderProcessing:
      return {
        eventKey: NotificationEventType.OrderProcessing,
        variant: NotificationEventVariant.ORDER,
        title: t(`notification.${NotificationEventType.OrderProcessing}.title`),
        content: t(`notification.${NotificationEventType.OrderProcessing}.content`, {
          vendorName: params.vendorName,
        }),
        orderId: params.orderId,
      }
    case NotificationEventType.OrderCommented:
      return {
        eventKey: NotificationEventType.OrderCommented,
        variant: NotificationEventVariant.INFO,
        title: t(`notification.${NotificationEventType.OrderCommented}.title`, { friendlyOrderNumber: params.friendlyOrderNumber }),
        orderId: params.orderId,
      }
    case NotificationEventType.OrderUnaccepted:
      return {
        eventKey: NotificationEventType.OrderUnaccepted,
        variant: NotificationEventVariant.WARNING,
        title: t(`notification.${NotificationEventType.OrderUnaccepted}.title`, { count: params.count }),
      }

    case NotificationEventType.PosSynchroFinishedSuccess:
      return {
        eventKey: NotificationEventType.PosSynchroFinishedSuccess,
        variant: NotificationEventVariant.SUCCESS,
        title: t(`notification.${NotificationEventType.PosSynchroFinishedSuccess}.title`),
      }

    case NotificationEventType.PosSynchroFinishedFailed:
      return {
        eventKey: NotificationEventType.PosSynchroFinishedFailed,
        variant: NotificationEventVariant.ERROR,
        title: t(`notification.${NotificationEventType.PosSynchroFinishedFailed}.title`),
        content: t(`notification.${NotificationEventType.PosSynchroFinishedFailed}.content`),
      }

    case NotificationEventType.AddProductToOrder:
      return {
        eventKey: NotificationEventType.AddProductToOrder,
        variant: NotificationEventVariant.INFO,
        title: t(`notification.${NotificationEventType.AddProductToOrder}.title`, {
          friendlyOrderNumber: params.friendlyOrderNumber,
          productName: params.productName,
        }),
        orderId: params.orderId,
      }

    case NotificationEventType.OrderCancelledByClient:
      return {
        eventKey: NotificationEventType.OrderCancelledByClient,
        variant: NotificationEventVariant.WARNING,
        title: t(`notification.${NotificationEventType.OrderCancelledByClient}.title`, {
          friendlyOrderNumber: params.friendlyOrderNumber,
          clientName: params.clientName,
        }),
        orderId: params.orderId,
      }
  }
}

export const notificationsLabel = (count: number, t: TFunction) => {
  if (count === 0) {
    return t('notification.noNewNotifications')
  }
  if (count > 99) {
    return t('notification.moreThanCountNotifications', { count })
  }
  return t('notification.countNotifications', { count })
}
