import { useTranslation } from 'react-i18next'

import { Alert, Box, InputAdornment, Typography } from '@mui/material'
import { TextField } from 'core/components'
import { lighterSecondaryBgr } from 'core/theme/palette'
import { RulePriceValues } from 'management/types'

import { FiltersState } from '.'
import RuleSummaryItem from './RuleSummaryItem'
import { fieldBoxStyle } from './styles'

interface RuleSummaryContainerProps {
  rulePriceValues: RulePriceValues
  onChangePriceValues: (pkey: keyof RulePriceValues, val: string | number) => void
  filtersState: FiltersState
}

const RuleSummaryContainer = ({ filtersState, rulePriceValues, onChangePriceValues }: RuleSummaryContainerProps) => {
  const { t } = useTranslation()

  const numberField = (pkey: keyof RulePriceValues, other: any) => (
    <TextField
      type="number"
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      name={pkey}
      label={t(`platformManagement.${pkey}`)}
      value={rulePriceValues[pkey]}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      onChangeOverride={val => onChangePriceValues(pkey, val)}
      {...other}
    />
  )

  return (
    <>
      {filtersState.allVendors ? (
        <RuleSummaryItem label={t('margins.vendor')} noIcon>
          {filtersState.allVendors?.name}
        </RuleSummaryItem>
      ) : (
        <Alert variant="outlined" severity="info">
          <Typography variant="subtitle1">{t('vendors.filters')}</Typography>
        </Alert>
      )}
      {filtersState.allDeliverySites && (
        <RuleSummaryItem label={t('margins.deliverySite')}>{filtersState.allDeliverySites?.name}</RuleSummaryItem>
      )}
      {filtersState.allVendors && filtersState.allProductCategories && (
        <RuleSummaryItem label={t('margins.category')}>{filtersState.allProductCategories?.name}</RuleSummaryItem>
      )}
      {filtersState.allVendors && filtersState.product && (
        <RuleSummaryItem label={t('margins.product')}>{filtersState.allVendors && filtersState.product?.name}</RuleSummaryItem>
      )}
      {filtersState.allVendors && (
        <Box sx={[fieldBoxStyle, { bgcolor: lighterSecondaryBgr }]} mt={3}>
          <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }} my={2}>
            {numberField('specialDiscount', { inputProps: { min: 0, max: 100 } })}
            {numberField('retroComission', { inputProps: { min: 0, max: 100 } })}
            {numberField('margin', { inputProps: { min: -99.9, max: 1000 } })}
          </Box>
        </Box>
      )}
    </>
  )
}

export default RuleSummaryContainer
