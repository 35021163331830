import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { MainFilters, MainPaper, MainTable } from 'core/components'
import { ActionType } from 'core/consts'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { adminMinimalPriceRulesSortOptions, createMinimalPriceRulesFilters } from 'management/helpers'
import { useDeleteMinimalPrice, useGetMinimalPriceList } from 'management/hooks'
import { MinimalPrice } from 'management/types'

import RuleForm from './RuleForm'
import { mapMinimalPriceRulesColumns, mapMinimalPriceRulesData } from './mapRules'
import { MinimalPriceFiltersState, minimalPriceFiltersStateInit, useFilters } from './useFilters'

const MinimalPriceRules = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const [filtersState, setFiltersState] = useState<MinimalPriceFiltersState>(minimalPriceFiltersStateInit)

  type SelectedRuleType = {
    rule: MinimalPrice
    actionType: ActionType
  }

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRule, setSelectedRule] = useState<SelectedRuleType | null>(null)

  const { deleteMinimalPriceRule } = useDeleteMinimalPrice(selectedRule?.rule.productId ?? '')

  const sortOptions = useMemo(() => adminMinimalPriceRulesSortOptions(t), [t])
  const [sort, setSort] = useState(sortOptions.find(el => el.id === 'product.name')?.id ?? sortOptions[0].id)

  const { filters } = useFilters()

  const {
    minimalPriceRuleListData,
    isFetching,
    isError,
    refetch: refetchMinimalPriceRuleListData,
  } = useGetMinimalPriceList({
    Filters: createMinimalPriceRulesFilters(searchTerm, filtersState),
    Sorts: sort,
    Page: page + 1,
    PageSize: pageSize,
  })

  const [ruleFormOpen, setRuleFormOpen] = useState(false)

  const onAddRuleOpen = () => {
    setRuleFormOpen(!ruleFormOpen)
  }

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage)
  }, [])
  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize)
  }, [])

  const handleSearch = useCallback((search: string) => {
    setSearchTerm(`(Product.Vendor.Name|Product.Name)@=*${search}`)
    setPage(0)
  }, [])

  const onSetFiltersState = useCallback((data: any) => {
    setPage(0)
    setFiltersState(data)
  }, [])

  const onSetSort = useCallback((data: string) => {
    setSort(data)
  }, [])

  const clearAllFilters = useCallback(() => {
    setPage(0)
    setFiltersState(minimalPriceFiltersStateInit)
  }, [])

  const handleCloseDeleteRuleDialog = () => {
    setSelectedRule(null)
  }

  const handleOpenDeleteRuleDialog = (rule: MinimalPrice) => {
    setSelectedRule({
      rule,
      actionType: ActionType.DELETE,
    })
  }

  const handleDeleteRule = async () => {
    await deleteMinimalPriceRule(
      {},
      {
        onSuccess: () => {
          refetchMinimalPriceRuleListData()
          handleCloseDeleteRuleDialog()
          snackbar.success(t('platformManagement.rules.deleteRuleCallback.success'))
        },
        onError: () => {
          snackbar.error(t('platformManagement.rules.deleteRuleCallback.error'))
        },
      },
    )
  }

  return (
    <>
      <Box mb={4}>
        <Button variant="contained" onClick={onAddRuleOpen}>
          {t('platformManagement.action.addMinimalPriceRule')}
        </Button>
      </Box>
      <Box position="relative">
        <RuleForm open={ruleFormOpen} onClose={onAddRuleOpen} refetchMinimalPriceRuleListData={refetchMinimalPriceRuleListData} />
      </Box>
      <MainFilters
        filters={filters}
        handleSearch={handleSearch}
        sort={sort}
        setSort={onSetSort}
        sortOptions={sortOptions}
        filtersState={filtersState}
        setFiltersState={onSetFiltersState}
        clearAllFilters={clearAllFilters}
      />
      <MainPaper sx={{ position: 'relative', flex: 1 }}>
        <MainTable
          columns={mapMinimalPriceRulesColumns(t)}
          rows={mapMinimalPriceRulesData(minimalPriceRuleListData ?? { totalCount: 0, items: [] }, t, {
            handleDelete: handleOpenDeleteRuleDialog,
          })}
          count={minimalPriceRuleListData?.totalCount}
          pagination={{
            page: page,
            onPageChange: handlePageChange,
            pageSize,
            onPageSizeChange: handlePageSizeChange,
          }}
          loading={isFetching}
          error={isError}
        />
      </MainPaper>
      <Dialog open={selectedRule?.actionType === ActionType.DELETE} fullWidth maxWidth="sm">
        <DialogTitle>{t('platformManagement.action.deleteRule')}</DialogTitle>
        <DialogContent>
          <Typography variant="h4" mb={2}>
            {t('platformManagement.action.deleteRuleInfo')}
          </Typography>{' '}
          <Typography variant="h2">{selectedRule?.rule.productName}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteRuleDialog} variant="outlined" startIcon={<Close />} color="primary">
            {t('common.close')}
          </Button>
          <Button color="error" onClick={handleDeleteRule} variant="contained">
            {t('common.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default MinimalPriceRules
