import * as signalR from '@microsoft/signalr'
import { HubConnectionState, IHttpConnectionOptions } from '@microsoft/signalr'
import { getJwtToken } from 'api/tokenManagement'
import { APP_NOTIFICATION_HUB } from 'core/consts'
import { NotificationEventFn, NotificationEventFnType, NotificationEventType } from 'core/types'

class NotificationHub {
  private connection: signalR.HubConnection
  static instance: NotificationHub
  connOptions: IHttpConnectionOptions
  public events: (fn: NotificationEventFn) => void

  constructor() {
    this.connOptions = { accessTokenFactory: () => this.readToken()! }
    this.connection = new signalR.HubConnectionBuilder().withUrl(APP_NOTIFICATION_HUB, this.connOptions).withAutomaticReconnect().build()

    this.connect()

    this.events = (evenfFn: NotificationEventFn) => {
      Object.values(NotificationEventType).forEach(eventType => {
        this.connection.on(eventType, notificationData => {
          if (typeof evenfFn[NotificationEventFnType[eventType]] === 'function') {
            evenfFn[NotificationEventFnType[eventType]]?.(notificationData)
          }
        })
      })
    }
  }

  private canConnect(): boolean {
    try {
      return this.connection.state == HubConnectionState.Disconnected && (this.readToken()?.length ?? 0) > 0
    } catch {
      return false
    }
  }

  private readToken(): string | null {
    return getJwtToken()
  }

  public connect(): void {
    if (!this.canConnect()) {
      return
    }
    this.connection.start().catch(err => {
      console.error(err)
    })
  }

  public disconnect(): void {
    this.connection.stop()
  }

  public static getInstance(): NotificationHub {
    if (!NotificationHub.instance) {
      NotificationHub.instance = new NotificationHub()
    }
    return NotificationHub.instance
  }
}

export default NotificationHub.getInstance()
