import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { ClientBaseReportRes, ClientDetailedReportRes } from 'core/types'

export const useClientBaseReport = (params: { clientId: string; year?: string }) => {
  const { data, isLoading, error, ...other } = useRequest<ClientBaseReportRes>(apiEndpoints.getClientBaseReport(params), {
    enabled: !!params.clientId,
  })

  return { isLoadingClientBaseReport: isLoading, clientBaseReportData: data, error, ...other }
}

export const useClientDetailedReport = (params: { clientId: string; year: string; month: string }) => {
  const { data, isLoading, error, ...other } = useRequest<ClientDetailedReportRes>(apiEndpoints.getClientDetailedReport(params), {
    enabled: !!params.clientId,
  })

  return { isLoadingClientDetailedReport: isLoading, clientDetailedReportData: data, error, ...other }
}
