import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Container, Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import Logo from 'core/components/Logo'
import { UserRoute } from 'core/consts'

import { FullscreenDrawer, menuContainerStyle, menuItemStyle } from './styles'

interface MobileMenuProps {
  items: any
}
export const MobileMenu = ({ items }: MobileMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return
    }
    setMenuOpen(open)
  }

  return (
    <>
      <IconButton sx={{ display: { sm: 'none' }, mr: 1 }} color="inherit" aria-label="open drawer" edge="end" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Box component="nav">
        <FullscreenDrawer anchor="left" open={menuOpen} onClose={toggleDrawer(false)}>
          <Box display="flex" justifyContent="end" p={2.5} mr={2}>
            <IconButton color="inherit" onClick={toggleDrawer(false)} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={menuContainerStyle} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <NavLink to={UserRoute.HOME}>
              <Logo size={100} />
            </NavLink>
            <Container sx={{ my: 2 }}>
              <Divider />
            </Container>
            <List sx={{ width: '100%' }}>
              {items.map((el: any) => (
                <ListItem key={el.title} sx={{ pb: 0.5, mb: -0.5, justifyContent: 'center' }}>
                  <ListItemButton component={NavLink} to={el.to ?? ''} sx={menuItemStyle}>
                    <ListItemText primary={el.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </FullscreenDrawer>
      </Box>
    </>
  )
}
