import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'

export const useSynchronizeDotykackaSell = (params: { deliverySiteId: number; from?: string }) => {
  const { data, isFetching, isError, refetch, ...other } = useRequest(apiEndpoints.getSynchronizeDotykackaSell(params), {
    enabled: false,
    retry: 1,
  })

  return {
    syncDotykackaSellIsLoading: isFetching,
    syncDotykackaSellData: data,
    syncDotykackaSellIsError: isError,
    syncDotykackaSellRefetch: refetch,
    ...other,
  }
}
