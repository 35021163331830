import { SxProps, Theme } from '@mui/material'
import { lighterSecondaryBgr } from 'core/theme/palette'

export const selectedContainerStyle: SxProps<Theme> = {
  px: 2,
  py: 1,
  bgcolor: 'background.paper',
  borderRadius: 1,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 1,
  boxShadow: 1,
  '&:hover': {
    boxShadow: 2,
  },
}

export const selectedCountContainerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  pr: { xs: 0, sm: 2 },
  mr: 2,
  borderRight: { xs: 0, sm: 1 },
  borderRightColor: { xs: 0, sm: 'grey.300' },
}

export const selectedCountStyle: SxProps<Theme> = {
  p: 1,
  bgcolor: lighterSecondaryBgr,
  borderRadius: 0.5,
  minWidth: 40,
  textAlign: 'center',
}

export const noDataStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  px: 2,
}
