import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { User } from 'core/types'
import { GetParams, PaginatedResult } from 'core/types/paginatedResult'

type Params = {
  Filters: any
  Sorts?: string
  Page: number
  PageSize: number
}

export const useUsers = (params: Params) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<User>>(apiEndpoints.getAllUsers(params))
  return { usersData: data, isLoading, ...other }
}

type AllUsersForClientParams = {
  ClientId: string
} & GetParams

export const useUsersForClient = (params: AllUsersForClientParams) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<User>>(apiEndpoints.getAllUsersForClient(params))
  return { usersData: data, isLoading, ...other }
}
