import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import HelpIcon from '@mui/icons-material/Help'
import { Box, IconButton, Link, Typography } from '@mui/material'
import { RouteVal } from 'config/routeTypes'
import { NoAuthLayout } from 'core/components'
import { SKALO_EMAIL, SKALO_PHONE_NUMBER } from 'core/consts'

export const Contact = () => {
  const { t } = useTranslation()
  return (
    <NoAuthLayout align="top">
      <Box sx={{ p: 4 }}>
        <Typography variant="h1" mb={4}>
          {t('home.contact.title')}
        </Typography>
        <Box>
          <Typography variant="h4" mb={1}>
            {t('common.phoneNumber')}
            <Link href={`tel:${SKALO_PHONE_NUMBER}`} color="info.main" underline="hover" ml={1}>
              {SKALO_PHONE_NUMBER}
            </Link>
          </Typography>
          <Typography variant="h4">
            {t('common.email')}
            <Link href={`mailto:${SKALO_EMAIL}`} color="info.main" underline="hover" ml={1}>
              {SKALO_EMAIL}
            </Link>
          </Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ mr: 2 }}>{t('common.contactPageInfo')}</Typography>
            <IconButton aria-label="settings" component={NavLink} to={`/${RouteVal.MANUAL}`} target="_blank">
              <HelpIcon sx={{ color: 'info.main' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </NoAuthLayout>
  )
}
