import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

const targets = [
  { name: 'Dostarczone', nameKey: 'admin.home.targets.views', value: 12 },
  { name: 'W trakcie', nameKey: 'admin.home.targets.followers', value: 5 },
  { name: 'Wymagające uwagi', nameKey: 'admin.home.targets.income', value: 1 },
]

const total = targets.reduce((sum, target) => sum + target.value, 0)

const PersonalTargetsWidget = () => {
  const { t } = useTranslation()

  return (
    <Card sx={{ mb: 4, maxHeight: '300px' }}>
      <CardHeader title={t('admin.home.targets.title')} />
      <CardContent>
        <List>
          {targets.map(target => (
            <ListItem disableGutters key={target.name}>
              <ListItemText>
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography component="div" variant="h6">
                    {t(target.nameKey)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography component="div" variant="h6">
                    {`${target.value}`}
                  </Typography>
                </Box>
                <LinearProgress
                  aria-label={`${t(target.nameKey)} progress`}
                  sx={{
                    color: target.name === 'Dostarczone' ? 'green' : target.name === 'Wymagające uwagi' ? 'error.main' : 'warning.main',
                  }}
                  color="inherit"
                  variant="determinate"
                  value={(target.value / total) * 100}
                />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

export default PersonalTargetsWidget
