import { Box, BoxProps, darken, lighten, styled } from '@mui/material'

interface StyledSimpleItemProps extends BoxProps {
  color?: 'grey' | 'primary' | 'warning' | 'error'
  variant?: 'filled' | 'outlined'
}

export const StyledSimpleItem = styled(Box, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'variant',
})<StyledSimpleItemProps>(({ theme, color = 'grey', variant = 'outlined' }) => {
  const getColor = () => {
    switch (color) {
      case 'grey':
        return theme.palette.grey[600]
      case 'primary':
        return theme.palette.primary.main
      case 'warning':
        return theme.palette.warning.main
      case 'error':
        return theme.palette.error.main
      default:
        return theme.palette.grey[500]
    }
  }
  return theme.unstable_sx({
    borderLeft: 3,
    borderColor: getColor(),
    bgcolor: variant === 'filled' ? getColor() : theme.palette.mode === 'dark' ? darken(getColor(), 0.35) : lighten(getColor(), 0.95),
    px: 2,
    py: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: '100%',
    color: variant === 'filled' ? 'common.white' : 'inherit',
  })
})
