import { StyledComponent } from '@emotion/styled'
import { Box, BoxProps, styled } from '@mui/material'

export const StyledGridBox: StyledComponent<BoxProps> = styled(Box)<BoxProps>(({ theme }) =>
  theme.unstable_sx({
    mt: 2,
    py: 4,
    px: 2,
    border: 1,
    borderColor: 'grey.100',
    borderRadius: 0.5,
    '&:not(:last-child)': {
      mb: 4,
    },
    bgcolor: 'background.paper',
  }),
)
