import { ReactNode, useCallback, useEffect, useState, useTransition } from 'react'

import AddIcon from '@mui/icons-material/Add'
import { Box, Collapse, SxProps, Typography } from '@mui/material'

import { activeHeaderStyle, boxStyle, headerStyle } from './styles'

export interface CustomAccordionProps {
  open?: boolean
  title: string
  children: ReactNode
  handleToggleCallback?: (active: boolean) => void
  sx?: SxProps
  titleSx?: SxProps
}

const CustomAccordion = ({ open = false, title, handleToggleCallback, sx = [], titleSx = [], children }: CustomAccordionProps) => {
  const [, startTransition] = useTransition()
  const [active, setActive] = useState(open)

  const handleToggle = useCallback(() => {
    startTransition(() => {
      setActive(!active)
    })
  }, [active])

  useEffect(() => {
    if (typeof handleToggleCallback === 'function') {
      handleToggleCallback(active)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <Box sx={[boxStyle, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        onClick={handleToggle}
        sx={[headerStyle, active && activeHeaderStyle, ...(Array.isArray(titleSx) ? titleSx : [titleSx])]}
        className={active ? 'active-title' : 'title'}
      >
        <Typography variant="h5" fontWeight={700} component="div">
          {title}
        </Typography>
        <Box sx={[{ mr: 1, display: 'flex', '& svg': { transition: '0.3s' } }, active ? { '& svg': { transform: 'rotate(45deg)' } } : {}]}>
          <AddIcon color="primary" />
        </Box>
      </Box>
      <Collapse in={active} data-testid="main-accordion-content" aria-hidden={!active}>
        <Box sx={{ px: 3, pt: 2.5, pb: 1, whiteSpace: 'pre-line' }}>{children}</Box>
      </Collapse>
    </Box>
  )
}
export { CustomAccordion }
