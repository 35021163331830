import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import { Autocomplete } from 'core/components'
import { CallbackFnData, FilterName, SelectOption } from 'core/types'
import { useGetOrderItems } from 'items/hooks/useGetOrderItems'

import { useCompanyContext } from '../contexts/CompanyContext'

interface CompanySelectorProps {
  showError: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
}

const CompanySelector: React.FC<CompanySelectorProps> = ({ showError, setError }) => {
  const { t } = useTranslation()

  const { selectedCompany, setSelectedCompany } = useCompanyContext()

  const deliverySite = selectedCompany ? { id: selectedCompany.id.toString(), name: selectedCompany.name } : null

  const { refetchOrderItems } = useGetOrderItems(selectedCompany?.id)

  const [deliverySiteData, setDeliverySiteData] = useState<CallbackFnData | null>(null)

  const onChangeFiltersVal = useCallback((val: SelectOption | null) => {
    setSelectedCompany(val?.otherData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedCompany) {
      refetchOrderItems()
      setError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany])

  const handleCallbackFn = useCallback((data: CallbackFnData) => {
    setDeliverySiteData(data)
  }, [])

  useEffect(() => {
    if (deliverySiteData?.data?.length === 1) {
      setSelectedCompany(deliverySiteData?.data[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverySiteData])

  return (
    <>
      <Autocomplete
        name={FilterName.DELIVERY_SITES}
        filterName={FilterName.DELIVERY_SITES}
        apiRequest="getDeliverSites"
        value={deliverySite}
        setValue={onChangeFiltersVal}
        noPagination
        error={showError}
        disabled={deliverySiteData?.data?.length === 1}
        errorHelperText={showError ? t('orders.selectRestaurantError') : ''}
        callbackFn={handleCallbackFn}
      />

      {deliverySiteData?.callbackType === 'isError' && <Typography>Error loading restaurants</Typography>}
    </>
  )
}

export default CompanySelector
