import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MonetizationOn, Warehouse } from '@mui/icons-material'
import { Box } from '@mui/material'
import { MainTabPanel, MainTabs, PageTitle } from 'core/components'
import { DeliverySite } from 'core/types'
import { AdminCompanySelector } from 'management/helpers/AdminCompanySelector'

import { AdministratorPosProducts } from './AdministratorPosProducts'
import { DotykackaSales } from './DotykackaSales'

export enum Tab {
  POS_PRODUCTS = 'base',
  SALES = 'detailed',
}

const AdministratorDotykackaData = () => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(Tab.POS_PRODUCTS)
  const [selectedCompany, setSelectedCompany] = useState<DeliverySite | null>(null)

  const tabs = [
    {
      value: Tab.POS_PRODUCTS,
      label: t('administrator.dotykackaWarehouse'),
      icon: <Warehouse />,
    },
    {
      value: Tab.SALES,
      label: t('administrator.dotykackaSales'),
      icon: <MonetizationOn />,
    },
  ]
  const handleChangeTabs = (newValue: string) => setSelectedTab(newValue as Tab)

  return (
    <Box>
      <Box mb={4}>
        <PageTitle title={t('finanse.reportFor')} subtitle={<AdminCompanySelector onChange={setSelectedCompany} />} />
      </Box>
      <MainTabs tabs={tabs} selected={selectedTab} onChangeSelected={handleChangeTabs}>
        <MainTabPanel selectedValue={selectedTab} value={Tab.POS_PRODUCTS}>
          <AdministratorPosProducts selectedDeliverySite={selectedCompany} />
        </MainTabPanel>
        <MainTabPanel selectedValue={selectedTab} value={Tab.SALES}>
          <DotykackaSales selectedDeliverySite={selectedCompany} />
        </MainTabPanel>
      </MainTabs>
    </Box>
  )
}

export { AdministratorDotykackaData }
