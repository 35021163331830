import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Close } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCompanyContext } from 'companies/contexts/CompanyContext'
import { DeliverySite, UnsuccessfulItem } from 'core/types'

import MainTable from '../MainTable'
import { mapUnsuccessfulItemsDialogColumns, mapUnsuccessfulItemsDialogData } from './mapUnsuccessfulItemsDialog'

type UnsuccessfulItemsDialogProps = {
  title: string
  unsuccessfulItems: UnsuccessfulItem[]
  deliverySite?: DeliverySite
  handleCloseDialog: () => void
}

export const UnsuccessfulItemsDialog = ({ unsuccessfulItems, handleCloseDialog, title, deliverySite }: UnsuccessfulItemsDialogProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedCompany, setSelectedCompany } = useCompanyContext()

  const handleGoToBasket = () => {
    if (deliverySite) {
      setSelectedCompany(deliverySite)
    }
    navigate('/basket')
  }

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {selectedCompany && deliverySite && deliverySite?.id !== selectedCompany?.id && (
          <Typography variant="h2" color="error" mb={2}>
            <Trans
              i18nKey="userOrders.companyChanged"
              values={{
                selectedCompanyName: deliverySite?.name,
              }}
            />
          </Typography>
        )}
        {unsuccessfulItems.length > 0 && (
          <>
            <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
              <Typography variant="subtitle1">{t('shoppingList.infoTitle')}</Typography>
              <Typography variant="body2">{t('shoppingList.infoContent')}</Typography>
            </Alert>
            <MainTable columns={mapUnsuccessfulItemsDialogColumns(t)} rows={mapUnsuccessfulItemsDialogData(unsuccessfulItems, t)} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="outlined" startIcon={<Close />} color="primary">
          {t('common.close')}
        </Button>
        <Button onClick={handleGoToBasket} variant="contained">
          {t('common.goToCart')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
