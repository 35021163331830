import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import { ChartLegend, ChartTooltip, MainPaper } from 'core/components'
import { VendorInfo } from 'core/types'
import { COLORS } from 'finanse/helpers'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

interface DetailedReportPieChartProps {
  costsPerVendor: VendorInfo[]
}

const DetailedReportPieChart = ({ costsPerVendor }: DetailedReportPieChartProps) => {
  const { t } = useTranslation()

  return (
    <MainPaper>
      <Typography variant="subtitle1">{t('finanse.orderedFromVendors')}</Typography>
      <ResponsiveContainer width="99%" height={360}>
        <PieChart width={290} height={290}>
          <Pie dataKey="totalCost" nameKey="vendorName" isAnimationActive={false} data={costsPerVendor} outerRadius={80} label>
            {costsPerVendor.map((el, idx) => (
              <Cell key={`cell-${el.vendorId}`} fill={COLORS[idx % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
            content={({ active, label, payload }: any) => (
              <ChartTooltip
                active={active}
                label={label}
                payload={payload?.map((el: any) => ({
                  name: el.name,
                  value: el.value,
                  color: el.payload.fill,
                }))}
                rounded
              />
            )}
          />
          <Legend
            content={({ payload }: any) => (
              <ChartLegend payload={payload?.map((el: any) => ({ value: el.value, color: el.color }))} rounded />
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    </MainPaper>
  )
}

export { DetailedReportPieChart }
