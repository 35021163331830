import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import apiEndpoints from 'api/endpoints'
import { FilterName, FilterType } from 'core/types'
import { ALL_SELECTED, prepareOrderPaymentMethodsList, prepareOrderStatusList } from 'core/utils'
import { useGetVendorsList } from 'vendor/hooks/useGetVendorsList'

export type FiltersState = {
  [FilterName.ALL_DELIVERY_SITES]: string
  [FilterName.ORDER_STATUS]: string
  [FilterName.PAYMENT_METHODS]: string
}
export const filtersStateInit = {
  [FilterName.ALL_DELIVERY_SITES]: ALL_SELECTED,
  [FilterName.ORDER_STATUS]: ALL_SELECTED,
  [FilterName.PAYMENT_METHODS]: ALL_SELECTED,
}

export const useOrdersFilters = () => {
  const { t } = useTranslation()

  const { vendorsList } = useGetVendorsList()
  const vendorId = vendorsList && vendorsList.length > 0 ? vendorsList[0].id : ''
  const filters = useMemo(
    () => [
      {
        filterName: FilterName.ALL_DELIVERY_SITES,
        api: apiEndpoints.getPastDeliverySites({ vendorId }),
        apiDisabled: !vendorId,
        filterType: FilterType.SELECT,
      },
      {
        filterName: FilterName.ORDER_STATUS,
        data: prepareOrderStatusList(t),
        filterType: FilterType.SELECT_CHIP,
      },
      {
        filterName: FilterName.PAYMENT_METHODS,
        data: prepareOrderPaymentMethodsList(t),
        filterType: FilterType.SELECT,
      },
    ],
    [vendorId, t],
  )

  return {
    filters,
  }
}
