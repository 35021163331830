import { ReactNode } from 'react'

import { Box, Divider, SxProps, Theme, Typography, TypographyVariant } from '@mui/material'

const titleStyles = (rightEl: boolean) => ({
  display: 'flex',
  alignItems: { xs: 'left', md: rightEl ? 'center' : 'start' },
  justifyContent: 'space-between',
  flexDirection: { xs: 'column', md: 'row' },
  mb: 1,
})

export interface PaperTitleProps {
  title: ReactNode
  titleVariant?: TypographyVariant
  rightEl?: ReactNode
  children?: ReactNode
  sx?: SxProps<Theme>
}

const PaperTitle = ({ title, titleVariant = 'h4', rightEl, sx = [], children }: PaperTitleProps) => {
  return (
    <>
      <Box sx={[titleStyles(!!rightEl), ...(Array.isArray(sx) ? sx : [sx])]}>
        <Typography variant={titleVariant} component="div" py={1}>
          {title}
        </Typography>
        <Box>{rightEl}</Box>
      </Box>
      {children && children}
      <Divider sx={{ mt: 2, mb: 3 }} />
    </>
  )
}

export { PaperTitle }
