import apiEndpoints from 'api/endpoints'
import { useMutate, useRequest } from 'core/hooks'
import { PaginatedResult } from 'core/types'
import { MinimalPrice } from 'management/types'

export const usePutMinimalPrice = () => {
  const { mutate, ...other } = useMutate(apiEndpoints.putMinimalPrice())
  return { postMinimalPriceRule: mutate, ...other }
}

export const useDeleteMinimalPrice = (id: string) => {
  const { mutate, ...others } = useMutate(apiEndpoints.deleteMinimalPrice(id))
  return { deleteMinimalPriceRule: mutate, ...others }
}

export const useGetMinimalPriceList = (params: any) => {
  const { data, ...other } = useRequest<PaginatedResult<MinimalPrice>>(apiEndpoints.getMinimalPriceList(params))
  return { minimalPriceRuleListData: data, ...other }
}
