import { ListAlt, MonetizationOn, ShowChart, SupervisorAccount } from '@mui/icons-material'
import { formatPrice } from 'core/utils'
import { t } from 'i18next'

export const cardsData = (data: any, palette: any) => [
  {
    title: t('finanse.totalExpenses'),
    value: `${formatPrice(data.ordersTotalCost)}`,
    icon: MonetizationOn,
    color: palette.success.main,
    linkTitle: t('finanse.viewMyFinances'),
    linkTo: '/client-finances',
  },
  {
    title: t('finanse.orders'),
    value: data.ordersTotalCount,
    icon: ListAlt,
    color: palette.info.main,
    linkTitle: t('finanse.viewAllOrders'),
    linkTo: '/my-orders',
  },
  {
    title: t('finanse.averageExpenses'),
    value: `${formatPrice(data.ordersTotalAverage)}`,
    icon: ShowChart,
    color: palette.primary.main,
    linkTitle: t('finanse.viewMyFinances'),
    linkTo: '/client-finances',
  },
  {
    title: t('finanse.vendors'),
    value: data.uniqueVendors.length,
    icon: SupervisorAccount,
    color: palette.warning.main,
    linkTitle: t('finanse.viewCatalog'),
    linkTo: '/catalog',
  },
]
