import apiEndpoints from 'api/endpoints'
import { OnError, OnSuccess, useMutate } from 'core/hooks'

export const useAddFavouriteItem = (onError: OnError, onSuccess: OnSuccess) => {
  const { isLoading, mutate, isSuccess, error } = useMutate<any>(apiEndpoints.postAddFavoriteItem(), {
    onError,
    onSuccess,
  })
  return { isLoggingIn: isLoading, addToFavourite: mutate, isSuccess, error }
}
