import { SxProps, Theme, darken, lighten } from '@mui/material'

export const menuItemStyle: SxProps<Theme> = {
  justifyContent: 'center',
  gap: 2,
  py: 1,
  color: 'text.primary',
  '& > .MuiListItemIcon-root': {
    color: (theme: Theme) => lighten(theme.palette.text.primary, 0.3),
  },
  borderRadius: 1,
  ':hover': {
    background: (theme: Theme) =>
      theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.7) : lighten(theme.palette.secondary.main, 0.8),
  },
  '&.active': {
    background: (theme: Theme) =>
      theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.5) : lighten(theme.palette.secondary.main, 0.5),
    color: 'text.primary',
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
    '& > .MuiListItemIcon-root': {
      color: 'text.primary',
    },
  },
}
