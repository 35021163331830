import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { DeliverySiteManagementTab } from 'config/routeTypes'
import dotykackaImg from 'core/assets/dotykacka-sign-standard.png'
import { AvatarImg, PhoneNumber } from 'core/components'
import { DeliverySite } from 'core/types'
import { formatAddress } from 'core/utils'
import { TFunction } from 'i18next'

type Actions = {
  handleEdit: (el: DeliverySite, eventType: DeliverySiteManagementTab) => void
  handleDelete: (el: DeliverySite) => void
  handleGetDotykackaIntegrationUrl: (deliverySiteId: number) => void
}

export const mapDeliverySitesData = (data: DeliverySite[], t: TFunction, actions: Actions, isAdmin?: boolean) => {
  const mappedData = data.map(el => {
    return {
      rowId: el.id,
      data: {
        img: <AvatarImg imgUrl={el.deliverySiteLogoUrl} name={el.name} sx={{ width: 40, height: 40 }} />,
        name: el.name,
        phoneNumber: <PhoneNumber phoneCode="+48" phoneNo={el.phoneNumber} variant="body2" />,
        address: formatAddress(el.deliveryAddress),
      },
      actions: isAdmin
        ? [
            {
              title: t('common.edit'),
              icon: <EditIcon />,
              callback: () => actions.handleEdit(el, DeliverySiteManagementTab.MAIN_DATA),
            },
            {
              title: t('common.delete'),
              icon: <DeleteIcon />,
              callback: () => actions.handleDelete(el),
              separator: true,
            },
          ]
        : [
            {
              title: t('common.edit'),
              icon: <EditIcon />,
              callback: () => actions.handleEdit(el, DeliverySiteManagementTab.MAIN_DATA),
            },
            {
              title: t('common.integrateDotykacka'),
              callback: () => actions.handleGetDotykackaIntegrationUrl(el.id),
              img: dotykackaImg,
            },
            {
              title: t('common.delete'),
              icon: <DeleteIcon />,
              callback: () => actions.handleDelete(el),
              separator: true,
            },
          ],
    }
  })

  return mappedData
}

export const mapDeliverySitesColumns = (t: TFunction) => [
  { title: '', id: 'img' },
  { title: t('deliverySites.name'), id: 'name' },
  { title: t('clients.phoneNumber'), id: 'phoneNumber' },
  { title: t('deliverySites.address'), id: 'address' },
  { title: t('common.actions'), id: 'actions', align: 'right' },
]

export const cellStyles = [
  {
    id: 'name',
    styles: {
      fontWeight: 700,
      color: 'primary.main',
    },
  },
  {
    id: 'address',
    styles: {
      color: 'text.secondary',
    },
  },
]
