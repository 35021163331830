import { useMutation } from '@tanstack/react-query'

import axiosInstance from '../../api/axiosInstance'

interface OrderItemParams {
  deliverySiteId: number | null
  productId: string
  quantity: number
  vendorId: string
}

interface OrderItemType {
  name: string
  price: number
  productId: string
  quantity: number
  vendorId: string
  vendorName: string
}

const createOrderItem = async (params: OrderItemParams): Promise<OrderItemType> => {
  const { data } = await axiosInstance.put('ShoppingCart/', params)
  return data
}

export function useCreateOrderItem() {
  const { isLoading, mutateAsync } = useMutation((params: OrderItemParams) => createOrderItem(params))

  return { isLoadingCreateOrderItem: isLoading, createOrderItem: mutateAsync }
}
