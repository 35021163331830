import { ReactNode, useCallback, useEffect, useState, useTransition } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Collapse, SxProps, Typography } from '@mui/material'

export const headerStyle = (title: any) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: typeof title === 'string' ? 'flex-start' : 'center',
})

export interface MainAccordionProps {
  open?: boolean
  title: string
  children: ReactNode
  handleToggleCallback?: (active: boolean) => void
  sx?: SxProps
  titleSx?: SxProps
}

const MainAccordion = ({ open = false, title, handleToggleCallback, sx = [], titleSx = [], children }: MainAccordionProps) => {
  const [, startTransition] = useTransition()
  const [active, setActive] = useState(open)

  const handleToggle = useCallback(() => {
    startTransition(() => {
      setActive(!active)
    })
  }, [active])

  useEffect(() => {
    if (typeof handleToggleCallback === 'function') {
      handleToggleCallback(active)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <>
      <Box sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
        <Box
          onClick={handleToggle}
          sx={[headerStyle(title), ...(Array.isArray(titleSx) ? titleSx : [titleSx])]}
          data-testid="main-accordion-title"
        >
          <Typography variant="subtitle1" component="div" lineHeight={1.4}>
            {title}
          </Typography>
          <Box
            sx={[{ ml: 1, display: 'flex', '& svg': { transition: '0.3s' } }, active ? { '& svg': { transform: 'rotate(180deg)' } } : {}]}
          >
            <ExpandMoreIcon fill="inherit" />
          </Box>
        </Box>
        <Collapse in={active} data-testid="main-accordion-content" aria-hidden={!active}>
          <Box>{children}</Box>
        </Collapse>
      </Box>
    </>
  )
}
export { MainAccordion }
