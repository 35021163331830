import { SxProps, Theme } from '@mui/material'

export const boxStyle: SxProps<Theme> = {
  borderRadius: 1,
  p: 0.4,
  pl: 1.6,
  maxWidth: '20rem',
  border: 1,
  borderColor: 'grey.200',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
