import { MutationOptions, QueryKey, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import axiosInstance, { axiosNoAuthInstance } from 'api/axiosInstance'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

type EndpointRequest = AxiosRequestConfig & { queryKey?: QueryKey }

export interface ApiConfig<Data = unknown, Error = unknown>
  extends Omit<UseQueryOptions<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
  initialData?: AxiosResponse<Data>
  isCancelable?: boolean
  noAuth?: boolean
}

export interface ApiReturn<Data, Error>
  extends Pick<
    UseQueryResult<AxiosResponse<Data>, AxiosError<Error>>,
    'isLoading' | 'isFetching' | 'isError' | 'error' | 'status' | 'isSuccess' | 'refetch'
  > {
  data: Data | undefined
  response: AxiosResponse<Data> | undefined
  queryKey: QueryKey
}

export interface MutationConfig<Data = unknown, Error = unknown>
  extends Omit<MutationOptions<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
  initialData?: Data
}

export function useRequest<Data = unknown, Error = unknown>(
  request: EndpointRequest,
  { initialData, isCancelable = true, noAuth, ...config }: ApiConfig<Data, Error> = {},
): ApiReturn<Data, Error> {
  const queryKey = request.queryKey ?? ([request && JSON.stringify(request)] as QueryKey)

  const axiosReq = () => (noAuth ? axiosNoAuthInstance(request) : axiosInstance(request))
  const { isFetching, status, isError, isLoading, isSuccess, data, error, refetch } = useQuery(
    queryKey,
    isCancelable
      ? ({ signal }) => {
          request.signal = signal
          return axiosReq()
        }
      : () => axiosReq(),
    {
      refetchOnWindowFocus: false,
      initialData,
      ...config,
    },
  )

  return {
    data: data && data?.data,
    response: data,
    error,
    status,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    refetch,
    queryKey,
  }
}

export interface MutateReturn<Data>
  extends Pick<UseMutationResult<AxiosResponse<Data>>, 'isLoading' | 'isError' | 'error' | 'status' | 'isSuccess' | 'reset' | 'isIdle'> {
  data: Data | undefined
  response: AxiosResponse<Data> | undefined | null
  mutate: any
  mutateAsync: any
}

export function useMutate<Data = unknown, Error = unknown>(
  request: EndpointRequest,
  { noAuth, ...config }: MutationConfig<Data, Error> & { noAuth?: boolean } = {},
): MutateReturn<Data> {
  const resInfo = useMutation<AxiosResponse<Data>, AxiosError<Error>>(
    (data: any) => (noAuth ? axiosNoAuthInstance({ ...request, data }) : axiosInstance({ ...request, data })),
    config,
  )

  return {
    ...resInfo,
    data: resInfo.data && resInfo.data.data,
    response: resInfo.data,
  }
}

export type OnMutate = ((variables: AxiosError<unknown>) => unknown) | undefined | any
export type OnError = ((error: Error, variables: AxiosError<unknown>, context?: unknown) => void | Promise<unknown>) | undefined | any
export type OnSuccess = ((data: AxiosResponse<any>, variables: AxiosError<unknown>) => void | Promise<void>) | void | undefined | any
