import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import { Button, Grid, Typography } from '@mui/material'
import { ReactComponent as SuccessSvg } from 'core/assets/success.svg'
import { NoAuthLayout } from 'core/components'
import Logo from 'core/components/Logo'
import SvgContainer from 'core/components/SvgContainer'
import { UserRoute } from 'core/consts'
import { useAddDotykackaClientIntegration, useSynchronizeDotykackaSell } from 'core/hooks/api'
import { AddDotykackaClientIntegration } from 'core/types'

enum IntegrationOutputType {
  SUCCESS = 'success',
  ERROR = 'error',
}

type IntegrationOutput = IntegrationOutputType | ''

const DotykackaClientIntegration = () => {
  const [searchParams] = useSearchParams()
  const dotykackaPosToken = searchParams.get('token')
  const dotykackaPosCloudId = searchParams.get('cloudid')
  const deliverySiteId = searchParams.get('state')

  const { addDotykackaClientIntegration } = useAddDotykackaClientIntegration()
  const { syncDotykackaSellRefetch } = useSynchronizeDotykackaSell({
    deliverySiteId: Number(deliverySiteId),
  })

  const [integrationOutput, setIntegrationOutput] = useState<IntegrationOutput>('')

  const { t } = useTranslation()

  const onAddDotykackaClientIntegration = async (data: AddDotykackaClientIntegration) => {
    await addDotykackaClientIntegration(data, {
      onSuccess: () => {
        syncDotykackaSellRefetch()
      },
      onError: () => {
        setIntegrationOutput(IntegrationOutputType.ERROR)
      },
    })
  }

  useEffect(() => {
    if (dotykackaPosToken && dotykackaPosCloudId && deliverySiteId) {
      onAddDotykackaClientIntegration({ dotykackaPosToken, dotykackaPosCloudId, deliverySiteId: Number(deliverySiteId) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <NoAuthLayout maxWidth="xl">
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <SvgContainer>
            <SuccessSvg />
          </SvgContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <Logo />

          {integrationOutput === IntegrationOutputType.ERROR ? (
            <Typography color="error" sx={{ whiteSpace: 'pre-line', mt: 4 }}>
              {t('common.errors.dotykackaClientIntegration')}
            </Typography>
          ) : (
            <>
              <Typography variant="h4" mt={4} mb={2}>
                {t('pos.dotykackaClientIntegrationConfirmation.title')}
              </Typography>
              <Typography color="text.secondary" variant="body2" mt={2} sx={{ whiteSpace: 'pre-line' }}>
                {t('pos.dotykackaClientIntegrationConfirmation.content')}
              </Typography>
            </>
          )}
          <Button variant="contained" component={Link} to={UserRoute.HOME} replace sx={{ mt: 4 }}>
            {t('acceptOrder.backToSkalo')}
          </Button>
        </Grid>
      </Grid>
    </NoAuthLayout>
  )
}

export default DotykackaClientIntegration
