import EditIcon from '@mui/icons-material/Edit'
import { PaginatedResult, User } from 'core/types'
import { TFunction } from 'i18next'

type Actions = {
  handleEdit: (el: User) => void
}

export const formatUserName = (user: User): string => {
  return `${user.firstName} ${user.lastName}`
}

export const mapUsersData = (data: PaginatedResult<User>, t: TFunction, action: Actions) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.id,
      data: {
        name: formatUserName(el),
        email: el.email,
      },
      actions: [
        {
          title: t('common.edit'),
          icon: <EditIcon />,
          callback: () => action.handleEdit(el),
        },
      ],
    }
  })

  return mappedData
}

export const mapUsersColumns = (t: TFunction) => [
  { title: t('users.name'), id: 'name' },
  { title: t('users.email'), id: 'contactEmail' },
  { title: t('common.actions'), id: 'actions', align: 'right' },
]
