import { ProductUnit } from 'core/consts'

export const product = {
  unit: 'Jednostka',
  unitVal: {
    [ProductUnit.Piece]: 'Sztuka',
    [ProductUnit.Kilogram]: 'Kilogram',
    [ProductUnit.Liter]: 'Litr',
  },
  unitValShort: {
    [ProductUnit.Piece]: 'szt.',
    [ProductUnit.Kilogram]: 'kg',
    [ProductUnit.Liter]: 'l',
  },
}
