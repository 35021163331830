import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Call, ExpandMore, ManageAccounts } from '@mui/icons-material'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button, Collapse, Divider, IconButton, ListItemIcon, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'auth/contexts/AuthProvider'
import { MenuRoute } from 'config/menuRoutes'
import Logo from 'core/components/Logo'
import { UserRole, UserRoute, drawerCollapsedWidth, drawerWidth } from 'core/consts'

import { menuItemStyle } from './styles'

type DrawerProps = {
  items: MenuRoute[]
  collapsed: boolean
  mobileOpen: boolean
  onDrawerToggle: () => void
  onSettingsToggle: () => void
}

const Drawer = ({ items, collapsed, mobileOpen, onDrawerToggle, onSettingsToggle }: DrawerProps) => {
  const queryClient = useQueryClient()
  const { userInfo, logout } = useAuth()
  const { t } = useTranslation()
  const roles = userInfo?.roles as UserRole[]

  const theme = useTheme()
  const matchesLGDown = useMediaQuery(theme.breakpoints.down('lg'))

  const width = collapsed ? drawerCollapsedWidth : drawerWidth

  const [expandSubMenu, setExpandSubMenu] = useState<string[]>([])

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    queryClient.clear()
    logout()
  }

  const changeExpandSubMenu = (key: string) => {
    setExpandSubMenu(prev => {
      const isExist = prev.find(el => el === key)
      if (isExist) {
        return prev.filter(a => a !== key)
      } else return [...prev, key]
    })
  }

  const dividerEl = (
    <ListItem sx={{ pb: 0.5, mb: -0.5 }}>
      <Divider sx={{ width: '100%' }} />
    </ListItem>
  )

  return (
    <MuiDrawer
      variant={matchesLGDown ? 'temporary' : 'permanent'}
      anchor="left"
      open={mobileOpen}
      onClose={onDrawerToggle}
      ModalProps={{
        keepMounted: matchesLGDown, // Better open performance on mobile.
      }}
      sx={{
        width: width,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
          borderRight: 1,
          borderColor: 'grey.300',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box>
        <NavLink to={UserRoute.HOME}>
          <Logo
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 1,
              my: collapsed ? 2.224 : 0,
            }}
            size={collapsed ? 50 : 110}
          />
        </NavLink>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" boxShadow={1}>
        <List>
          {items.map(el => (
            <Fragment key={'key' + el.to + (el.subRoutes ? el.subRoutes?.[0].to : '')}>
              {roles?.some(item => el.roles.includes(item)) && (
                <>
                  {el.subRoutes && !el.to ? (
                    <>
                      {el.divider && dividerEl}
                      <ListItem sx={{ pb: 0.5, mb: -1 }} dense>
                        <Tooltip title={el.title && collapsed ? el.title : ''} placement="right">
                          <ListItemButton onClick={() => changeExpandSubMenu(el.expandKey as string)} sx={menuItemStyle}>
                            <ListItemIcon>{el.icon}</ListItemIcon>
                            {!collapsed && <ListItemText primary={el.title} />}
                            <ExpandMore
                              fontSize="small"
                              sx={{
                                transition: 'transform 300ms',
                                transform: expandSubMenu.find(a => a === el.expandKey) ? 'rotate(180deg)' : 'rotate(0deg)',
                              }}
                            />
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                      <Collapse in={Boolean(expandSubMenu.find(a => a === el.expandKey))} timeout="auto" unmountOnExit>
                        <List component="div">
                          {el.subRoutes.map(subRoute => (
                            <ListItem key={subRoute.to} sx={{ pb: 0, pl: 4, mb: -1 }} dense>
                              <Tooltip title={subRoute.title && collapsed ? subRoute.title : ''} placement="right">
                                <ListItemButton
                                  component={NavLink}
                                  to={subRoute.to}
                                  target={subRoute.blank ? '_blank' : ''}
                                  sx={menuItemStyle}
                                >
                                  {subRoute.icon && <ListItemIcon>{subRoute.icon}</ListItemIcon>}
                                  {!collapsed && <ListItemText primary={subRoute.title} />}
                                </ListItemButton>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <>
                      {el.divider && dividerEl}
                      <ListItem sx={{ pb: 0.5, mb: -0.5 }} dense>
                        <Tooltip title={el.title && collapsed ? el.title : ''} placement="right">
                          <ListItemButton component={NavLink} to={el.to ?? ''} target={el.blank ? '_blank' : ''} sx={menuItemStyle}>
                            <ListItemIcon>{el.icon}</ListItemIcon>
                            {!collapsed && <ListItemText primary={el.title} />}
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                    </>
                  )}
                </>
              )}
            </Fragment>
          ))}
        </List>
        <List sx={{ mb: 3 }}>
          {dividerEl}
          <ListItem dense sx={{ pb: 0.5, mb: -0.5 }}>
            <Tooltip title={collapsed ? t('page.title.profile') : ''} placement="right">
              <ListItemButton component={NavLink} to="/profile" sx={menuItemStyle}>
                <ListItemIcon>
                  <ManageAccounts />
                </ListItemIcon>
                {userInfo && !collapsed && (
                  <>
                    <ListItemText
                      primary={`${userInfo.firstName} ${userInfo.lastName}`}
                      sx={{
                        borderRight: 1,
                        mr: 1,
                      }}
                    />
                    <IconButton onClick={handleLogout}>
                      <LogoutIcon />
                    </IconButton>
                  </>
                )}
              </ListItemButton>
            </Tooltip>
          </ListItem>
          <ListItem dense sx={{ pb: 0.5 }}>
            <Tooltip title={collapsed ? t('admin.drawer.menu.settings') : ''} placement="right">
              <ListItemButton onClick={onSettingsToggle} sx={menuItemStyle}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                {!collapsed && <ListItemText primary={t('admin.drawer.menu.settings')} />}
              </ListItemButton>
            </Tooltip>
          </ListItem>
          <ListItem dense sx={{ pb: 0.5 }}>
            <Tooltip title={collapsed ? t('home.contact.title') : ''} placement="right">
              <ListItemButton component={NavLink} to="/contact" sx={menuItemStyle}>
                <ListItemIcon>
                  <Call />
                </ListItemIcon>
                {!collapsed && <ListItemText primary={t('home.contact.title')} />}
              </ListItemButton>
            </Tooltip>
          </ListItem>
          {collapsed && (
            <ListItem dense sx={{ pb: 0.5, mb: -0.5 }}>
              <Tooltip title={t('common.logout')} placement="right">
                <ListItemButton sx={menuItemStyle} component={Button} onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}
        </List>
      </Box>
    </MuiDrawer>
  )
}

export default Drawer
