import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Chip, TextFieldProps as MuiTextFieldProps, TextField } from '@mui/material'
import Downshift, { ControllerStateAndHelpers } from 'downshift'

export type TagsInputProps = {
  name: string
  selectedItems: string[]
  setSelectedItems: (items: string[]) => void
} & MuiTextFieldProps

export function TagsInput({ selectedItems, setSelectedItems, ...other }: TagsInputProps) {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleAddTag = () => {
    if (!inputValue.trim() || selectedItems.includes(inputValue.trim())) {
      setInputValue('')
      return
    }
    setSelectedItems([...selectedItems, inputValue.trim()])
    setInputValue('')
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddTag()
    }
  }

  const handleChange = (selectedItem: string | null) => {
    if (!selectedItem || selectedItems.includes(selectedItem)) return

    setSelectedItems([...selectedItems, selectedItem])
    setInputValue('')
  }

  const handleDelete = (item: string) => () => {
    setSelectedItems(selectedItems.filter(selected => selected !== item))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return (
    <Downshift inputValue={inputValue} onChange={handleChange} selectedItem={selectedItems.join(',')} itemToString={item => item ?? ''}>
      {({ getInputProps }: ControllerStateAndHelpers<string>) => {
        const { onBlur, onChange, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
        })

        return (
          <div>
            <Box sx={{ display: 'flex' }}>
              <TextField
                // sx={{ width: { xs: '100%', md: '50%' } }}
                fullWidth
                InputProps={{
                  onBlur,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event)
                    if (onChange) onChange(event)
                  },
                  ...(inputProps as Partial<typeof inputProps>),
                }}
                {...other}
                {...inputProps}
              />
              <Button variant="contained" onClick={handleAddTag} sx={{ ml: -2, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                {t('common.add')}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
              {selectedItems.map(item => (
                <Chip key={item} tabIndex={-1} label={item} onDelete={handleDelete(item)} sx={{ m: 0.3 }} />
              ))}
            </Box>
          </div>
        )
      }}
    </Downshift>
  )
}
