import { Backdrop, Box, SxProps, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Logo from './Logo'

const loaderStyle: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  left: 0,
  bgcolor: 'rgba(0, 0, 0, 0.1)',
  width: '100%',
  height: '100%',
  borderRadius: 1,
  zIndex: theme => theme.zIndex.drawer + 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
interface LoaderProps {
  relative?: boolean
  sx?: SxProps<Theme>
}

const Loader = ({ relative, sx = [] }: LoaderProps) => {
  const theme = useTheme()
  const loader = (
    <Logo
      size={100}
      sx={{
        '@keyframes pulse': {
          '0%': {
            opacity: 1,
          },
          '50%': {
            opacity: 0.4,
          },
          '100%': {
            opacity: 1,
          },
        },
        animation: 'pulse 1.5s ease-in-out 0.5s infinite',
        color: theme.palette.mode === 'light' ? 'grey.300' : 'grey.600',
        textAlign: 'center',
        px: 3,
        py: 8,
      }}
    />
  )
  return relative ? (
    <Box sx={[loaderStyle, ...(Array.isArray(sx) ? sx : [sx])]}>{loader}</Box>
  ) : (
    <Backdrop open sx={[{ zIndex: theme => theme.zIndex.drawer + 1 }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {loader}
    </Backdrop>
  )
}

export default Loader
