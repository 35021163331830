import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

import SearchComponent from '../SearchComponent'

export interface SearchWrapperProps {
  isResult: boolean
  searchTerm: string
  setSearchTerm: Dispatch<SetStateAction<string>>
  searchParam?: string
  children: React.ReactNode
}

const SearchWrapper = ({ isResult, searchTerm, setSearchTerm, searchParam, children }: SearchWrapperProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Typography variant="h4" mb={3}>
        {t('search.provideYourQuestion')}
      </Typography>
      <SearchComponent searchParam={searchParam} onSearch={val => setSearchTerm(val.toLowerCase())} sx={{ mb: 6 }} />
      <Box>
        {searchTerm.length > 0 && isResult && (
          <Typography variant="h5" mb={3}>
            {t('search.searchResults')}
          </Typography>
        )}
        {isResult ? (
          <>{children}</>
        ) : (
          <Box>
            <Typography variant="subtitle1" mb={1}>
              {t('search.notFoundFor', { val: searchTerm })}.
            </Typography>
            <Typography>{t('search.tryAnotherText')}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SearchWrapper
