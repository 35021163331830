import apiEndpoints from 'api/endpoints'
import { useMutate, useRequest } from 'core/hooks'
import { UploadCatalogRes } from 'core/types'

type Params = {
  vendorId: string
}
export const useGetVendorIntegration = (params: Params) => {
  const { data, isLoading, ...other } = useRequest<UploadCatalogRes>(apiEndpoints.getVendorIntegration(params), {
    enabled: !!params?.vendorId,
    retry: 0,
  })
  return { vendorIntegrationData: data, isLoading, ...other }
}

export const usePostVendorIntegrationUploadCatalog = () => {
  const { mutate, ...others } = useMutate(apiEndpoints.postVendorIntegrationUploadCatalog())
  return { uploadVendorCatalog: mutate, ...others }
}
