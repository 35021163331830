import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@mui/material'
import { ChartLegend, ChartTooltip, Loader, MainPaper, PaperTitle, Select } from 'core/components'
import { Client } from 'core/types'
import { getLastYearsList, last12month } from 'core/utils'
import dayjs from 'dayjs'
import { useClientBaseReport } from 'finanse/hooks'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface ClientBaseReportChartProps {
  selectedClient: string
  clients: Client[]
}

const ClientBaseReportChart = ({ selectedClient, clients }: ClientBaseReportChartProps) => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  const [year, setYear] = useState(last12month)

  const { clientBaseReportData, isLoadingClientBaseReport } = useClientBaseReport({
    clientId: selectedClient,
    year: year === last12month ? '' : year,
  })

  return (
    <MainPaper sx={{ mb: 2, position: 'relative' }}>
      {isLoadingClientBaseReport && <Loader relative />}
      <PaperTitle
        title={t('finanse.summary')}
        rightEl={
          <Select
            label={t('common.year')}
            name="year"
            value={year}
            onChange={e => setYear(e.target.value)}
            options={getLastYearsList(2, t)}
            sx={{ width: { xs: '100%', sm: '14rem' } }}
          />
        }
      />

      <ResponsiveContainer width="99%" height={244}>
        <LineChart
          width={500}
          height={300}
          data={
            clientBaseReportData
              ? Object.entries(clientBaseReportData.totalOrderCost).map(([date, value]) => ({ date: dayjs(date).format('MMMM'), value }))
              : []
          }
        >
          <XAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} dataKey="date" tickMargin={8} />
          <YAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} tickMargin={8} />
          <CartesianGrid strokeDasharray="1 3" vertical={false} />
          <Line
            name={clients.find(el => el.id === selectedClient)?.name}
            type="linear"
            dataKey="value"
            stroke={palette.primary.main}
            strokeWidth={4}
            activeDot={{ r: 4 }}
          />

          <Tooltip
            content={({ active, payload, label }: any) => (
              <ChartTooltip
                active={active}
                label={label}
                payload={payload?.map((el: any) => ({
                  name: el.name,
                  value: el.value,
                  color: el.stroke,
                }))}
                rounded
              />
            )}
          />

          <Legend
            content={({ payload }: any) => (
              <ChartLegend payload={payload?.map((el: any) => ({ value: el.value, color: el.payload.stroke }))} rounded />
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </MainPaper>
  )
}

export { ClientBaseReportChart }
