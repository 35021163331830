import { PaymentTypeVal, ProductUnit } from 'core/consts'
import { DeliveryConditions, PaymentLimit } from 'core/types'
import dayjs, { Dayjs } from 'dayjs'
import { OrderItemsResponse, VendorsCart } from 'items/types/types'

export const checkOnlinePaymentSelected = (vendorsCarts: VendorsCart[]) => {
  const onlinePayment = vendorsCarts.filter(el => el.isEnabled).some(el => el.selectedPaymentType === PaymentTypeVal.OnlinePayment)
  const spingo = vendorsCarts.filter(el => el.isEnabled).some(el => el.selectedPaymentType === PaymentTypeVal.Spingo)

  return onlinePayment && spingo
}

export const checkVariableWeightProductInOnlinePaymentCarts = (vendorsCarts: VendorsCart[]) => {
  const onlinePaymentCarts =
    vendorsCarts.filter(
      cart => cart.selectedPaymentType === PaymentTypeVal.OnlinePayment || cart.selectedPaymentType === PaymentTypeVal.Spingo,
    ) || []

  return onlinePaymentCarts.some(vendorCart => vendorCart.cartItems.some(cartItem => cartItem.unit !== ProductUnit.Piece))
}

export const checkSpingoAlertVisible = (orderItemsData: OrderItemsResponse | undefined, spingoLimitData: PaymentLimit | undefined) =>
  orderItemsData &&
  !checkOnlinePaymentSelected(orderItemsData.vendorsCarts.filter(vendorCart => vendorCart.isEnabled)) &&
  spingoLimitData &&
  spingoLimitData.totalLimit === 0 &&
  orderItemsData.vendorsCarts.filter(vendorCart => vendorCart.isEnabled).some(p => p.selectedPaymentType === PaymentTypeVal.Spingo)

export const getEstimatedDeliveryDate = (deliveryConditions: DeliveryConditions, firstDelivery: boolean, date: Dayjs) => {
  const {
    delayFirstDelivery,
    deliveryDays,
    deliveryHourFrom,
    deliveryHourTo,
    deliveryNextDayHourFrom,
    deliveryNextDayHourTo,
    deliveryOrderBeforeHour,
  } = deliveryConditions

  //Delivery day after placing the order, plus checking whether the new date is within Vendor's delivery days
  let deliveryDate = date.add(1, 'day')
  const orderHour = deliveryDate.hour()
  const isNotVendorDeliveryDay = () => !deliveryDays.includes(deliveryDate.weekday())
  const deliveryOrderBeforeHourCondition = deliveryOrderBeforeHour !== 0 && orderHour >= deliveryOrderBeforeHour
  while (isNotVendorDeliveryDay()) {
    deliveryDate = deliveryDate.add(1, 'day')
  }
  // If firstDelivery and  delayFirstDelivery, add 1 day, plus checking whether the new date is within Vendor's delivery days
  if (firstDelivery && delayFirstDelivery) {
    deliveryDate = deliveryDate.add(1, 'day')
    while (isNotVendorDeliveryDay()) {
      deliveryDate = deliveryDate.add(1, 'day')
    }
  }

  // Checking whether the order is before deliveryOrderBeforeHour, if not add 1 day, plus checking whether the new date is within Vendor's delivery days
  if (deliveryOrderBeforeHourCondition) {
    deliveryDate = deliveryDate.add(1, 'day')
    while (isNotVendorDeliveryDay()) {
      deliveryDate = deliveryDate.add(1, 'day')
    }
  }

  const formattedDate = deliveryDate.format('DD.MM.YYYY')
  const deliveryDay = deliveryDate.format('dddd')

  const deliveryHours = `${dayjs()
    .hour(deliveryOrderBeforeHourCondition ? deliveryHourFrom : deliveryNextDayHourFrom)
    .format('HH:00')} - ${dayjs()
    .hour(deliveryOrderBeforeHourCondition ? deliveryHourTo : deliveryNextDayHourTo)
    .format('HH:00')}`

  const finalDate = `${deliveryDate.format('DD.MM.YYYY (dddd)')}, ${deliveryHours}`
  const finalDateShort = `${deliveryDate.format('ddd, D MMM')}, ${deliveryHours}`
  return {
    deliveryDate: formattedDate,
    deliveryDay,
    deliveryHours,
    finalDate,
    finalDateShort,
  }
}

export const paymentWithSpingoImpossible = (orderItemsData: OrderItemsResponse | undefined, spingoLimitData: PaymentLimit | undefined) => {
  const enabledVendorCarts =
    orderItemsData?.vendorsCarts.filter(
      el => el.isEnabled && el.allowedPaymentTypes.includes(PaymentTypeVal.Spingo) && el.selectedPaymentType === PaymentTypeVal.Spingo,
    ) || []

  if (enabledVendorCarts.length === 0) return false

  const totalCost = enabledVendorCarts?.reduce((sum, cart) => sum + cart.vendorTotalCostGross, 0) || 0
  return spingoLimitData && spingoLimitData.remainingLimit < totalCost && spingoLimitData.totalLimit !== 0
}
