import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Collapse, Divider, Grid, InputAdornment, Typography } from '@mui/material'
import { Autocomplete, Connector, MainPaper, ProductSearch, StyledGridItem, TextField } from 'core/components'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { lighterSecondaryBgr } from 'core/theme/palette'
import { FilterName, Item, Vendor } from 'core/types'
import { usePutMinimalPrice } from 'management/hooks'

import { fieldBoxStyle, paperStyle } from './styles'

export type FiltersState = {
  [FilterName.ALL_VENDORS]: Vendor | null
  product: Item | null
}
export const filtersStateInit = {
  [FilterName.ALL_VENDORS]: null,
  product: null,
}

interface RuleFormProps {
  open: boolean
  onClose: () => void
  refetchMinimalPriceRuleListData: () => void
}

const RuleForm = ({ open, onClose, refetchMinimalPriceRuleListData }: RuleFormProps) => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  const [filtersState, setFiltersState] = useState<FiltersState>(filtersStateInit)
  const { postMinimalPriceRule } = usePutMinimalPrice()

  const [skaloAndClientPrice, setSkaloAndClientPrice] = useState({
    clientPrice: 0,
    skaloPrice: 0,
  })

  const resetAll = () => {
    setFiltersState(filtersStateInit)
    onClose()
  }

  const onChangeFiltersVal = useCallback((filterName: string, val: any) => {
    setFiltersState(prev => ({
      ...prev,
      [filterName]: val,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectProduct = useCallback((product: Item | null) => {
    setFiltersState(prev => ({
      ...prev,
      product,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addMinimalPriceRule = async () => {
    await postMinimalPriceRule(
      {
        productId: filtersState.product?.id,
        clientPrice: skaloAndClientPrice.clientPrice,
        skaloPrice: skaloAndClientPrice.skaloPrice,
      },
      {
        onSuccess: () => {
          refetchMinimalPriceRuleListData()
          snackbar.success(t('platformManagement.rules.addRuleCallback.success'))
          setSkaloAndClientPrice({ clientPrice: 0, skaloPrice: 0 })
          resetAll()
        },
        onError: () => {
          snackbar.error(t('platformManagement.rules.addRuleCallback.error'))
        },
      },
    )
  }

  return (
    <Collapse in={open}>
      <MainPaper sx={paperStyle}>
        <Grid container spacing={3}>
          <StyledGridItem item xs={12} md={6}>
            {/* vendors */}
            <Autocomplete
              filterName={FilterName.ALL_VENDORS}
              apiRequest="getAllVendors"
              value={filtersState[FilterName.ALL_VENDORS]}
              setValue={(val: any) => onChangeFiltersVal(FilterName.ALL_VENDORS, val)}
            />
            <Connector>AND</Connector>
            {/* product search */}
            <ProductSearch vendorId={filtersState.allVendors?.id ?? ''} onSelectProduct={onSelectProduct} />
          </StyledGridItem>
          <Grid item xs={12} md={6}>
            {filtersState.product && Boolean(filtersState[FilterName.ALL_VENDORS]) ? (
              <Box>
                <Typography variant="h3" mb={3}>
                  {t('platformManagement.rules.minimalPriceFor')}
                </Typography>
                <Typography variant="body1">{filtersState.product.name}</Typography>
                <Box sx={[fieldBoxStyle, { bgcolor: lighterSecondaryBgr }]} mt={3}>
                  <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }} my={2}>
                    <TextField
                      type="number"
                      sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 1 }}
                      name="skaloPrice"
                      label={t('platformManagement.skaloPrice')}
                      value={skaloAndClientPrice.skaloPrice}
                      inputProps={{ min: 0, step: 0.01, endAdornment: <InputAdornment position="end">PLN</InputAdornment> }}
                      onChange={e => setSkaloAndClientPrice(prev => ({ ...prev, skaloPrice: Number(e.target.value) }))}
                    />
                    <TextField
                      type="number"
                      sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 1 }}
                      name="clientPrice"
                      label={t('platformManagement.clientPrice')}
                      value={skaloAndClientPrice.clientPrice}
                      inputProps={{ min: 0, step: 0.01, endAdornment: <InputAdornment position="end">PLN</InputAdornment> }}
                      onChange={e => setSkaloAndClientPrice(prev => ({ ...prev, clientPrice: Number(e.target.value) }))}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Typography variant="h4">{t('platformManagement.rules.vendorPlusProduct')}</Typography>
            )}

            <Box display="flex" justifyContent="flex-end" gap={2} mt={{ xs: 4, md: 6 }}>
              <Button onClick={resetAll} variant="outlined" startIcon={<CloseIcon />}>
                {t('common.cancel')}
              </Button>
              <Button disabled={!filtersState.product} variant="contained" startIcon={<SaveIcon />} onClick={addMinimalPriceRule}>
                {t('platformManagement.action.saveRule')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MainPaper>
      <Divider sx={{ my: 4, borderColor: 'grey.300' }} />
      <Typography variant="h4" mb={3}>
        {t('platformManagement.minimalPriceRulesList')}
      </Typography>
    </Collapse>
  )
}

export default RuleForm
