import { Box, Tooltip, Typography } from '@mui/material'

import AvatarImg from '../AvatarImg'

export interface NameWithLogoProps {
  title: string
  imgUrl?: string | null
  imgSize?: number
}

export const NameWithLogo = ({ title, imgUrl, imgSize = 32 }: NameWithLogoProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Box sx={{ width: imgSize, height: imgSize }}>
          <AvatarImg imgUrl={imgUrl} name={title} sx={{ width: imgSize, height: imgSize }} />
        </Box>
      </Box>
      <Tooltip title={title}>
        <Typography component="div" variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
          {title}
        </Typography>
      </Tooltip>
    </Box>
  )
}
