import { PaymentTypeVal } from 'core/consts'

export enum DialogTypes {
  CHANGE_ORDER_STATUS = 'dotykacka',
  EDIT_PRODUCT = 'cancelOrder',
  SWAP_PRODUCT = 'swapProduct',
  ADD_PRODUCT = 'addProduct',
  DELETE_PRODUCT = 'deleteProduct',
  SET_DAILY_PRICE = 'setDailyPrice',
}
export interface VendorOrdersParams {
  Filters?: string
  Sorts?: string
  Page?: number
  PageSize?: number
  OrderRole: 0 | 1 | 2 // Client = 0, Vendor = 1, Any = 2
  DeliverySiteId?: string
  Status?: string
  PaymentMethod: string
}

export interface VendorOrdersType {
  id: string
  status: string
  orderDate: string
  counterparty: string
  productCount: number
  totalCost: number
  totalCostGross: number
  skaloTotalCost: number
  skaloTotalCostGross: number
  deliverySiteName: string
  friendlyOrderNumber: string
  paymentMethod: PaymentTypeVal
}

export interface UpdateSingleProductOrder {
  productOrderId: string
  quantity: number
  comment: string
}

export interface SwapProductOrder {
  productOrderId: string
  targetProductId: string
  comment: string
}
export interface AddProductToOrder {
  orderId: string
  productId: string
  vendorId: string
  quantity: number
}

export interface VendorMonthlyReportParams {
  vendorId: string
  year: number
  month: number
}

export interface VendorMonthlyReportType {
  dailyIncome: {
    day: number
    value: number
  }[]
  totalIncome: number
}

export interface VendorStatisticsParams {
  vendorId: string
}

export interface VendorStatisticsType {
  totalIncome: number
  totalOrders: number
  totalCustomers: number
  averageOrderValue: number
  topPerformingCategory: string | null
  topPerformingSubcategory: string | null
  highestRevenueProduct: {
    productName: string
    image: string | null
    totalEarnings: number
  }
  mostSoldProduct: {
    productName: string
    image: string | null
    soldAmount: number
  }
}
