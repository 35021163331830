export const fieldBoxStyle = {
  border: 1,
  borderColor: 'grey.400',
  borderRadius: 0.5,
  bgcolor: 'grey.50',
  p: 2,
}

export const paperStyle = {
  overflow: 'visible',
  mb: 6,
  py: 4,
  border: 1,
  borderColor: 'grey.300',
  '&::before': {
    borderTop: 1,
    borderLeft: 1,
    borderColor: 'grey.300',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 23,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}
