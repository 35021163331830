import { SxProps, Theme, darken, lighten } from '@mui/material'

export const boxStyle: SxProps<Theme> = {
  mb: 3,
  pb: 4,
  border: 1,
  borderColor: 'grey.200',
  borderRadius: 2,
  bgcolor: 'background.paper',
  position: 'relative',
}

export const titleStyle = {
  background: (theme: Theme) =>
    theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.5) : lighten(theme.palette.secondary.main, 0.6),
  p: 2,
  mb: 4,
  borderTopRightRadius: '1rem',
  borderTopLeftRadius: '1rem',
  position: 'sticky',
  top: 80,
  zIndex: 2,
}
