import { SxProps } from '@mui/material'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export interface ButtonLinkProps {
  title: string
  btnTitle: string
  id?: string
  fnCallback: () => void
  disabled?: boolean
  sx?: SxProps
}

const ButtonLink = ({ title, btnTitle, fnCallback, disabled, id, sx = [] }: ButtonLinkProps) => {
  return (
    <Typography variant="body2" color="text.secondary" sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
      {title}
      <Link
        id={id}
        component="button"
        variant="inherit"
        onClick={e => {
          e.preventDefault()
          fnCallback()
        }}
        color="text.primary"
        disabled={disabled}
        sx={{ ml: 0.5 }}
      >
        {btnTitle}
      </Link>
    </Typography>
  )
}
export default ButtonLink
