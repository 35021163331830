import { TFunction } from 'i18next'

export enum CatalogUploadApiErrorCode {
  IncorrectFile = 'VendorIntegrationErrors.IncorrectFile',
  IncorrectVatDefinition = 'VendorIntegrationErrors.IncorrectVatDefinition',
  IncorrectPriceColumnDefinition = 'VendorIntegrationErrors.IncorrectPriceColumnDefinition',
  IncorrectNameColumnDefinition = 'VendorIntegrationErrors.IncorrectNameColumnDefinition',
  OtherIntegrationType = 'VendorIntegrationErrors.OtherIntegrationType',
  MismatchedColumns = 'VendorIntegrationErrors.MismatchedColumns',
  MissingConfiguration = 'VendorIntegrationErrors.MissingConfiguration',
}

export const getCatalogUploadErrorCodeMess = (errorCode: string | undefined, t: TFunction) => {
  switch (errorCode) {
    case CatalogUploadApiErrorCode.IncorrectFile:
      return t(`errorCode.${CatalogUploadApiErrorCode.IncorrectFile}.title`)
    case CatalogUploadApiErrorCode.IncorrectVatDefinition:
      return t(`errorCode.${CatalogUploadApiErrorCode.IncorrectVatDefinition}.title`)
    case CatalogUploadApiErrorCode.IncorrectPriceColumnDefinition:
      return t(`errorCode.${CatalogUploadApiErrorCode.IncorrectPriceColumnDefinition}.title`)
    case CatalogUploadApiErrorCode.IncorrectNameColumnDefinition:
      return t(`errorCode.${CatalogUploadApiErrorCode.IncorrectNameColumnDefinition}.title`)
    case CatalogUploadApiErrorCode.OtherIntegrationType:
      return t(`errorCode.${CatalogUploadApiErrorCode.OtherIntegrationType}.title`)
    case CatalogUploadApiErrorCode.MismatchedColumns:
      return t(`errorCode.${CatalogUploadApiErrorCode.MismatchedColumns}.title`)
    case CatalogUploadApiErrorCode.MissingConfiguration:
      return t(`errorCode.${CatalogUploadApiErrorCode.MissingConfiguration}.title`)
    default:
      return t('errorCode.unknownError.title')
  }
}
