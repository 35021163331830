import apiEndpoints from 'api/endpoints'
import { useMutate, useRequest } from 'core/hooks'
import { Client, GetParams, PaginatedResult } from 'core/types'

type Params = {
  IsActive?: string
  IdentityConfirmed?: string
} & GetParams

export const useAllClients = (params: Params) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<Client>>(apiEndpoints.getAllClients(params))
  return { isLoadingAllClients: isLoading, allClientsData: data, ...other }
}

export const useClientDetails = (id: string) => {
  const { data, refetch, ...other } = useRequest<Client>(apiEndpoints.getClientDetails(id), { enabled: !!id })
  return { clientData: data, refetchClient: refetch, ...other }
}

export const useVerifyClient = () => {
  const { mutate, isLoading, ...other } = useMutate(apiEndpoints.postVerifyClient())
  return { postVerifyClient: mutate, verifyClientIsLoading: isLoading, ...other }
}
