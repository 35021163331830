import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Box,
  Collapse,
  FormControlLabel,
  FormHelperText,
  Link,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  SxProps,
  Typography,
} from '@mui/material'
import { RequiredSign } from 'core/components/RequiredSign'

export interface CheckboxProps extends Omit<MuiCheckboxProps, 'onChange'> {
  label: string
  name: string
  expandedText?: React.ReactNode
  required?: boolean
  error?: boolean
  errorHelperText?: string
  value: boolean
  sx?: SxProps
  onChange: (val: boolean) => void
}

export const Checkbox = ({
  label,
  name,
  expandedText,
  error,
  errorHelperText,
  value,
  required,
  size,
  onChange,
  sx = [],
}: CheckboxProps) => {
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(false)

  const handleToggle = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setExpanded(!expanded)
    },
    [expanded],
  )

  const handleChange = (event: any) => {
    onChange(event.target.checked)
  }

  return (
    <Box sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
      <FormControlLabel
        control={<MuiCheckbox checked={value} size={size} />}
        name={name}
        label={
          <Typography variant={size === 'small' ? 'body2' : 'body1'} sx={{ alignSelf: 'center' }}>
            {label}
            {required && <RequiredSign />}
            {expandedText && (
              <Link component="button" color="primary" onClick={handleToggle} sx={{ ml: 1, display: 'inline-flex', alignItems: 'center' }}>
                {expanded ? (
                  <>
                    {t('common.action.collapse')} <ExpandLess fontSize="small" />
                  </>
                ) : (
                  <>
                    {t('common.action.expand')} <ExpandMore fontSize="small" />
                  </>
                )}
              </Link>
            )}
          </Typography>
        }
        onChange={handleChange}
        sx={{ alignItems: 'flex-start' }}
      />
      {expandedText && (
        <Collapse in={expanded}>
          <CheckboxCaption size={size} text={expandedText} />
        </Collapse>
      )}
      {error && errorHelperText && (
        <FormHelperText error>
          <CheckboxCaption size={size} text={errorHelperText} error />
        </FormHelperText>
      )}
    </Box>
  )
}

interface CheckboxCaptionProps {
  size?: 'small' | 'medium'
  text?: React.ReactNode
  error?: boolean
}

export const CheckboxCaption = ({ size, text, error }: CheckboxCaptionProps) => (
  <Typography
    variant={size === 'small' ? 'caption' : 'body2'}
    component={error ? 'span' : 'div'}
    color={error ? 'error.main' : 'text.secondary'}
    ml={size === 'small' ? 3.2 : 3.6}
  >
    {text}
  </Typography>
)
