import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import StarIcon from '@mui/icons-material/Star'
import { Box } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useAuth } from 'auth/contexts/AuthProvider'

const AchievementWidget = () => {
  const { userInfo } = useAuth()
  const { t } = useTranslation()

  return (
    <Card sx={{ color: 'primary.contrastText', marginBottom: '20px' }}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'space-between',
          minHeight: '350px',
        }}
      >
        <Avatar sx={{ bgcolor: 'primary.main', mb: 3 }}>
          <StarIcon />
        </Avatar>
        <Typography gutterBottom variant="h5" component="div" color="primary.main">
          {t('admin.home.achievement.title', { name: userInfo?.firstName })}
        </Typography>
        <Typography color="primary.main" component="h3" variant="h2">
          {t('admin.home.achievement.action')}
        </Typography>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Button
            component={RouterLink}
            to="/orders"
            variant="contained"
            disabled
            sx={{
              '&.Mui-disabled': {
                opacity: 1,
                backgroundColor: 'primary.main',
                color: 'inherit',
              },
            }}
          >
            Finansowanie zakupu
          </Button>
          <Button
            component={RouterLink}
            to="/orders"
            variant="contained"
            disabled
            sx={{
              '&.Mui-disabled': {
                opacity: 1,
                backgroundColor: 'primary.main',
                color: 'inherit',
              },
            }}
          >
            Limit kupiecki
          </Button>
          <Button
            component={RouterLink}
            to="/orders"
            variant="contained"
            disabled
            sx={{
              '&.Mui-disabled': {
                opacity: 1,
                backgroundColor: 'primary.main',
                color: 'inherit',
              },
            }}
          >
            Płatność ratalna
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default AchievementWidget
