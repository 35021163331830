import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AxiosResponse } from 'axios'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { PricingRuleDto, PricingRuleSimulatedRes } from 'core/types'
import { FiltersState } from 'management/components/RulesManagement/RuleForm'
import { RuleDatesValid, RulePriceValues } from 'management/types'

import { usePostPricingRule, usePutPricingRuleSimulateAdd } from './useApiPricingRule'

export interface RuleManagement {
  rulePriceValues: RulePriceValues
  ruleDatesValid: RuleDatesValid
  filtersState: FiltersState
  resetAll: () => void
  refetchPricingRuleListData: () => void
}

export const useRuleManagement = ({
  rulePriceValues,
  ruleDatesValid,
  filtersState,
  resetAll,
  refetchPricingRuleListData,
}: RuleManagement) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { postPricingRule } = usePostPricingRule()
  const { putPricingRuleSimulateAdd } = usePutPricingRuleSimulateAdd()

  const [simulatedData, setSimulatedData] = useState<PricingRuleSimulatedRes | null>(null)

  const mapData = (): PricingRuleDto => ({
    validFrom: ruleDatesValid.validFrom ? ruleDatesValid.validFrom.toISOString() : null,
    validTo: ruleDatesValid.validTo ? ruleDatesValid.validTo.toISOString() : null,
    startAsap: ruleDatesValid.startAsap,

    vendorId: filtersState.allVendors?.id ?? '',

    margin: Number(rulePriceValues.margin),
    retroComission: Number(rulePriceValues.retroComission),
    specialDiscount: Number(rulePriceValues.specialDiscount),

    productId: filtersState.product ? filtersState.product.id : null,
    deliverySiteId: filtersState.allDeliverySites ? Number(filtersState.allDeliverySites.id) : null,
    categoryId: filtersState.allProductCategories ? Number(filtersState.allProductCategories) : null,
  })

  const handlePostPricingRule = async () => {
    const bodyData = mapData()

    postPricingRule(bodyData, {
      onSuccess: () => {
        resetAll()
        refetchPricingRuleListData()
        snackbar.success(t('platformManagement.rules.addRuleCallback.success'))
      },
      onError: () => {
        snackbar.error(t('platformManagement.rules.addRuleCallback.error'))
      },
    })
  }
  const handleSimulatePricingRuleAdd = async () => {
    const bodyData = mapData()

    putPricingRuleSimulateAdd(bodyData, {
      onSuccess: async (res: AxiosResponse<PricingRuleSimulatedRes>) => {
        if (res.data?.modified.length || res.data?.removing.length) {
          setSimulatedData(res.data)
        } else {
          setSimulatedData(null)
          await handlePostPricingRule()
        }
      },
      onError: () => {
        snackbar.error(t('platformManagement.rules.addRuleCallback.error'))
      },
    })
  }

  useEffect(() => {
    setSimulatedData(null)
  }, [filtersState, ruleDatesValid, rulePriceValues])

  return {
    handleSimulatePricingRuleAdd,
    handlePostPricingRule,
    simulatedData,
  }
}
