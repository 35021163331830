import apiEndpoints from 'api/endpoints'
import { useMutate } from 'core/hooks'

export const useReadNotification = () => {
  return useMutate(apiEndpoints.readNotification())
}

export const useReadAllNotifications = () => {
  return useMutate(apiEndpoints.readAllNotifications())
}
