import { QueryKey } from '@tanstack/react-query'
import { ResponseType } from 'axios'
import { FileType } from 'core/consts'
import { GetParams } from 'core/types'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'
export interface Endpoint {
  url: string
  method?: HttpMethod
  responseType?: ResponseType
  queryKey?: QueryKey
  params?: any
  headers?: any
  paramsSerializer?: any
}

const apiEndpoints = {
  // admin
  postAdminVendorLogo: () =>
    baseKey('Administrator/AddVendorLogo', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  getVendorIntegration: (params: any) => baseKey('VendorIntegration', { method: 'GET', params }),
  postVendorIntegrationUploadCatalog: () =>
    baseKey('VendorIntegration/UploadVendorCatalog', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  getAdminAllowedPayments: (vendorId: string) => baseKey(`AdminAllowedPayments/${vendorId}`, { method: 'GET' }),
  getAdminDisabledDSitesForVendor: (vendorId: string) => baseKey(`AdminDisabledDeliverySitesForVendor/${vendorId}`, { method: 'GET' }),
  postAdminDisabledDSitesForVendor: () => baseKey('AdminDisabledDeliverySitesForVendor/Add', { method: 'POST' }),
  deleteAdminDisabledDSitesForVendor: () => baseKey('AdminDisabledDeliverySitesForVendor/Delete', { method: 'POST' }),

  postAdminAllowedPayments: () => baseKey('AdminAllowedPayments/AddOrUpdate', { method: 'POST' }),
  getPaymentMethodsLimits: () => baseKey('AllowedPayments/GetPaymentMethodsLimits', { method: 'GET' }),
  postSetPaymentMethodsLimits: () => baseKey('AdminAllowedPayments/SetPaymentMethodsLimits', { method: 'POST' }),

  getAllClients: (params: any) => baseKey('Client/ListAll', { method: 'GET', params }),
  getClientDetails: (id: string) => baseKey(`Client/${id}`, { method: 'GET' }),
  postVerifyClient: () => baseKey('Administrator/VerifyClient', { method: 'POST' }),

  getAllDeliverySites: (params?: GetParams) => baseKey('Administrator/ListAllDeliverySites', { method: 'GET', params }),
  getAllDeliverySitesExtended: (params?: GetParams) => baseKey('Administrator/ListAllExtendedDeliverySites', { method: 'GET', params }),
  getAdminDeliverySiteDetails: (id: string) => baseKey(`Administrator/ListAllDeliverySites/${id}`, { method: 'GET' }),
  getAllUsers: (params: any) => baseKey('Administrator/ListAllUsers', { method: 'GET', params }),
  getAllUsersForClient: (params: any) => baseKey('Administrator/ListAllUsersForClient', { method: 'GET', params }),
  getAllOrders: (params: any) => baseKey('Administrator/OrderList', { method: 'GET', params }),
  putAdminUser: () => baseKey('Administrator/UpdateUser', { method: 'PUT' }),

  getAllVendors: (params?: any) => baseKey('Vendor/ListAll', { method: 'GET', params }),
  getVendorDetails: (id: string) => baseKey(`Vendor/${id}`, { method: 'GET' }),
  postAdminVendor: () => baseKey('Administrator/AddVendor', { method: 'POST' }),
  putAdminVendor: () => baseKey('Administrator/UpdateVendor', { method: 'PUT' }),

  // Pricing rules
  getPricingRuleList: (params: any) => baseKey('Administrator/Pricing/List', { method: 'GET', params }),
  postPricingRule: () => baseKey('Administrator/Pricing', { method: 'POST' }),
  putPricingRuleSimulateAdd: () => baseKey('Administrator/Pricing/SimulateAdd', { method: 'PUT' }),

  //Minimal price rules

  putMinimalPrice: () => baseKey('Administrator/MinimalPrice', { method: 'PUT' }),
  deleteMinimalPrice: (id: string) => baseKey(`Administrator/MinimalPrice?productId=${id}`, { method: 'DELETE' }),
  getMinimalPriceList: (params: any) => baseKey('Administrator/MinimalPrice/List', { method: 'GET', params }),

  //Dotykacka
  getDotykackaPosProducts: (params: any) => baseKey('Administrator/PosProducts', { method: 'GET', params }),

  // others
  postRegister: () => baseKey('/User/register', { method: 'POST' }),

  postLogin: () => baseKey('/User/login', { method: 'POST' }),
  postLogout: () => baseKey('/User/logout', { method: 'POST' }),
  postStartProcessingWithId: () => baseKey('Orders/processing', { method: 'POST' }),
  postStartProcessing: () => baseKey('Orders/startProcessing', { method: 'POST' }),
  postProductImage: () =>
    baseKey('ProductImage/Upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  getProductStock: (params: any) => baseKey('Product/Stock', { method: 'GET', params }),
  getCatalog: (params: any, queryKey?: QueryKey) => baseKey('Product', { method: 'GET', params, queryKey }),
  getProducts: (params: any, queryKey?: QueryKey) =>
    baseKey('Product', {
      method: 'GET',
      params,
      paramsSerializer: {
        indexes: null,
      },
      queryKey,
    }),

  postRefreshToken: () => baseKey('User/refreshToken/', { method: 'POST' }),

  // consumer complaint
  putConsumerComplaint: () => baseKey('ConsumerComplaint', { method: 'PUT' }),

  //invoices
  getInvoicesList: (params: any) => baseKey('Invoices/ListAll', { method: 'GET', params }),
  downloadInvoice: (type: FileType, clientId: string, id: string) =>
    baseKey(`Invoices/Download/${type}/${clientId}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': type === FileType.PDF ? 'application/pdf' : 'text/csv',
      },
      responseType: 'blob',
    }),

  // vendor
  getVendorOrders: (params: any) => baseKey('Orders/', { method: 'GET', params }),
  postCommitOrderChanges: () => baseKey('Orders/commitChanges', { method: 'POST' }),
  getVendorCatalog: (params: any, vendorId: string, queryKey: QueryKey) =>
    baseKey(`Product/Vendor/${vendorId}`, { method: 'GET', params, queryKey }),
  getVendorList: () => baseKey('/Vendor/List', { method: 'GET' }),
  putChangeOrderStatus: (status: string | undefined, id: string | undefined) => baseKey(`Orders/status/${status}/${id}`, { method: 'PUT' }),
  postVendorLogo: () =>
    baseKey('Vendor/AddVendorLogo', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  postUploadVendorCatalog: () =>
    baseKey('Vendor/UploadVendorCatalog', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  postSwapProductOrder: () => baseKey('ProductOrder/Swap', { method: 'POST' }),
  postAddProductOrder: () => baseKey('ProductOrder/Add', { method: 'POST' }),
  deleteProductOrder: () => baseKey('ProductOrder/Delete', { method: 'DELETE' }),
  postUpdateProductOrder: () => baseKey('ProductOrder/Update', { method: 'POST' }),
  putDailyPrice: () => baseKey('ProductOrder/SetDailyPrice', { method: 'PUT' }),
  putVendor: () => baseKey('Vendor/Update', { method: 'PUT' }),

  //vendor finances
  getVendorMonthlyReport: (params: any) => baseKey('VendorFinances/getVendorMonthlyReport', { method: 'GET', params }),
  getVendorStatistics: (params: any) => baseKey('VendorFinances/getVendorStatistics', { method: 'GET', params }),
  getPastDeliverySites: (params: { vendorId: string | undefined }) =>
    baseKey('Vendor/PastDeliverySites', { method: 'GET', params: params }),

  // client
  getCategories: () => baseKey('Categories/All', { method: 'GET' }),
  getDeliverSites: () => baseKey('DeliverySite/', { method: 'GET' }),
  getManagedDeliverySites: (clientId: string) => baseKey(`DeliverySite/List/${clientId}`, { method: 'GET' }),

  getOrders: (params: any, queryKey?: QueryKey) => baseKey('Orders/', { method: 'GET', params, queryKey }),
  getOrderDetails: (id: string) => baseKey(`/Orders/${id}`, { method: 'GET' }),
  putChangeOrderPaymentMethod: () => baseKey('Orders/ChangePaymentMethod', { method: 'PUT' }),

  getOrderItems: (deliverySiteId: number) => baseKey(`ShoppingCart/${deliverySiteId}`, { method: 'GET' }),
  putRenameShoppingList: () => baseKey('ShoppingList/Rename', { method: 'PUT' }),
  postShoppingCartFromCart: () => baseKey('ShoppingList/FromCart', { method: 'POST' }),
  putClearShoppingCart: () => baseKey('ShoppingCart/Clear', { method: 'PUT' }),
  putUpdateShoppingCart: () => baseKey('ShoppingCart/Update', { method: 'PUT' }),
  putCartPaymentMethod: () => baseKey('ShoppingCart/PaymentMethod', { method: 'PUT' }),
  getClientDetailsByTaxNumber: (taxNumber: string) => baseKey(`Client/DetailsByTaxNumber/${taxNumber}`, { method: 'GET' }),
  getClients: () => baseKey('Client/List', { method: 'GET' }),
  getClientAvailableVendors: (params: { deliverySiteIds: number[] | string[] | undefined }) =>
    baseKey('Client/AvailableVendors', {
      method: 'GET',
      params: params,
      paramsSerializer: {
        indexes: null,
      },
    }),
  getClientAvailableCategories: (params: { deliverySiteId: number | undefined }) =>
    baseKey('Categories/Available', { method: 'GET', params }),

  postAddFavoriteItem: () => baseKey('/FavouriteProduct/Favourite', { method: 'POST' }),
  putDeleteFavoriteItem: () => baseKey('/FavouriteProduct/Favourite/Remove', { method: 'PUT' }),

  getDeliverySiteDetails: (id: string) => baseKey(`DeliverySite/${id}`, { method: 'GET' }),
  postAddDeliverySite: () => baseKey('/DeliverySite/Add', { method: 'POST' }),
  putUpdateDeliverySite: () => baseKey('/DeliverySite/Update', { method: 'PUT' }),
  deleteDeliverySite: (id: number) => baseKey(`DeliverySite/Delete/${id}`, { method: 'DELETE' }),
  postDeliverySiteLogo: () =>
    baseKey('DeliverySite/AddDeliverySiteLogo', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  postOrderIssue: () => baseKey('Orders/ReportIssue', { method: 'POST' }),
  postSendOrder: () => baseKey('/ShoppingCart/Order', { method: 'POST' }),
  //client orders
  postAcceptOrder: () => baseKey('Orders/acceptWithToken', { method: 'POST' }),
  postCancelOrder: () => baseKey('Orders/Cancel', { method: 'POST' }),
  postOrderAgain: (orderId: string) => baseKey(`Orders/OrderAgain/${orderId}`, { method: 'POST' }),
  postAcceptOrderWithChanges: () => baseKey('Orders/acceptChanges', { method: 'POST' }),
  postAddCommentToOrder: () => baseKey('Orders/addComment', { method: 'POST' }),
  postRequestOrderInvoice: () => baseKey('Orders/RequestInvoice', { method: 'POST' }),
  postRequestInvoiceFromVendor: () => baseKey('Orders/RequestInvoiceFromVendor', { method: 'POST' }),
  getPossiblePaymentMethodsToChange: (params: { orderId: string }) =>
    baseKey('Orders/GetPossiblePaymentMethodsToChange', { method: 'GET', params }),
  putClientMarkAsDelivered: (orderId: string) => baseKey(`Orders/clientMarkAsDelivered/${orderId}`, { method: 'PUT' }),

  // client shopping lists
  postAddShoppingListToCart: (shoppingListId: string) => baseKey(`ShoppingList/AddToCart/${shoppingListId}`, { method: 'POST' }),
  getShoppingLists: (params: any) => baseKey('ShoppingList/List', { method: 'GET', params }),
  getShoppingListDetails: (params: any) => baseKey('ShoppingList', { method: 'GET', params }),
  postShoppingList: () => baseKey('ShoppingList', { method: 'POST' }),
  putShoppingListDetails: () => baseKey('ShoppingList', { method: 'PUT', queryKey: ['ShoppingListDetails', 'Edit'] }),
  deleteShoppingList: (id: string) => baseKey(`ShoppingList?shoppingListId=${id}`, { method: 'DELETE' }),

  // client finances
  getClientBaseReport: (params: { clientId: string; year?: string }) => baseKey('Finances/getClientBaseReport', { method: 'GET', params }),
  getClientDetailedReport: (params: { clientId: string; year: string; month: string }) =>
    baseKey('Finances/getClientDetailedReport', { method: 'GET', params }),

  getDeliverySiteBaseReport: (params: { clientId: string; year?: string }) =>
    baseKey('Finances/getDeliverySiteBaseReport', { method: 'GET', params }),
  getDeliverySiteDetailedReport: (params: { clientId: string; year: string; month: string }) =>
    baseKey('Finances/getDeliverySiteDetailedReport', { method: 'GET', params }),

  postCompanyClient: () => baseKey('Client', { method: 'POST' }),
  putCompanyClient: () => baseKey('Client/Update', { method: 'PUT' }),
  deleteCompanyClient: (id: string) => baseKey(`Client/Delete/${id}`, { method: 'DELETE' }),

  getClientCatalog: (params: any, subcategoryParams: string) => baseKey(`Product?${subcategoryParams}`, { method: 'GET', params }),
  getProductById: (productId: string) => baseKey(`Product/${productId}`, { method: 'GET' }),
  putProduct: () => baseKey('Product', { method: 'PUT' }),

  // POS integration
  getDotykackaIntegrationUrl: (deliverySiteId: number) =>
    baseKey(`Client/CreateDotykackaIntegrationUrl?deliverySiteId=${deliverySiteId}`, { method: 'GET' }),
  getBasicPosSalesReport: (params: { deliverySiteId: number; from?: string }) =>
    baseKey('Client/GetBasicPosSalesReport', { method: 'GET', params }),
  getPosIngredients: (params: any) => baseKey('Pos/Ingredients', { method: 'GET', params }),
  getSynchronizeDotykackaSell: (params: { deliverySiteId: number; from?: string }) =>
    baseKey('Client/SynchronizeDotykackaSell', { method: 'GET', params }),
  postAddDotykackaClientIntegration: () => baseKey('Client/AddDotykackaClientIntegration', { method: 'POST' }),
  postStockupDotykackaWarehouse: () => baseKey('Orders/StockupDotykackaWarehouse', { method: 'POST' }),
  getPosIngredientsNames: (params: any) => baseKey('Pos/IngredientNames', { method: 'GET', params }),

  // Spingo
  getSpingoLimit: (clientId: string) => baseKey(`Spingo/Limit?clientId=${clientId}`, { method: 'GET' }),
  postRequestLimit: () => baseKey('Spingo/RequestLimit', { method: 'POST' }),

  // Notifications
  getNotifications: () => baseKey('Notifications', { method: 'GET' }),
  readNotification: () => baseKey('Notifications/Read', { method: 'PUT' }),
  readAllNotifications: () => baseKey('Notifications/ReadAll', { method: 'PUT' }),

  // code verification
  postGenerateVerificationCode: () => baseKey('PhoneValidation/Generate', { method: 'POST' }),
  postVerifyVerificationCode: () => baseKey('PhoneValidation/Validate', { method: 'POST' }),
}

const baseUrl = (
  path: string,
  method?: HttpMethod,
  responseType?: ResponseType,
  params?: any,
  headers?: any,
  paramsSerializer?: any,
): Endpoint => ({
  url: path,
  method,
  responseType,
  params,
  headers,
  paramsSerializer,
})

const baseKey = (
  path: string,
  arg?: {
    method?: HttpMethod
    queryKey?: QueryKey
    responseType?: ResponseType
    params?: any
    headers?: any
    paramsSerializer?: any
  },
): Endpoint => {
  const queryKey = arg?.queryKey ?? [
    ...path.split('/').filter(value => value.length),
    ...(arg?.params ? Object.values(arg?.params).map(el => el) : []),
  ]
  return {
    queryKey,
    ...baseUrl(path, arg?.method, arg?.responseType, arg?.params, arg?.headers, arg?.paramsSerializer),
  }
}

export default apiEndpoints
