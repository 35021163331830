import { useState } from 'react'

import { SwapHoriz } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, TextField, Typography } from '@mui/material'
import { MainTable } from 'core/components'
import SearchComponent from 'core/components/SearchComponent'
import { catalogFiltersStateInit } from 'core/consts'
import { Item, ProductOrder } from 'core/types'
import { formatPrice } from 'core/utils/formatUtils'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { createFilters } from 'items/helpers/itemHelpers'
import { mapVendorProductsToSwap, mapVendorProductsToSwapColumns } from 'vendor/helpers/mapVendorProductsToSwap'
import { useGetVendorCatalog } from 'vendor/hooks/useGetVendorCatalog'
import { SwapProductOrder } from 'vendor/types/types'
import * as Yup from 'yup'

type SwapOrderDetailDialogProps = {
  onClose: () => void
  orderProduct: ProductOrder
  vendorId: string
  onSave: (updateOrder: SwapProductOrder) => void
  processing: boolean
}

const SwapOrderDetailDialog: React.FC<SwapOrderDetailDialogProps> = ({ onClose, orderProduct, onSave, processing, vendorId }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<Item | null>(null)
  const { vendorCatalog } = useGetVendorCatalog(
    {
      Page: page + 1,
      PageSize: rowsPerPage,
      Filters: createFilters(searchTerm, catalogFiltersStateInit),
    },
    vendorId,
    ['Vendor', 'List', vendorId, page, rowsPerPage, searchTerm],
  )

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setRowsPerPage(newPageSize)
  }

  const formik = useFormik({
    initialValues: {
      productOrderId: orderProduct.id,
      targetProductId: selectedProduct?.id ?? '',
      comment: '',
    },
    validationSchema: Yup.object({
      targetProductId: Yup.string().required(),
    }),
    onSubmit: values => {
      onSave(values)
    },
    enableReinitialize: true,
  })

  const handleSelect = (item: Item) => {
    setSelectedProduct(item)
    formik.setFieldValue('targetProductId', item.id)
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t('orders.swap')}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">{orderProduct.productName}:</Typography>
              <Typography variant="h5">
                {orderProduct.quantity} x {formatPrice(orderProduct.pricePerUnit)}
              </Typography>
            </Box>
            {selectedProduct && <SwapHoriz />}
            {selectedProduct && (
              <Box>
                <Typography variant="h5">{selectedProduct.name}:</Typography>
                <Typography variant="h5">
                  {Math.min(orderProduct.quantity, selectedProduct.stock)} x {formatPrice(selectedProduct.price)}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography mt={2} color="warning.main">
            {t('orders.swapProposal')}
          </Typography>
          <FormControl fullWidth required>
            <TextField
              id="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              label={t('vendorOrders.addComment')}
              margin="dense"
            />
          </FormControl>
          <Divider sx={{ margin: '1em 0' }} />
          <SearchComponent onSearch={setSearchTerm} />
          <Box height="20rem" overflow="auto" margin="dense">
            {vendorCatalog && (
              <MainTable
                columns={mapVendorProductsToSwapColumns(t)}
                rows={mapVendorProductsToSwap(
                  vendorCatalog.items,
                  {
                    handleSelect: handleSelect,
                  },
                  [orderProduct],
                  t,
                )}
                count={vendorCatalog?.totalCount}
                pagination={{
                  page: page,
                  onPageChange: handlePageChange,
                  pageSize: rowsPerPage,
                  onPageSizeChange: handlePageSizeChange,
                }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="outlined" startIcon={<CloseIcon />}>
            {t('common.cancel')}
          </Button>
          <LoadingButton loading={processing} color="primary" variant="contained" type="submit">
            {t('common.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default SwapOrderDetailDialog
