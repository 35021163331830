export const enum UserRole {
  ADMIN = 'admin',
  VENDOR = 'vendor',
  CLIENT = 'client',
  COMPANY_ADMIN = 'company_admin',
  TEST_VENDOR = 'test_vendor',
  RESTAURANT_MANAGER = 'manager',
}

export const enum IsChanged {
  DELETED = 'deleted',
  ADDED = 'added',
  NONE = 'none',
}

export const enum ValueRange {
  MAX_POSITIVE_VALUE = 2147483647,
}

export const enum ActionType {
  DELETE = 'delete',
  ADD = 'add',
  NONE = 'none',
  EDIT = 'edit',
}

export const enum FileType {
  DEFAULT = '',
  PDF = 'pdf',
  CSV = 'csv',
}

export const PHONE_REGEXP_PL = /^(\+(\d){2})?\d{9}$/s

export const SPINGO_BANK_ACCOUNT = '80 1870 1058 2900 1022 2924 5409'
export const SPINGO_ACCOUNT_LINK = 'https://panel.spingo.pl/account/sign-in'
export const SPINGO_FORM_LINK =
  'https://wniosek.faktoria.pl/FMBewnioseknew/WizardWfB.aspx?b=1&p=2&s=297&l=Faktoria&Field1=681f95990aafc08451857eeee1e0502df482079d'

export const SKALO_PHONE_NUMBER = '+48 787 597 007'
export const SKALO_EMAIL = 'biuro@skalo.pl'
