import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { TFunction } from 'i18next'

export const invoicesListSortOptions = (t: TFunction) => [
  {
    id: '-issueDate',
    name: t('orders.orderDateDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'issueDate',
    name: t('orders.orderDateAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-netTotal',
    name: t('orders.sumAsc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'netTotal',
    name: t('orders.sumDesc'),
    icon: <ArrowUpward />,
  },
]
