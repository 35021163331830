import { Trans, useTranslation } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import SpingoLogo from 'core/assets/spingo-logo.jpg'
import { LinearProgressBar } from 'core/components'
import { SPINGO_FORM_LINK } from 'core/consts'
import { Client, PaymentLimit } from 'core/types'
import { formatPrice } from 'core/utils'

interface SpingoLimitDialogProps {
  onClose: () => void
  paymentLimit?: PaymentLimit
  client: Client | null
}

const SpingoLimitDialog = ({ onClose, client, paymentLimit }: SpingoLimitDialogProps) => {
  const { t } = useTranslation()

  const handleRedirectToSpingo = () => {
    window.open(SPINGO_FORM_LINK, '_blank')
    onClose()
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Trans
          i18nKey="payment.yourPaymentLimitIn"
          components={{
            tag: <img src={SpingoLogo} height={36} alt="Spingo Logo" style={{ marginLeft: '0.5rem' }} />,
          }}
        />
      </DialogTitle>
      <DialogContent>
        {paymentLimit && paymentLimit?.totalLimit > 0 ? (
          <Typography variant="body1">
            <Trans
              i18nKey="payment.limitForCompany"
              values={{
                name: client?.name,
                val: formatPrice(paymentLimit?.remainingLimit),
              }}
              components={{
                nameTag: <Typography variant="subtitle1" component="span" color="primary" ml={0.5}></Typography>,
              }}
            />
          </Typography>
        ) : (
          <>
            <Typography variant="body1" mb={2}>
              <Trans
                i18nKey="payment.noLimitForCompany"
                values={{ name: client?.name }}
                components={{
                  nameTag: <Typography variant="subtitle1" component="span" color="primary" ml={0.5}></Typography>,
                }}
              />
            </Typography>
            <Typography mb={4}>{t('payment.youWillBeRedirectedToSpingo')}</Typography>
            <LinearProgressBar timerValue={400} onTimeIsOver={handleRedirectToSpingo} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {paymentLimit && paymentLimit?.totalLimit > 0 ? t('common.action.close') : t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SpingoLimitDialog
