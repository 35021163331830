import { useTranslation } from 'react-i18next'

import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, SvgIconProps, SxProps, Typography } from '@mui/material'

import { LabelWithIcon } from '../fields'
import { commonStyle } from './styles'

export interface FilterLabelProps {
  label: string
  icon?: React.FC<SvgIconProps>
  onClear?: () => void
  sx?: SxProps
}

export const FilterLabel = ({ label, icon, onClear, sx = [] }: FilterLabelProps) => {
  const { t } = useTranslation()
  return (
    <Box sx={[commonStyle, { py: typeof onClear === 'function' ? 1.4 : 1.6 }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h6">{icon ? <LabelWithIcon label={label} icon={icon} /> : t(label)}</Typography>
      {typeof onClear === 'function' && (
        <IconButton aria-label="clear" onClick={onClear} size="small" sx={{ p: 0.3 }}>
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  )
}
