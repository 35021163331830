import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { GetParams, PaginatedResult, PosIngredientsReport } from 'core/types'

type GetPosIngredientsParams = {
  deliverySiteId: number
} & GetParams

export const useGetPosIngredients = (params: GetPosIngredientsParams) => {
  const { data, ...other } = useRequest<PaginatedResult<PosIngredientsReport>>(apiEndpoints.getPosIngredients(params), {
    enabled: !!params.deliverySiteId,
  })

  return {
    posIngredients: data,
    ...other,
  }
}
