import { Box, SxProps, Theme, Typography } from '@mui/material'

export interface ContentItemProps {
  label: string
  value: React.ReactNode
  sx?: SxProps<Theme>
  valueSx?: SxProps<Theme>
  inline?: boolean
}

const ContentItem = ({ label, value, sx = [], valueSx = [], inline = false }: ContentItemProps) => {
  return (
    <Box
      sx={[
        { display: 'flex', flexDirection: inline ? 'row' : 'column', alignItems: inline ? 'center' : 'flex-start' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="body2" color="text.secondary" mb={inline ? 0 : 0.5} mr={inline ? 1 : 0}>
        {label}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={[{ display: 'flex' }, ...(Array.isArray(valueSx) ? valueSx : [valueSx])]}>
        {value}
      </Typography>
    </Box>
  )
}

export default ContentItem
