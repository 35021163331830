import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePostVerifyVerificationCode } from 'core/hooks/api'
import { useFormikContext } from 'formik'

import CodeVerification from '.'

interface CodeVerificationContainerProps {
  onChangePhoneNo: () => void
  onResendCode: (phoneNo: string) => void
  phoneNumberFieldName: string
}

export const CodeVerificationContainer = ({ phoneNumberFieldName, onResendCode, onChangePhoneNo }: CodeVerificationContainerProps) => {
  const { t } = useTranslation()
  const postVerifyVerificationCode = usePostVerifyVerificationCode()
  const { values, submitForm }: any = useFormikContext()

  const phoneNumber = values[phoneNumberFieldName]?.toString()

  const [errorInfo, setErrorInfo] = useState('')

  const [visible, setVisible] = useState(true)

  const onSubmitCode = async (code: string) => {
    await postVerifyVerificationCode.mutate(
      { code, phoneNumber },
      {
        onSuccess: () => {
          setErrorInfo('')
          setVisible(false)
          setTimeout(() => {
            submitForm()
          }, 50)
        },
        onError: () => {
          setErrorInfo(t('message.incorrectVerificationCode'))
        },
      },
    )
  }

  return visible ? (
    <CodeVerification
      phoneNo={phoneNumber}
      onResendCode={onResendCode}
      onChangePhoneNo={onChangePhoneNo}
      onSubmitCode={onSubmitCode}
      errorMsg={errorInfo}
      loading={postVerifyVerificationCode.isLoading}
    />
  ) : (
    <></>
  )
}
