import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Chip, Grid, Typography } from '@mui/material'
import { AvatarImg, ContentItem } from 'core/components'
import { PaymentTypeVal } from 'core/consts'
import { Vendor } from 'core/types'
import { formatAddress, getPaymentMethodIcon, getWeekdaysList } from 'core/utils'
import dayjs from 'dayjs'

import { boxStyle } from './styles'

type VendorDetailsProps = {
  vendor: Vendor
}

const VendorDetails: React.FC<VendorDetailsProps> = ({ vendor }) => {
  const { t } = useTranslation()
  // const allPaymentMethods = prepareOrderPaymentMethodsList(t, { noAll: true })

  return (
    <Box>
      <Grid container rowSpacing={5} columnSpacing={3}>
        <Grid item xs={12} lg={6}>
          <Box sx={boxStyle}>
            <Grid container rowSpacing={3} columnSpacing={6}>
              <Grid item xs="auto">
                <ContentItem label={t('vendors.name')} value={vendor.name} />
              </Grid>
              <Grid item>
                <ContentItem label={t('vendors.taxNumber')} value={vendor.taxNumber} />
              </Grid>
              <Grid item>{vendor.vendorLogoUrl && <AvatarImg imgUrl={vendor.vendorLogoUrl} name={vendor.name} />}</Grid>
              <Grid item xs={12}>
                <ContentItem label={t('vendors.contactEmail')} value={vendor.contactEmail} />
              </Grid>
              <Grid item xs={12}>
                <ContentItem label={t('address.title')} value={formatAddress(vendor.invoicingAddress)} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={boxStyle}>
            <Grid container rowSpacing={3} columnSpacing={6}>
              <Grid item>
                <ContentItem label={t('vendors.deliveryConditions.orderLogisticMinimum')} value={<>{vendor.orderLogisticMinimum} zł</>} />
              </Grid>
              <Grid item>
                <ContentItem label={t('vendors.deliveryConditions.operatingRange')} value={<>{vendor.operatingRange} km</>} />
              </Grid>
              <Grid item xs={12}>
                <ContentItem
                  label={t('payment.paymentsMethods')}
                  value={
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                      {vendor.allowedPaymentMethods?.map(value => (
                        <Box key={value} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {getPaymentMethodIcon(value as PaymentTypeVal)}
                          {t(`payment.paymentsMethodVal.${value}`)}
                        </Box>
                      ))}
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ContentItem
                  label={t('vendors.deliveryConditions.deliveryDays')}
                  value={
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {getWeekdaysList().map((el, index) => (
                        <Chip
                          key={index.toString()}
                          label={el.name.toUpperCase()}
                          color="primary"
                          sx={{ px: 3, py: 2, border: 1, borderColor: 'primary.light' }}
                          variant={
                            vendor.deliveryConditions?.deliveryDays?.find((a: number) => a === el.id) !== undefined ? 'filled' : 'outlined'
                          }
                        />
                      ))}
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ContentItem
                  label={t('vendors.deliveryConditions.standardDeliveryDays')}
                  value={`${dayjs().hour(vendor.deliveryConditions?.deliveryHourFrom).format('HH:00')} - ${dayjs().hour(vendor.deliveryConditions?.deliveryHourTo).format('HH:00')}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" mb={0.5}>
                  {t('vendors.deliveryConditions.nextDayDeliveryConditions')}
                </Typography>
                <Typography>
                  <Trans
                    i18nKey="vendors.deliveryConditions.orderBeforeWillBeDelivered"
                    values={{
                      orderBeforeHour: dayjs().hour(vendor.deliveryConditions?.deliveryOrderBeforeHour).format('HH:00'),
                      time: `${dayjs().hour(vendor.deliveryConditions?.deliveryNextDayHourFrom).format('HH:00')} - ${dayjs().hour(vendor.deliveryConditions?.deliveryNextDayHourTo).format('HH:00')}`,
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" mb={0.5}>
                  {t('vendors.deliveryConditions.delayFirstDeliveryTitle')}
                </Typography>
                <Typography>
                  <Trans
                    i18nKey="vendors.deliveryConditions.delayFirstDeliveryVendorDetails"
                    values={{
                      delayFirstDelivery: vendor?.deliveryConditions.delayFirstDelivery ? t('common.yes') : t('common.no'),
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default VendorDetails
