import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useDeleteClient } from 'companies/hooks'
import { TitleWithName } from 'core/components'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { Client } from 'core/types'

export interface WithDeleteDialogInjectedProps {
  openDeleteDialog: (client: Client) => void
}

export interface WithDeleteDialogProps {
  onDeleteCallback?: (client: Client) => void
  onClose?: () => void
}

export const withDeleteDialog = <T extends object>(
  WrappedComponent: React.ComponentType<T & WithDeleteDialogInjectedProps & WithDeleteDialogProps>,
) => {
  return (props: T & WithDeleteDialogProps) => {
    const { onDeleteCallback, onClose } = props
    const queryClient = useQueryClient()
    const { t } = useTranslation()
    const snackbar = useSnackbar()
    const [clientData, setClientData] = useState<Client | null>(null)

    const openDialog = (client: Client) => {
      setClientData(client)
    }
    const closeDialog = () => {
      setClientData(null)
      if (typeof onClose === 'function') {
        onClose()
      }
    }

    const { deleteClient } = useDeleteClient(clientData?.id ?? '')

    const onConfirm = async () => {
      await deleteClient(
        {},
        {
          onSuccess: () => {
            if (typeof onDeleteCallback === 'function') {
              onDeleteCallback(clientData!)
            }
            queryClient.invalidateQueries()
            closeDialog()
            snackbar.success(t('company.deleteCompany.success'))
          },
          onError: () => {
            snackbar.error(t('company.deleteCompany.error'))
          },
        },
      )
    }

    return (
      <>
        <WrappedComponent {...props} openDeleteDialog={openDialog} />

        <Dialog open={Boolean(clientData)} onClose={closeDialog}>
          <DialogTitle>
            <TitleWithName title="confirmDeleteClientDialog.title" value={clientData?.name} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t('confirmDeleteClientDialog.message')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeDialog} startIcon={<CloseIcon />}>
              {t('confirmDeleteClientDialog.cancel')}
            </Button>
            <Button onClick={onConfirm} variant="contained" color="error">
              {t('confirmDeleteClientDialog.delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
