import DeleteIcon from '@mui/icons-material/Delete'
import { PaginatedResult } from 'core/types'
import { TFunction } from 'i18next'
import { MinimalPrice } from 'management/types'

type Actions = {
  handleDelete: (el: MinimalPrice) => void
}

export const mapMinimalPriceRulesData = (data: PaginatedResult<MinimalPrice>, t: TFunction, actions: Actions) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.productId,
      data: {
        product: el.productName,
        vendor: el.vendorName,
        skaloPrice: el.skaloPrice,
        clientPrice: el.clientPrice,
      },
      actions: [
        {
          title: t('common.delete'),
          icon: <DeleteIcon />,
          callback: () => actions.handleDelete(el),
        },
      ],
    }
  })

  return mappedData
}

export const mapMinimalPriceRulesColumns = (t: TFunction) => [
  { title: t('margins.product'), id: 'product' },
  { title: t('margins.vendor'), id: 'vendor' },
  { title: t('platformManagement.skaloPrice'), id: 'skaloPrice' },
  { title: t('platformManagement.clientPrice'), id: 'clientPrice' },
  { title: t('common.actions'), id: 'actions', align: 'right' },
]
