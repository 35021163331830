/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactNode, memo } from 'react'

import { ArrowDropDown } from '@mui/icons-material'
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  OutlinedInputProps,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { SelectOption, SelectType } from 'core/types'

import { chipStyle, commonSelectItemStyle, commonSelectStyle } from './styles'

export type SelectProps = MuiSelectProps & {
  name: string
  error?: boolean
  errorHelperText?: string
  helperText?: string
  options: SelectOption[]
  onChange?: (e: SelectChangeEvent<any>, child: ReactNode) => void
  inputProps?: OutlinedInputProps
  type?: SelectType
}

export const Select = memo(
  ({
    name,
    label,
    value,
    options,
    onChange,
    placeholder,
    error,
    errorHelperText,
    helperText,
    inputProps,
    disabled,
    type = SelectType.DEFAULT,
    sx = [],
    required,
    multiple,
    ...rest
  }: SelectProps) => {
    const inputDisabled = disabled || inputProps?.disabled
    const sxStyle = sx ? (Array.isArray(sx) ? sx : [sx]) : []

    const getRenderValue = (selectedOption: SelectOption | undefined, iconSize?: 'small' | 'large') => {
      const label = selectedOption?.name ? selectedOption?.name : selectedOption?.id
      switch (type) {
        case SelectType.CHIP:
          return (
            <Chip
              label={
                <Box display="flex">
                  {selectedOption?.icon && selectedOption.iconPosition === 'start' && (
                    <IconBox iconSize={iconSize}>{selectedOption.icon}</IconBox>
                  )}
                  {label}
                  {selectedOption?.icon && (!selectedOption.iconPosition || selectedOption.iconPosition === 'end') && (
                    <IconBox iconSize={iconSize}>{selectedOption.icon}</IconBox>
                  )}
                </Box>
              }
              // @ts-ignore
              sx={[{ bgcolor: selectedOption?.styles?.color ?? 'grey.500' }, chipStyle]}
            />
          )

        default:
          return (
            <Box display="flex" alignItems="center">
              {selectedOption?.icon && selectedOption.iconPosition === 'start' && (
                <IconBox noRenderedLabel={selectedOption?.noRenderedLabel}>{selectedOption.icon}</IconBox>
              )}
              {!selectedOption?.noRenderedLabel && (
                <Typography
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    // @ts-ignore
                    color: selectedOption?.styles?.color ?? 'inherit',
                  }}
                >
                  {label}
                </Typography>
              )}
              {selectedOption?.icon && (!selectedOption.iconPosition || selectedOption.iconPosition === 'end') && (
                <IconBox>{selectedOption.icon}</IconBox>
              )}
            </Box>
          )
      }
    }

    return (
      <FormControl required={required} sx={[{ width: { xs: '100%', sm: '18rem' }, borderRadius: 1 }, ...sxStyle]}>
        {label && (
          <InputLabel id={`${name}-select`} sx={{ color: inputDisabled ? 'text.secondary' : 'text.primary' }}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          labelId={`${name}-select`}
          label={label}
          name={name}
          id={`${name}-select`}
          value={value || value === 0 ? value : ''}
          displayEmpty={!!placeholder}
          placeholder={placeholder || ''}
          // @ts-ignore
          onChange={onChange}
          disabled={inputDisabled}
          error={error}
          multiple={multiple}
          renderValue={(selected: any) => {
            if (multiple) {
              if (selected?.length === 0 && placeholder) {
                return (
                  <Typography color="text.secondary" variant="inherit" component="span">
                    {placeholder}
                  </Typography>
                )
              }
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  {selected.map((value: string) => (
                    <Box key={value}>
                      {getRenderValue(
                        options.find(el => el.id === value),
                        'small',
                      )}
                    </Box>
                  ))}
                </Box>
              )
            } else {
              const selectedOption = options.find(el => el.id === selected)
              return getRenderValue(selectedOption)
            }
          }}
          IconComponent={ArrowDropDown}
          required={required}
          {...rest}
          {...inputProps}
          inputProps={{ id: `${name}-select` }}
          sx={commonSelectStyle}
          MenuProps={{
            sx: { maxWidth: 'min-content' },
          }}
        >
          <MenuItem disabled value="" sx={{ display: 'none' }}>
            {placeholder}
          </MenuItem>
          {options?.map(o => (
            <MenuItem
              key={o.id.toString()}
              value={o.id}
              sx={[commonSelectItemStyle, ...(Array.isArray(o.styles) ? o.styles : [o.styles])]}
              disabled={o.disabled}
            >
              <Typography display="flex" alignItems="center" component="div">
                {o?.icon && o.iconPosition === 'start' && <IconBox>{o.icon}</IconBox>}
                {o.name ? o.name : o.id}
                {o?.icon && (!o.iconPosition || o.iconPosition === 'end') && <IconBox>{o.icon}</IconBox>}
              </Typography>
            </MenuItem>
          ))}
        </MuiSelect>
        {helperText && (
          <FormHelperText sx={{ mt: 0.8, ml: 1.725 }}>
            <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
              {helperText}
            </Typography>
          </FormHelperText>
        )}
        {error && errorHelperText && (
          <FormHelperText sx={{ mt: 0.8, ml: 1.725 }} error>
            <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
              {errorHelperText}
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    )
  },
)

const IconBox = ({
  children,
  iconSize,
  noRenderedLabel,
}: {
  children: ReactNode
  iconSize?: 'small' | 'large'
  noRenderedLabel?: boolean
}) => (
  <Box
    sx={[
      { minHeight: iconSize === 'small' ? '1rem' : '2rem', minWidth: 'auto', my: -0.5, display: 'flex', alignItems: 'center' },
      iconSize === 'small' && { '& svg': { fontSize: '1.375rem', mr: -1 } },
      noRenderedLabel ? { mr: -2 } : {},
    ]}
  >
    {children}
  </Box>
)
