import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@mui/material'
import { ChartLegend, ChartTooltip, Loader, MainPaper, PaperTitle, Select } from 'core/components'
import { getLastYearsList, last12month } from 'core/utils'
import { COLORS, mapDeliverySiteData } from 'finanse/helpers'
import { useDeliverySiteBaseReport } from 'finanse/hooks'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface DeliverySiteReportBaseReportChartProps {
  selectedClient: string
}

const DeliverySiteReportBaseReportChart = ({ selectedClient }: DeliverySiteReportBaseReportChartProps) => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  const [year, setYear] = useState(last12month)

  const { deliverySiteBaseReportData, isLoadingDeliverySiteBaseReport } = useDeliverySiteBaseReport({
    clientId: selectedClient,
    year: year === last12month ? '' : year,
  })

  const mappedData = () => (deliverySiteBaseReportData ? mapDeliverySiteData(deliverySiteBaseReportData) : [])

  return (
    <MainPaper sx={{ mb: 2, position: 'relative' }}>
      {isLoadingDeliverySiteBaseReport && <Loader relative />}
      <PaperTitle
        title={t('finanse.summary')}
        rightEl={
          <Select
            label={t('common.year')}
            name="year"
            value={year}
            onChange={e => setYear(e.target.value)}
            options={getLastYearsList(2, t)}
            sx={{ width: { xs: '100%', sm: '14rem' } }}
          />
        }
      />

      <ResponsiveContainer width="99%" height={244}>
        <LineChart width={500} height={300} data={mappedData()}>
          <XAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} dataKey="date" tickMargin={8} />
          <YAxis tick={{ fill: palette.text.secondary, fontSize: '0.825rem' }} tickLine={false} tickMargin={8} />
          <CartesianGrid strokeDasharray="1 3" vertical={false} />
          {deliverySiteBaseReportData?.map((el, idx) => (
            <Line
              key={el.deliverySiteId}
              name={el.deliverySiteName}
              type="linear"
              dataKey={el.deliverySiteId}
              stroke={COLORS[idx % COLORS.length]}
              strokeWidth={4}
              activeDot={{ r: 4 }}
            />
          ))}

          <Tooltip
            content={({ active, payload, label }: any) => (
              <ChartTooltip
                active={active}
                label={label}
                payload={payload?.map((el: any) => ({ name: el.name, value: el.value, color: el.stroke }))}
                rounded
              />
            )}
          />

          <Legend
            content={({ payload }: any) => (
              <ChartLegend payload={payload?.map((el: any) => ({ value: el.value, color: el.payload.stroke }))} rounded />
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </MainPaper>
  )
}

export { DeliverySiteReportBaseReportChart }
