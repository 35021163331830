import EditIcon from '@mui/icons-material/Edit'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { VendorManagementTab } from 'config/routeTypes'
import { AvatarImg } from 'core/components'
import { VendorListItem } from 'core/types'
import { TFunction } from 'i18next'

type Actions = {
  handleEdit: (el: VendorListItem, eventType: VendorManagementTab) => void
  handleUploadCatalog: (el: VendorListItem, e: React.ChangeEvent<HTMLInputElement>) => void
}

export const mapVendorsData = (data: VendorListItem[], t: TFunction, action: Actions) => {
  const mappedData = data.map(el => {
    return {
      rowId: el.id,
      data: {
        img: <AvatarImg imgUrl={el.vendorLogoUrl} name={el.name} />,
        name: el.name,
        contactEmail: el.contactEmail,
        taxNumber: el.taxNumber,
      },
      actions: [
        {
          title: t('common.edit'),
          icon: <EditIcon />,
          callback: () => action.handleEdit(el, VendorManagementTab.MAIN_DATA),
        },
        {
          title: t('common.catalogUpload', { fileFormat: '.xlsx' }),
          icon: <UploadFileIcon />,
          callback: (_el: any, e: any) => action.handleUploadCatalog(el, e),
          acceptFiles: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      ],
    }
  })

  return mappedData
}

export const mapVendorsColumns = (t: TFunction) => [
  { title: '', id: 'img' },
  { title: t('vendors.name'), id: 'name' },
  { title: t('vendors.contactEmail'), id: 'contactEmail' },
  { title: t('vendors.taxNumber'), id: 'taxNumber' },
  { title: t('common.actions'), id: 'actions', align: 'right' },
]
