export const platformManagement = {
  rule: 'Reguła',
  rulesList: 'Lista reguł',
  minimalPriceRulesList: 'Lista reguł cen minimalnych',
  specialDiscount: 'Zniżka specjalna',
  retroComission: 'Prowizja retro',
  margin: 'Marża',
  categorySelected: 'Odznacz kategorię jesli chcesz ustawić regułę na produkt',
  rules: {
    priceRuleFor: 'Reguła cenowa dla',
    minimalPriceFor: 'Cena minimalna dla:',
    ruleConditions: 'Ustawienia reguły cenowej',
    vendorPlusProduct: 'Wybierz dostawcę i produkt',
    priceRules: 'Reguły cenowe',
    minimalPriceRules: 'Reguły ceny minimalnej',
    newRuleImpact: 'Wpływ nowej reguły cenowej na obecne',
    addRuleCallback: {
      success: 'Pomyślnie dodano nową regułę cenową',
      error: 'Błąd podczas dodawania reguły cenowej',
    },
    deleteRuleCallback: {
      success: 'Pomyślnie usunięto regułę cenową',
      error: 'Błąd podczas usuwania reguły cenowej',
    },
  },
  validFrom: 'Ważna od',
  validTo: 'Ważna do',
  activeNow: 'Aktywne teraz',
  skaloPrice: 'Cena Skalo',
  clientPrice: 'Cena dla Klienta',
  ruleStatus: {
    willBeRemoved: 'Będzie usunięta',
    willBeModified: 'Będzie zmodyfikowana',
    willBeAdded: 'Będzie dodana',
  },
  action: {
    addRule: 'Dodaj regułę cenową',
    addMinimalPriceRule: 'Dodaj cenę minimalną',
    saveRule: 'Zapisz regułę cenową',
    acceptAndSaveRule: 'Akceptuj zmiany i zapisz regułę',
    deleteRule: 'Usuwanie reguły',
    deleteRuleInfo: 'Czy na pewno chcesz usunąć minimalną kwotę dla:',
  },
  sort: {
    specialDiscountAsc: 'Zniżka specjalna: od najniższej',
    specialDiscountDesc: 'Zniżka specjalna: od najwyższej',
    retroComissionAsc: 'Prowizja retro: od najniższej',
    retroComissionDesc: 'Prowizja retro: od najwyższej',
    marginAsc: 'Marża: od najniższej',
    marginDesc: 'Marża: od najwyższej',
    productNameAsc: 'Produkty A-Z',
    productNameDesc: 'Produkty Z-A',
    validFromAsc: 'Ważna od: rosnąco',
    validFromDesc: 'Ważna od: malejąco',
    validToAsc: 'Ważna do: rosnąco',
    validToDesc: 'Ważna do: malejąco',
  },
}
