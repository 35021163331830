import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQueryClient } from '@tanstack/react-query'
import { useCompanyContext } from 'companies/contexts/CompanyContext'
import { cataloLogisticsInit, catalogMainFiltersStateInit } from 'core/consts'
import { CataloLogisticsState, CatalogFilterType, CatalogFiltersState, CatalogSelectedCategory, Item, SelectOption } from 'core/types'
import { useAddFavouriteItem, useCreateOrderItem, useDeleteFavouriteItem, useGetOrderItems } from 'items/hooks'

import { useSnackbar } from './SnackbarProvider'

export const CatalogContext = createContext({
  selectedCategories: [],
  setSelectedCategories: () => {},
  page: 0,
  handlePageChange: () => {},
  rowsPerPage: 0,
  handlePageSizeChange: () => {},
  handleToggleFavourite: () => {},
  handleSearch: () => {},
  handleChangeFilters: () => {},
  handleConfirmAddToBasket: () => {},
  filtersState: catalogMainFiltersStateInit,
  sort: '',
  setSort: () => {},
  showCompanySelectorError: false,
  setShowCompanySelectorError: () => {},
  searchTerm: '',

  selectedVendor: null,
  setSelectedVendor: () => {},

  selectedPaymentMethods: [],
  setSelectedPaymentMethods: () => {},

  selectedLogistic: cataloLogisticsInit,
  setSelectedLogistic: () => {},

  clearFilters: () => {},
} as {
  selectedCategories: CatalogSelectedCategory[]
  setSelectedCategories: Dispatch<SetStateAction<CatalogSelectedCategory[]>>

  selectedPaymentMethods: SelectOption[]
  setSelectedPaymentMethods: Dispatch<SetStateAction<SelectOption[]>>

  page: number
  handlePageChange: (newPage: number) => void
  rowsPerPage: number
  handlePageSizeChange: (newPage: number) => void
  handleToggleFavourite: (item: Item) => void
  handleSearch: (search: string) => void
  handleChangeFilters: (key: keyof CatalogFiltersState, val: any) => void
  handleConfirmAddToBasket: (product: Item, quantity: number) => void
  filtersState: CatalogFiltersState
  sort: string
  setSort: Dispatch<SetStateAction<string>>
  showCompanySelectorError: boolean
  setShowCompanySelectorError: Dispatch<SetStateAction<boolean>>
  searchTerm: string

  selectedVendor: SelectOption | null
  setSelectedVendor: Dispatch<SetStateAction<SelectOption | null>>

  selectedLogistic: CataloLogisticsState
  setSelectedLogistic: Dispatch<SetStateAction<CataloLogisticsState>>

  clearFilters: (type: CatalogFilterType, val?: any) => void
})

export const CatalogContextProvider = ({ children }: any) => {
  const { t } = useTranslation()
  const { selectedCompany } = useCompanyContext()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const [selectedCategories, setSelectedCategories] = useState<CatalogSelectedCategory[]>([])
  const [selectedVendor, setSelectedVendor] = useState<SelectOption | null>(null)
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<SelectOption[]>([])
  const [selectedLogistic, setSelectedLogistic] = useState<CataloLogisticsState>(cataloLogisticsInit)

  const [filtersState, setFiltersState] = useState<CatalogFiltersState>(catalogMainFiltersStateInit)

  const [searchTerm, setSearchTerm] = useState('')
  const [sort, setSort] = useState('-orderCount')

  const [showCompanySelectorError, setShowCompanySelectorError] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const { createOrderItem } = useCreateOrderItem()
  const { refetchOrderItems } = useGetOrderItems(selectedCompany?.id)

  const handleConfirmAddToBasket = useCallback(
    (product: Item, quantity: number) => {
      if (!selectedCompany) {
        snackbar.error(t('orders.selectRestaurantBeforeAddingProductToBasket'))
        setShowCompanySelectorError(true)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      } else if (selectedCompany && product) {
        createOrderItem({
          deliverySiteId: selectedCompany.id,
          productId: product.id,
          quantity: quantity,
          vendorId: product.vendorId,
        }).then(() => {
          refetchOrderItems()
          snackbar.success(t('orders.basketAdd', { item: product.name }))
        })
      } else {
        snackbar.error(t('orders.basketAddFailed'))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCompany],
  )

  const handleSearch = useCallback((search: string) => {
    setSearchTerm(search)
    setPage(0)
  }, [])

  const handleChangeFilters = useCallback((key: keyof CatalogFiltersState, val: any) => {
    setFiltersState(prev => ({
      ...prev,
      [key]: val,
    }))
  }, [])

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage)
  }, [])
  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setRowsPerPage(newPageSize)
  }, [])

  const handleFavouriteItemError = () => {
    snackbar.error(t('common.errors.unexpected.subTitle'))
  }

  const refetchCatalog = () => queryClient.invalidateQueries({ queryKey: ['Product'] })

  const { addToFavourite } = useAddFavouriteItem(handleFavouriteItemError, refetchCatalog)
  const { deleteFavouriteItem } = useDeleteFavouriteItem(handleFavouriteItemError, refetchCatalog)

  const handleToggleFavourite = async (item: Item) => {
    item.isFavourite ? await deleteFavouriteItem({ productId: item.id }) : await addToFavourite({ productId: item.id })
  }

  const clearFilters = useCallback((type: CatalogFilterType, val?: any) => {
    switch (type) {
      case 'categories':
        setSelectedCategories([])
        break
      case 'category':
        setSelectedCategories(prev => prev.filter(sc => sc.category.id !== val))
        break
      case 'vendor':
        setSelectedVendor(null)
        break
      case 'filters':
        setFiltersState(catalogMainFiltersStateInit)
        break
      case 'favouriteOnly':
        setFiltersState(prev => ({ ...prev, favouriteOnly: catalogMainFiltersStateInit.favouriteOnly }))
        break
      case 'previouslyOrdered':
        setFiltersState(prev => ({ ...prev, previouslyOrdered: catalogMainFiltersStateInit.previouslyOrdered }))
        break
      case 'isInStock':
        setFiltersState(prev => ({ ...prev, isInStock: catalogMainFiltersStateInit.isInStock }))
        break
      case 'nextDayDelivery':
        setFiltersState(prev => ({ ...prev, nextDayDelivery: catalogMainFiltersStateInit.nextDayDelivery }))
        break
      case 'paymentMethods':
        setSelectedPaymentMethods([])
        break
      case 'paymentMethod':
        setSelectedPaymentMethods(prev => prev.filter(sc => sc.id !== val))
        break
      case 'minimalLogisticsValue':
        setSelectedLogistic(cataloLogisticsInit)
        break
      case 'all':
        setSelectedVendor(null)
        setFiltersState(catalogMainFiltersStateInit)
        setSelectedLogistic(cataloLogisticsInit)
        setSelectedPaymentMethods([])
        setSelectedCategories([])
        break

      default:
        break
    }
  }, [])

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['Product'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPage(0)
  }, [selectedCategories, filtersState, selectedVendor])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [page])

  return (
    <CatalogContext.Provider
      value={{
        page,
        handlePageChange,
        rowsPerPage,
        handlePageSizeChange,
        handleToggleFavourite,
        handleSearch,
        handleChangeFilters,
        handleConfirmAddToBasket,
        filtersState,
        sort,
        setSort,
        showCompanySelectorError,
        setShowCompanySelectorError,
        searchTerm,

        selectedVendor,
        setSelectedVendor,

        selectedCategories,
        setSelectedCategories,

        selectedPaymentMethods,
        setSelectedPaymentMethods,

        selectedLogistic,
        setSelectedLogistic,

        clearFilters,
      }}
    >
      {children}
    </CatalogContext.Provider>
  )
}

export function useCatalogContext() {
  return useContext(CatalogContext)
}
