export const goodPractices = {
  client: {
    '1': {
      content: 'Weryfikacja limitu kredytowego od razu po rejestracji w celu uniknięcia robienia tego przy zamówieniu',
    },
    '2': {
      content: 'Korzystanie z integracji z systemem POS w celu pełnej kontroli swojego magazynu',
    },
    '3': {
      content: 'Dodawanie komentarzy do zamówienia w przypadku jakichś niejasności lub zmiany jego szczegółów',
    },
    '4': {
      content:
        'Dodawanie komentarzy do Dostawcy przy składaniu zamówienia (w koszyku) w celu przekazania jakichś informacji np. „dostawa przed godziną 11” lub „prośba o podjechanie od tyłu budynku” itp.',
    },
  },
  vendor: {
    '1': {
      content:
        'Monitorowanie na bieżąco maila do zamówień oraz zmienianie statusów – Klient ma informacje na jakim etapie jest jego zamówienie i nie musi martwić się brakiem dostawy',
    },
    '2': {
      content: 'Bezzwłoczne wysyłanie aktualnych cenników w celu uniknięcia pomyłek cenowych',
    },
    '3': {
      content:
        'Informowanie Skalo na bieżąco o promocjach lub chęci szybkiej sprzedaży jakiegoś produktu w celu przekazania informacji Klientom',
    },
    '4': {
      content: 'Dostarczenie zdjęć produktów – Klient często kupuje oczami',
    },
    '5': {
      content: 'Dodawanie komentarzy do zamówień w przypadku zmian (waga, produkt itp.) – klient nie będzie zaskoczony przy dostawie',
    },
  },
}
