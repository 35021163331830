import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'

import { VendorStatisticsParams, VendorStatisticsType } from '../types/types'

export const useGetVendorStatistics = (params: VendorStatisticsParams, vendorId: string) => {
  const { data, ...other } = useRequest<VendorStatisticsType>(apiEndpoints.getVendorStatistics(params), {
    enabled: !!vendorId,
  })

  return { vendorStatistics: data, ...other }
}
