import { PaletteMode } from '@mui/material'

const palette = {
  grey: {
    '50': '#f3f5f7',
    '100': '#f1f4fd',
    '200': '#e9ecf5',
    '300': '#d2d7e4',
    '400': '#a7b2c0',
    '500': '#66768E',
    '600': '#475467',
    '700': '#344054',
    '800': '#1D2939',
    '900': '#101C2D',
  },
}

export const darkPalette = {
  ...palette,
  contrastThreshold: 4.5,
  mode: 'dark' as PaletteMode,
  error: {
    main: '#e74444',
  },
  info: {
    main: '#4FC3F7',
  },
  primary: {
    main: '#7690d3',
    contrastText: palette.grey[900],
  },
  secondary: {
    main: '#c5e3eb',
  },
  success: {
    main: '#26bf94',
  },
  warning: {
    main: '#FFD54F',
  },
  text: {
    primary: palette.grey[100],
    secondary: palette.grey[300],
    disabled: palette.grey[600],
  },
  divider: palette.grey[700],
  background: {
    paper: palette.grey[900],
    default: palette.grey[800],
  },
  action: {
    selectedOpacity: 0,
    selected: palette.grey[800],
  },
}

export const lightPalette = {
  ...palette,
  contrastThreshold: 3,
  mode: 'light' as PaletteMode,
  error: {
    main: '#e74444',
  },
  info: {
    main: '#00B0FF',
  },
  primary: {
    main: '#304373',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#c5e3eb',
  },
  success: {
    main: '#26bf94',
    contrastText: '#FFF',
  },
  warning: {
    main: '#FFC400',
  },
  text: {
    primary: palette.grey[700],
    secondary: palette.grey[500],
    disabled: palette.grey[300],
  },
  divider: palette.grey[100],
  background: {
    paper: '#FFF',
    default: '#f8f9fb',
  },
  action: {
    selectedOpacity: 0,
    selected: palette.grey[50],
  },
}

export const secondaryBgr = 'rgba(197, 227, 235)'
export const lighterSecondaryBgr = 'rgba(197, 227, 235, 0.5)'
export const lightErrorBgr = '#fdeaea'
export const lightInfoBgr = '#bfebff'
export const lightSuccessBgr = '#e9faf2'
