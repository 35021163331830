import { StyledComponent } from '@emotion/styled'
import { Grid, GridProps, styled } from '@mui/material'

export const StyledGridItem: StyledComponent<GridProps> = styled(Grid)<GridProps>(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    pr: 3,
    borderRight: { xs: 0, md: 1 },
    borderRightColor: { xs: 'none', md: 'grey.300' },
  }),
)
