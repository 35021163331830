import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DashboardIcon from '@mui/icons-material/Dashboard'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'

const data = [
  {
    name: 'Pażdziernik',
    Faktura: 1489966,
  },
  {
    name: 'Listopad',
    Faktura: 1447906,
  },
  {
    name: 'Grudzień',
    Faktura: 1567846,
  },
  {
    name: 'Styczeń',
    Faktura: 1440684,
  },
]

const views = '1 440 684 zł'

const ViewsWidgetVendor = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Card>
      <CardContent>
        <Typography align="center" component="div" marginBottom={2} variant="body2">
          {t('admin.home.views.income')}
        </Typography>
        <Typography align="center" component="div" variant="h2">
          Styczeń: {views}
        </Typography>
        <Box sx={{ height: 216 }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={350}
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis
                axisLine={false}
                dataKey="name"
                interval="preserveStartEnd"
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
                tickLine={false}
              />
              <Tooltip
                contentStyle={{
                  borderRadius: 16,
                  boxShadow: theme.shadows[3],
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.background.paper,
                }}
              />
              <Area
                type="monotone"
                dataKey="Faktura"
                fill={theme.palette.primary.main}
                fillOpacity={0.3}
                stroke={theme.palette.primary.main}
                strokeWidth={6}
                activeDot={{ r: 8 }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
        <Card sx={{ bgcolor: 'background.default', mt: 5 }}>
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'background.paper', mr: 2 }}>
              <DashboardIcon />
            </Avatar>
            <Box sx={{ flexGrow: 1 }}>
              <Typography component="div" variant="h6">
                {t('admin.home.views.action')}
              </Typography>
            </Box>
            <IconButton aria-label="Go to vendor-finances" component={RouterLink} to="/vendor-finances">
              <ChevronRightIcon />
            </IconButton>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  )
}

export default ViewsWidgetVendor
