import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { DeliverySiteBaseReportRes, DeliverySiteDetailedReportRes } from 'core/types'

export const useDeliverySiteBaseReport = (params: { clientId: string; year?: string }) => {
  const { data, isLoading, error, ...other } = useRequest<DeliverySiteBaseReportRes>(apiEndpoints.getDeliverySiteBaseReport(params), {
    enabled: !!params.clientId,
  })

  return { isLoadingDeliverySiteBaseReport: isLoading, deliverySiteBaseReportData: data, error, ...other }
}

export const useDeliverySiteDetailedReport = (params: { clientId: string; year: string; month: string }) => {
  const { data, isLoading, error, ...other } = useRequest<DeliverySiteDetailedReportRes>(
    apiEndpoints.getDeliverySiteDetailedReport(params),
    { enabled: !!params.clientId },
  )

  return { isLoadingDSDetailedReport: isLoading, dsDetailedReportData: data, error, ...other }
}
