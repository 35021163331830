import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { GetParams, InvoiceList, PaginatedResult } from 'core/types'

type Params = {
  clientId?: string
} & GetParams

export const useGetInvoicesList = (params: Params) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<InvoiceList>>(apiEndpoints.getInvoicesList(params), {
    enabled: !!params.clientId,
  })

  return { isLoadingInvoicesList: isLoading, invoicesList: data, ...other }
}
