export const faq = {
  whatIsSkaloUsedFor: {
    title: 'Do czego służy system Skalo?',
    content:
      'System Skalo służy do kompleksowego zarządzania procesem zamawiania produktów przez restaurację oraz znacznie go ułatwia. Skalo oferuje dostęp do katalogów wielu dostawców z różnych gam produktowych, daje możliwość finansowania zamówień oraz ograniczenia ilości dokumentów finansowych. Dzięki połączeniu z systemem POS, zamówione produkty od razu lądują w systemie magazynowym a wszystkie dane zakupowe gromadzone są w jednym miejscu.',
  },
  whatIsTheCostOfUsingSkalo: {
    title: 'Jaki jest koszt korzystania ze Skalo?',
    content: 'Korzystanie z systemu Skalo jest bezpłatne.',
  },
  howToCreateAccount: {
    title: 'Jak założyć konto?',
    content:
      'Aby założyć konto, należy wejść w zakładkę „Zaloguj się” a następnie kliknąć w pole „Nie masz konta? Zarejestruj się!”.\nNastępnie otrzymasz mail z linkiem weryfikacyjnym do uwierzytelnienia swojego adresu e-mail.',
  },
  howToAddCompany: {
    title: 'Jak dodać firmę do konta?',
    content:
      'Po zalogowaniu się do aktywnego/uwierzytelnionego profilu należy przejść do zakładki „Restauracje” a następnie kliknąć w kafelek „Dodaj nową firmę” i wypełnić pole NIP. Następnie dane Firmy zostaną automatycznie wypełnione. Po uzupełnieniu danych należy kliknąć przycisk „Dodaj”.\nSkalo automatycznie zweryfikuje zgodność wprowadzonych danych z rzeczywistością.',
  },
  howLongToVerifyCompany: {
    title: 'Ile czasu czeka się na weryfikację Firmy?',
    content: 'Weryfikacja konta zajmuje maksymalnie 2 godziny robocze.',
  },
  howToAddRestaurant: {
    title: 'Jak dodać restaurację?',
    content:
      'W zakładce „Restauracje” należy kliknąć przycisk „Dodaj nową restaurację”.\nZ listy rozwijanej należy wybrać Firmę, do której należy restauracja.\nPo przypisaniu Firmy należy wypełnić pozostałe dane adresowe. Możliwe jest również dodanie logotypu restauracji przyciskiem po prawej stronie.',
  },
  howToPlaceFirstOrder: {
    title: 'Jak zrealizować pierwsze zamówienie?',
    content:
      'Należy wejść w zakładkę „Zamawiarka” (jeśli dodałeś więcej niż jedną restaurację, musisz wybrać tę dla której realizujesz zamówienie z listy rozwijanej na środku strony).\nProdukty możesz wybierać z dostępnych kategorii, możesz je filtrować według ulubionych dostawców.\nProdukty zamawiane regularnie będą pojawiać się na górze listy według sortowania po najczęściej zamawianych pozycjach.\nPo wybraniu pozycji, którą chce się zamówić, należy wybrać jej ilość i kliknąć w niebieski przycisk „dodaj do koszyka”.\nProdukty można oznaczać jako ulubione klikając w ikonkę serduszka w kolumnie „Akcje”.',
  },
  howToAccessCart: {
    title: 'Jak przejść do koszyka?',
    content:
      'Aby przejść do koszyka, należy kliknąć jasnoniebieski przycisk w prawym górnym rogu strony z ikonką koszyka.\nPo weryfikacji produktów w zamówieniu należy wybrać metodę płatności, zaakceptować regulamin po prawej stronie i kliknąć przycisk „Dostawa i płatność”.',
  },
  whatArePaymentMethods: {
    title: 'Jakie są metody płatności?',
    content: 'Płatność przy odbiorze, Odroczony termin płatności od Dostawcy, Limit kredytowy oraz Płatność online (karta).',
  },
  howToCreateShoppingList: {
    title: 'Jak stworzyć listę zakupową?',
    content:
      'Listę zakupową można stworzyć na dwa sposoby: Po dodaniu produktów do koszyka, pojawi się w nim w prawym górnym rogu kafelek „Dodaj do listy zakupowej”. Dla przeszłych zamówień można to uczynić w zakładce „Moje zamówienia” w kolumnie „Akcje” – wystarczy kliknąć ikonkę listy.',
  },
  howToChangeProfileData: {
    title: 'Jak zmienić dane profilu?',
    content: 'Dane profilu można zmienić klikając na nazwę użytkownika w lewym dolnym rogu.',
  },
  whatToDoIfWrongProductOrdered: {
    title: 'Co zrobić w przypadku zamówienia błędnego produktu?',
    content:
      'Do momentu przyjęcia zamówienia do realizacji przez Dostawcę możliwe jest anulowanie go. Gdy nie ma już możliwości anulowania zamówienia, należy zgłosić pomyłkę w komentarzu do Dostawcy, znajdującym się na dole w szczegółach zamówienia.',
  },
  whatToDoIfWrongPaymentMethod: {
    title: 'Co zrobić w przypadku wybrania złej metody płatności?',
    content:
      'Należy wrócić do Skalo, przejść w szczegóły zamówienia i kliknąć kafelek „Zmień metodę płatności” po prawej stronie, a następnie wybrać odpowiednią metodę płatności.',
  },
  howToApplyForCreditLimit: {
    title: 'Jak mogę ubiegać się o limit kredytowy?',
    content:
      'Aby otrzymać limit kredytowy, należy przejść do zakładki „Restauracje”, a następnie kliknąć fioletowy przycisk „Sprawdź limit w Spingo” przy swojej Firmie. Użytkownik zostaje przekierowany na stronę Spingo w celu wypełnienia niezbędnych danych do weryfikacji.',
  },
  whoCanApplyForCreditLimit: {
    title: 'Kto może ubiegać się o limit kredytowy?',
    content: 'O limit kredytowy może ubiegać się każda firma zarejestrowana od minimum 12 miesięcy.',
  },
  howToPayCreditObligation: {
    title: 'Jak spłacić zobowiązanie wobec instytucji finansowej udzielającej limit kredytowy?',
    content:
      'Przy podsumowaniu zamówienia wskazany zostanie numer konta do spłaty zobowiązania wraz z kwotą. Termin darmowej spłaty wynosi 15 dni, istnieje możliwość wydłużenia terminu o kolejne 30 dni.',
  },
  howToLinkSkaloWithDotykacka: {
    title: 'Jak połączyć konto Skalo z kontem Dotykaćki?',
    content:
      'Należy przejść do zakładki „Restauracje” i kliknąć przycisk „Integracja z Dotykacką” z logiem Dotykacki. Użytkownik zostanie przeniesiony na panel Dotykacki, na którym musi zalogować się na swoje konto.',
  },
  howToOrderInvoice: {
    title: 'Jak zamówić fakturę do zamówienia?',
    content:
      'Dla każdego dostarczonego zamówienia, opłaconego metodą płatności: limit kredytowy lub płatność online, można zamówić fakturę w zakładce „Moje zamówienia” zaznaczając boxy przy odpowiednich zamówieniach, dla których wymagana jest faktura – istnieje możliwość zamówienia faktury dla pojedynczego zamówienia jak i faktury zbiorczej dla kilku.',
  },
  whenToOrderInvoice: {
    title: 'Kiedy można zamówić fakturę do zamówienia?',
    content:
      'Fakturę do zamówienia można zamówić tylko i wyłącznie dla dwóch metod płatności: płatność online oraz limit kredytowy. Możliwość zamówienia faktury pojawi się, gdy zamówienie będzie miało status „dostarczone”.',
  },
  howToFileProductComplaint: {
    title: 'Jak zgłosić reklamacje produktów?',
    content:
      'Gdy zamówienie ma status dostarczone, należy wejść w jego szczegóły i kliknąć na czerwony napis „Złóż reklamację” po prawej stronie, a następnie wypełnić formularz oraz dodać zdjęcie reklamowanego produktu.',
  },
  whenWillIGetComplaintInfo: {
    title: 'Kiedy otrzymam informacje na temat mojej reklamacji?',
    content:
      'Reklamowane przez ciebie produkty Skalo przesyła do odpowiedniego dostawcy.\nDostawca ma 24 godziny na odniesienie się do danej reklamacji.',
  },
  howToCancelOrder: {
    title: 'Jak anulować zamówienie?',
    content:
      'Zamówienie można anulować, gdy ma ono status: zamówienie złożone, zamówienie opłacone lub płatność nieudana. Należy to zrobić klikając w szczegóły zamówienia w zakładce „Moje zamówienia” i wybrać kafelek „Anuluj zamówienie” po prawej stronie.',
  },
  whenWillIReceiveOrderedProducts: {
    title: 'Kiedy otrzymam zamówione produkty?',
    content:
      'Jeżeli zamówienie zostanie złożone przed wyznaczoną przez Dostawcę godziną, zamówienie zostanie dostarczone kolejnego dnia roboczego.\nNiektórzy Dostawcy realizują pierwsze zamówienie z jednodniowym przesunięciem (z A na C).\nKolejne zamówienia od tego samego Dostawcy będą już realizowane dnia następnego.',
  },
  whatIsDeliveryArea: {
    title: 'Jaki jest obszar dostaw?',
    content: 'Wszystkie produkty wyświetlane Klientowi w Zamawiarce znajdują się w obszarze dostawy.',
  },
  howToSettleUndeliveredProducts: {
    title: 'Jak rozliczane są niedostarczone produkty?',
    content: 'W przypadku niedostarczenia produktów przez Dostawcę następuje zwrot środków na podstawie faktury korygującej.',
  },
  howToContactSkaloTeam: {
    title: 'Jak skontaktować się z zespołem Skalo?',
    content:
      'Z zespołem Skalo można skontaktować się telefonicznie, mailowo lub poprzez dedykowany czat na aplikacji. Wszystkie dane kontaktowe znajdują się w zakładce „Kontakt”.',
  },
  whyIsThereExtraChargeForVariableWeightProducts: {
    title: 'Dlaczego przy produktach zmiennowagowych, pobierane jest 20% więcej kwoty danego produktu?',
    content:
      'Produkty zmiennowagowe ważone są przez dostawcę dopiero po złożeniu zamówienia przez Skalo. Skalo pobiera nadwyżkę, aby zabezpieczyć się przed błędnym rozliczeniem kwoty zakupu towaru. Całość nadwyżki zostaje zwrócona Klientowi po zatwierdzeniu prawidłowych wag produktów i wyrównaniu różnicy.',
  },
  whereToFindRegulations: {
    title: 'Gdzie znajdę regulaminy?',
    content:
      'Linki do regulaminów korzystania z subskrypcji oraz korzystania z aplikacji znajdują się w prawym dolnym rogu strony – w każdej zakładce dostępnej na Skalo.',
  },
  doesSkaloSellProducts: {
    title: 'Czy Skalo sprzedaje własne produkty?',
    content:
      'Nie, Skalo jest jedynie pośrednikiem w zawieraniu transakcji pomiędzy Klientem a Dostawcą. Wszystkie produkty widoczne w Zamawiarce stanowią własność Dostawcy.\nSkalo nie realizuje również bezpośrednio dostaw do swoich Klientów.',
  },
  dailyPrice: {
    title: 'Co oznacza “Cena dnia” przy wybranych produktach?',
    content:
      'Cena dnia przypisana jest do produktów, których cena jest dynamiczna i zmienia się częściej niż pozostałe pozycje z cennika Dostawcy. Cenę końcową Klient zobaczy w szczegółach zamówienia, po przyjęciu go do realizacji przez Dostawcę. Z tego powodu produkty z ceną dnia można zamówić tylko z metodą płatności przy odbiorze lub odroczonego terminu od Dostawcy.',
  },
}
