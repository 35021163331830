/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { useQueryClient } from '@tanstack/react-query'
import { useCompanyContext } from 'companies/contexts/CompanyContext'
import { PaymentTypeVal } from 'core/consts'
import { PaymentLimit } from 'core/types'
import { useGetOrderItems, useGetSpingoLimit } from 'items/hooks'
import { OrderItemsResponse } from 'items/types/types'
import { checkOnlinePaymentSelected } from 'orders/helpers'
import { OrderCommentPerVendor } from 'orders/types/types'

import { useAlert } from './AlertProvider'

// @ts-ignore
export const CartContext = createContext({
  orderItemsData: {},
  isLoadingInit: false,
  isLoadingOrderItems: false,
  refetchOrderItems: () => {},
  refetchAll: () => {},

  spingoLimitData: undefined,
  spingoLimitIsError: false,

  completeOrderDisabled: true,

  commentsForVendor: [],
  handleUpdateCommentForVendor: () => {},
} as {
  orderItemsData: OrderItemsResponse
  isLoadingInit: boolean
  isLoadingOrderItems: boolean
  refetchOrderItems: () => void
  refetchAll: () => void

  spingoLimitData: PaymentLimit | undefined
  spingoLimitIsError: boolean

  completeOrderDisabled: boolean

  commentsForVendor: OrderCommentPerVendor[]
  handleUpdateCommentForVendor: (vendorId: string, comment: string) => void
})

export const CartContextProvider = ({ children }: any) => {
  const { selectedCompany } = useCompanyContext()
  const queryClient = useQueryClient()
  const alert = useAlert()

  const deliverySiteId = selectedCompany?.id

  const { orderItemsData, refetchOrderItems, isLoading, isFetching } = useGetOrderItems(deliverySiteId)
  const isLoadingOrderItems = isLoading || isFetching

  // check spingo limit if payment is available
  const { spingoLimitData, spingoLimitIsError } = useGetSpingoLimit(
    selectedCompany?.responsibleClientId ?? '',
    Boolean(
      orderItemsData &&
        orderItemsData?.vendorsCarts.length > 0 &&
        orderItemsData?.vendorsCarts.some(vendorCart => vendorCart.allowedPaymentTypes.find(p => p === PaymentTypeVal.Spingo)),
    ),
  )

  const [commentsForVendor, setCommentsForVendor] = useState<OrderCommentPerVendor[]>([])

  useEffect(() => {
    if (orderItemsData) {
      const onlinePaymentSelected =
        orderItemsData && checkOnlinePaymentSelected(orderItemsData.vendorsCarts.filter(vendorCart => vendorCart.isEnabled))
      if (onlinePaymentSelected) {
        alert.error({ message: <Trans i18nKey="payment.onlyOneOnlinePayment" /> })
      } else {
        alert.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemsData])

  const completeOrderDisabled = Boolean(orderItemsData && checkOnlinePaymentSelected(orderItemsData.vendorsCarts))

  const handleUpdateCommentForVendor = (vendorId: string, comment: string) => {
    setCommentsForVendor(prev => {
      return prev.map(el => (el.vendorId === vendorId ? { ...el, comment } : el))
    })
  }

  const invalidateRecommendedProducts = () => {
    queryClient.invalidateQueries({ queryKey: ['Product'] })
  }

  const handleRefetchAll = () => {
    refetchOrderItems()
    setTimeout(() => {
      invalidateRecommendedProducts()
    }, 100)
  }

  useEffect(() => {
    setCommentsForVendor(prev => {
      const updatedComments =
        orderItemsData?.vendorsCarts.map(vendorCart => {
          const existingComment = prev.find(comment => comment.vendorId === vendorCart.vendor.id)
          return {
            vendorId: vendorCart.vendor.id,
            comment: existingComment ? existingComment.comment : '',
            isEnabled: vendorCart.isEnabled,
          }
        }) ?? []
      return updatedComments
    })
  }, [orderItemsData?.vendorsCarts])

  useEffect(() => {
    return () => {
      alert.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CartContext.Provider
      value={{
        orderItemsData: orderItemsData as OrderItemsResponse,
        isLoadingInit: isLoading,
        isLoadingOrderItems,
        refetchOrderItems,
        refetchAll: handleRefetchAll,

        spingoLimitData,
        spingoLimitIsError,

        completeOrderDisabled,

        commentsForVendor,
        handleUpdateCommentForVendor,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export function useCartContext() {
  return useContext(CartContext)
}
