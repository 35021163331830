import { useParams } from 'react-router'

import { NotReadyWrapper } from 'core/components'
import { SingleProduct } from 'items/components/SingleProduct'
import { useGetProductById } from 'items/hooks'

export const AdminProductDetails = () => {
  const { uuid: productId } = useParams()

  const { data: productDetails, isLoading, isError, isSuccess } = useGetProductById(productId ?? '')
  return (
    productDetails && (
      <NotReadyWrapper isSuccess={isSuccess} isError={isError} isLoading={isLoading}>
        <SingleProduct product={productDetails} />
      </NotReadyWrapper>
    )
  )
}
