import { Field, FormikValues, getIn, useFormikContext } from 'formik'

import { Select, SelectProps } from '.'

export interface ControlledSelectProps extends SelectProps, FormikValues {}

export const ControlledSelect = ({ name, sx = [], ...otherProps }: ControlledSelectProps) => {
  const { errors, touched, isSubmitting, isValid, submitForm } = useFormikContext()
  const error = getIn(errors, name) && getIn(touched, name)
  const errorMsg = getIn(errors, name)
  const sxStyle = sx ? (Array.isArray(sx) ? sx : [sx]) : []

  const onSubmit = () => {
    if (!isSubmitting && isValid) {
      submitForm()
    }
  }

  return (
    <Field
      as={Select}
      name={name}
      error={error}
      errorHelperText={errorMsg}
      sx={[{ width: { xs: '100%' } }, ...sxStyle]}
      {...otherProps}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          onSubmit()
        }
      }}
    />
  )
}
