import { useTranslation } from 'react-i18next'

import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { VendorOrderStatus } from 'core/consts/vendorOrderStatus'
import { statusDisplayProperties } from 'core/types/statusDisplayProperties'

type ChangeOrderStatusDialogProps = {
  onClose: () => void
  changeStatus: (status: VendorOrderStatus) => void
  onSave: () => void
  selectedStatus: string
}

export const ChangeOrderStatusDialog = ({ onClose, onSave, selectedStatus, changeStatus }: ChangeOrderStatusDialogProps) => {
  const { t } = useTranslation()
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{t('order.changeStatus')}</DialogTitle>
      <DialogContent>
        <Box display="flex" gap={2}>
          {Object.values(VendorOrderStatus).map(status => (
            <Chip
              key={status}
              label={t(statusDisplayProperties[status]?.messageKey)}
              variant={selectedStatus === status ? 'filled' : 'outlined'}
              sx={
                selectedStatus === status
                  ? { bgcolor: statusDisplayProperties[status]?.color, color: 'common.white', pointerEvents: 'none' }
                  : { borderColor: statusDisplayProperties[status]?.color, color: statusDisplayProperties[status]?.color }
              }
              onClick={() => changeStatus(status)}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('order.cancel')}</Button>
        <Button variant="contained" onClick={onSave}>
          {t('order.confirmChange')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
