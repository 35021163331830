import { StyledComponent } from '@emotion/styled'
import { Box, BoxProps, styled } from '@mui/material'

export const StyledSearchContainer: StyledComponent<BoxProps> = styled(Box)<BoxProps>(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    gap: { xs: 2, md: 1 },
    flexWrap: 'wrap',
    flexDirection: { xs: 'column', sm: 'row', width: '100%' },
    mb: 2,
  }),
)
