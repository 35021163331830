import { useMutation } from '@tanstack/react-query'

import axiosInstance from '../../api/axiosInstance'

const acceptOrder = async (id: string) => {
  const { data } = await axiosInstance.put(`Orders/accept/${id}`)
  return data
}

export function useAcceptOrder() {
  const { mutateAsync, isLoading, isSuccess, error } = useMutation(acceptOrder)
  return { acceptOrder: mutateAsync, isLoading, isSuccess, error }
}
