import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import NotificationHub from 'SignalR/Notifications/NotificationHub'
import { AxiosError, AxiosResponse } from 'axios'
import { useCompanyContext } from 'companies/contexts/CompanyContext'
import { UserRoute } from 'core/consts'
import { useNotifications } from 'core/contexts'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { useLocalStorage } from 'core/hooks/useLocalStorage'
import { jwtDecode } from 'jwt-decode'

import axiosInstance from '../../api/axiosInstance'
import { getJwtToken, setJwtToken } from '../../api/tokenManagement'
import { useLogin } from '../hooks/useLogin'
import { useLogout } from '../hooks/useLogout'
import { DecodeJwtPayload, UserResponse } from '../types/types'

export const getUserRoles = () => {
  let userRole: string[] = []
  const token = getJwtToken()
  if (token) {
    const decodedToken: DecodeJwtPayload = jwtDecode(token)
    if (typeof decodedToken.role === 'string') {
      userRole = [decodedToken.role]
    } else {
      userRole = decodedToken.role
    }
  }
  return userRole
}

interface AuthContextInterface {
  hasRole: (roles?: string[]) => boolean
  isLoggingIn: boolean
  isLoggingOut: boolean
  login: (email: string, password: string) => Promise<UserResponse>
  logout: () => Promise<void>
  userInfo?: UserResponse
  isAuthenticated: boolean
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface)

interface AuthProviderProps {
  children?: React.ReactNode
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userResponse, setUserResponseInLocalStorage] = useLocalStorage<UserResponse | null>('userResponse', null)

  const jwtToken = getJwtToken()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { setSelectedCompany } = useCompanyContext()
  const snackbar = useSnackbar()
  const { setEventsData } = useNotifications()

  const handleLoginSuccess = (res: AxiosResponse<UserResponse>) => {
    setJwtToken(res?.data.token)
    setUserResponseInLocalStorage(res?.data)
    setUserInfo(res?.data)
    NotificationHub.connect()
    navigate(UserRoute.HOME, { replace: true })
  }

  const handleLoginError = (error: AxiosError) => {
    if (error.response && error.response.data === 'Email not confirmed') {
      snackbar.error(t('login.errors.emailNotConfirmed'))
    } else {
      snackbar.error(t('common.errors.unexpected.subTitle'))
    }
  }

  const { isLoggingIn, login } = useLogin(handleLoginError, handleLoginSuccess)

  const handleLogoutRes = () => {
    setSelectedCompany(null)
    setJwtToken('')
    setUserResponseInLocalStorage(null)
    setUserInfo(undefined)
    NotificationHub.disconnect()
    setEventsData([])
    navigate('/', { replace: true })
  }

  const handleLogoutSuccess = () => handleLogoutRes()
  const handleLogoutError = () => {
    handleLogoutRes()
    snackbar.error(t('common.errors.unexpected.subTitle'))
  }

  const { isLoggingOut, logout } = useLogout(handleLogoutError, handleLogoutSuccess)

  const [userInfo, setUserInfo] = useState<UserResponse | undefined>(userResponse ?? undefined)

  const isAuthenticated = Boolean(jwtToken && userInfo)

  useEffect(() => {
    if (isAuthenticated) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`
    } else {
      delete axiosInstance.defaults.headers.common['Authorization']
    }
  }, [jwtToken, isAuthenticated])

  const hasRole = (roles?: string[]) => {
    return roles?.length ? userInfo?.roles.some(role => roles.includes(role)) ?? false : true
  }

  const handleLogin = async (email: string, password: string) => await login({ email, password })

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout,
        userInfo,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}

export default AuthProvider
