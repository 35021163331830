export const termsOfSubscription = {
  title: 'Regulamin korzystania z Subskrypcji Aplikacji SKALO',
  content: `<h4>I.	WPROWADZENIE</h4>
  1.	Niniejszy Regulamin korzystania z subskrypcji Aplikacji SKALO (dalej: Regulamin) określa warunki jakim podlega korzystanie z Subskrypcji Aplikacji SKALO (dalej: Aplikacja lub Aplikacja SKALO) i realizowanie Transakcji za jej pośrednictwem. 
  2.	W przypadku chęci korzystania z Subskrypcji Aplikacji SKALO konieczne jest potwierdzenie zapoznania się i akceptacja warunków i treści Regulaminu poprzez zaznaczenie przez Użytkownika odpowiedniego pola (dalej Checkbox). Zgodnie z dyspozycją przepisu z art. 772 Kodeksu Cywilnego, oświadczenie woli złożone w sposób elektroniczny, tj.: w tym przypadku poprzez zaznaczenie odpowiedniego Checkbox jest równoważne z oświadczeniem woli złożonym w formie dokumentowej oraz potwierdzeniem akceptacji Regulaminu. Akceptacja pozostałych klauzul, tj.: wyrażenie zgody na przetwarzanie danych osobowych w celach marketingowych nie jest niezbędna do akceptacji Regulaminu i rozpoczęcia korzystania z Subskrypcji Aplikacji SKALO.
  3.	Użytkownik oświadcza, że zamierza korzystać z Subskrypcji Aplikacji SKALO jako podmiot gospodarczy lub spółka handlowa w rozumieniu powszechnie obowiązujących przepisów prawa lub jako osoba prowadząca jednoosobową działalność gospodarczą, dla której zgodnie z danymi zawartymi w szczególności w Centralnej Ewidencji i Informacji o Działalności Gospodarczej, posiada ona charakter zawodowy i jest związana bezpośrednio z jego działalnością gospodarczą.
  4.	SKALO Sp. z o.o. zastrzega sobie możliwość zmiany Regulaminu. Użytkownik powiadamiany będzie o aktualnym brzmieniu Regulaminu poprzez udostępnienie w terminie 7 dni przed wejściem w życie treści nowego Regulaminu pocztą elektroniczną na adres poczty elektronicznej wskazany przez Użytkownika w Aplikacji SKALO, co niniejszym Użytkownik akceptuje. 
  5.	Akceptacja Regulaminu jest dobrowolna, aczkolwiek konieczna w celu korzystania z Subskrypcji Aplikacji SKALO, a finalnie zawierania transakcji za pośrednictwem Aplikacji SKALO. 
  6.	Po zaakceptowaniu postanowień niniejszego Regulaminu stają się one wiążące stosownie dla Użytkownika. Regulamin może ulec zmianom w dowolnym czasie zgodnie z ust, 4 powyżej, jednakże zmiany nie będą miały wpływu na korzystanie z Aplikacji SKALO i zamówienia złożone przed wejściem w życie zmian Regulaminu. 
  7.	W razie pytań dotyczących Regulaminu lub zasad przetwarzania danych osobowych, Użytkownik może skontaktować się z nami poprzez kontakt e-mailowy pod adresem: biuro@skalo.pl
  8.	Poprzez Aplikację SKALO mogą być organizowane Promocje na wybrane produkty. O zasadach dotyczących promocji decyduje SKALO Sp. z o.o., podając je do wiadomości publicznej w Aplikacji SKALO lub za pośrednictwem podjęcia innych działań marketingowych po uprzednim uzyskaniu zgody Użytkownika.
  9.	Ogłoszenia, reklamy, oferty, cenniki i inne informacje o Produktach, podane w Aplikacji SKALO, w szczególności ich opisy, parametry techniczne i użytkowe oraz ceny, stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 Kodeksu cywilnego.
  10.	SKALO Sp. z o.o.  zastrzega, iż realizowanie transakcji za pośrednictwem Aplikacji SKALO uzależnione jest każdorazowo od akceptacji transakcji przez SKALO Sp. z o.o. W przypadku, gdy weryfikacja stosownie Użytkownika wykaże jakiekolwiek ryzyko lub nieprawidłowości, SKALO Sp. z o.o. zastrzega prawo do odmowy zawarcia transakcji z Użytkownikiem. 
  11.	Subskrypcja zaczyna obowiązywać po Rejestracji Konta przez Użytkownika.
  
  <h4>II.	INFORMACJE O NAS</h4>
  SKALO Sp. z o.o. z siedzibą w Konstancin-Jeziorna przy ul. Saneczkowej 12, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Warszawie XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0001053355, posiadającą REGON: 526146481 oraz NIP: 1231538881 (dalej: SKALO).
  
  <h4>III.	DEFINICJE</h4>
  1.	Aplikacja SKALO – autorskie oprogramowanie SKALO służące do składania Zamówień i procesowania Transakcji na Produkty od Dostawcy przez Klientów,
  2.	Użytkownik - podmiot, tj.: osoba prowadząca jednoosobową działalność gospodarczą, dla której zgodnie z danymi zawartymi w szczególności w Centralnej Ewidencji i Informacji o Działalności Gospodarczej, Umowa z Usługodawcą posiada charakter zawodowy i jest bezpośrednio związana z jej działalnością gospodarczą, tj do której nie stosuje się przepisu art. 38a ustawy z dnia z dnia 30 maja 2014 r. o prawach konsumenta (Dz.U.2020.287 t.j. ze zm.) lub/i spółka handlowa w rozumieniu powszechnie obowiązujących przepisów prawa, która zamierza zawrzeć Zamówienie za pośrednictwem Aplikacji SKALO,
  3.	Dostawca – podmiot współpracujący ze SKALO oferujący Produkty dla Klienta oraz realizujący Zamówienie, 
  4.	Katalog Produktów – uporządkowany spis danych dotyczących rzeczy udostępniany w Aplikacji SKALO przez danego Dostawcę, 
  5.	Klient – Użytkownik, który złożył Zamówienie na Produkty od Dostawcy,
  6.	Konto – prowadzony dla Użytkownika przez SKALO Sp. z o.o. pod unikalną nazwą (login) zbiór zasobów, w którym gromadzone są dane wprowadzone przez Użytkownika oraz informacje o jego działaniach w ramach Aplikacji SKALO, 
  7.	Produkt – produkt oferowany przez Dostawcę w Aplikacji SKALO,
  8.	Transakcja – procedury zawierania i wykonania umów sprzedaży Produktów pomiędzy Użytkownikiem, a a Dostawcą w ramach Aplikacji SKALO,
  9.	Koszyk - wirtualne narzędzie, umożliwiające Użytkownikowi agregację i wybór Produktów przed ich zamówieniem oraz przeliczenie wartości Zamówienia. Podczas wyboru Produktów Użytkownik może dowolnie zarządzać zawartością Koszyka poprzez dodawanie do niego lub usuwania z niego Produktów,
  10.	Subskrypcja – umowa korzystania z Aplikacji SKALO zawierana pomiędzy Użytkownikiem, a SKALO na warunkach określonych w Regulaminie.
  11.	Produkt – towar, który został oznaczony i zidentyfikowany w Katalogu Produktów poprzez określenie jego nazwy oraz cech, 
  12.	Rejestracja – procedura zakładania konta w Aplikacji SKALO,
  13.	Zamówienie – oświadczenie woli Klienta złożone za pośrednictwem Aplikacji SKALO dotyczące Transakcji na Produkty od Dostawcy, określające m.in.: rodzaj i ilość Produktów, ilości i ceny Produktów oraz dane Klienta stanowiące złożenie SKALO i Dostawcy przez Klienta za pośrednictwem Aplikacji SKALO oferty nabycia Produktów będących przedmiotem Transakcji. 
  
  <h4>IV.	Przedmiot Subskrypcji Aplikacji SKALO</h4>
  1.	Niniejszy Regulamin określa ogólne zasady świadczenia Subskrypcji Aplikacji SKALO przez SKALO na rzecz Użytkownika. 
  2.	W ramach Subskrypcji, Użytkownik zobowiązuje się do przestrzegania warunków niniejszego Regulaminu. Jednocześnie Użytkownik zobowiązuje każdorazowo przed zawarciem danej Transakcji do zapoznania się oraz akceptacji Regulaminu Korzystania z Aplikacji SKALO. 
  3.	SKALO oferuje Użytkownikowi dostęp do Aplikacji SKALO nieodpłatnie.
  4.	Użytkownik zobowiązuje się używać Aplikację SKALO zgodnie z jej przeznaczeniem. Użytkownik może korzystać z Aplikacji wyłącznie na własne potrzeby i w związku z prowadzoną przez siebie działalnością gospodarczą. Zabronione są wszelkie zmiany i modyfikacje Aplikacji SKALO przez Użytkownika. 
  5.	Użytkownik przy Rejestracji Konta otrzyma od SKALO pocztą elektroniczną na wskazany adres e-mail informacje o Koncie (login, hasło) oraz wszelkie niezbędne informacje do korzystania z Aplikacji SKALO.
  6.	SKALO nie gwarantuje spełnienia oczekiwań Użytkownika w zastosowaniu Subskrypcji i Aplikacji SKALO w prowadzonej przez niego działalności. W interesie Użytkownika leży uprzednie zapoznanie się z możliwościami i funkcjonalnościami Aplikacji SKALO. SKALO zastrzega, iż Aplikacja SKALO jest udostępniana Użytkownikowi „as it is” w rozumieniu wersji przekazanej do użytkowaniu Użytkownikowi, tj.: SKALO nie dostosuje Aplikacji SKALO do konkretnych potrzeb Użytkownika, a proces rozwoju i aktualizowania Aplikacji SKALO uzależniony jest od decyzji SKALO. Jednocześnie, Użytkownik przyjmuje do wiadomości i nie wnosi zastrzeżeń, iż w toku korzystania z Aplikacji SKALO, SKALO ma prawo dokonywać zmian, aktualizacji i rozwijać wedle własnego uznania Aplikację SKALO. 
  7.	SKALO nie ponosi odpowiedzialności za szkody wynikające z błędnej konfiguracji lub używania Aplikacji SKALO przez Użytkownika.
  8.	SKALO nie ponosi odpowiedzialności za jakiekolwiek korzyści, które Użytkownik mógłby osiągnąć, gdyby mu szkody nie wyrządzono w związku z korzystaniem z Subskrypcji i Aplikacji SKALO. Ograniczenie odpowiedzialności nie dotyczy szkód wyrządzonych Użytkownikowi z winy umyślnej SKALO. Strony wyłączają stosowanie przepisów dotyczących rękojmi za wady fizyczne. 
  9.	Wszelkie dane i informacje przekazane przez Użytkownika w ramach użytkowania Aplikacji SKALO stanowią własność Użytkownika i Użytkownik ponosi za nie pełną odpowiedzialność. SKALO nie ponosi odpowiedzialności za poprawność danych i informacji przekazanych przez Użytkownika w ramach korzystania z Aplikacji SKALO.
  10.	Wszelkie dane znajdujące się w Aplikacji SKALO stanowią własność SKALO, natomiast wszelkie dane wprowadzane w Aplikacji SKALO przez Użytkownika zgodnie z ust. 9 powyżej stanowią jego własność.
  11.	Użytkownik zobowiązany jest zapewnić poszanowanie praw autorskich oraz praw własności intelektualnej SKALO, jak również podmiotów trzecich, których oprogramowanie jest udostępniane Użytkownikowi w ramach korzystania z Aplikacji SKALO.
  12.	W celu korzystania z Subskrypcji Aplikacji SKALO przez Użytkownika konieczne jest spełnienie następujących warunków technicznych:
  a)	posiadanie urządzenia komputerowego, 
  b)	posiadanie dostępu do Internetu gwarantującego nieprzerwany dostęp do Aplikacji SKALO podczas korzystania z niej. 
  13.	Korzystanie z Subskrypcji Aplikacji SKALO wymaga każdorazowego zalogowania przy użyciu loginu i hasła. 
  14.	Bez uprzedniej, pisemnej zgody SKALO, Użytkownik nie może instalować w Aplikacji SKALO żadnego zewnętrznego oprogramowania, tj.: innego niż udostępniane przez SKALO w ramach korzystania z Aplikacji SKALO.
  15.	SKALO zastrzega sobie możliwość jednostronnej zmiany warunków i zasad korzystania z Subskrypcji Aplikacji SKALO bez konieczności uzyskania zgody Użytkownika. 
  16.	SKALO nie ponosi odpowiedzialności za szkody spowodowane: 
  a)	problemami telekomunikacyjnymi; 
  b)	niedziałaniem albo wadliwym działaniem oprogramowania firm trzecich, w tym działaniem wirusów komputerowych; 
  c)	niewłaściwą konfiguracją i użytkowaniem Aplikacji SKALO po stronie Użytkownika; 
  d)	wadami sprzętu komputerowego Użytkownika;
  e)	brakiem lub przerwami w dostępie do Internetu po stronie Użytkownika; 
  f)	nieprawidłową obsługą Aplikacji SKALO przez użytkowników Użytkownika; 
  g)	błędami lub brakami w danych wprowadzonych do Aplikacji SKALO przez Użytkownika; 
  h)	z udostępnieniem Aplikacji SKALO przez Użytkownika osobom nieuprawnionym danych lub danych uwierzytelniających lub też brakiem u Użytkownika odpowiednich zabezpieczeń przed przejęciem wymienionych wyżej danych,
  i)	nieprawdziwymi, wadliwymi lub niepełnymi informacjami/danymi przekazanymi przez Użytkownika w ramach korzystania z Aplikacji SKALO.
  17.	Użytkownik nie ma prawa do:
  a)	dokonywania lub umożliwiania dokonania kopii Aplikacji SKALO lub poszczególnych jego elementów; 
  b)	udostępniania Aplikacji SKALO osobom trzecim do używania pod jakimkolwiek tytułem (odpłatnym lub darmowym);
  c)	korzystania z Aplikacji SKALO wspólnie z osobami trzecimi;
  d)	dokonywania opracowań Aplikacji SKALO oraz innych czynności mających na celu stworzenie w oparciu o Aplikację SKALO lub jego części i jego dokumentację programów komputerowych 
  o analogicznym lub podobnym jak Aplikacja SKALO przeznaczeniu lub funkcji;
  e)	dokonywania innych modyfikacji Aplikacji SKALO w całości lub w części, w tym w celu rozwijania Aplikacji SKALO i tworzenia jego rozszerzeń, aktualizacji i opracowań, w tym także stanowiących utwory zależne, samoistne lub części innego utworu, w tym utworu zbiorowego;
  f)	wynajmowania, użyczania, oddawania w leasing lub współużytkowania Aplikacji SKALO;
  g)	dekodowania, rozpowszechniania oraz dekompilacji Aplikacji SKALO;
  h)	wykonywania praw zależnych do Aplikacji SKALO.
  18.	Użytkownik zobowiązany jest odpowiednio zabezpieczyć dane używane w ramach Subskrypcji Aplikacji SKALO oraz dane uwierzytelniające przed niepowołanym dostępem osób trzecich i nie może ich udostępniać osobom trzecim. Wszelkie szkody wynikające z niedopełnienia powyższego obowiązku obciążają Użytkownika. 
  19.	Użytkownik nie ma prawa usuwać ani też modyfikować jakichkolwiek oznaczeń znajdujących się 
  w Aplikacji SKALO, w tym znaków towarowych lub innych oznaczeń w niej zamieszczonych. 
  20.	SKALO przysługuje prawo do kontroli sposobu używania z Subskrypcji Aplikacji SKALO przez Użytkownika. 
  21.	Użytkownik zobowiązuje się nie wykorzystywać ani nie rozpowszechniać oprogramowania Aplikacji SKALO i narzędzi grożących uszkodzeniem Aplikacji SKALO, w tym również innego oprogramowania typu „malware” w tym wirusowego, szpiegującego bez zgody zainteresowanych osób, jak również oprogramowania typu „Trojan” i „adware” służącego do wyświetlania reklam bez zgody użytkowników sieci Internet. Użytkownik zobowiązuje się również nie naruszać w jakikolwiek sposób praw osób trzecich, nie stosować rozwiązań, które mogłyby wpływać na obniżenie wydajności Aplikacji SKALO, nie podejmować czynności, których skutkiem pośrednim lub bezpośrednim byłoby ominięcie zabezpieczeń Aplikacji SKALO, nie zamieszczać w Aplikacji SKALO żadnych plików bez zgody SKALO, w tym również nie instalować/nie zamieszczać linków do serwisów nielegalnych i naruszających obowiązujące przepisy oraz dobre obyczaje.
  22.	Użytkownik zobowiązany jest powstrzymać się od jakichkolwiek czynności, których skutkiem pośrednim lub bezpośrednim byłoby zniszczenie lub usunięcie całości lub części zasobów Aplikacji SKALO jak również powstrzymać się od czynności, które mogłyby naruszać interesy SKALO.
  23.	SKALO zobowiązuje się do zapewnienia Użytkownikowi nieprzerwanego dostępu do Aplikacji SKALO.
  24.	W przypadku wystąpienia nieprawidłowości (awarii, błędów lub usterek) Aplikacji SKALO, Użytkownik zobowiązany jest niezwłocznie (nie później niż w terminie 24 godzin) od wystąpienia nieprawidłowości poinformować o tym fakcie SKALO na adres e-mail biuro@skalo.pl. Użytkownik zobowiązany jest sprawdzić, czy niedziałanie lub nienależyte działanie Aplikacji SKALO nie wynika z przyczyn, za które nie ponosi odpowiedzialności SKALO. W zgłoszeniu nieprawidłowości Użytkownik zobowiązany jest wskazań szczegółowy opis, okoliczności uzasadniające lub inne informacje potrzebne SKALO do wykonania analizy nieprawidłowości, a następnie do usprawnienia Aplikacji SKALO.
  25.	W ramach Umowy, SKALO nie zapewnia kopii bezpieczeństwa (back’up) Aplikacji SKALO używanej przez Użytkownik. 
  26.	SKALO może wykorzystywać dane Użytkownik wprowadzone do Aplikacji SKALO na własne potrzeby. 
  SKALO oferuje różne formy płatności oferowane Klientom przez Dostawcę w zależności od możliwości oraz oceny transakcji i ryzyka danego Klienta. Formy płatności będą wskazane w Aplikacji SKALO przy realizacji Zamówienia przez Klienta.
  
  <h4>V.	KORZYSTANIE Z APLIKACJI SKALO I PROCEDURA SKŁADANIA ZAMÓWIEŃ ORAZ DOSTĘPNOŚĆ I DOSTAWA PRODUKTÓW </h4>
  Korzystanie z Aplikacji SKALO reguluje Regulamin Korzystania z Aplikacji SKALO z którym Użytkownik zobowiązany jest zapoznać się i zaakceptować jego treść przed zawarciem Transakcji.  Dostępność Produktów danego Dostawcy znajduje się w Aplikacji SKALO w Katalogu Produktów.

  <h4>VI.	ROZWIĄZANIE I WYGAŚNIĘCIE SUBSKRYPCJI</h4>
  Użytkownik może zrezygnować z Subskrypcji w Aplikacji SKALO w dowolnym momencie poprzez złożenie stosownej dyspozycji SKALO na adres: biuro@skalo.pl oraz poprzez wykonanie instrukcji przewidzianych przez SKALO w przypadku anulowania Subskrypcji, przy czym dostęp do Aplikacji SKALO w ramach Subskrypcji będzie zapewniony do końca danego okresu rozliczeniowego.  Rezygnacja z Subskrypcji powoduje automatyczne zlikwidowanie konta na koniec danego okresu rozliczeniowego. W przypadku chęci przywrócenia Subskrypcji, Użytkownik zobowiązany jest skontaktować się ze SKALO i wykonać przekazane instrukcje lub po zlikwidowaniu konta dokonać ponownej Rejestracji Konta w Aplikacji SKALO. 
  VII.	OGRANICZENIE LUB ZABLOKOWANIE KORZYSTANIA Z SUBSKRYPCJI APLIKACJI SKALO
  SKALO ma prawo ograniczyć lub zablokować Użytkownikowi dostęp do Subskrypcji Aplikacji SKALO w przypadku naruszenia niniejszego Regulaminu lub naruszenia Regulaminu Korzystania z Aplikacji SKALO. W przypadku naruszenia lub niewywiązania się z Transakcji przez Użytkownika względem Dostawcy, SKALO zastrzega sobie prawo do zablokowania dostępu do Subskrypcji Aplikacji SKALO.
  
  <h4>VIII.	DANE OSOBOWE</h4>
  Użytkownik przyjmuje do wiadomości, iż:
  a)	Administratorem danych osobowych dotyczących Usługobiorcy oraz osób zaangażowanych po jego stronie w realizację Subskrypcji Aplikacji SKALO jest SKALO Sp. z o.o. z siedzibą w Konstancinie-Jeziorna przy ul. Saneczkowej 12 wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Warszawie XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0001053355, posiadająca REGON: 526146481 oraz NIP: 1231538881;
  b)	administrator wskazuje, iż w kwestiach związanych z danymi osobowymi można skontaktować się za pośrednictwem adresu e-mail: biuro@skalo.pl;
  c)	dane osobowe przetwarzane są w celu prawidłowej realizacji Subskrypcji, w związku z realizacją obowiązków wynikającą z przepisów prawa , a także – w zakresie prawnie usprawiedliwionego interesu administratora – w celu ustalenia, dochodzenia lub obrony przed roszczeniami wynikającymi z umowy subskrypcji oraz przesyłania informacji handlowych, na podstawie odpowiednio art. 6 ust. 1 lit. b, art. 6 ust. 1 lit. c, oraz art. 6 ust. 1 lit. f Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE. (RODO);
  d)	dane osobowe będą przechowywane przez okres niezbędny do realizacji Subskrypcji Aplikacji SKALO oraz wypełnienia obowiązków prawnych ciążących na administratorze oraz dochodzenia i obrony przed roszczeniami przez okres wskazany w przepisach rachunkowych i podatkowych a w przypadku obrony i dochodzenia roszczeń do czasu ich przedawnienia. W przypadku wyrażenia zgody na otrzymywanie informacji handlowych dane osobowe będą przetwarzane do momentu wycofania zgody, której udzielenie jest dobrowolne i która może zostać wycofana w każdym momencie bez wpływu na legalność przetwarzania tych danych przed jej wycofaniem;
  e)	dane osobowe będą udostępniane wyłącznie podmiotom, które biorą udział w procesie związanym z realizacją Subskrypcji Aplikacji SKALO oraz podmiotom świadczącym dodatkowe usługi z zakresu sprzedaży produktów i usług jak również innym podmiotom, którym obowiązek przekazania danych osobowych będzie wynikał z przepisów prawa; 
  f)	podane dane osobowe nie będą podlegały profilowaniu oraz zautomatyzowanym decyzjom;
  g)	Pani/Pana dane osobowe nie będą przekazywane do Państw trzecich lub organizacji międzynarodowych;
  h)	ma Pani/Pan prawo dostępu do treści swoich danych zgodnie z art. 15 RODO, prawo żądania sprostowania danych osobowych, usunięcia, ograniczenia przetwarzania zgodnie z art. 18 RODO, prawo do przenoszenia danych i prawo do wniesienia sprzeciwu wobec przetwarzania danych jak i również prawo do wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych;
  i)	podanie danych jest dobrowolne, aczkolwiek niezbędne do zawarcia i realizacji Subskrypcji Aplikacji SKALO oraz Transakcji. Ich niepodanie uniemożliwi zawarcie Subskrypcji.
  
  <h4>IX.	POSTANOWIENIA KOŃCOWE</h4>
  1.	Niniejszy Regulamin dostępny jest nieodpłatnie w Aplikacji SKALO. Regulamin jest udostępniany w formie, która umożliwia pozyskanie, odtwarzanie i utrwalanie treści niniejszego Regulaminu za pomocą systemu teleinformatycznego, którym posługuje się Użytkownik. W sprawach nieuregulowanych zastosowanie mają przepisy Kodeksu Cywilnego. 
  2.	Wszelkie spory wynikające z korzystania z Aplikacji SKALO jak i wszelkie kwestie z nimi związane będą rozstrzygane przez sąd powszechny właściwy dla siedziby SKALO. 
  3.	Uznanie jakiegokolwiek postanowienia niniejszego Regulaminu za niezgodne z prawem, nieważne lub nieskuteczne nie ma wpływu na ważność lub skuteczność pozostałych postanowień Regulaminu, zaś w miejsce postanowienia uznanego za niezgodne z prawem, nieważne lub nieskuteczne stosuje się odpowiednie postanowienie, które będzie zgodne z prawem, ważne lub skuteczne, a które będzie najbardziej zbliżone do treści postanowienia uznanego za niezgodne z prawem, nieważne lub nieskuteczne.
  4.	Użytkownik nie może przenosić żadnych praw wynikających z Subskrypcji Aplikacji SKALO na inny podmiot bez uprzedniej, pisemnej zgody SKALO.
  5.	Użytkownik oświadcza i gwarantuje, iż nie ma żadnych ograniczeń do zawarcia Subskrypcji Aplikacji SKALO i zapewnia, że ma prawo do zawarcia Subskrypcji Aplikacji SKALO.
  6.	W przypadku zmiany danych Użytkownika, w tym zwłaszcza danych adresowych (adres korespondencyjny lub e-mailowy) Użytkownik zobowiązuje się do poinformowania SKALO o takiej zmianie w terminie 7 dni pod rygorem uznania, iż korespondencja wysłana na dotychczasowe dane Użytkownika wskazane w Aplikacji SKALO wywołają skutek doręczenia.
  7.	Subskrypcja Aplikacji SKALO podlega prawu polskiemu, a w sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie mają przepisy prawa, w tym przede wszystkim przepisy Kodeksu Cywilnego. 
  8.	Subskrypcja Aplikacji SKALO zostaje zawarta w postaci elektronicznej zgodnie z treścią Regulaminu. Użytkownik oświadcza, iż poprzez akceptację niniejszego Regulaminu za pośrednictwem odznaczenia stosownego pola checkbox w Aplikacji SKALO akceptuje warunki Regulaminu.
  `,
}
