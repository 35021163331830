const {
  REACT_APP_SKALO_API,
  REACT_APP_IMAGE_URL,
  REACT_APP_SKALO_SERVER,
  REACT_APP_NAME,
  REACT_APP_NOTIFICATION_HUB,
  NODE_ENV,
  REACT_APP_GA_TRACKING_ID,
} = process.env

// CORE APP
const APP_BASE_URL = REACT_APP_SKALO_API ?? ''
const APP_IMAGE_URL = REACT_APP_IMAGE_URL ?? ''
const APP_NAME = REACT_APP_NAME ?? 'Skalo.pl'
const APP_SKALO_SERVER = REACT_APP_SKALO_SERVER ?? ''
const APP_NOTIFICATION_HUB = REACT_APP_NOTIFICATION_HUB ?? ''
const APP_NODE = NODE_ENV ?? ''
export { APP_BASE_URL, APP_IMAGE_URL, APP_NAME, APP_SKALO_SERVER, APP_NOTIFICATION_HUB, APP_NODE, REACT_APP_GA_TRACKING_ID }
