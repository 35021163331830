import apiEndpoints from 'api/endpoints'
import { useMutate, useRequest } from 'core/hooks'
import { PaginatedResult, VendorDisabledDeliverySite } from 'core/types'

export const useAdminDisabledDSitesForVendor = (vendorId: string) => {
  const { data, ...other } = useRequest<PaginatedResult<VendorDisabledDeliverySite>>(apiEndpoints.getAdminDisabledDSitesForVendor(vendorId))
  return { disabledDSitesData: data, ...other }
}

export const usePostAdminDisabledDSitesForVendor = () => {
  const { mutate, ...others } = useMutate(apiEndpoints.postAdminDisabledDSitesForVendor())
  return { postAdminDisabledDSitesForVendor: mutate, ...others }
}

export const useDeleteAdminDisabledDSitesForVendor = () => {
  const { mutate, ...others } = useMutate(apiEndpoints.deleteAdminDisabledDSitesForVendor())
  return { deleteAdminDisabledDSitesForVendor: mutate, ...others }
}
