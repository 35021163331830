import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'

import { OrderItemsResponse } from '../types/types'

export const useGetOrderItems = (deliverySiteId: number | undefined) => {
  const { data, refetch, ...other } = useRequest<OrderItemsResponse>(apiEndpoints.getOrderItems(deliverySiteId ?? 0), {
    enabled: !!deliverySiteId,
  })

  return { orderItemsData: data, refetchOrderItems: refetch, ...other }
}
