import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Chip, Grid, Typography } from '@mui/material'
import { Loader, MainPaper, Select } from 'core/components'
import { useBasicPosSalesReport } from 'core/hooks/api'
import { DeliverySite } from 'core/types'
import { getDaysAgoList } from 'core/utils'

interface DotykackaSalesProps {
  selectedDeliverySite: DeliverySite | null
}

export const DotykackaSales: React.FC<DotykackaSalesProps> = ({ selectedDeliverySite }) => {
  const { t, i18n } = useTranslation()

  const dateFromList = useMemo(
    () => [getDaysAgoList(1, t), getDaysAgoList(3, t), getDaysAgoList(7, t), getDaysAgoList(30, t)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language],
  )

  const [dateFrom, setDateFrom] = useState(dateFromList[0].id)

  const { posSalesReportData, posSalesReportsLoading, posSalesReportsIsSuccess, posSalesReportsIsError } = useBasicPosSalesReport({
    deliverySiteId: Number(selectedDeliverySite?.id),
    from: dateFrom,
  })

  const soldProductsList = posSalesReportData && posSalesReportData?.length > 0 ? posSalesReportData : []

  useEffect(() => {
    setDateFrom(dateFromList[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <Box>
      <Select
        label={t('common.dateRange')}
        name="dateFrom"
        value={dateFrom}
        onChange={e => setDateFrom(e.target.value)}
        options={dateFromList}
        sx={{ width: { xs: '100%', sm: '10rem' }, mb: 2 }}
      />

      {selectedDeliverySite ? (
        <Box position="relative" sx={{ minHeight: 80 }}>
          {posSalesReportsLoading && <Loader relative />}
          {posSalesReportsIsError && (
            <Typography color="error" sx={{ whiteSpace: 'pre-line' }}>
              {t('common.errors.unexpected.subTitle')}
            </Typography>
          )}
          {posSalesReportsIsSuccess && (
            <>
              {soldProductsList.length > 0 ? (
                <MainPaper>
                  {/* header */}
                  <Grid container spacing={2} mb={3}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('orders.productName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('orders.quantity')}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('orders.price')}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('orders.ingredients')}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* products and ingredients list */}
                  <Box sx={{ overflowY: 'auto' }}>
                    {soldProductsList.map((el, idx) => (
                      <Grid container spacing={2} alignItems="baseline" key={el.productName + idx} mb={2}>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">{el.productName}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2">
                            {el.quantity} {t('common.unit.pcs')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2">{(el.totalIncomeNet / el.quantity).toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={7} display="flex" alignItems="center" flexWrap="wrap" gap={0.5}>
                          {el.ingredients.length > 0 ? (
                            el.ingredients.map((ing, iidx) => <Chip key={ing + iidx} label={ing} />)
                          ) : (
                            <Typography variant="body2">-</Typography>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </MainPaper>
              ) : (
                // products not found for selected deliverySiteId and date range
                <MainPaper>
                  <Typography>{t('administrator.notFoundDotykackaData')}</Typography>
                </MainPaper>
              )}
            </>
          )}
        </Box>
      ) : (
        // deliverySiteId not selected
        <Typography variant="body2" color="text.secondary">
          {t('pos.selectCompanyToSeeWhatYouSold')}
        </Typography>
      )}
    </Box>
  )
}
