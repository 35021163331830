import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import apiEndpoints from 'api/endpoints'
import { useAuth } from 'auth/contexts/AuthProvider'
import {
  Autocomplete,
  ContentItem,
  ControlledCheckbox,
  ControlledSelect,
  ControlledTagsInput,
  ControlledTextField,
  FileUpload,
  ItemCard,
} from 'core/components'
import { APP_IMAGE_URL, UserRole } from 'core/consts'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { FilterName, Item, SelectOption } from 'core/types'
import { formatPricePerUnit, productUnitList } from 'core/utils'
import { Formik } from 'formik'
import { initialProductValues, validationProductSchema } from 'items/helpers/productHelpers'
import { usePutProduct } from 'items/hooks'
import { useAddProductImage } from 'items/hooks/useAddProductImage'
import { boxStyle } from 'items/styles/styles'

import noImage from '../images/noImage.png'

interface SingleProductProps {
  product: Item
}

export const SingleProduct: React.FC<SingleProductProps> = ({ product }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { userInfo } = useAuth()
  const roles = userInfo?.roles
  const { uuid: productId } = useParams()
  const { updateProduct } = usePutProduct()
  const { uploadProductImage } = useAddProductImage()
  const queryClient = useQueryClient()

  const initialValues = initialProductValues(product)

  const onSuccessAction = () => {
    snackbar.success(t('productDetails.success'))
    const qk = apiEndpoints.getProductById(productId ?? '').queryKey
    queryClient.removeQueries(qk)
    navigate(roles?.includes(UserRole.ADMIN) ? '/admin-catalog' : '/vendor-catalog')
  }

  const handleUploadProductImage = async (file: File, productId: string) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('productId', productId)

    uploadProductImage(formData, {
      onSuccess: () => {
        onSuccessAction()
      },
      onError: () => {
        snackbar.error(t('productDetails.imageUpload.error'))
      },
    })
  }

  const handleSaveChanges = (values: typeof initialValues) => {
    updateProduct(
      {
        id: productId,
        unit: values.unit,
        tags: values.tags,
        isActive: values.isActive,
        storageAmount: values.storageAmount,
        minCartAmount: values.minCartAmount,
        purchaseIncrement: Number(values.purchaseIncrement) === 0 ? null : values.purchaseIncrement,
        subcategoryId: Number(values.subcategory.id),
      },
      {
        onSuccess: async () => {
          if (values.file && productId) {
            await handleUploadProductImage(values.file, productId)
          } else {
            onSuccessAction()
          }
        },
        onError: () => {
          snackbar.error(t('productDetails.error'))
        },
      },
    )
  }

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationProductSchema(t)} onSubmit={handleSaveChanges}>
        {(formik: any) => {
          const handleFileChange = async (file: File | null) => {
            await formik.setFieldValue('file', file)
          }
          const handleSubcategoryChange = async (subcategory: SelectOption) => {
            await formik.setFieldValue('subcategory', subcategory)
          }
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <ItemCard title={t('productDetails.basicInfo')}>
                    <Box sx={boxStyle}>
                      <ContentItem label={t('productDetails.name')} value={formik.values.name} />
                      <ContentItem label={t('productDetails.price')} value={formatPricePerUnit(formik.values.price, formik.values.unit)} />
                      <ContentItem label={t('productDetails.vat')} value={<>{formik.values.vat}%</>} />
                      <ContentItem
                        label={t('productDetails.stock')}
                        value={`${formik.values.stock} ${t(`product.unitValShort.${formik.values.unit}`)}`}
                      />
                    </Box>

                    <Grid container columnSpacing={2} rowSpacing={4} sx={{ mt: 1 }}>
                      <Grid item xs={12} md={6}>
                        <ControlledSelect name="unit" label={t('product.unit')} options={productUnitList(t)} required />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          filterName={FilterName.ALL_SUBCATEGORIES}
                          apiRequest="getCategories"
                          noPagination
                          value={formik.values.subcategory}
                          setValue={handleSubcategoryChange}
                          error={!formik.values.subcategory}
                          errorHelperText={t('common.validations.required')}
                          required
                        />
                      </Grid>
                      <Grid item>
                        <ControlledTextField type="number" name="storageAmount" label={t('productDetails.storageAmount')} />
                      </Grid>
                      <Grid container item columnSpacing={2} rowSpacing={4}>
                        <Grid item xs={12} sm={6}>
                          <ControlledTextField
                            type="number"
                            name="minCartAmount"
                            label={t('productDetails.minCartAmount')}
                            helperText={t('productDetails.minCartAmountHelper')}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ControlledTextField
                            type="number"
                            name="purchaseIncrement"
                            label={t('productDetails.purchaseIncrement')}
                            helperText={t('productDetails.purchaseIncrementHelper')}
                            placeholder={t('productDetails.purchaseIncrementUnset')}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <ControlledCheckbox name="isActive" label={t('productDetails.isActive')} />
                      </Grid>
                    </Grid>
                  </ItemCard>

                  <ItemCard title={t('productDetails.metaData')}>
                    <Grid container columnSpacing={2} rowSpacing={4}>
                      <Grid item xs={12} md={6}>
                        <Box sx={boxStyle}>
                          <ControlledTagsInput name="tags" label={t('productDetails.addTag')} />
                        </Box>
                      </Grid>
                    </Grid>
                  </ItemCard>
                </Grid>

                <Grid item xs={12} md={4}>
                  <ItemCard title={t('productDetails.productImage')} sx={{ position: 'sticky', top: 80 }}>
                    <Box sx={boxStyle}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: { md: 5 }, mb: 2 }}>
                        <img
                          src={product.images && product.images.length > 0 ? `${APP_IMAGE_URL}${product.images[0].uri}` : noImage}
                          alt={product.name}
                          style={{ height: '15rem' }}
                        />
                      </Box>
                      <FileUpload
                        name="productImage"
                        title={t('productDetails.selectNewProductImage')}
                        onFileChange={handleFileChange}
                        selectedFile={formik.values.file}
                        sx={{ flexDirection: 'column' }}
                      />
                    </Box>
                  </ItemCard>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  component={Link}
                  to={roles?.includes(UserRole.ADMIN) ? '/admin-catalog' : '/vendor-catalog'}
                  variant="outlined"
                  startIcon={<CloseIcon />}
                >
                  {t('common.cancel')}
                </Button>
                <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
                  {t('common.save')}
                </Button>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
