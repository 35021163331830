import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { TFunction } from 'i18next'

export const commonSortOptions = (t: TFunction) => [
  {
    id: '-orderDate',
    name: t('orders.orderDateDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'orderDate',
    name: t('orders.orderDateAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: 'totalCost',
    name: t('orders.priceAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-totalCost',
    name: t('orders.priceDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'deliverySite.name',
    name: t('orders.deliverySiteNameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-deliverySite.name',
    name: t('orders.deliverySiteNameDesc'),
    icon: <ArrowDownward />,
  },
]

export const userOrdersSortOptions = (t: TFunction) => [
  ...commonSortOptions(t),
  {
    id: 'vendor.name',
    name: t('orders.vendorNameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-vendor.name',
    name: t('orders.vendorNameDesc'),
    icon: <ArrowDownward />,
  },
]

export const adminOrdersSortOptions = (t: TFunction) => [
  ...commonSortOptions(t),
  {
    id: 'vendor.name',
    name: t('orders.vendorNameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-vendor.name',
    name: t('orders.vendorNameDesc'),
    icon: <ArrowDownward />,
  },
]

export const vendorOrdersSortOptions = (t: TFunction) => commonSortOptions(t)
