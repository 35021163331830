import { useQueries } from '@tanstack/react-query'
import axiosInstance from 'api/axiosInstance'
import { Endpoint } from 'api/endpoints'
import { SingleFilter } from 'core/types'

export const useFetchMultiple = (filters: SingleFilter[]) => {
  const results = useQueries({
    queries: filters.map(el => ({
      queryKey: el.api?.queryKey,
      queryFn: () => axiosInstance(el.api as Endpoint),
      enabled: !el.apiDisabled,
    })),
  })

  const mappedData = filters.map((el, idx) => ({
    filterName: el.filterName,
    filterType: el.filterType,
    apiResult: results[idx],
  }))

  return {
    mappedData,
  }
}
