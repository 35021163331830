import { ComponentType } from 'react'
import { Navigate } from 'react-router'

import { useAuth } from 'auth/contexts/AuthProvider'
import { UserRoute } from 'core/consts'

function withNoAuth<T extends object>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const { isAuthenticated } = useAuth()
    return isAuthenticated ? <Navigate to={UserRoute.HOME} replace /> : <WrappedComponent {...props} />
  }
}

export { withNoAuth }
