import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { useTheme } from '@mui/material/styles'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const data = [
  {
    name: 'Lipiec',
    pv: 1581706,
  },
  {
    name: 'Sierpień',
    pv: 1540468,
  },
  {
    name: 'Wrzesień',
    pv: 1460486,
  },
  {
    name: 'Pażdziernik',
    pv: 1489966,
  },
  {
    name: 'Listopad',
    pv: 1447906,
  },
  {
    name: 'Grudzień',
    pv: 1567846,
  },
  {
    name: 'Styczeń',
    pv: 1440684,
  },
]

const ActivityWidgetVendor = () => {
  const theme = useTheme()

  return (
    <Card>
      <CardHeader title="Miesięczna sprzedaż 2023/2024" />
      <CardContent>
        <ResponsiveContainer width="99%" height={500}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 16,
              left: 16,
              bottom: 5,
            }}
          >
            <XAxis axisLine={false} tick={{ fill: theme.palette.text.secondary, fontSize: 12 }} tickLine={false} dataKey="name" />
            <YAxis
              axisLine={false}
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              tickLine={false}
              domain={[1400000, 1600000]}
            />
            <Tooltip
              contentStyle={{
                borderRadius: 16,
                boxShadow: theme.shadows[3],
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.background.paper,
              }}
            />
            <Line name="Wartość" type="linear" dataKey="pv" stroke={theme.palette.primary.main} strokeWidth={6} activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}

export default ActivityWidgetVendor
