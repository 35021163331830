import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Box, Collapse, Typography } from '@mui/material'
import { SelectedTableItem, SelectedTableItemAction, TableAlert } from 'core/types'

import { selectedContainerStyle, selectedCountContainerStyle, selectedCountStyle } from './styles'

export interface SelectedItemsActionsProps {
  selectedItems: SelectedTableItem[]
  checkboxSelectionActions?: SelectedTableItemAction[]
  alerts: TableAlert[]
}

const SelectedItemsActions = memo(({ selectedItems, checkboxSelectionActions = [], alerts }: SelectedItemsActionsProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ position: 'sticky', top: 80, zIndex: 2, mt: -1, mb: 0 }}>
      <Collapse in={selectedItems.length > 0 && checkboxSelectionActions.length > 0}>
        <>
          <Box sx={selectedContainerStyle}>
            <Typography variant="subtitle1" component="div" sx={selectedCountContainerStyle}>
              <Typography variant="h5" sx={selectedCountStyle}>
                {selectedItems.length}
              </Typography>
              {t('common.selected2')}
            </Typography>
            {checkboxSelectionActions.map((el, i) => (
              <Box key={i} onClick={() => el.callback(selectedItems)} sx={{ pointerEvents: el.disabled ? 'none' : 'all' }}>
                {el.component}
              </Box>
            ))}
          </Box>
          {alerts.length > 0 &&
            alerts.map(el => (
              <Alert key={el.title} variant="outlined" severity={el.severity} sx={{ bgcolor: 'background.paper', borderRadius: 0 }}>
                {el.title && <Typography variant="subtitle1">{el.title}</Typography>}
                {el.text && (
                  <Typography variant="body2" whiteSpace="pre-line">
                    {el.text}
                  </Typography>
                )}
              </Alert>
            ))}
        </>
      </Collapse>
    </Box>
  )
})

export default SelectedItemsActions
