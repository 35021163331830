import { useEffect, useState } from 'react'

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
    resizing: false,
  })

  useEffect(() => {
    let timeout: any
    const handleResize = () => {
      clearTimeout(timeout)

      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        resizing: true,
      })

      timeout = setTimeout(() => {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight,
          resizing: false,
        })
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return size
}
