import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Apartment, Store } from '@mui/icons-material'
import { Box, SelectChangeEvent, Typography } from '@mui/material'
import { MainPaper, MainTabPanel, MainTabs, PageTitle, Select } from 'core/components'
import { Client } from 'core/types'

import { ClientBaseReportChart, ClientDetailedReport } from './ClientReport'
import { DSDetailedReport, DeliverySiteReportBaseReportChart } from './DeliverySiteReport'

export enum Tab {
  CLIENT = 'base',
  DELIVERY_SITE = 'detailed',
}

interface FinanseSummaryProps {
  clients: Client[]
}

const FinanseSummary = ({ clients }: FinanseSummaryProps) => {
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState<string>(clients.length > 1 ? '' : clients[0].id)
  const [selectedTab, setSelectedTab] = useState(Tab.CLIENT)

  const handleChangeClient = (e: SelectChangeEvent<any>) => {
    setSelectedClient(e.target.value)
  }

  const tabs = [
    {
      value: Tab.CLIENT,
      label: t('finanse.clientDataReport'),
      icon: <Apartment />,
    },
    {
      value: Tab.DELIVERY_SITE,
      label: t('finanse.deliverySiteDataReport'),
      icon: <Store />,
    },
  ]
  const handleChangeTabs = (newValue: string) => setSelectedTab(newValue as Tab)

  return (
    <Box mb={4}>
      <Box mb={4}>
        <PageTitle
          title={t('finanse.reportFor')}
          subtitle={
            clients.length > 1 ? (
              <Select
                label={t('clients.companyName')}
                name="client"
                value={selectedClient}
                onChange={handleChangeClient}
                options={clients}
                disabled={clients.length === 1}
              />
            ) : (
              <Typography variant="h2" component="div" color="primary" display="inline-flex" alignItems="center" py={2}>
                {clients[0].name}
              </Typography>
            )
          }
        />
      </Box>
      {selectedClient ? (
        <Box>
          <MainTabs tabs={tabs} selected={selectedTab} onChangeSelected={handleChangeTabs}>
            <MainTabPanel selectedValue={selectedTab} value={Tab.CLIENT}>
              <ClientBaseReportChart clients={clients} selectedClient={selectedClient} />
              <ClientDetailedReport selectedClient={selectedClient} />
            </MainTabPanel>
            <MainTabPanel selectedValue={selectedTab} value={Tab.DELIVERY_SITE}>
              <DeliverySiteReportBaseReportChart selectedClient={selectedClient} />
              <DSDetailedReport selectedClient={selectedClient} />
            </MainTabPanel>
          </MainTabs>
        </Box>
      ) : (
        <MainPaper>
          <Typography>{t('finanse.clientNotSelected')}</Typography>
        </MainPaper>
      )}
    </Box>
  )
}

export { FinanseSummary }
