import { Button, ButtonProps, styled } from '@mui/material'

export const boxStyle = {
  border: 1,
  borderRadius: 0.5,
  borderColor: 'grey.200',
  display: 'flex',
  alignItems: 'stretch',
  width: 'fit-content',
  flexDirection: 'row',
}

export const boxVerticalStyle = {
  borderTop: 1,
  borderColor: 'grey.200',
  display: 'flex',
  alignItems: 'stretch',
  width: '100%',
  flexDirection: 'column',
}

interface StyledActionBtnProps extends ButtonProps {
  inline?: boolean
  pulsate: boolean
}

export const StyledActionBtn = styled(Button, {
  shouldForwardProp: prop => prop !== 'inline' && prop !== 'pulsate',
})<StyledActionBtnProps>(({ inline, pulsate }) => ({
  padding: '0.75rem',
  borderRadius: 0,
  minWidth: 'unset',
  ...(inline && {
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    width: '100%',
  }),
  ...(!inline && {
    borderTopRightRadius: '0.25rem',
    borderBottomRightRadius: '0.25rem',
    marginLeft: '0.25rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    height: '100%',
  }),
  ...(pulsate && {
    '@keyframes pulse': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0.7 },
      '100%': { opacity: 1 },
    },
    animation: 'pulse 1.5s ease-in-out infinite',
  }),
}))

export const progressStyle = {
  height: 2,
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
}
