import React, { ReactNode, createContext, useContext, useState } from 'react'

import { Typography, styled } from '@mui/material'
import Alert, { AlertColor, AlertProps } from '@mui/material/Alert'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import { drawerCollapsedWidth, drawerWidth } from 'core/consts'

import { useSettings } from './SettingsProvider'

export const StyledAlert = styled(Alert)<AlertProps>(({ theme }) => {
  return theme.unstable_sx({
    width: '100%',
    borderRadius: 0,
  })
})

type ValueType = {
  message: ReactNode
}

interface AlertContextInterface {
  error: ({ message }: ValueType) => void
  success: ({ message }: ValueType) => void
  close: () => void
}

export const AlertContext = createContext({} as AlertContextInterface)

type AlertProviderProps = {
  children: React.ReactNode
}

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState<ValueType>({
    message: '',
  })
  const [severity, setSeverity] = useState<AlertColor | null>(null)
  const { collapsed } = useSettings()

  const ml = collapsed ? drawerCollapsedWidth : drawerWidth

  const handleCloseSnackbar = (event: React.SyntheticEvent<any, Event> | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleCloseAlert = () => {
    setOpen(false)
  }

  const error = (val: ValueType) => {
    setMessage(val)
    setSeverity('error')
    setOpen(true)
  }

  const success = (val: ValueType) => {
    setMessage(val)
    setSeverity('success')
    setOpen(true)
  }

  const close = () => {
    setMessage({
      message: '',
    })
    setSeverity(null)
    setOpen(false)
  }

  return (
    <AlertContext.Provider value={{ error, success, close }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleCloseSnackbar}
        sx={{
          width: { xs: '100%', lg: `calc(100% - ${ml}px)` },
          left: { xs: 0, lg: `${ml}px` },
          top: '80px !important',
        }}
      >
        <StyledAlert onClose={handleCloseAlert} severity={severity ?? undefined} variant="filled">
          <Typography variant="body2" component="div" sx={{ whiteSpace: 'pre-line' }}>
            {message.message}
          </Typography>
        </StyledAlert>
      </Snackbar>
    </AlertContext.Provider>
  )
}

export function useAlert() {
  return useContext(AlertContext)
}

export default AlertProvider
