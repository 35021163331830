import { SxProps, Theme } from '@mui/material'

export const commonSelectStyle: SxProps<Theme> = {
  bgcolor: 'background.paper',
  '& .MuiSelect-icon': {
    top: 'calc(50% - 12px)',
    transition: '0.3s',
  },
}

export const commonSelectItemStyle: SxProps<Theme> = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  '&.Mui-selected': {
    pointerEvents: 'none',
  },
  '&.MuiAutocomplete-option': {
    py: 1.5,
  },
  '&.MuiAutocomplete-option[aria-selected="true"]': {
    bgcolor: 'grey.50',
  },
}
