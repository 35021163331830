import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { Vendor, VendorListItem } from 'core/types'
import { GetParams, PaginatedResult } from 'core/types/paginatedResult'

export const useAllVendors = (params: GetParams) => {
  const { data, isLoading, refetch, ...other } = useRequest<PaginatedResult<VendorListItem>>(apiEndpoints.getAllVendors(params))
  return { vendorsData: data, isLoading, refetchVendors: refetch, ...other }
}

export const useVendorDetails = (id: string) => {
  const { data, refetch, ...other } = useRequest<Vendor>(apiEndpoints.getVendorDetails(id), { enabled: !!id })
  return { vendorData: data, refetchVendor: refetch, ...other }
}
