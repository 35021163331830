import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MainFilters, MainPaper, MainTable } from 'core/components'
import { FilterName } from 'core/types'
import { vendorOrdersSortOptions } from 'orders/helpers'
import { useGetVendorOrders } from 'vendor/hooks/useGetVendorOrders'

import { cellStyles, mapVendorOrdersColumns, mapVendorOrdersData } from './mapTableData'
import { FiltersState, filtersStateInit, useOrdersFilters } from './useOrdersFilters'

const VendorOrders = () => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)

  const sortOptions = useMemo(() => vendorOrdersSortOptions(t), [t])
  const [sort, setSort] = useState(sortOptions.find(el => el.id === '-orderDate')?.id ?? sortOptions[0].id)

  const [searchTerm, setSearchTerm] = useState('')

  const [filtersState, setFiltersState] = useState<FiltersState>(filtersStateInit)

  const { filters } = useOrdersFilters()

  const { vendorOrders, isFetching } = useGetVendorOrders({
    OrderRole: 1,
    Page: currentPage + 1,
    PageSize: pageSize,
    Sorts: sort,
    Filters: searchTerm,
    DeliverySiteId: filtersState[FilterName.ALL_DELIVERY_SITES],
    Status: filtersState[FilterName.ORDER_STATUS],
    PaymentMethod: filtersState[FilterName.PAYMENT_METHODS],
  })

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const handleSearch = useCallback((search: string) => {
    setSearchTerm(`(DeliverySite.Name|FriendlyOrderNumber)@=*${search}`)
    setCurrentPage(0)
  }, [])

  const onSetFiltersState = useCallback((data: any) => {
    setCurrentPage(0)
    setFiltersState(data)
  }, [])

  const onSetSort = useCallback((data: string) => {
    setSort(data)
  }, [])

  const clearAllFilters = useCallback(() => {
    setCurrentPage(0)
    setFiltersState(filtersStateInit)
  }, [])

  return (
    <>
      <MainFilters
        filters={filters}
        handleSearch={handleSearch}
        sort={sort}
        setSort={onSetSort}
        sortOptions={sortOptions}
        filtersState={filtersState}
        setFiltersState={onSetFiltersState}
        clearAllFilters={clearAllFilters}
      />
      <MainPaper sx={{ position: 'relative' }}>
        <MainTable
          columns={mapVendorOrdersColumns(t)}
          rows={mapVendorOrdersData(vendorOrders ?? { totalCount: 0, items: [] }, t)}
          count={vendorOrders?.totalCount}
          cellStyles={cellStyles}
          pagination={{
            page: currentPage,
            onPageChange: handlePageChange,
            pageSize,
            onPageSizeChange: handlePageSizeChange,
          }}
          loading={isFetching}
        />
      </MainPaper>
    </>
  )
}

export default VendorOrders
