import { QueryKey } from '@tanstack/react-query'
import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { GetParams, Item, PaginatedResult } from 'core/types'

interface GetItemsParams extends GetParams {
  subcategoryIds?: null | number[]
}

export const useGetVendorCatalog = (params: GetItemsParams, vendorId: string, queryKey: QueryKey) => {
  const { data, ...other } = useRequest<PaginatedResult<Item>>(apiEndpoints.getVendorCatalog(params, vendorId, queryKey), {
    enabled: !!vendorId,
  })
  return { vendorCatalog: data, ...other }
}
