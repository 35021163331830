import { OrderItem } from 'core/types'

export const sumTotalCost = (items: OrderItem[]) => {
  return items.reduce((acc: number, item: OrderItem) => acc + item.totalCost, 0)
}

export const filterUniqueVendors = (items: OrderItem[]) => {
  const uniqueCounterparties: any = {}
  return items
    .filter((item: OrderItem) => {
      if (!uniqueCounterparties[item.counterparty]) {
        uniqueCounterparties[item.counterparty] = true
        return true
      }
      return false
    })
    .map(el => el.counterparty)
}
