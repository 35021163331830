import { Link as RouterLink } from 'react-router-dom'

import { AddShoppingCart } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { AddToBasket } from 'core/components'
import { Item, PaginatedResult, PosIngredientsReport, PosPreviouslyOrdered } from 'core/types'
import { TFunction } from 'i18next'

type Actions = {
  handleConfirmAddToBasket: (el: PosPreviouslyOrdered, quantity: number) => void
}
export const mapPosWarehouseData = (data: PaginatedResult<PosIngredientsReport>, t: TFunction, action: Actions) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.posProductId,
      data: {
        posStock: `${el.quantity.toFixed(2)} / ${t(`product.unitValShort.${el.unit}`)}`,
        name: <Chip key={el.name} label={el.name} component={RouterLink} to={`/catalog?search=${encodeURIComponent(el.name)}`} clickable />,
        previouslyOrdered: el.previousOrder ? (
          <Chip
            key={el.previousOrder.name}
            label={el.previousOrder.name}
            component={RouterLink}
            to={`/catalog?search=${encodeURIComponent(el.previousOrder.name)}`}
            clickable
          />
        ) : (
          '-'
        ),
        addToBasket: el.previousOrder ? (
          <>
            {el.previousOrder.stock >= 1 ? (
              <AddToBasket
                onAction={quantity => action.handleConfirmAddToBasket(el.previousOrder as Item, quantity)}
                stock={el.previousOrder.stock}
                tooltipTitle={t('orders.addToBasket')}
                name={el.previousOrder.id}
                icon={<AddShoppingCart />}
                unit={el.previousOrder.unit}
                value={el.previousOrder.cartCount}
                min={el.previousOrder.minCartAmount}
                purchaseIncrement={el.previousOrder.purchaseIncrement}
              />
            ) : (
              <Chip color="error" variant="outlined" label={t('orders.outOfStock')} />
            )}
          </>
        ) : (
          '-'
        ),
      },
    }
  })

  return mappedData
}

export const mapPosWarehouseColumns = (t: TFunction) => [
  { title: t('pos.stock'), id: 'posStock' },
  { title: t('pos.ingredientName'), id: 'name' },
  { title: t('pos.previouslyOrdered'), id: 'previouslyOrdered' },
  { title: t('orders.addToBasket'), id: 'addToBasket' },
]

export const cellStyles = [
  {
    id: 'addToBasket',
    styles: {
      width: '13.25rem',
    },
  },
]
