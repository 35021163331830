import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import { Loader, MainPaper, MainTable } from 'core/components'
import SearchComponent from 'core/components/SearchComponent'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { User } from 'core/types'

import { useUpdateUser } from '../../hooks/useUpdateUser'
import { useUsers } from '../../hooks/useUsers'
import EditUserDialog from './EditUserDialog'
import { mapUsersColumns, mapUsersData } from './mapUsersTable'

const UsersManagement: React.FC = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [searchTerm, setSearchTerm] = useState('')
  const { usersData, refetch, isFetching } = useUsers({
    Page: currentPage + 1,
    PageSize: pageSize,
    Filters: searchTerm,
  })

  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const { updateUser, isLoading: isUpdating } = useUpdateUser()
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const handleOpenEditDialog = (user: User) => {
    setSelectedUser(user)
    setEditDialogOpen(true)
  }

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false)
  }

  const handleUpdateUser = async (updatedUser: User) => {
    await updateUser(updatedUser, {
      onSuccess: () => {
        snackbar.success(t('common.success.update.subTitle'))
        refetch()
        handleCloseEditDialog()
      },
      onError: () => {
        snackbar.error(t('payment.snackbar.error'))
      },
    })
  }

  const handleSearch = (search: string) => {
    setSearchTerm(`(FirstName|LastName|Email)@=*${search}`)
    setCurrentPage(0)
  }

  return (
    <MainPaper sx={{ position: 'relative' }}>
      {isFetching && <Loader relative />}
      <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item xs={12} md={6}>
          <SearchComponent onSearch={handleSearch} />
        </Grid>
      </Grid>

      {usersData && (
        <MainTable
          columns={mapUsersColumns(t)}
          rows={mapUsersData(usersData, t, { handleEdit: handleOpenEditDialog })}
          count={usersData?.totalCount}
          pagination={{
            page: currentPage,
            onPageChange: handlePageChange,
            pageSize,
            onPageSizeChange: handlePageSizeChange,
          }}
        />
      )}

      {editDialogOpen && (
        <EditUserDialog open onClose={handleCloseEditDialog} onSave={handleUpdateUser} user={selectedUser} processing={isUpdating} />
      )}
    </MainPaper>
  )
}

export default UsersManagement
