import { SxProps, Theme } from '@mui/material'

export const boxStyle: SxProps<Theme> = {
  mt: 2,
  py: 4,
  px: 2,
  border: 1,
  borderColor: 'grey.200',
  borderRadius: 0.5,
}
