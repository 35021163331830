import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { UserRoute } from 'core/consts'

import BelvedereLogo from '../images/belvedereLogo.jpg'
import NamaLogo from '../images/namaLogo.png'
import PomidoroLogo from '../images/pomidoroLogo.png'
import SelaviLogo from '../images/selaviLogo.png'
import VilanoLogo from '../images/vilanoLogo.jpg'

const meetings = [
  {
    id: '1',
    person: 'Selavi Warsaw',
    date: 'Piątek 02.02, 8:00 - 10:00',
    image: SelaviLogo,
  },
  {
    id: '2',
    person: 'Restauracja Belvedere',
    date: 'Piątek 02.02, 11:00 - 12:00',
    image: BelvedereLogo,
  },
  {
    id: '3',
    person: 'Nama Sushi Wilanów',
    date: 'Piątek 02.02, 10:00 - 12:00',
    image: NamaLogo,
  },
  {
    id: '4',
    person: 'Vilano',
    date: 'Piątek 02.02, 10:00 - 14:00',
    image: VilanoLogo,
  },
  {
    id: '5',
    person: 'Pomidoro',
    date: 'Sobota 03.02, 10:00 - 11:30',
    image: PomidoroLogo,
  },
  {
    id: '6',
    person: 'Nama Sushi Fort',
    date: 'Sobota 03.02, 11:00 - 13:00',
    image: NamaLogo,
  },
]

const MeetingWidgetsVendor = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Typography component="h2" marginBottom={3} variant="h4">
        {t('admin.home.meeting.title')}
      </Typography>
      {meetings.map(meeting => (
        <Card key={meeting.id} sx={{ mb: 2 }}>
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={`${meeting.person} avatar`} src={meeting.image} sx={{ mr: 2, padding: '5px', backgroundColor: 'white' }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography component="div" variant="h6">
                {meeting.person}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="div">
                {meeting.date}
              </Typography>
            </Box>
            <IconButton aria-label="Go to details" component={RouterLink} to={UserRoute.HOME}>
              <ChevronRightIcon />
            </IconButton>
          </CardContent>
        </Card>
      ))}
    </React.Fragment>
  )
}

export default MeetingWidgetsVendor
