export enum GeneralRoute {
  NOT_FOUND = '404',
  TERMS_OF_SUBSCRIPTION = 'terms-of-subscription',
  TERMS_OF_USAGE = 'terms-of-usage',
  PRIVACY_POLICY = 'privacy-policy',
  LANDING_PAGE = 'landing-page',
}
export enum UserRoute {
  HOME = '/home',
}
