import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Collapse, Divider, Grid, Typography } from '@mui/material'
import { Autocomplete, Connector, MainPaper, ProductSearch, StyledGridItem } from 'core/components'
import { Category, DeliverySite, FilterName, Item, Vendor } from 'core/types'
import { useRuleManagement } from 'management/hooks'
import { RuleDatesValid, RulePriceValues } from 'management/types'

import DateRange from './DateRange'
import RuleSummaryContainer from './RuleSummaryContainer'
import SimulateAddPreview from './SimulateAddPreview'
import { fieldBoxStyle, paperStyle } from './styles'

export type FiltersState = {
  [FilterName.ALL_DELIVERY_SITES]: DeliverySite | null
  [FilterName.ALL_VENDORS]: Vendor | null
  [FilterName.ALL_PRODUCT_CATEGORIES]: Category | null
  product: Item | null
}
export const filtersStateInit = {
  [FilterName.ALL_DELIVERY_SITES]: null,
  [FilterName.ALL_VENDORS]: null,
  [FilterName.ALL_PRODUCT_CATEGORIES]: null,
  product: null,
}

const ruleDatesValidInit = {
  validFrom: null,
  startAsap: true,
  validTo: null,
}
const priceValuesInit = {
  specialDiscount: 0,
  margin: 0,
  retroComission: 0,
}
interface RuleFormProps {
  open: boolean
  onClose: () => void
  refetchPricingRuleListData: () => void
}

const RuleForm = ({ open, onClose, refetchPricingRuleListData }: RuleFormProps) => {
  const { t } = useTranslation()

  const [filtersState, setFiltersState] = useState<FiltersState>(filtersStateInit)

  const [rulePriceValues, setRulePriceValues] = useState<RulePriceValues>(priceValuesInit)
  const [ruleDatesValid, setRuleDatesValid] = useState<RuleDatesValid>(ruleDatesValidInit)

  const resetAll = () => {
    setRulePriceValues(priceValuesInit)
    setRuleDatesValid(ruleDatesValidInit)
    setFiltersState(filtersStateInit)
    onClose()
  }

  const { handleSimulatePricingRuleAdd, handlePostPricingRule, simulatedData } = useRuleManagement({
    rulePriceValues,
    filtersState,
    ruleDatesValid,
    resetAll,
    refetchPricingRuleListData,
  })

  const onChangeFiltersVal = useCallback((filterName: string, val: any) => {
    setFiltersState(prev => ({
      ...prev,
      [filterName]: val,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectProduct = useCallback((product: Item | null) => {
    setFiltersState(prev => ({
      ...prev,
      product,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangePriceValues = useCallback((pkey: keyof RulePriceValues, val: string | number) => {
    setRulePriceValues(prev => ({
      ...prev,
      [pkey]: val,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Collapse in={open}>
      <MainPaper sx={paperStyle}>
        <Grid container spacing={3}>
          <StyledGridItem item xs={12} md={6}>
            <Typography variant="h4" mb={3}>
              {t('platformManagement.rules.ruleConditions')}
            </Typography>
            <Box sx={fieldBoxStyle}>
              <DateRange ruleDatesValid={ruleDatesValid} setRuleDatesValid={setRuleDatesValid} />
            </Box>
            <Connector>AND</Connector>
            {/* vendors */}
            <Autocomplete
              filterName={FilterName.ALL_VENDORS}
              apiRequest="getAllVendors"
              value={filtersState[FilterName.ALL_VENDORS]}
              setValue={(val: any) => onChangeFiltersVal(FilterName.ALL_VENDORS, val)}
            />
            <Connector>AND</Connector>

            {/* delivery sites */}
            <Autocomplete
              filterName={FilterName.ALL_DELIVERY_SITES}
              apiRequest="getAllDeliverySites"
              value={filtersState[FilterName.ALL_DELIVERY_SITES]}
              setValue={(val: any) => onChangeFiltersVal(FilterName.ALL_DELIVERY_SITES, val)}
            />

            <Connector>AND</Connector>

            <Box sx={fieldBoxStyle}>
              <Box display="flex" flexDirection="column">
                {/* categories */}
                <Autocomplete
                  filterName={FilterName.ALL_PRODUCT_CATEGORIES}
                  apiRequest="getCategories"
                  value={filtersState[FilterName.ALL_PRODUCT_CATEGORIES]}
                  setValue={(val: any) => onChangeFiltersVal(FilterName.ALL_PRODUCT_CATEGORIES, val)}
                  noPagination
                />

                <Connector>OR</Connector>

                {/* product search */}
                <ProductSearch
                  vendorId={filtersState.allVendors?.id ?? ''}
                  onSelectProduct={onSelectProduct}
                  categorySelected={Boolean(filtersState[FilterName.ALL_PRODUCT_CATEGORIES])}
                />
              </Box>
            </Box>
          </StyledGridItem>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" mb={3}>
              {t('platformManagement.rules.priceRuleFor')}
            </Typography>
            <RuleSummaryContainer rulePriceValues={rulePriceValues} onChangePriceValues={onChangePriceValues} filtersState={filtersState} />
            {simulatedData && <SimulateAddPreview simulatedData={simulatedData} />}
            <Box display="flex" justifyContent="flex-end" gap={2} mt={{ xs: 4, md: 6 }}>
              <Button onClick={resetAll} variant="outlined" startIcon={<CloseIcon />}>
                {t('common.cancel')}
              </Button>
              {simulatedData ? (
                <Button variant="contained" color="error" startIcon={<SaveIcon />} onClick={handlePostPricingRule}>
                  {t('platformManagement.action.acceptAndSaveRule')}
                </Button>
              ) : (
                <Button
                  disabled={!filtersState.allVendors}
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSimulatePricingRuleAdd}
                >
                  {t('platformManagement.action.saveRule')}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </MainPaper>
      <Divider sx={{ my: 4, borderColor: 'grey.300' }} />
      <Typography variant="h4" mb={3}>
        {t('platformManagement.rulesList')}
      </Typography>
    </Collapse>
  )
}

export default RuleForm
