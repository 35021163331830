import { ReactNode } from 'react'

import { South } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

interface RuleSummaryItemProps {
  label: string
  children: ReactNode
  noIcon?: boolean
}

const RuleSummaryItem = ({ label, noIcon, children }: RuleSummaryItemProps) => {
  return (
    <>
      {!noIcon && <South />}
      <Box display="flex" gap={1} alignItems="baseline">
        <Typography variant="body2">{label}</Typography>
        <Typography variant="subtitle1">{children}</Typography>
      </Box>
    </>
  )
}

export default RuleSummaryItem
