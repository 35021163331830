import React from 'react'
import { useNavigate } from 'react-router-dom'

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import { Badge, Fab } from '@mui/material'
import { useCompanyContext } from 'companies/contexts/CompanyContext'
import { useGetOrderItems } from 'items/hooks/useGetOrderItems'

const BasketIconWithBadge: React.FC = () => {
  const navigate = useNavigate()
  const { selectedCompany } = useCompanyContext()
  const { orderItemsData } = useGetOrderItems(selectedCompany?.id)
  const itemCount = orderItemsData?.uniqueProductsCount || 0

  const handleBasketClick = () => {
    navigate('/basket')
  }

  return (
    <Fab color="secondary" onClick={handleBasketClick}>
      <Badge badgeContent={itemCount} color="error">
        <ShoppingBasketIcon />
      </Badge>
    </Fab>
  )
}

export default BasketIconWithBadge
