import { ProductUnit } from 'core/consts'
import { Address } from 'core/types'
import { t } from 'i18next'

import { districtList } from './districtList'

/**
 * Formats a price value as a string with two decimal places and adds currency.
 * @param price The price to be formatted.
 * @returns The formatted price string with currency.
 */
export function formatPrice(price: number | string, gross?: 'gross' | 'net'): string {
  const parsedPrice = typeof price === 'string' ? parseFloat(price) : price
  const additional = gross ? ' ' + (gross === 'gross' ? t('common.gross') : t('common.net')) : ''
  if (isNaN(parsedPrice)) {
    return `0,00\u00A0${t('common.pln')}${additional}`
  } else {
    return `${parsedPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}\u00A0${t('common.pln')}${additional}`
  }
}

export function formatPricePerUnit(price: number | string, unit?: ProductUnit): string {
  return unit ? `${formatPrice(price)} / ${t(`product.unitValShort.${unit}`)}` : formatPrice(price)
}

export const formatAddress = (a: Address) => {
  const address = `${a.street} ${a.streetNumber}${a.apartmentNumber ? '/' + a.apartmentNumber : ''}, ${a.city} ${a.postalCode}, ${districtList.find(el => el.value === a.district)?.label}`
  return address
}
