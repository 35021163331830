import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router'

import { MonetizationOn, Warehouse } from '@mui/icons-material'
import { Box } from '@mui/material'
import CompanySelector from 'companies/components/CompanySelector'
import { PosTab } from 'config/routeTypes'
import { MainTabPanel, MainTabs, PageTitle } from 'core/components'
import { DotykackaReport } from 'pos/components/DotykackaReport'
import { PosIngredients } from 'pos/components/DotykackaWarehouse'

const ClientDotykackaData = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const path = pathname.split('/')[2]
  const [selectedTab, setSelectedTab] = useState(path ?? PosTab.WAREHOUSE)

  const tabs = [
    {
      value: PosTab.WAREHOUSE,
      label: t('pos.warehouse'),
      icon: <Warehouse />,
      to: PosTab.WAREHOUSE,
    },
    {
      value: PosTab.RECENTLY_SOLD,
      label: t('pos.recentlySold'),
      icon: <MonetizationOn />,
      to: PosTab.RECENTLY_SOLD,
    },
  ]
  const handleChangeTabs = (newValue: string) => setSelectedTab(newValue as PosTab)

  return (
    <>
      {!path && <Navigate replace to={PosTab.WAREHOUSE} />}
      <Box>
        <Box mb={4}>
          <PageTitle title={t('pos.showDataFor')} subtitle={<CompanySelector showError={false} setError={() => {}} />} />
        </Box>
        <MainTabs tabs={tabs} selected={selectedTab} onChangeSelected={handleChangeTabs}>
          <MainTabPanel selectedValue={selectedTab} value={PosTab.WAREHOUSE}>
            <PosIngredients />
          </MainTabPanel>
          <MainTabPanel selectedValue={selectedTab} value={PosTab.RECENTLY_SOLD}>
            <DotykackaReport />
          </MainTabPanel>
        </MainTabs>
      </Box>
    </>
  )
}

export { ClientDotykackaData }
