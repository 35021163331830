import { PaginatedResult } from 'core/types'
import { formatPricePerUnit } from 'core/utils/formatUtils'
import { TFunction } from 'i18next'
import { DotykackaPosProducts } from 'management/types'

export const mapAdministratorPosProducts = (data: PaginatedResult<DotykackaPosProducts>) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.productName,
      data: {
        name: el.productName,
        deliverySiteName: el.deliverySiteName,
        price: formatPricePerUnit(el.unitPriceWithoutVat, el.unit),
        vat: el.vat,
        quantity: <strong>{el.quantity.toFixed(3)}</strong>,
        unit: el.unit,
      },
    }
  })
  return mappedData
}

export const mapAdministratorPosProductsColumns = (t: TFunction) => [
  { title: t('administrator.title.name'), id: 'name' },
  { title: t('administrator.title.deliverySite'), id: 'deliverySiteName' },
  { title: t('administrator.title.price'), id: 'price' },
  { title: t('administrator.title.vat'), id: 'averagePrice' },
  { title: t('administrator.title.available'), id: 'quantity' },
  { title: t('administrator.title.unit'), id: 'unit' },
]
