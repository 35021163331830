import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Button, IconButton, SxProps, Theme, Tooltip, Typography } from '@mui/material'

import AvatarImg from '../AvatarImg'
import { boxStyle } from './styles'

const MAX_FILE_SIZE = 1024 // 1MB

export interface FileUploadProps {
  onFileChange: (file: File | null) => void
  name: string
  title: string
  selectedFile?: File | null
  accept?: string
  maxSize?: number
  sx?: SxProps<Theme>
}

const FileUpload = ({ name, title, selectedFile, onFileChange, accept = 'image/*', maxSize = MAX_FILE_SIZE, sx = [] }: FileUploadProps) => {
  const { t } = useTranslation()

  const [errorMsg, setErrorMsg] = useState('')

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileSizeKiloBytes = file.size / 1024
      if (fileSizeKiloBytes > maxSize) {
        setErrorMsg(t('common.validations.fileMaxSize', { size: `${fileSizeKiloBytes}MB` }))
      } else {
        setErrorMsg('')
        onFileChange(file)
      }
    }
  }

  return (
    <Box>
      <Box sx={[{ display: 'flex', gap: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}>
        <Button component="label" role={undefined} variant="outlined" tabIndex={-1} startIcon={<CloudUploadIcon />} id={`${name}-btn`}>
          {title}
          <input id={name} name={name} type="file" hidden onChange={handleFileChange} accept={accept} />
        </Button>
        {selectedFile && (
          <Box sx={boxStyle}>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
              {accept === 'image/*' && (
                <AvatarImg
                  img={URL.createObjectURL(selectedFile)}
                  name="Uploaded Image"
                  sx={{ width: 36, height: 36, bgcolor: 'transparent' }}
                />
              )}
              <Typography variant="body1" mx={1} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {selectedFile.name}
              </Typography>
            </Box>
            <Tooltip title={t('common.delete')}>
              <IconButton onClick={() => onFileChange(null)}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {errorMsg && (
        <Typography color="error" variant="body2" mt={0.8}>
          {errorMsg}
        </Typography>
      )}
    </Box>
  )
}

export default FileUpload
