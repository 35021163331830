import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Checkbox, CheckboxProps, Collapse, FormControlLabel, FormHelperText, Link, SxProps, Typography } from '@mui/material'
import { RequiredSign } from 'core/components/RequiredSign'
import { Field, FormikValues, getIn, useFormikContext } from 'formik'

import { CheckboxCaption } from '.'

export interface ControlledCheckboxProps extends CheckboxProps, FormikValues {
  name: string
  expandedText?: React.ReactNode
  required?: boolean
  sx?: SxProps
}

export const ControlledCheckbox = ({ label, name, expandedText, required, size, sx = [], ...otherProps }: ControlledCheckboxProps) => {
  const { t } = useTranslation()
  const { values, errors, touched } = useFormikContext()
  const error = getIn(errors, name) && getIn(touched, name)
  const errorMsg = getIn(errors, name)
  const value = getIn(values, name)

  const [expanded, setExpanded] = useState(false)

  const handleToggle = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setExpanded(!expanded)
    },
    [expanded],
  )

  return (
    <Box sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Field
        as={FormControlLabel}
        control={<Checkbox checked={value} size={size} />}
        name={name}
        label={
          <Typography variant={size === 'small' ? 'body2' : 'body1'} sx={{ alignSelf: 'center' }}>
            {label}
            {required && <RequiredSign />}
            {expandedText && <> </>}
            {expandedText && (
              <Link component="button" color="primary" onClick={handleToggle} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                {expanded ? (
                  <>
                    {t('common.action.collapse')} <ExpandLess fontSize="small" />
                  </>
                ) : (
                  <>
                    {t('common.action.expand')} <ExpandMore fontSize="small" />
                  </>
                )}
              </Link>
            )}
          </Typography>
        }
        sx={{ alignItems: 'flex-start' }}
        {...otherProps}
      />
      {expandedText && (
        <Collapse in={expanded}>
          <CheckboxCaption size={size} text={expandedText} />
        </Collapse>
      )}
      {error && errorMsg && (
        <FormHelperText error>
          <CheckboxCaption size={size} text={errorMsg} error />
        </FormHelperText>
      )}
    </Box>
  )
}
