import Home from 'admin/pages/Home'
import { useAuth } from 'auth/contexts/AuthProvider'
import { UserRole } from 'core/consts'
import DashboardVendor from 'vendor/pages/DashboardVendor'

import DashboardClient from './DashboardClient'

export const DashboardWrapper = () => {
  const { userInfo } = useAuth()
  const roles = userInfo?.roles

  return (
    <>
      {roles?.some(role => role === UserRole.CLIENT || role === UserRole.RESTAURANT_MANAGER) && <DashboardClient />}
      {roles?.includes(UserRole.VENDOR) && <DashboardVendor />}
      {roles?.includes(UserRole.ADMIN) && <Home />}
    </>
  )
}
