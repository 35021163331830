import { SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import NotificationHub from 'SignalR/Notifications/NotificationHub'
import { useAuth } from 'auth/contexts/AuthProvider'
import { useGetNotification } from 'core/components/NotificationCenter/hooks/useGetNotifications'
import { notificationItemFactory } from 'core/consts'
import { NotificationEvent, NotificationEventData, NotificationEventFn, NotificationEventFnType, NotificationEventType } from 'core/types'

export const NotificationsContext = createContext({ eventsData: [], setEventsData: () => {} } as {
  eventsData: NotificationEvent[]
  setEventsData: (value: SetStateAction<NotificationEvent[]>) => void
})

export const NotificationsContextProvider = ({ children }: any) => {
  const { t } = useTranslation()
  const [eventsData, setEventsData] = useState<NotificationEvent[]>([])
  const { isAuthenticated } = useAuth()
  const { notifications } = useGetNotification(isAuthenticated)
  const { events } = NotificationHub
  const findEl = (eventKey: NotificationEventType, params: any) => {
    return notificationItemFactory(eventKey, t, params)
  }

  useEffect(() => {
    const notificationEventFns: NotificationEventFn = {} as NotificationEventFn

    Object.values(NotificationEventType).forEach(eventType => {
      notificationEventFns[NotificationEventFnType[eventType]] = (data: NotificationEventData) => {
        setEventsData(prev => [
          ...prev,
          {
            id: data.id,
            read: data.read,
            timestamp: data.timestamp,
            data: data.data,
            type: data.type,
            ...findEl(eventType, data.data),
          },
        ])
      }
    })

    events(notificationEventFns)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  useEffect(() => {
    if (notifications) {
      const existingNotificationEvents: NotificationEvent[] = notifications.map<NotificationEvent>(n => ({
        id: n.id,
        read: n.read,
        timestamp: n.timestamp,
        data: n.data,
        type: n.type,
        ...findEl(n.type, n.data),
      }))
      setEventsData(existingNotificationEvents)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  return <NotificationsContext.Provider value={{ eventsData, setEventsData }}>{children}</NotificationsContext.Provider>
}

export function useNotifications() {
  return useContext(NotificationsContext)
}
