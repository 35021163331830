import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import { useIsFetching } from '@tanstack/react-query'

import Loader from '../Loader'

interface NotReadyWrapperProps {
  children?: React.ReactNode
  isSuccess?: boolean
  isLoading?: boolean
  isError?: boolean
  errorMsg?: string
}

const NotReadyWrapper = ({ isLoading, isSuccess, isError, errorMsg, children }: NotReadyWrapperProps) => {
  const { t } = useTranslation()
  const isFetching = useIsFetching()

  if (!isError) {
    return (
      <>
        {Boolean(isLoading !== undefined ? isLoading : isFetching) && <Loader />}
        {isSuccess && children}
      </>
    )
  }

  if (isError || (!isSuccess && !isLoading)) {
    return (
      <Typography color="error" sx={{ whiteSpace: 'pre-line' }}>
        {errorMsg ?? t('common.errors.unexpected.subTitle')}
      </Typography>
    )
  }

  return <div />
}

export default NotReadyWrapper
