import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { ManualTab, RouteVal } from 'config/routeTypes'

export interface ExclamationMarkProps extends Pick<IconButtonProps, 'color' | 'sx' | 'size'> {}

const ExclamationMark = ({ sx = [], ...props }: ExclamationMarkProps) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t('common.variableWeightProduct')}>
      <IconButton
        component={RouterLink}
        to={`/${RouteVal.MANUAL}/${ManualTab.FAQ}?search=${encodeURIComponent('zmiennowagowy')}`}
        target="_blank"
        size="small"
        color="warning"
        sx={[{ cursor: 'help' }, ...(Array.isArray(sx) ? sx : [sx])]}
        {...props}
      >
        <PriorityHighIcon fontSize={props.size} />
      </IconButton>
    </Tooltip>
  )
}

export default ExclamationMark
