import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import HelpIcon from '@mui/icons-material/Help'
import SettingsIcon from '@mui/icons-material/Settings'
import { ListItem, ListItemButton, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { useAuth } from 'auth/contexts/AuthProvider'
import { RouteVal } from 'config/routeTypes'
import { Footer, SettingsDrawer } from 'core/components'
import Logo from 'core/components/Logo'
import { UserRoute } from 'core/consts'

import { MobileMenu } from '../LandingLayout/MobileMenu'
import { menuItemStyle } from '../LandingLayout/styles'
import { containerStyle } from './styles'

interface NoAuthLayoutProps {
  children: React.ReactNode
  maxWidth?: 'sm' | 'xl' | 'lg' | 'md'
  noLogo?: boolean
  align?: 'center' | 'left' | 'top'
  title?: React.ReactNode
}

export const NoAuthLayout = ({ maxWidth = 'sm', noLogo = false, align = 'center', title, children }: NoAuthLayoutProps) => {
  const { t } = useTranslation()
  const [settingsOpen, setSettingsOpen] = useState(false)
  const { isAuthenticated } = useAuth()
  const navItems = isAuthenticated
    ? [
        {
          title: t('home.contact.title'),
          to: '/contact',
        },
      ]
    : [
        {
          title: t('home.login.title'),
          to: '/login',
        },
        {
          title: t('home.contact.title'),
          to: '/contact',
        },
      ]

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen)
  }

  return (
    <Box>
      <AppBar
        color="inherit"
        sx={{ bgcolor: noLogo ? 'inherit' : 'background.default', borderBottom: noLogo ? 0 : 1, borderColor: 'grey.300' }}
        position="fixed"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex', gap: 3, alignItems: 'center' }}>
              {!noLogo && (
                <Box sx={{ display: 'flex' }}>
                  <NavLink to={UserRoute.HOME}>
                    <Logo size={100} />
                  </NavLink>
                </Box>
              )}
              {title && (
                <Typography variant="h2" component={typeof title === 'string' ? 'h1' : 'div'} sx={{ flexGrow: 1 }}>
                  {title}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1, mr: 4 }}>
              {navItems.map(el => (
                <ListItem key={el.title} disablePadding>
                  <ListItemButton component={NavLink} to={el.to ?? ''} sx={[menuItemStyle, { textAlign: 'center', textWrap: 'nowrap' }]}>
                    <Typography variant="h5" component="span">
                      {el.title}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
            <Box display="inline-flex" gap={2}>
              <IconButton aria-label="settings" component={NavLink} to={`/${RouteVal.MANUAL}`} target="_blank">
                <HelpIcon />
              </IconButton>
              <IconButton aria-label="help-center" component="span" onClick={handleSettingsToggle}>
                <SettingsIcon />
              </IconButton>
              <MobileMenu items={navItems} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SettingsDrawer onDrawerToggle={handleSettingsToggle} open={settingsOpen} />
      <Box sx={containerStyle}>
        <Container
          component="main"
          maxWidth={maxWidth}
          sx={[
            { flex: 1, display: 'flex', flexDirection: 'column', mb: 6 },
            align === 'center' && { justifyContent: 'center', alignItems: 'center' },
          ]}
        >
          {children}
        </Container>
        <Container maxWidth="xl">
          <Footer />
        </Container>
      </Box>
    </Box>
  )
}
