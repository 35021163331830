import React, { ReactNode, createContext, useContext, useState } from 'react'

import { DeliverySite } from 'core/types'

interface CompanyContextProps {
  selectedCompany: DeliverySite | null
  setSelectedCompany: (company: DeliverySite | null) => void
}

const defaultState: CompanyContextProps = {
  selectedCompany: null,
  setSelectedCompany: () => {},
}

export const CompanyContext = createContext<CompanyContextProps>(defaultState)

export const useCompanyContext = () => useContext(CompanyContext)

interface CompanyProviderProps {
  children: ReactNode
}

export const CompanyProvider: React.FC<CompanyProviderProps> = ({ children }) => {
  const setInitSelectedCompany = () => {
    const storedCompany = localStorage.getItem('selectedCompany')
    if (storedCompany) {
      return JSON.parse(storedCompany)
    } else return null
  }

  const [selectedCompany, setSelectedCompany] = useState<DeliverySite | null>(setInitSelectedCompany())

  const handleSelectedCompany = (company: DeliverySite | null) => {
    setSelectedCompany(company)
    if (company) {
      localStorage.setItem('selectedCompany', JSON.stringify(company))
    } else {
      localStorage.removeItem('selectedCompany')
    }
  }

  return (
    <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany: handleSelectedCompany }}>{children}</CompanyContext.Provider>
  )
}
