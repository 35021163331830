import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { BasicPosSalesReport } from 'core/types'

export const useBasicPosSalesReport = (params: { deliverySiteId: number; from?: string }) => {
  const { data, isLoading, isFetching, isSuccess, isError, refetch, ...other } = useRequest<BasicPosSalesReport[]>(
    apiEndpoints.getBasicPosSalesReport(params),
    {
      enabled: !!params.deliverySiteId,
      retry: 1,
    },
  )

  return {
    posSalesReportsLoading: isFetching || isLoading,
    posSalesReportData: data,
    posSalesReportsIsSuccess: isSuccess,
    posSalesReportsIsError: isError,
    posSalesReportRefetch: refetch,
    ...other,
  }
}
