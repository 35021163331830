import { useState } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Button, ButtonProps, Menu, MenuItem } from '@mui/material'
import { Theme } from '@mui/system'

export const ButtonMenu = ({
  btnProps,
  items,
  btnTitle,
}: {
  btnProps?: ButtonProps
  btnTitle: string
  items: { label: string; disabled?: boolean; action: () => void }[]
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id="positioned-button"
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...btnProps}
        sx={{
          textWrap: 'nowrap',
        }}
      >
        {btnTitle}
        <Box sx={[{ ml: 1, display: 'flex', '& svg': { transition: '0.3s' } }, open ? { '& svg': { transform: 'rotate(180deg)' } } : {}]}>
          <ExpandMore fill="inherit" />
        </Box>
      </Button>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none',
            border: (theme: Theme) => `1px solid ${theme.palette.grey[300]}`,
            borderRadius: 1,
          },
        }}
      >
        {items.map(el => (
          <MenuItem sx={{ minWidth: '10rem' }} disabled={el.disabled} key={el.label} onClick={el.action}>
            {el.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
