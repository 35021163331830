import { memo } from 'react'

import { SxProps, Theme, Typography, TypographyVariant } from '@mui/material'
import { formatPhoneNumber } from 'core/utils'

export interface PhoneNumberProps {
  phoneCode: string
  phoneNo?: number | string
  variant?: TypographyVariant
  color?: string
  sx?: SxProps<Theme>
}

const PhoneNumber = memo(({ phoneCode, phoneNo, variant = 'subtitle1', color = 'text.primary', sx = [] }: PhoneNumberProps) => {
  return (
    <Typography variant={variant} color={color} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {phoneNo ? (
        <>
          <Typography component="span" variant={variant} color="text.secondary" sx={{ pr: 0.5 }}>
            {phoneCode}
          </Typography>
          {formatPhoneNumber(phoneNo.toString(), ' ')}
        </>
      ) : (
        '-'
      )}
    </Typography>
  )
})

export default PhoneNumber
