import { HTMLInputTypeAttribute, memo, useCallback, useMemo, useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material'
import { ReactComponent as FlagPLIcon } from 'core/assets/flagPL.svg'

import { commonInputStyle, phoneAdornmentStyle } from './styles'

export type TextFieldProps = {
  name: string
  error?: boolean
  errorHelperText?: string
  onChange?: (e: React.ChangeEvent<any>) => void
  onChangeOverride?: (val: string | number) => void
  inputProps?: InputBaseComponentProps
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  type?: HTMLInputTypeAttribute | 'phone'
} & Omit<MuiTextFieldProps, 'onChange'>

export const TextField = memo(
  ({
    name,
    label,
    value,
    onChange,
    onChangeOverride,
    placeholder,
    error,
    helperText,
    errorHelperText,
    type,
    inputProps,
    sx = [],
    startAdornment,
    endAdornment,
    ...rest
  }: TextFieldProps) => {
    const sxStyle = sx ? (Array.isArray(sx) ? sx : [sx]) : []

    const { min, max } = inputProps ?? {}
    const [showPassword, setShowPassword] = useState(false)

    const startAdornmentPhone = useMemo(() => {
      if (type === 'phone') {
        return (
          <InputAdornment position="start" disablePointerEvents data-testid={`startAdornment-${type}`}>
            <Box display="flex">
              <FlagPLIcon />
              <Typography variant="body2" sx={phoneAdornmentStyle}>
                (+48)
              </Typography>
            </Box>
          </InputAdornment>
        )
      }
      return
    }, [type])

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (typeof onChangeOverride === 'function') {
          if (type === 'number') {
            const val = e.target.value
            let newVal = val
            if (max !== undefined && val > max) newVal = max
            if (min !== undefined && val < min) newVal = min
            onChangeOverride(newVal)
          } else onChangeOverride(e.target.value)
        }
        if (typeof onChange === 'function') {
          onChange(e)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [type, max, min, value],
    )

    const handleClickShowPassword = () => setShowPassword(show => !show)
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
    }

    return (
      <Box sx={[{ borderRadius: 1, '& .MuiInputLabel-root': { display: 'flex' } }, ...sxStyle]}>
        <MuiTextField
          name={name}
          id={`${name}-input`}
          value={value === null ? '' : value}
          label={label}
          placeholder={placeholder || ''}
          onChange={handleChange}
          error={error}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type === 'phone' ? 'number' : type}
          inputProps={{
            inputMode: type === 'number' ? 'decimal' : 'text',
            ...inputProps,
          }}
          {...rest}
          InputProps={{
            startAdornment: type === 'phone' ? startAdornmentPhone : startAdornment,
            endAdornment:
              type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : (
                endAdornment
              ),
            ...rest.InputProps,
          }}
          sx={commonInputStyle}
        />
        {helperText && (
          <FormHelperText sx={{ mt: 0.8, ml: 1.725 }}>
            <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
              {helperText}
            </Typography>
          </FormHelperText>
        )}
        {error && errorHelperText && (
          <FormHelperText sx={{ mt: 0.8, ml: 1.725 }} error>
            <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
              {errorHelperText}
            </Typography>
          </FormHelperText>
        )}
      </Box>
    )
  },
)
