import { Theme, lighten } from '@mui/material'
import { darken } from '@mui/system'

export const radiusStyle = {
  '&:first-child': {
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    '& > .title, .active-title': {
      borderTopLeftRadius: '0.5rem',
      borderTopRightRadius: '0.5rem',
    },
  },
  '&:last-child': {
    borderBottomLeftRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    '& > .title': {
      borderBottomLeftRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    },
  },
}

export const boxStyle = {
  border: 1,
  borderColor: 'grey.200',
  bgcolor: 'background.paper',
  ...radiusStyle,
}

export const headerStyle = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  p: 3,
  '&:hover': {
    background: (theme: Theme) =>
      theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.7) : lighten(theme.palette.secondary.main, 0.9),
  },
}

export const activeHeaderStyle = {
  background: (theme: Theme) =>
    `${theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.6) : lighten(theme.palette.secondary.main, 0.8)} !important`,
}
