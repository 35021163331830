import { useEffect, useState } from 'react'

import { Box, LinearProgress, SxProps } from '@mui/material'

export interface LinearProgressBarProps {
  timerValue: number // in ms
  stopTimer?: boolean
  color?: 'primary' | 'inherit' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  onTimeIsOver?: () => void
  sx?: SxProps
}

export const LinearProgressBar = ({ timerValue, color, stopTimer, onTimeIsOver, sx = [] }: LinearProgressBarProps) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          clearInterval(timer)
          return 100
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, timerValue)

    if (stopTimer) {
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [timerValue, stopTimer])

  useEffect(() => {
    if (progress === 100 && typeof onTimeIsOver === 'function') {
      onTimeIsOver()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  return (
    <Box sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <LinearProgress variant="determinate" value={progress} color={color} />
    </Box>
  )
}
