import { StyledComponent } from '@emotion/styled'
import { Box, BoxProps, styled } from '@mui/material'

export const StyledConnector: StyledComponent<BoxProps> = styled(Box)<BoxProps>(({ theme }) =>
  theme.unstable_sx({
    ml: '50%',
    height: '2rem',
    width: '1px',
    bgcolor: 'grey.400',
  }),
)
