import { useEffect } from 'react'
import { getCookieConsentValue } from 'react-cookie-consent'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

import { REACT_APP_GA_TRACKING_ID } from 'core/consts'

const usePageTracking = () => {
  const TRACKING_ID = REACT_APP_GA_TRACKING_ID ?? ''
  const consent = getCookieConsentValue('skalo-www-cookie')
  const location = useLocation()

  const trackPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
  }

  useEffect(() => {
    if (TRACKING_ID && consent === 'true') {
      ReactGA.initialize(TRACKING_ID)
    }
  }, [TRACKING_ID, consent])

  useEffect(() => {
    if (TRACKING_ID !== '' && consent === 'true') {
      trackPageView()
    }
  }, [location, consent, TRACKING_ID])
}

export default usePageTracking
