import { useMemo } from 'react'

import { FilterName, FilterType, SingleFilter, Vendor } from 'core/types'

export type MinimalPriceFiltersState = {
  [FilterName.ALL_VENDORS]: Vendor | null
}
export const minimalPriceFiltersStateInit = {
  [FilterName.ALL_VENDORS]: null,
}

export const useFilters = () => {
  const filters = useMemo(
    (): SingleFilter[] => [
      {
        filterName: FilterName.ALL_VENDORS,
        apiRequest: 'getAllVendors',
        filterType: FilterType.AUTOCOMPLETE,
      },
    ],
    [],
  )

  return {
    filters,
  }
}
