import { useEffect, useRef, useState } from 'react'

import { Box, Paper, SxProps, Theme, Typography } from '@mui/material'
import { appBarHeight } from 'core/consts'

import { boxStyle, titleStyle } from './styles'

export interface ItemCardProps {
  title?: string
  children: React.ReactNode
  rightEl?: React.ReactNode
  sx?: SxProps<Theme>
  titleSx?: SxProps<Theme>
}

export const ItemCard = ({ title, children, rightEl, sx = [], titleSx = [] }: ItemCardProps) => {
  const stickyRef = useRef<HTMLDivElement>(null)
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const stickyTop = stickyRef.current.getBoundingClientRect().top
        setIsFixed(Math.floor(stickyTop) <= appBarHeight)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Paper ref={stickyRef} sx={[boxStyle, ...(Array.isArray(sx) ? sx : [sx])]}>
      {title && (
        <Box
          sx={[titleStyle, isFixed && { borderRadius: 0 }, ...(Array.isArray(titleSx) ? titleSx : [titleSx])]}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" fontWeight={700}>
            {title}
          </Typography>
          {rightEl && rightEl}
        </Box>
      )}
      <Box px={2} mt={title ? 0 : 2}>
        {children}
      </Box>
    </Paper>
  )
}
