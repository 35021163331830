import { Navigate, RouteProps } from 'react-router'

import { useAuth } from 'auth/contexts/AuthProvider'

type PrivateRouteProps = {
  roles?: string[]
} & RouteProps

const PrivateRoute = ({ children, roles }: PrivateRouteProps) => {
  const { hasRole, userInfo } = useAuth()
  if (userInfo) {
    // if route is accessible for all roles it's undefined
    if (!roles || hasRole(roles)) {
      return children
    }
    return <Navigate to="/403" replace={false} />
  } else {
    return <Navigate to="/login" />
  }
}

export default PrivateRoute
