import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { Breadcrumbs, Link, Typography } from '@mui/material'

export const PageTitleBreadcrumbs = ({ items }: { items: { title: string; to?: string }[] }) => {
  const { t } = useTranslation()
  return (
    <Typography variant="inherit" component="div">
      <Breadcrumbs aria-label="drawer-breadcrumb">
        {items.slice(0, -1).map(el => (
          <Link key={el.title} underline="hover" variant="h2" color="inherit" component={RouterLink} to={el.to ?? ''}>
            {t(el.title)}
          </Link>
        ))}

        <Typography variant="h2" component="h1" color="text.primary">
          {t(items[items.length - 1].title)}
        </Typography>
      </Breadcrumbs>
    </Typography>
  )
}
