import { Box } from '@mui/material'
import splashLogo from 'core/assets/logo-loop.gif'

interface LoadingScreenProps {
  loading: boolean
}

export const LoadingScreen = ({ loading }: LoadingScreenProps) => {
  return (
    <Box
      sx={{
        display: loading ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        bgcolor: 'common.white',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        opacity: loading ? 1 : 0,
        transition: 'opacity 1s ease-in-out',
      }}
    >
      <img src={splashLogo} alt="Loading..." />
    </Box>
  )
}
