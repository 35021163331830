import apiEndpoints from 'api/endpoints'
import { OnError, OnSuccess, useMutate } from 'core/hooks'

export const useDeleteFavouriteItem = (onError: OnError, onSuccess: OnSuccess) => {
  const { isLoading, mutate, isSuccess, error } = useMutate<any>(apiEndpoints.putDeleteFavoriteItem(), {
    onError,
    onSuccess,
  })
  return { isLoggingIn: isLoading, deleteFavouriteItem: mutate, isSuccess, error }
}
