import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { CookieConsent } from 'core/components'
import { APP_SKALO_SERVER } from 'core/consts'
import { NotificationsContextProvider } from 'core/contexts'
import AlertProvider from 'core/contexts/AlertProvider'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'
import 'dayjs/locale/uk'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekday from 'dayjs/plugin/weekday'

import AppRoutes from './AppRoutes'
import AuthProvider from './auth/contexts/AuthProvider'
import { CompanyProvider } from './companies/contexts/CompanyContext'
import QueryWrapper from './core/components/QueryWrapper'
import SettingsProvider from './core/contexts/SettingsProvider'
import SnackbarProvider from './core/contexts/SnackbarProvider'
import usePageTracking from './core/hooks/usePageTracking'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  })
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(relativeTime)

dayjs.updateLocale('pl', {
  months: [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ],
  weekStart: 1,
  // weekdays: [
  //   "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
  // ]
})
// ! it's important to start the week on Monday for ALL languages
dayjs.updateLocale('en', { weekStart: 1 })
dayjs.updateLocale('uk', { weekStart: 1 })

function App() {
  usePageTracking()

  return (
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider>
          <QueryWrapper>
            <AlertProvider>
              <SnackbarProvider>
                <CompanyProvider>
                  <AuthProvider>
                    <NotificationsContextProvider>
                      <AppRoutes />
                      {APP_SKALO_SERVER === 'PROD' && <TawkMessengerReact propertyId="65f406ec9131ed19d97a1fc1" widgetId="1hp0jm2uq" />}
                      <CookieConsent />
                    </NotificationsContextProvider>
                  </AuthProvider>
                </CompanyProvider>
              </SnackbarProvider>
            </AlertProvider>
          </QueryWrapper>
        </SettingsProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
