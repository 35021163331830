import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useDeleteDeliverySite } from 'companies/hooks/useDeleteDeliverySite'
import { TitleWithName } from 'core/components'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { DeliverySite, ManagedDeliverySite } from 'core/types'

interface ConfirmDeleteDialogProps {
  onClose: () => void
  onCallback: (site: DeliverySite | ManagedDeliverySite) => void
  site: DeliverySite | ManagedDeliverySite | null
}

const ConfirmDeleteDeliverySiteDialog = ({ onClose, onCallback, site }: ConfirmDeleteDialogProps) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { deleteSite } = useDeleteDeliverySite(site?.id ?? 0)

  const onConfirm = async () => {
    await deleteSite(
      {},
      {
        onSuccess: () => {
          onCallback(site!)
          snackbar.success(t('confirmDeleteDialog.deleted.success'))
        },
        onError: () => {
          snackbar.error(t('confirmDeleteDialog.deleted.error'))
        },
      },
    )
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <TitleWithName title="confirmDeleteDialog.title" value={site?.name} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('confirmDeleteDialog.message')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} startIcon={<CloseIcon />}>
          {t('confirmDeleteDialog.cancel')}
        </Button>
        <Button onClick={onConfirm} variant="contained" color="error">
          {t('confirmDeleteDialog.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteDeliverySiteDialog
