import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { DeliverySite } from 'core/types'
import { GetParams, PaginatedResult } from 'core/types/paginatedResult'

export const useAllDeliverySites = (params: GetParams) => {
  const { data, isLoading, ...other } = useRequest<PaginatedResult<DeliverySite>>(apiEndpoints.getAllDeliverySitesExtended(params))
  return { isLoadingAllDeliverySites: isLoading, allDeliverySitesData: data, ...other }
}

export const useDeliverySiteDetails = (id: string, _isAdmin?: boolean) => {
  const { data, refetch, ...other } = useRequest<DeliverySite>(apiEndpoints.getDeliverySiteDetails(id), { enabled: !!id })
  return { deliverySiteData: data, refetchDeliverySite: refetch, ...other }
}
