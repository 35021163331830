import { faq } from './faq'
import { goodPractices } from './goodPractices'
import { notification } from './notification'
import translation from './translation.json'

export const messages_en = {
  notification,
  faq,
  goodPractices,
  ...translation,
}
