import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { usePostGenerateVerificationCode } from 'core/hooks/api'

interface CodeVerification {
  phoneNumberId: string
}

export const useCodeVerification = ({ phoneNumberId }: CodeVerification) => {
  const { t } = useTranslation()
  const [loadingVerificationCode, setLoadingVerificationCode] = useState(false)
  const [codeVerificationOpen, setCodeVerificationOpen] = useState(false)
  const snackbar = useSnackbar()

  const postGenerateVerificationCode = usePostGenerateVerificationCode()

  const generateVerificationCode = async (phoneNumber: string) => {
    setLoadingVerificationCode(true)
    await postGenerateVerificationCode.mutate(
      { phoneNumber },
      {
        onSuccess: () => {
          setCodeVerificationOpen(true)
          setLoadingVerificationCode(false)
        },
        onError: () => {
          setCodeVerificationOpen(true)
          setLoadingVerificationCode(false)
          snackbar.error(t('errorCode.codeVerification.generationError'))
        },
      },
    )
  }

  const handleChangePhoneNo = () => {
    setCodeVerificationOpen(false)
    setTimeout(() => {
      document.querySelector<HTMLInputElement>(`#${phoneNumberId}`)?.focus()
    }, 50)
  }

  return {
    codeVerificationOpen,
    generateVerificationCode,
    handleChangePhoneNo,
    loadingVerificationCode,
  }
}
