import { useTranslation } from 'react-i18next'

import { Box, Container, Divider, Typography } from '@mui/material'
import { GeneralRoute } from 'core/consts'

import CustomLink from '../CustomLink'

interface FooterProps {
  linksVisible?: boolean
}

const Footer = ({ linksVisible }: FooterProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Divider sx={{ borderColor: 'grey.300' }} />
      <Container maxWidth="xl">
        <Box
          py={2.8}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
          display="flex"
          justifyContent={linksVisible ? 'space-between' : 'center'}
          alignItems="center"
        >
          <Typography color="text.secondary" variant="body2" sx={{ pt: { xs: 1.6, md: 0 } }}>
            {t('common.copyright', { year: new Date().getFullYear() })}
          </Typography>

          {linksVisible && (
            <Box display="flex" flexWrap="wrap">
              <CustomLink href={`/${GeneralRoute.TERMS_OF_SUBSCRIPTION}`} target="_blank" id="terms-of-subscription-link">
                {t('common.termsOfSubscription')}
              </CustomLink>
              <CustomLink href={`/${GeneralRoute.TERMS_OF_USAGE}`} target="_blank" id="terms-of-usage-link">
                {t('common.termsOfUsage')}
              </CustomLink>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
