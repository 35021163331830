import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { KeyboardDoubleArrowRight } from '@mui/icons-material'
import { Box, Button, Link, Typography } from '@mui/material'
import { useQueries } from '@tanstack/react-query'
import axiosInstance from 'api/axiosInstance'
import apiEndpoints from 'api/endpoints'
import { AxiosResponse } from 'axios'
import { useClients } from 'companies/hooks'
import SpingoLogo from 'core/assets/spingo-logo.jpg'
import { Loader, MainPaper, PaperTitle } from 'core/components'
import { SPINGO_FORM_LINK } from 'core/consts'
import { PaymentLimit } from 'core/types'
import { formatPrice } from 'core/utils'

export const SpingoLimitsList = () => {
  const { t } = useTranslation()
  const { clients, isLoadingClients } = useClients()

  const spingoLimits = useQueries({
    queries: clients
      ? clients.map(c => {
          return {
            queryKey: [apiEndpoints.getSpingoLimit(c.id)],
            queryFn: () => axiosInstance(apiEndpoints.getSpingoLimit(c.id)),
            select: (data: AxiosResponse<PaymentLimit>) => {
              return {
                ...data,
                data: {
                  ...data?.data,
                  clientId: c.id,
                  clientName: c.name,
                },
              }
            },
          }
        })
      : [],
  })

  const isLoadingSpingoLimits = isLoadingClients || spingoLimits.some(el => el.isLoading)

  return (
    <MainPaper sx={{ pb: 4, position: 'relative' }}>
      {isLoadingSpingoLimits && <Loader relative />}
      <PaperTitle title={t('common.creditLimit')} rightEl={<img src={SpingoLogo} height={24} alt="Spingo Logo" />} />
      {clients && clients.length > 0 ? (
        spingoLimits.map(queryResult => {
          const el = queryResult.data?.data
          return (
            el && (
              <Box key={el.clientId} sx={{ display: 'flex', gap: 0.5, flexDirection: 'column', my: 3 }}>
                <Typography variant="h3">{el.clientName}</Typography>
                {el.remainingLimit && el.totalLimit ? (
                  <Typography variant="body1">
                    <Trans
                      i18nKey="payment.remainingLimitSpingo"
                      values={{ totalLimit: formatPrice(el.totalLimit), remainingLimit: formatPrice(el.remainingLimit) }}
                    />
                  </Typography>
                ) : (
                  <Link href={SPINGO_FORM_LINK} target="_blank" variant="subtitle1" color="primary">
                    {t('payment.getFundsNow')}
                  </Link>
                )}
              </Box>
            )
          )
        })
      ) : (
        <Button component={NavLink} to="/restaurants" variant="outlined" sx={{ width: '100%' }} endIcon={<KeyboardDoubleArrowRight />}>
          {t('company.addFirstCompany')}
        </Button>
      )}
    </MainPaper>
  )
}
