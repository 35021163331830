import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Chip } from '@mui/material'
import { MainFilters, MainPaper, MainTable } from 'core/components'
import { adminPricingRulesSortOptions, createPricingRulesFilters } from 'management/helpers'
import { useGetPricingRuleList } from 'management/hooks'

import RuleForm from './RuleForm'
import { mapRulesColumns, mapRulesData } from './mapRules'
import { FiltersState, filtersStateInit, useFilters } from './useFilters'

const RulesManagement = () => {
  const { t } = useTranslation()
  const [filtersState, setFiltersState] = useState<FiltersState>(filtersStateInit)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')

  const sortOptions = useMemo(() => adminPricingRulesSortOptions(t), [t])
  const [sort, setSort] = useState(sortOptions.find(el => el.id === '-validFrom')?.id ?? sortOptions[0].id)

  const { filters } = useFilters()
  const [activeNow, setActiveNow] = useState(true)

  const {
    pricingRuleListData,
    isFetching,
    isError,
    refetch: refetchPricingRuleListData,
  } = useGetPricingRuleList({
    Filters: createPricingRulesFilters(searchTerm, filtersState, activeNow),
    Sorts: sort,
    Page: page + 1,
    PageSize: pageSize,
  })

  const [ruleFormOpen, setRuleFormOpen] = useState(false)

  const onAddRuleOpen = () => {
    setRuleFormOpen(!ruleFormOpen)
  }

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage)
  }, [])
  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize)
  }, [])

  const handleSearch = useCallback((search: string) => {
    setSearchTerm(`(Vendor.Name|DeliverySite.Name|Category.Name|Product.Name)@=*${search}`)
    setPage(0)
  }, [])

  const onSetFiltersState = useCallback((data: any) => {
    setPage(0)
    setFiltersState(data)
  }, [])

  const onSetSort = useCallback((data: string) => {
    setSort(data)
  }, [])

  const clearAllFilters = useCallback(() => {
    setPage(0)
    setFiltersState(filtersStateInit)
    setActiveNow(true)
  }, [])

  return (
    <>
      <Box mb={4}>
        <Button variant="contained" onClick={onAddRuleOpen}>
          {t('platformManagement.action.addRule')}
        </Button>
      </Box>
      <Box position="relative">
        <RuleForm open={ruleFormOpen} onClose={onAddRuleOpen} refetchPricingRuleListData={refetchPricingRuleListData} />
      </Box>
      <MainFilters
        filters={filters}
        handleSearch={handleSearch}
        sort={sort}
        setSort={onSetSort}
        sortOptions={sortOptions}
        filtersState={filtersState}
        setFiltersState={onSetFiltersState}
        clearAllFilters={clearAllFilters}
      />
      <Chip
        label={t('platformManagement.activeNow')}
        onClick={() => setActiveNow(!activeNow)}
        color={activeNow ? 'secondary' : 'default'}
        variant={activeNow ? 'filled' : 'outlined'}
        sx={{ mb: 4 }}
      />
      <MainPaper sx={{ position: 'relative', flex: 1 }}>
        <MainTable
          columns={mapRulesColumns(t)}
          rows={mapRulesData(pricingRuleListData ?? { totalCount: 0, items: [] }, t)}
          count={pricingRuleListData?.totalCount}
          pagination={{
            page: page,
            onPageChange: handlePageChange,
            pageSize,
            onPageSizeChange: handlePageSizeChange,
          }}
          loading={isFetching}
          error={isError}
        />
      </MainPaper>
    </>
  )
}
export default RulesManagement
