import { AllInclusive } from '@mui/icons-material'
import { lightErrorBgr, lightInfoBgr, lightSuccessBgr } from 'core/theme/palette'
import { PricingRuleSimulated, PricingRuleSimulatedRes } from 'core/types'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

type Modification = 'removing' | 'adding' | 'modified'
interface ResultItem extends PricingRuleSimulated {
  modification: Modification
}

export const mapSimulatedData = (data: PricingRuleSimulatedRes | null, t: TFunction) => {
  const result: ResultItem[] = data
    ? Object.keys(data).flatMap((modification: string) =>
        data[modification as keyof PricingRuleSimulatedRes].map((item: PricingRuleSimulated) => ({
          ...item,
          modification: modification as Modification,
        })),
      )
    : []
  const modificationOrder: Record<Modification, number> = {
    removing: 1,
    modified: 2,
    adding: 3,
  }

  result.sort((a, b) => modificationOrder[a.modification] - modificationOrder[b.modification])

  const mappedData = result.map(el => {
    return {
      rowId: el.id + String(el.validFrom) + String(el.validTo),
      data: {
        validFrom: el.validFrom ? (
          <div>
            <p>{dayjs(el.validFrom).format('YYYY-MM-DD')}</p>
            <p>{dayjs(el.validFrom).format('HH:mm')}</p>
          </div>
        ) : (
          'ASAP'
        ),
        validTo: el.validTo ? (
          <div>
            <p>{dayjs(el.validTo).format('YYYY-MM-DD')}</p>
            <p>{dayjs(el.validTo).format('HH:mm')}</p>
          </div>
        ) : (
          <AllInclusive />
        ),
        specialDiscount: el.specialDiscount + '%',
        retroCommission: el.retroCommission + '%',
        margin: el.margin + '%',
        mod: getBgrColor(el, t).text,
      },
      styles: {
        bgcolor: getBgrColor(el, t).color,
      },
    }
  })

  return mappedData
}

export const getBgrColor = (el: ResultItem, t: TFunction) => {
  switch (el.modification) {
    case 'removing':
      return { text: t('platformManagement.ruleStatus.willBeRemoved'), color: lightErrorBgr }

    case 'modified':
      return { text: t('platformManagement.ruleStatus.willBeModified'), color: lightInfoBgr }

    case 'adding':
      return { text: t('platformManagement.ruleStatus.willBeAdded'), color: lightSuccessBgr }

    default:
      return { text: '', color: 'inherit' }
  }
}

export const mapSimulatedColumns = (t: TFunction) => [
  { title: t('platformManagement.validFrom'), id: 'validFrom' },
  { title: t('platformManagement.validTo'), id: 'validTo' },
  { title: t('platformManagement.specialDiscount'), id: 'specialDiscount' },
  { title: t('platformManagement.retroComission'), id: 'retroComission' },
  { title: t('platformManagement.margin'), id: 'margin' },
  { title: t('platformManagement.rule'), id: 'mod' },
]

export const simulatedCellStyles = [
  {
    id: 'validFrom',
    styles: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: 'validTo',
    styles: {
      whiteSpace: 'nowrap',
    },
  },
]
