import { useQueries } from '@tanstack/react-query'
import axiosInstance from 'api/axiosInstance'
import apiEndpoints from 'api/endpoints'
import { OrderStatus } from 'core/consts'

const ordersParams = {
  OrderRole: 0,
  Sorts: '-orderDate',
  Status: OrderStatus.Delivered,
}

const productsParams = {
  Page: 1,
  PageSize: 10,
  Filters: 'Ordered==true',
  Sorts: '-orderCount',
}

export const useDashboard = () => {
  const ordersQueryReq = apiEndpoints.getOrders(ordersParams)
  const productsQueryReq = apiEndpoints.getCatalog(productsParams)

  const [orders, products] = useQueries({
    queries: [
      {
        queryKey: ordersQueryReq.queryKey,
        queryFn: () => axiosInstance(ordersQueryReq),
      },
      {
        queryKey: productsQueryReq.queryKey,
        queryFn: () => axiosInstance(productsQueryReq),
      },
    ],
  })

  return {
    orders,
    products,
    ordersData: orders.data?.data,
    productsData: products.data?.data,
    isSuccess: orders.isSuccess && products.isSuccess,
    isError: orders.isError || products.isError,
    isLoading: orders.isLoading || products.isLoading,
  }
}
