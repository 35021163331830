import { Box, Tooltip, Typography } from '@mui/material'
import { ButtonMenu } from 'core/components'
import { FileType } from 'core/consts'
import { InvoiceList, PaginatedResult } from 'core/types'
import { formatDateAndTime, formatPrice, getPaymentMethodIcon } from 'core/utils'
import { TFunction } from 'i18next'

type Actions = {
  handleDownloadInvoice: (el: InvoiceList, fileType: FileType) => void
}

export const mapInvoicesList = (data: PaginatedResult<InvoiceList>, t: TFunction, actions: Actions) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.invoiceNumber,
      data: {
        invoiceNumber: el.invoiceNumber,
        issueDate: (
          <Tooltip title={formatDateAndTime(el.issueDate, true)}>
            <span>{formatDateAndTime(el.issueDate)}</span>
          </Tooltip>
        ),
        totalAmount: (
          <>
            <Typography variant="h2">{formatPrice(el.netTotal)} </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {formatPrice(el.grossTotal, 'gross')}
            </Typography>
          </>
        ),
        paymentMethod: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {getPaymentMethodIcon(el.paymentMethod)}
            {t(`payment.paymentsMethodVal.${el.paymentMethod}`)}
          </Box>
        ),
        downloadDate: (
          <Box>
            <Typography>
              <Typography component="span" variant="subtitle1" sx={{ color: 'error.main' }} mr={1}>
                PDF
              </Typography>
              {formatDateAndTime(el.downloadDate)}
            </Typography>
            <Typography>
              <Typography component="span" variant="subtitle1" sx={{ color: 'success.main' }} mr={1}>
                CSV
              </Typography>
              {formatDateAndTime(el.attachmentDownloadDate)}
            </Typography>
          </Box>
        ),
        download: (
          <ButtonMenu
            btnTitle={t('invoices.downloadInvoice')}
            items={[
              {
                label: t('invoices.downloadPDF'),
                action: () => actions.handleDownloadInvoice(el, FileType.PDF),
              },
              {
                label: t('invoices.downloadCSV'),
                action: () => actions.handleDownloadInvoice(el, FileType.CSV),
              },
            ]}
          />
        ),
      },
    }
  })
  return mappedData
}

export const mapInvoicesListColumns = (t: TFunction) => [
  { title: t('invoices.name'), id: 'invoiceNumber' },
  { title: t('invoices.dateOfIssue'), id: 'issueDate' },
  { title: t('invoices.totalAmount'), id: 'totalAmount' },
  { title: t('invoices.paymentMethod'), id: 'paymentMethod' },
  { title: t('invoices.downloadDate'), id: 'downloadDate' },
  { title: t('invoices.download'), id: 'download' },
]
